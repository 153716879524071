/**
 * This sevice is for handling Organization info
 * that is not mananged by the Auth server.
 *
 */

// @flow
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { LoggerService } from '../../shared/logger.service';

import { orderBy } from 'lodash';
import moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  baseUrl = '/core-api/api/organization';

  static parameters = [HttpClient, LoggerService];
  constructor(http: HttpClient, loggerService: LoggerService) {
    this.http = http;
    this.loggerService = loggerService;
  }

  getEmailPriority() {
    const promise = new Promise((resolve, reject) => {
      this.http
        .get(`${this.baseUrl}/settings/campaign-priority`)
        .toPromise()
        .then(
          results => {
            var emailPriority = orderBy(results, 'rank', 'asc');
            resolve(emailPriority);
          },
          //Error Handling...
          error => {
            this.loggerService.logMessage('Error getting priority list.', 'error', error);
            console.error('Error getting priority list.', error);
            reject(error);
          }
        );
    });

    return promise;
  }

  updateCampaignPriority(campaignPriorities) {
    return this.http.post(`${this.baseUrl}/settings/campaign-priority`, campaignPriorities);
  }

  getRecommendedSendTimeRestrictions(orgId, restrictSendTimes, timezone) {
    var daysOfTheWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

    const promise = new Promise((resolve, reject) => {
      var params = new HttpParams();
      params = params.append('orgId', orgId);

      this.http
        .get('/api/olap/recommendedSendTimeRestrictions', { params })
        .toPromise()
        .then(
          recommendedSendTimeRestrictions => {
            if(!recommendedSendTimeRestrictions) {
              // If recommendation is not found, dont restrict
              daysOfTheWeek.forEach(day => {
                restrictSendTimes.daysOfTheWeek[day] = true;
              });

              restrictSendTimes.startHour = 0;
              restrictSendTimes.endHour = 23;

              resolve();
            }
            else {
              this.processRecommendedSendTimeRestrictions(restrictSendTimes, recommendedSendTimeRestrictions, timezone).then(() => {
                resolve();
              });
            }
          },
          error => {
            reject(error);
          }
        );
    });
    return promise;
  } //end: getRecommendedSendTimeRestrictions()

  processRecommendedSendTimeRestrictions(restrictSendTimes, recommendedSendTimeRestrictions, timezone) {
    var daysOfTheWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

    const promise = new Promise((resolve) => {
      // set send days
      daysOfTheWeek.forEach(day => {
        restrictSendTimes.daysOfTheWeek[day] = recommendedSendTimeRestrictions[day];
      });

      // set send hours in campaign timezone
      var timeZoneOffset = moment
        .tz(timezone)
        .format('Z')
        .slice(0, 3);

      restrictSendTimes.startHour = parseInt(recommendedSendTimeRestrictions.start_hour_utc) + parseInt(timeZoneOffset);
      restrictSendTimes.endHour = parseInt(recommendedSendTimeRestrictions.end_hour_utc) + parseInt(timeZoneOffset);

      if(restrictSendTimes.startHour > 23) restrictSendTimes.startHour = restrictSendTimes.startHour - 23;
      if(restrictSendTimes.endHour > 23) restrictSendTimes.endHour = restrictSendTimes.endHour - 23;

      if(restrictSendTimes.startHour < 0) restrictSendTimes.startHour = 24 + restrictSendTimes.startHour;
      if(restrictSendTimes.endHour < 0) restrictSendTimes.endHour = 24 + restrictSendTimes.endHour;

      if(restrictSendTimes.startHour > restrictSendTimes.endHour) {
        // Check if all hours are included
        if(restrictSendTimes.endHour + 1 == restrictSendTimes.startHour) {
          restrictSendTimes.startHour = 0;
          restrictSendTimes.endHour = 23;
        }
        else if(23 - restrictSendTimes.startHour > restrictSendTimes.endHour) {
          restrictSendTimes.endHour = 23;
        }
        else {
          restrictSendTimes.startHour = 0;
        }
      }

      restrictSendTimes.startTime = new Date();
      restrictSendTimes.startTime.setHours(restrictSendTimes.startHour, 0, 0, 0);

      restrictSendTimes.endTime = new Date();
      restrictSendTimes.endTime.setHours(restrictSendTimes.endHour, 0, 0, 0);

      resolve();
    });

    return promise;
  }

  getDemoRecommendedSendWindow(restrictSendTimes, timezone) {
    const promise = new Promise((resolve) => {
      this.http
        .get('/assets/demo/global/recommendedSendWindow.json')
        .toPromise()
        .then(
          recommendedSendWindow => {
            console.log('recommendedSendWindow', recommendedSendWindow);
            this.processRecommendedSendTimeRestrictions(
              restrictSendTimes, recommendedSendWindow, timezone
            ).then(() => {
              resolve();
            });
          });
    });

    return promise;
  }
}
