import { Component, ElementRef, HostListener, Input, SimpleChanges, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'viz-scatter-chart',
  template: '<div></div>'
})
export class ScatterChartComponent implements OnInit {
  @Input() data;
  @Input() height;
  @Input() axisValues;
  @Input() visible;

  @HostListener('window:resize', ['$event'])
  onResize() {
    //If not vislble, don't resize.
    if(!(this.htmlElement.offsetParent !== null)) return;

    //event.target.innerWidth;
    this.width = this.htmlElement.parentElement.clientWidth;
    this.marginWidth = this.width - this.margin.left - this.margin.right;

    //append the svg object
    this.svg.attr('width', this.width);

    if(this.data) {
      this.render(this.data);
    }
  }

  margin = { top: 10, left: 30, bottom: 10, right: 30 };

  htmlElement: HTMLElement;
  svg;

  static parameters = [ElementRef];
  constructor(element: ElementRef) {
    this.htmlElement = element.nativeElement;
    this.host = d3.select(element.nativeElement);
  }

  ngOnInit() {
    //Set width to 100% of parent container.
    this.width = this.htmlElement.parentElement.clientWidth;

    //Add margin
    this.marginWidth = this.width - this.margin.left - this.margin.right;
    this.marginHeight = this.height - this.margin.top - this.margin.bottom;

    //append the svg object
    this.host.html('');
    this.svg = this.host
      .append('svg')
      .attr('shape-rendering', 'geometric-precision')
      .attr('height', this.height)
      .attr('width', this.width);

    // Define the div for the tooltip
    this.tooltip = this.host
      .append('div')
      .attr('class', 'scatter-tooltip')
      .style('opacity', 0);

    this.padding = 1;
    this.radius = 2.5;

    this.render(this.data);
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'data' && this.data && this.g) {
        this.render(this.data);
      }
      else if(propName === 'visible') {
        if(this.visible) {
          setTimeout(() => {
            this.onResize();
          });
        }
      }
    }
  }

  render(data) {
    this.svg.selectAll('*').remove();
    if(!data || !data.links) return;

    var sendTime = data.nodes[0].id;

    this.x = d3
      .scalePoint()
      .domain(this.axisValues)
      .range([this.margin.left, this.marginWidth + this.margin.right]);

    var xAxis = d3.axisBottom(this.x);

    //Non-linear to make it fade out faster.
    var opacity = d3
      .scalePow()
      .exponent(0.5)
      .domain([0, 23])
      .range([1, 0.1]);

    this.svg
      .append('g')
      .attr('class', 'axisDelay')
      .attr('transform', `translate(0,${this.marginHeight})`)
      .call(xAxis);

    // Add vertical line to show send time
    this.svg
      .append('line')
      .attr('x1', this.x(sendTime))
      .attr('y1', 0)
      .attr('x2', this.x(sendTime))
      .attr('y2', this.height - this.margin.top - this.margin.bottom)
      .style('stroke-width', 1)
      .style('stroke', '#999')
      .style('fill', 'none');

    var nodes = [];
    var index = 0;

    // Increase value of each dot if open count is high
    var maxOpens = 0;
    data.links.forEach(link => {
      if(link.opens > maxOpens) maxOpens = link.opens;
    });

    var interval = 1;
    if(maxOpens > 50 && maxOpens < 100) {
      interval = 2;
    }
    if(maxOpens > 100 && maxOpens < 500) {
      interval = 5;
    }
    else if(maxOpens >= 500 && maxOpens < 1000) {
      interval = 10;
    }
    else if(maxOpens >= 1000) {
      interval = 100;
    }

    data.links.forEach(link => {
      for(var i = 0; i < link.opens; i = i + interval) {
        nodes.push({
          index,
          source: link.source,
          target: link.target,
          x: this.x(link.target),
          y: this.margin.top + this.marginHeight / 2
        });

        index++;
      }
    });

    var simulation = d3
      .forceSimulation(nodes)
      .force('x', d3.forceX(d => this.x(d.target)).strength(1))
      .force('y', d3.forceY(this.marginHeight / 2))
      .force('collide', d3.forceCollide(3))
      .stop();

    for(var i = 0; i < 150; ++i) simulation.tick();

    this.svg
      .selectAll('circle')
      .data(nodes)
      .enter()
      .append('circle')
      .style('fill', '#0086d6') //bar: #0086d6, heat: #1f2d86
      .attr('cx', d => d.x)
      .attr('cy', d => d.y)
      .attr('r', this.radius)
      .style('opacity', d => {
        var diff = 0;
        var source = this.convertTo24Hour(d.source);
        var target = this.convertTo24Hour(d.target);

        if(target > source) {
          diff = target - source;
        }
        else if(source > target) {
          diff = 23 - (source - target);
        }

        return opacity(diff);
      })
      .on('mouseover', d => {
        this.mouseOver(d);
      })
      .on('mouseout', () => {
        this.mouseOut();
      });
  } //end: render()

  convertTo24Hour(hour) {
    if(hour === '12a') {
      return 0;
    }
    else if(hour === '12p') {
      return 12;
    }
    else if(hour.slice(-1) === 'a') {
      return parseInt(hour.substring(0, hour.length - 1));
    }
    else if(hour.slice(-1) === 'p') {
      return parseInt(hour.substring(0, hour.length - 1)) + 12;
    }
  }

  mouseOver(d) {
    var value = 0;
    this.data.links.forEach(link => {
      if(link.target === d.target) {
        value = link.opens;
      }
    });
    this.tooltip
      .transition()
      .duration(200)
      .style('opacity', 0.9);

    this.tooltip
      .html(`<b>${d.target}</b><br/>Opens: ${value}`)
      .style('left', `${this.x(d.target) - 20}px`)
      .style('top', `${d3.event.layerY - 60}px`);
  }

  mouseOut() {
    this.tooltip
      .transition()
      .duration(500)
      .style('opacity', 0);
  }
}
