import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OrgService } from '../../../components/auth/org.service';

@Component({
  selector: 'performance-filters',
  template: require('./performance-filters.html')
})
export class PerformanceFiltersComponent implements OnInit {
  @Output() action = new EventEmitter(true);

  static parameters = [BsModalRef, OrgService];
  constructor(bsModalRef: BsModalRef, orgService: OrgService) {
    this.bsModalRef = bsModalRef;
    this.orgService = orgService;
  }

  ngOnInit() {
    this.filterName = null;
    if(this.filter) {
      this.startDate = new Date(this.filter.startDate);
      this.endDate = new Date(this.filter.endDate);

      //If the selected filter has a name, set as default.
      if(typeof this.filter.name !== 'undefined' && this.filter.name) {
        this.filterName = this.filter.name;
      }
    }
  }

  updatePerformanceFilters() {
    this.filter.name = this.filterName; //update name

    var found = false;
    this.filters.forEach((f, i) => {
      if(f.name === this.filter.name) {
        found = true;
        this.filters[i] = this.filter;
      }
    });

    if(!found) {
      this.filters.push(this.filter);
    }

    this.orgService.updatePerformanceFilters(this.currentOrg._id, this.filters).toPromise().then(result => {
      console.log('----> updated Performance Filters.', result); //debug
    });
  }

  removeFilter(filter, index) {
    if(confirm(`Are you sure you want to remove this Performance Report filter: ${filter.name}?`)) {
      this.filters.splice(index, 1);
      this.orgService.updatePerformanceFilters(this.currentOrg._id, this.filters).toPromise().then(result => {
        console.log('----> removed Performance Filter.', result); //debug
      });
    }
  }

  ok() {
    if(this.mode === 'save') {
      this.updatePerformanceFilters();
    }

    this.action.emit(true);
    this.bsModalRef.hide();
  }

  cancel() {
    this.action.emit(false);
    this.bsModalRef.hide();
  }
}
