import { Component, ElementRef, HostListener, Input, SimpleChanges, OnInit } from '@angular/core';
import * as d3 from 'd3';
import * as d3Annotation from 'd3-svg-annotation';

@Component({
  selector: 'viz-nested-circles-chart',
  template: '<div></div>'
})
export class NestedCirclesComponent implements OnInit {
  @Input() inner;
  @Input() outer;
  @Input() threshold;
  @Input() height;
  @Input() onclick: (args: any) => void;

  @HostListener('window:resize', ['$event'])
  onResize() {
    //If not vislble, don't resize.
    if(!(this.htmlElement.offsetParent !== null)) return;
    this.width = this.htmlElement.parentElement.clientWidth;

    this.svg.attr('width', this.width);
    this.width = +this.svg.attr('width') - this.margin.left - this.margin.right;

    if(this.inner && this.outer) {
      this.render();
    }
  }

  margin = { top: 20, left: 20, bottom: 20, right: 20 };

  htmlElement: HTMLElement;
  svg;

  static parameters = [ElementRef];
  constructor(element: ElementRef) {
    this.htmlElement = element.nativeElement;
    this.host = d3.select(element.nativeElement);
    this.format = d3.format(',');
  }

  ngOnInit() {
    //Set width to 100% of parent container.
    this.width = this.htmlElement.parentElement.clientWidth;
    if(!this.height) this.height = 425;

    //append the svg object
    this.host.html('');
    this.svg = this.host
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height);

    this.g = this.svg.append('g').attr('transform', `translate(${this.margin.left},${this.margin.top})`);
    this.render();
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'inner' && this.inner || propName === 'outer' && this.outer) {
        this.render();
      }
    }
  }

  render() {
    if(!this.inner || !this.outer || typeof this.inner === 'undefined' || typeof this.outer === 'undefined' || typeof this.svg === 'undefined') return;

    //this.svg.selectAll('.donut-legend').remove();
    this.g.selectAll('*').remove();

    var radius = d3.scaleSqrt()
      .range([0, this.width * 1 / 5 - this.margin.left - this.margin.right])
      .domain([0, this.outer]);

    var cx = this.width * 2 / 3 - this.margin.left;
    var cy = this.height / 2 - this.margin.top;

    //Total
    this.g.append('circle')
      .attr('r', () => radius(this.outer))
      .attr('cx', cx)
      .attr('cy', cy)
      .attr('fill', '#84c2e7');

    //Size
    var innerCircle = this.g.append('circle')
      .attr('r', () => radius(this.inner))
      .attr('cx', cx)
      .attr('cy', cy)
      .attr('fill', '#1f3d4f')
      .attr('class', 'cursor-pointer')
      .style('pointer-events', 'visible');

    innerCircle.on('click', () => {
      this.onclick();
    });

    //Must be added after the click event.
    innerCircle.append('svg:title')
      .text('Click to view details.');

    this.addAnnotations(cx, cy, radius(this.inner), radius(this.outer));
  } //end: render()

  addAnnotations(x, y, innerR, outerR) {
    if(this.annotations) this.annotations.selectAll('*').remove();

    const annotations = [
      {
        note: {
          title: `Dark Pool (${this.format(this.inner)})`,
          label: `Problematic contacts that were sent at least ${this.format(this.threshold)} emails during the selected timeframe but did not open or click.`,
          lineType: 'vertical',
          wrap: 200
        },
        x: x + this.margin.left,
        y: y + this.margin.top,
        nx: 250 + this.margin.left,
        ny: 125 + this.margin.top
      },
      {
        note: {
          title: `Contacts Analyzed (${this.format(this.outer)})`,
          label: 'Total number of contacts with send activity during the selected timeframe.',
          lineType: 'vertical',
          wrap: 200
        },
        connector: { lineType: 'vertical' },
        x: x - outerR + this.margin.left + 15, // / 2 + this.margin.left,
        y: y + outerR / 2, // + this.margin.top,
        nx: 250 + this.margin.left,
        ny: 290 + this.margin.top
      }
    ].map(function(d) {
      d.color = '#4a5255';
      return d;
    });

    const makeAnnotations = d3Annotation
      .annotation()
      .type(d3Annotation.annotationCallout)
      .annotations(annotations);

    this.annotations = this.svg
      .append('g')
      .attr('class', 'annotation-group')
      .call(makeAnnotations);
  }

  /*
  mouseover(item) {
    this.svg.selectAll('.donut-path').style('opacity', 0.2);

    d3.select(`[id='path_${item.name}']`).style('opacity', 1);

    this.tooltip
      .transition()
      .duration(200)
      .style('opacity', 0.9);

    this.tooltip
      .html(`<b>${item.name}</b><br/><b>Total: </b>${item.total}`)
      .style('left', `${d3.event.layerX}px`)
      .style('top', `${d3.event.layerY - 20}px`);
  }

  mouseout() {
    this.svg.selectAll('.donut-path').style('opacity', 1);
    this.tooltip
      .transition()
      .duration(500)
      .style('opacity', 0);
  }
  */
}
