import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AlertModule } from 'ngx-bootstrap/alert';

import { DirectivesModule } from '../../components/directives.module';

import { UpdateFrequencyComponent } from './shared/update-frequency/update-frequency.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    AlertModule.forRoot(),
    DirectivesModule
  ],
  declarations: [UpdateFrequencyComponent],
  entryComponents: [UpdateFrequencyComponent],
  exports: [UpdateFrequencyComponent]
})
export class OrganizationModule {}
