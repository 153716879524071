import { Component, ElementRef, HostListener, Input, SimpleChanges, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'viz-donut-chart',
  template: '<div></div>'
})
export class DonutChartComponent implements OnInit {
  @Input() data;

  @HostListener('window:resize', ['$event'])
  onResize() {
    //If not vislble, don't resize.
    if(!(this.htmlElement.offsetParent !== null)) return;

    //event.target.innerWidth;
    this.width = this.htmlElement.parentElement.clientWidth - this.paddingLeftRight * 2;
    this.height = this.width + 50;
    this.radius = this.width / 2 - 25;

    this.svg.attr('width', this.width);
    this.svg.attr('height', this.height);

    if(this.data) {
      this.render(this.data);
    }
  }

  paddingLeftRight = 50;
  htmlElement: HTMLElement;
  svg;

  static parameters = [ElementRef];
  constructor(element: ElementRef) {
    this.htmlElement = element.nativeElement;
    this.host = d3.select(element.nativeElement);
  }

  ngOnInit() {
    //Set width to 100% of parent container.
    this.width = this.htmlElement.parentElement.clientWidth - this.paddingLeftRight * 2;
    this.height = this.width + 50;
    this.radius = this.width / 2 - 25;

    //append the svg object
    this.host.html('');
    this.svg = this.host
      .append('svg')
      .attr('height', this.height)
      .attr('width', this.width)
      .style('display', 'block')
      .style('margin', 'auto')
      .append('g')
      .attr('transform', `translate(${this.width / 2},${parseFloat(this.height / 2 - 25)})`);

    this.arc = d3
      .arc()
      .outerRadius(this.radius - 10)
      .innerRadius(this.radius / 2);

    this.pie = d3
      .pie()
      .sort(null)
      .value(d => d.total)
      .padAngle(0.01);

    this.g = this.svg.selectAll('.arc');

    this.tooltip = this.host
      .append('div')
      .attr('class', 'donut-chart-tooltip')
      .style('opacity', 0);

    this.legendRectSize = 10;

    this.render(this.data);
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'data' && this.data) {
        this.render(this.data);
      }
    }
  }

  render(data) {
    if(!data || data.length === 0 || typeof this.svg === 'undefined') return;

    this.svg.selectAll('.donut-legend').remove();
    this.g.selectAll('*').remove();

    this.g = this.svg
      .selectAll('.arc')
      .data(this.pie(data.distribution))
      .enter()
      .append('g');

    this.g
      .append('path')
      .attr('d', this.arc)
      .attr('class', 'donut-path')
      .attr('id', d => `path_${d.data.name}`)
      .style('fill', d => d.data.color)
      .on('mouseover', d => this.mouseover(d.data))
      .on('mouseout', () => this.mouseout());

    this.g
      .append('text')
      .attr('transform', d => {
        var _d = this.arc.centroid(d);
        return `translate(${_d})`;
      })
      .attr('dy', '.4em')
      .style('text-anchor', 'middle')
      .style('font-weight', '400')
      .attr('fill', '#fff')
      .text(d => {
        if(d.data.rate * 100 > 5) {
          return `${(d.data.rate * 100).toFixed(1)}%`;
        }
      });

    // Add the legend
    var legend = this.svg
      .selectAll('.legend')
      .data(data.distribution)
      .enter()
      .append('g')
      .attr('class', 'donut-legend')
      .attr('transform', (d, i) => `translate(${i * (this.radius - 20) - this.radius}, ${this.radius + 20})`)
      .on('mouseover', d => this.mouseover(d))
      .on('mouseout', () => this.mouseout());

    legend
      .append('rect')
      .attr('width', this.legendRectSize)
      .attr('height', this.legendRectSize)
      .style('fill', d => d.color)
      .style('stroke', d => d.color);

    legend
      .append('text')
      .attr('x', 15)
      .attr('y', 10)
      .text(d => d.name)
      .style('fill', '#000')
      .style('font-size', '12px');
  } //end: render()

  mouseover(item) {
    this.svg.selectAll('.donut-path').style('opacity', 0.2);

    d3.select(`[id='path_${item.name}']`).style('opacity', 1);

    this.tooltip
      .transition()
      .duration(200)
      .style('opacity', 0.9);

    this.tooltip
      .html(`<b>${item.name}</b><br/><b>Total: </b>${item.total}`)
      .style('left', `${d3.event.layerX}px`)
      .style('top', `${d3.event.layerY - 20}px`);
  }

  mouseout() {
    this.svg.selectAll('.donut-path').style('opacity', 1);
    this.tooltip
      .transition()
      .duration(500)
      .style('opacity', 0);
  }
}
