import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';

import { AuthService } from '../auth/auth.service';
import { ImpersonationService } from '../auth/impersonation.service';

import { DarkPoolService } from '../../app/dark-pool/shared/dark-pool.service';

import { daysBetweenDates, isLoading } from '../util';

@Component({
  selector: 'navbar',
  template: require('./navbar.html')
})
export class NavbarComponent {
  router;
  isAdmin;
  isLoggedIn;
  isInEloqua;
  isCollapsed;
  currentUser = {};

  static parameters = [ActivatedRoute, Router, CookieService, AuthService, ImpersonationService, DarkPoolService];
  constructor(route: ActivatedRoute, router: Router, cookies: CookieService, authService: AuthService, impersonation: ImpersonationService, darkPoolService: DarkPoolService) {
    this.route = route;
    this.router = router;
    this.cookies = cookies;
    this.authService = authService;
    this.impersonation = impersonation;
    this.darkPoolService = darkPoolService;
    this.isLoading = isLoading;

    this.isAdmin = false;
    this.isSpecial = false;
    this.isLoggedIn = false;
    this.isInEloqua = true;
    this.isCollapsed = true;
    this.editionMsg = '';
    this.newVersion = false;
    this.version = this.cookies.get('app-version');

    this.reset();

    this.authService.currentUserChanged.subscribe(user => {
      this.currentUser = user;
      this.reset();

      //We need this, because '/' redirects to '/login'
      if(window.location.pathname === '/login' && this.isLoggedIn) {
        //Angular shite still needs a timeout...  :(
        setTimeout(() => {
          this.router.navigateByUrl('/home'); //always go home when user changes.
        });
      }
    });

    this.authService.currentOrgChanged.subscribe(org => {
      if(typeof org !== 'undefined') {
        this.currentOrg = org;
        this.setEditionMsg();
        this.checkVersion();
      }
    });
  }

  reset() {
    this.authService.isLoggedIn().then(is => {
      this.isLoggedIn = is;
    });
    this.authService.isInEloqua().then(is => {
      this.isInEloqua = is;
    });
    this.authService.isAdmin().then(is => {
      this.isAdmin = is;
    });
    this.authService.getCurrentUser().then(user => {
      this.currentUser = user;
      //Special case for Demo Mode
      this.isSpecial = false;
      if(this.currentUser._id === 869) this.isSpecial = true;
    });
    this.authService.getCurrentOrg().then(org => {
      if(typeof org !== 'undefined') {
        this.currentOrg = org;
        this.setEditionMsg();
        this.checkVersion();

        setInterval(() => {
          this.checkVersion();
        }, 300000); //300000 === 5 minutes
      }
    });
  }

  setEditionMsg() {
    this.editionMsg = '';
    if(typeof this.currentOrg.active !== 'undefined') {
      if(this.currentOrg.active) {
        if(this.currentOrg.subscriptionLevel === 'pilot') {
          //Calculate days remaining...
          var endDate = new Date(this.currentOrg.subscriptionStartDate);
          endDate.setDate(endDate.getDate() + this.currentOrg.subscriptionLength - 1);
          var daysRemaining = daysBetweenDates(new Date(), endDate);
          if(daysRemaining <= 0) {
            this.editionMsg = 'Pilot: Last Day';
          }
          else {
            this.editionMsg = `Pilot: ${daysRemaining} Day${daysRemaining > 1 ? 's' : ''} Remaining`;
          }
        }
      }
      else {
        this.editionMsg = 'Account is disabled';
      }
    }
  }

  checkVersion() {
    this.newVersion = false;
    if(this.version) {
      var versionCheck = this.cookies.get('app-version');
      if(versionCheck !== this.version) {
        this.version = versionCheck;
        this.newVersion = this.version;
      }
    }
  }

  refreshVersion() {
    window.location.reload();
  }

  toggleDemoMode() {
    if(this.impersonation.isDemoMode()) {
      this.impersonation.stopDemoMode();
      this.router.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/active']);
        if(this.isSpecial) this.router.navigate(['/home']);
      });
    }
    else {
      this.impersonation.startDemoMode();
      this.router.navigateByUrl('/admin', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/home']);
      });
    }
  }

  logout() {
    return this.authService.logout().then(() => {
      this.router.navigateByUrl('/login');
      this.reset();
    });
  }
}
