import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { getCacheValue, setCacheValue } from '../../../../components/util.js';
import { orderBy } from 'lodash';
import moment from 'moment-timezone';

@Component({
  selector: 'dark-pool-notification',
  template: require('./dark-pool-notification.html')
})
export class DarkPoolNotificationComponent implements OnInit {
  @Input() currentOrg;
  @Input() messageType;

  ngOnInit() {
    this.newMessages = false;
    this.notifications = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'currentOrg') {
        if(this.currentOrg && typeof this.currentOrg.darkPoolNotifications !== 'undefined' && this.currentOrg.darkPoolNotifications) {
          var today = moment();
          this.notifications = [];
          this.currentOrg.darkPoolNotifications.forEach(notification => {
            if(notification.showMessage && (notification.messageType === 'all' || notification.messageType === this.messageType)) {
              notification.messageDate = new Date(notification.messageDate);

              //Did we already read this message _id?
              var key = `dpNotify::${notification._id}`;
              var msgRead = getCacheValue(key);
              if(!msgRead) {
                var check = moment(notification.messageDate);

                //Only if date is < 30 days old, show new message indicator.
                if(today.diff(check, 'days') < 30) {
                  this.newMessages = true;
                }
              }

              this.notifications.push(notification);
            }
          });

          this.notifications = orderBy(this.notifications, 'messageDate', 'desc');
        }
      }
    }
  }

  onShown() {
    if(this.newMessages) {
      this.notifications.forEach(notification => {
        //When the popover is opened, cached the ids.
        var key = `dpNotify::${notification._id}`;
        var msgRead = getCacheValue(key);
        if(!msgRead) {
          setCacheValue(key, true, 2592000000); //30 days
        }
      });

      this.newMessages = false;
    }
  }
}
