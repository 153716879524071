import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { AuthGuard } from '../../components/auth/auth-guard.service';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { QuillModule } from 'ngx-quill';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { FileUploadModule } from 'ng2-file-upload';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { DirectivesModule } from '../../components/directives.module';

import { HistogramColorComponent } from '../../components/data-viz/histogram-color/histogram-color.component';
import { HorizontalStackedBarComponent } from '../../components/data-viz/horizontal-stacked-bar/horizontal-stacked-bar.component';

import { DarkPoolComponent } from './dark-pool.component';
import { DarkPoolBotDetailsComponent } from './shared/dark-pool-bot-details/dark-pool-bot-details.component';
import { DarkPoolBotDetection } from './shared/dark-pool-bot-detection/dark-pool-bot-detection.component';
import { DarkPoolBouncebacks } from './shared/dark-pool-bouncebacks/dark-pool-bouncebacks.component';
import { DarkPoolCheckContactComponent } from './shared/dark-pool-check-contact/dark-pool-check-contact.component';
import { DarkPoolDomainAnalysis } from './shared/dark-pool-domain-analysis/dark-pool-domain-analysis.component';
import { DarkPoolDomainsToIgnoreComponent } from './shared/dark-pool-domains-to-ignore/dark-pool-domains-to-ignore.component';
import { DarkPoolEmailScanners } from './shared/dark-pool-email-scanners/dark-pool-email-scanners.component';
import { DarkPoolHelpComponent } from './shared/dark-pool-help/dark-pool-help.component';
import { DarkPoolNotificationComponent } from './shared/dark-pool-notification/dark-pool-notification.component';
import { DarkPoolTimeMachine } from './shared/dark-pool-time-machine/dark-pool-time-machine.component';
import { DarkPoolTimePeriodComponent } from './shared/dark-pool-time-period/dark-pool-time-period.component';
import { DarkPoolTitleComponent } from './shared/dark-pool-title/dark-pool-title.component';

const darkPoolRoutes: Routes = [
  {
    path: 'darkpool',
    component: DarkPoolComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forChild(darkPoolRoutes),
    NgxDatatableModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline'],    // toggled buttons
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
          [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ 'font': [] }],
          [{ 'align': [] }],
          ['clean'],
          ['link']
        ]
      }
    }),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ButtonsModule.forRoot(),
    CollapseModule.forRoot(),
    PopoverModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    DirectivesModule,
    FileUploadModule,
    InfiniteScrollModule,
    FormsModule
  ],
  declarations: [
    HistogramColorComponent,
    HorizontalStackedBarComponent,
    DarkPoolComponent,
    DarkPoolBotDetailsComponent,
    DarkPoolBotDetection,
    DarkPoolBouncebacks,
    DarkPoolCheckContactComponent,
    DarkPoolDomainAnalysis,
    DarkPoolDomainsToIgnoreComponent,
    DarkPoolEmailScanners,
    DarkPoolHelpComponent,
    DarkPoolNotificationComponent,
    DarkPoolTimeMachine,
    DarkPoolTimePeriodComponent,
    DarkPoolTitleComponent
  ],
  exports: [
    DarkPoolBotDetection,
    DarkPoolBouncebacks,
    DarkPoolDomainAnalysis,
    DarkPoolEmailScanners,
    DarkPoolNotificationComponent,
    DarkPoolTimeMachine,
    DarkPoolTimePeriodComponent,
    DarkPoolTitleComponent
  ],
  entryComponents: [
    DarkPoolBotDetailsComponent,
    DarkPoolCheckContactComponent,
    DarkPoolDomainsToIgnoreComponent,
    DarkPoolHelpComponent
  ]
})
export class DarkPoolModule {}
