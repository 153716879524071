import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  authService;

  static parameters = [AuthService, HttpClient, Router];
  constructor(authService: AuthService, http: HttpClient, router: Router) {
    this.authService = authService;
    this.http = http;
    this.router = router;
  }

  //TODO: Add role based checks.
  //https://github.com/angular-fullstack/generator-angular-fullstack/issues/2774
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const promise = new Promise(resolve => {
      this.authService.isLoggedIn().then(is => {
        if(!is) {
          //Check if opening the campaign details: '/campaigns/:campaignId'
          const tree = this.router.parseUrl(state.url);
          const group = tree.root.children.primary;
          const segments = group.segments;

          //If opening link to campaign, send campaignId to Eloqua to come back to after login.
          if(segments.length >= 2 && segments[0].path === 'campaigns' && segments[1].path !== 'eloqua') {
            var campaignId = segments[1].path;
            this.http
              .post('/auth/eloqua/redirect', { campaignId })
              .toPromise()
              .then(
                result => {
                  console.log('Successfully updated callback uri', result);
                  this.router.navigate(['/login']);
                  resolve(is);
                },
                //Error Handling...
                error => {
                  console.log('Error updating callback uri', error);
                  this.router.navigate(['/login']);
                  resolve(is);
                }
              );
          }
          else {
            this.router.navigate(['/login']);
            resolve(is);
          }
        }
        else {
          resolve(is);
        }
      });
    });

    return promise;
  }
}
