// @flow
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { BsModalService } from 'ngx-bootstrap/modal';

import { AuthService } from '../../../components/auth/auth.service';
import { OrgService } from '../../../components/auth/org.service';
import { DarkPoolService } from '../../dark-pool/shared/dark-pool.service';
import { WhoService } from '../../who/shared/who.service';
import { CdoDetailsComponent } from '../cdo-details/cdo-details.component';
import { FmPriorityMappingsComponent } from '../fm-priority-mappings/fm-priority-mappings.component';

import { daysBetweenDates } from '../../../components/util';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import { error } from '@angular/compiler/src/util';

@Component({
  selector: 'update-org',
  template: require('./update-org.html')
})
export class UpdateOrgComponent implements OnInit {
  @ViewChild('cdoTmpl', { static: true }) cdoTmpl: TemplateRef<any>;
  @ViewChild('unixDateTmpl', { static: true }) unixDateTmpl: TemplateRef<any>;

  organization = {
    darkPoolSetting: 'off',
    darkPoolNotifications: [],
    generatorBrandVoices: []
  };

  static parameters = [ActivatedRoute, HttpClient, Location, BsModalService, AuthService, OrgService, DarkPoolService, WhoService];
  constructor(route: ActivatedRoute, http: HttpClient, location: Location, modalService: BsModalService, authService: AuthService, orgService: OrgService, darkPoolService: DarkPoolService, whoService: WhoService) {
    this.route = route;
    this.http = http;
    this.location = location;
    this.modalService = modalService;
    this.authService = authService;
    this.orgService = orgService;
    this.darkPoolService = darkPoolService;
    this.whoService = whoService;

    this.today = new Date();
    this.subscriptionLevels = ['pilot', ''];
    this.darkPoolSettings = ['off', 'admin', 'freemium', 'premium'];
    this.darkPoolDatesChanged = false;
    this.updatingDarkPoolSettings = false;
    this.darkPoolTimeRange = {};

    //Setup grid
    this.page = {
      number: 0,
      size: 10,
      sort: {
        field: 'id',
        direction: 'asc'
      },
      total: 0,
      searchString: ''
    };

    this.allowNewFmPriority = false;
    this.newFmPriority = {
      value: 6,
      display: null
    };
  }

  ngOnInit() {
    this.alerts = [];
    this.orgIdSub = this.route.paramMap.subscribe(params => {
      this.orgId = params.get('orgId');
      this.userId = params.get('userId');
      this.orgService.get(this.orgId).subscribe(org => {
        this.organization = org;
        this.orgName = this.organization.name;
        this.pilotCheck();

        if(!this.organization.subscriptionStartDate) {
          this.organization.subscriptionStartDate = this.organization.created_at;
        }

        this.fmConfigDisplay = JSON.stringify(this.organization.fmConfig, null, 4);

        //Allowing renaming FM Priority rules.
        if(this.organization.fmConfig && (typeof this.organization.fmConfig.priorities === 'undefined' || this.organization.fmConfig.priorities === null)) {
          console.log('--- Setting default priorities.'); //debug
          this.organization.fmConfig.priorities = [{
            value: 1,
            display: 'Urgent'
          }, {
            value: 2,
            display: 'High'
          }, {
            value: 3,
            display: 'Medium'
          }, {
            value: 4,
            display: 'Low'
          }, {
            value: 5,
            display: 'Optional'
          }];
        }

        this.newFmPriority.value = this.organization.fmConfig.priorities.length + 1;

        //Hide Stripe purchases, since we aren't using it anymore.
        //this.onlinePurchasesTextArea = JSON.stringify(this.organization.onlinePurchases, null, 4);

        this.pilotStart = new Date(this.organization.subscriptionStartDate);
        this.initDarkPool();
        console.log('time range: ', this.darkPoolTimeRange)

        //Generator Brand Voices init.
        this.brandVoiceCollapsed = [];
        if(!this.organization.generatorBrandVoices) {
          this.organization.generatorBrandVoices = [];
        }
        else {
          this.organization.generatorBrandVoices.forEach((brandVoice, i) => {
            //init collapse
            this.brandVoiceCollapsed[i] = true;
          });
        }
      });

      //Get CDO Defintions
      this.loading = true;
      this.http
        .get(`/eloqua-api/api/export/custom-objects?orgId=${this.orgId}`)
        .toPromise()
        .then(
          customObjectDefs => {
            this.customObjectDefs = customObjectDefs;
            this.customObjectDefsDisplay = JSON.stringify(customObjectDefs, null, 4);

            this.rows = customObjectDefs;

            //Convert Epoch date to JS date.
            this.rows.forEach(item => {
              item.updatedAt = new Date(parseInt(item['updated-at']) * 1000);
            });

            this.page.total = customObjectDefs.length;
            this.loading = false;
          },
          //Error Handling...
          error => {
            console.log('Error getting Custom Object Defs', error);
          }
        );
    });

    this.columns = [
      { prop: 'id', name: 'ID', minWidth: 50, width: 50, maxWidth: 50 },
      { prop: 'name', cellTemplate: this.cdoTmpl, minWidth: 200, width: 300 },
      { prop: 'description', minWidth: 300, width: 400 },
      { prop: 'updatedAt', name: 'Last Update', cellTemplate: this.unixDateTmpl, cellClass: 'rightAlign', headerClass: 'rightAlign', minWidth: 150, width: 150, maxWidth: 150 }
    ];
  } //end: ngOnInit()

  initDarkPool() {
    this.darkPoolLoaded = false;
    this.darkPoolTimeRange = {
      startDate: null,
      endDate: null
    }

    // If org doesn't have darkPoolPeriods, add it.
    if(!this.organization.darkPoolPeriods) {
      this.organization.darkPoolPeriods = [];
    }

    // If org has no darkPoolPeriods, add one.
    if(this.organization.darkPoolPeriods.length <= 0) {
      this.addNewDarkPoolPeriod();
    }

    this.darkPoolService.getDarkPoolTimeRange(this.orgId)
      .then(response => {
        console.log('time range response', response);
        if(response) {
          this.darkPoolTimeRange.startDate = new Date(response.start_date);
          this.darkPoolTimeRange.endDate = new Date(response.end_date);
        }
      })
      .catch(error => {
        console.error('Error getting Dark Pool Time Range', error);
      });

    //Check darkPoolNotifications
    this.messageCollapsed = [];
    if(!this.organization.darkPoolNotifications) {
      this.organization.darkPoolNotifications = [];
    }
    else {
      this.organization.darkPoolNotifications.forEach((notification, i) => {
        notification.messageDate = new Date(notification.messageDate);

        //When we open, set times to noon. We only use the date portion anyway.
        notification.messageDate.setHours(12);
        notification.messageDate.setMinutes(0);
        notification.messageDate.setSeconds(0);

        //init collapse
        this.messageCollapsed[i] = true;
      });
    }
  }

  loadDarkPool() {
    if(!this.darkPoolLoaded) {
    //Setup the threshold chart
    this.darkPoolService.getThresholds(this.orgId, this.darkPoolTimeRange.startDate, this.darkPoolTimeRange.endDate, this.organization)
      .then(data => {
        if(data.length > 0) {
          this.organization.darkPoolPeriods[0].darkPoolData = data[0];
        }
      })
      .catch(e => {
        console.log('Exception getting Dark Pool threshold.', e);
      });
      this.darkPoolLoaded = true;
    }
  }

  filterCustomObjects() {
    const val = this.page.searchString.toLowerCase();
    const temp = this.customObjectDefs.filter(function(d) {
      var name = typeof d.name !== 'undefined' ? d.name : '';
      var description = typeof d.description !== 'undefined' ? d.description : '';
      return name.toLowerCase().indexOf(val) !== -1 || description.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.rows = temp;
    //Go back to the first page
    this.page.number = 0;
  }

  showCdoDetails(id) {
    //Open a modal with CDO details.
    const initialState = {
      orgId: this.orgId,
      cdoId: id
    };
    this.modalService.show(CdoDetailsComponent, { initialState });
  }

  ngOnDestroy() {
    if(this.orgIdSub) this.orgIdSub.unsubscribe();
  }

  goBack() {
    this.location.back();
  }

  darkPoolSettingChanged(setting) {
    this.organization.darkPoolSetting = setting;
  }

  subscriptionLevelChanged(subLevel) {
    this.organization.subscriptionLevel = subLevel;
  }

  pilotCheck() {
    this.pilotMessage = '';
    if(this.organization.active) {
      if(this.organization.subscriptionLevel === 'pilot') {
        var endDate = new Date(this.organization.subscriptionStartDate);
        endDate.setDate(endDate.getDate() + this.organization.subscriptionLength - 1);
        var daysRemaining = daysBetweenDates(new Date(), endDate);
        if(daysRemaining <= 0) {
          this.pilotMessage = 'Last Day of Pilot';
        }
        else {
          this.pilotMessage = `${daysRemaining} Day${daysRemaining > 1 ? 's' : ''} Remaining in Pilot`;
        }
      }
    }
    else {
      this.pilotMessage = 'Account Inactive';
    }
  }

  changePilotStartDate() {
    this.organization.subscriptionStartDate = this.pilotStart.toISOString();
  }

  updateOrg() {
    this.alerts = [];

    this.organization.darkPoolNotifications.forEach(notification => {
      notification.messageDate.setHours(12);
      notification.messageDate.setMinutes(0);
      notification.messageDate.setSeconds(0);
    });

    this.orgService
      .update(this.organization)
      .toPromise()
      .then(
        () => {
          this.alerts.push({ type: 'success', msg: 'Organization successfully updated.' });
          this.authService.currentOrg = this.organization;
          this.fmConfigDisplay = JSON.stringify(this.organization.fmConfig, null, 4);
          this.darkPoolDatesChanged = false;
        },
        //Error Handling...
        error => {
          this.alerts.push({ type: 'warning', msg: 'Error updating organization.' });
          console.log('Error updating organization', error);
        }
      );
  }

  // addNewDarkPoolPeriod() {
  //   var prevQuarter = moment().subtract(1, 'Q');
  //   var startDateString = prevQuarter.startOf('quarter').format('YYYY-MM-DD HH:mm:ss');
  //   var endDateString = prevQuarter.endOf('quarter').format('YYYY-MM-DD HH:mm:ss');

  //   this.organization.darkPoolPeriods.push({
  //     startDate: new Date(startDateString),
  //     endDate: new Date(endDateString),
  //     threshold: 6,
  //     contactExpiryPeriod: 12,
  //     isBouncebackLoaded: true
  //   });
  // }

  // removeDarkPoolPeriod(index) {
  //   if(confirm('Are you sure you want to remove this Dark Pool period?')) {
  //     this.organization.darkPoolPeriods.splice(index, 1);
  //   }
  // }

  updateDarkPoolTimeRange() {
    if(confirm('Are you sure you want to update this organization\'s Dark Pool settings?  (This is a very heavy operation in the database.)')) {
      this.updatingDarkPoolSettings = true;
      //Activate an endpoint to update the Dark Pool settings in the Database.
      this.darkPoolService.updateDarkPoolTimeRange(this.orgId, this.darkPoolTimeRange.startDate, this.darkPoolTimeRange.endDate).then(data => {
        this.updatingDarkPoolSettings = false;
      });
    }
  }

  addDarkPoolNotification() {
    var today = moment();
    var todayString = today.format('YYYY-MM-DD HH:mm:ss');

    this.organization.darkPoolNotifications.push({
      _id: uuidv4(),
      messageDate: new Date(todayString),
      messageType: 'all',
      showMessage: false,
      subject: '',
      message: ''
    });

    this.messageCollapsed[this.organization.darkPoolNotifications.length - 1] = false;
  }

  removeDarkPoolNotification(index) {
    if(confirm('Are you sure you want to remove this Dark Pool notification?')) {
      this.organization.darkPoolNotifications.splice(index, 1);
    }
  }

  addBrandVoice() {
    this.organization.generatorBrandVoices.push({
      _id: uuidv4(),
      show: false,
      name: '',
      description: ''
    });

    this.brandVoiceCollapsed[this.organization.generatorBrandVoices.length - 1] = false;
  }

  removeBrandVoice(index) {
    if(confirm('Are you sure you want to remove this Brand Voice?')) {
      this.organization.generatorBrandVoices.splice(index, 1);
    }
  }  

  addFmPriorityChange() {
    this.alerts = [];
    this.allowNewFmPriority = this.newFmPriority.display !== null && this.newFmPriority.display !== '';
  }

  addFmPriority() {
    this.organization.fmConfig.priorities = [...this.organization.fmConfig.priorities, this.newFmPriority];

    var newIndex = this.newFmPriority.value + 1;
    this.newFmPriority = {
      value: newIndex,
      display: null
    };

    this.allowNewFmPriority = false;
    this.alerts = [];
  }

  updateFmPriorityMappings(priority) {
    const initialState = {
      fmConfig: this.organization.fmConfig,
      priority
    };
    this.modalRef = this.modalService.show(FmPriorityMappingsComponent, { initialState, class: 'modal-lg' });

    //Save after close, if OK pressed.
    var sub = this.modalService.onHidden.subscribe(() => {
      if(this.modalRef.content.okClicked) {
        this.updateOrg();
      }
      if(sub) sub.unsubscribe();
    });
  }

  runStatsQueries() {
    this.loadingOrgStats = true;
    this.alerts = [];
    this.http.get(`/core-api/api/organization/population?impersonate=${this.userId}`)
      .toPromise()
      .then(population => {
        this.eloquaInstancePopulationSize = population.eloquaInstancePopulationSize;
        return this.getActiveContacts();
      })
      .then(() => this.getContactsSentAnEmail())
      .then(() => this.getActiveContactsFromEloquaSegment())
      .catch(error => {
        console.error('Error in runStatsQueries:', error);
      })
      .finally(() => {
        this.loadingOrgStats = false;
      });
  }

  getActiveContactsFromEloquaSegment() {
    return new Promise((resolve, reject) => {
      if (this.organization.activeContactsSegmentId) {
        this.refreshAndGetSegmentCount(this.organization.activeContactsSegmentId, resolve, reject);
      } else {
        this.createAndRefreshSegment(resolve, reject);
      }
    });
  }

  refreshAndGetSegmentCount(segmentId, resolve, reject) {
    this.http.post(`/eloqua-api/api/segment/refresh/${segmentId}?orgId=${this.orgId}`, {})
      .toPromise()
      .then(() => {
        setTimeout(() => {
          this.http.get(`/eloqua-api/api/segment/${segmentId}?orgId=${this.orgId}`)
            .toPromise()
            .then(segmentDetails => {
              console.log('active contacts segment:', segmentDetails);
              this.activeContactsEloquaSegmentCount = segmentDetails.count;
              resolve();
            })
            .catch(error => {
              console.log('Error fetching active contacts segment details:', error);
              this.alerts.push({ type: 'danger', msg: 'Error fetching active contacts segment details.' });
              reject(error);
            });
        }, 5000);
      })
      .catch(error => {
        console.log('Error refreshing active contacts segment:', error);
        this.alerts.push({ type: 'danger', msg: 'Error refreshing active contacts segment. Try refreshing the users token and running again.' });
        reject(error);
      });
  }

  createAndRefreshSegment(resolve, reject) {
    this.http.post(`/eloqua-api/api/segment/active-contacts-segment?orgId=${this.orgId}`, {})
      .toPromise()
      .then(segment => {
        console.log('active contacts segment:', segment.id);
        this.organization.activeContactsSegmentId = parseInt(segment.id);
        this.updateOrg();
        this.refreshAndGetSegmentCount(this.organization.activeContactsSegmentId, resolve, reject);
      })
      .catch(error => {
        console.log('Error creating active contacts segment:', error);
        this.alerts.push({ type: 'danger', msg: 'Error creating active contacts segment. Try refreshing the users token and running again.' });
        reject(error);
      });
  }

  async getActiveContacts() {
    const params = { orgId: this.organization._id };
    const activeContacts = await this.http.get('/api/olap/activeContacts', { params }).toPromise();
    this.activeContactCount = parseInt(activeContacts.count);
  }

  async getContactsSentAnEmail() {
    const params = { orgId: this.organization._id };
    const contactsSentAnEmail = await this.http.get('/api/olap/contactsSentAnEmail', { params }).toPromise();
    this.contactsSentAnEmail = parseInt(contactsSentAnEmail.count);
    this.inactiveContactCount = this.eloquaInstancePopulationSize - this.contactsSentAnEmail;
  }
}
