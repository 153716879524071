// @flow
import { Component, Input, SimpleChanges } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { CampaignService } from '../../../campaign/shared/campaign.service';
import { OrganizationService } from '../../../organization/shared/organization.service';

import { EmailPrioritySelectorComponent } from '../email-priority-selector/email-priority-selector.component';
import { LoggerService } from '../../../shared/logger.service';

@Component({
  selector: 'email-priority',
  template: require('./email-priority.html')
})
export class EmailPriorityComponent {
  @Input() campaign;

  bsModalRef: BsModalRef;

  static parameters = [BsModalService, LoggerService, CampaignService, OrganizationService];
  constructor(modalService: BsModalService, loggerService: LoggerService, campaignService: CampaignService, organizationService: OrganizationService) {
    this.modalService = modalService;
    this.loggerService = loggerService;
    this.campaignService = campaignService;
    this.organizationService = organizationService;
    this.emailPriorities = [];
    this.isCampaignInList = false;
    this.loading = false;
  }

  ngOnInit() {
    this.getEmailPriorities();
  }

  getEmailPriorities() {
    this.loading = true;
    this.organizationService.getEmailPriority().then(
      emailPriority => {
        this.emailPriorities = emailPriority[0];
        this.checkCampaignInList();
        this.loading = false;
      },
      //Error Handling...
      error => {
        console.log('Error getting email priority list', error);
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'campaign') {
        this.checkCampaignInList();
      }
    }
  }

  checkCampaignInList() {
    if(typeof this.campaign !== 'undefined' && typeof this.campaign.campaignId !== 'undefined' && this.emailPriorities.length > 0) {
      if(this.emailPriorities.map(o => o.campaignId).indexOf(this.campaign.campaignId) > 0) {
        this.isCampaignInList = true;
      }
      else {
        this.isCampaignInList = false;
      }
    }
  }

  reorderPriorityList(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.emailPriorities, event.previousIndex, event.currentIndex);
    this.saveCampaignPriorities();
  }

  removeEmail(index) {
    this.emailPriorities.splice(index, 1);
    this.saveCampaignPriorities();
  }

  // Add an emails to the priority list
  addEmailToPriorityList(campaignToAdd) {
    // If campaignToAdd, add that campaign to list, otherwise open campaign selector
    if(campaignToAdd && this.emailPriorities.map(o => o.campaignId).indexOf(campaignToAdd.campaignId) < 0) {
      var addCampaign = campaignToAdd;
      addCampaign.rank = this.emailPriorities.length;
      this.emailPriorities.push(addCampaign);
      this.saveCampaignPriorities();
    }
    else if(!campaignToAdd) {
      // Get campaign options
      this.loading = true;
      this.campaignService
        .active(30, true)
        .toPromise()
        .then(campaigns => {
          this.loading = false;
          // Remove campaigns already in priority list
          var emailOptions = [];
          campaigns.forEach(campaign => {
            if(
              this.emailPriorities
                .map(o => o.campaignId)
                .indexOf(campaign.campaignId) < 0
            ) {
              emailOptions.push(campaign);
            }
          });

          //Open modal to select...
          const initialState = {
            emailOptions,
            emailPriorities: this.emailPriorities
          };
          this.bsModalRef = this.modalService.show(EmailPrioritySelectorComponent, { initialState });
          var sub = this.bsModalRef.content.action.subscribe(save => {
            if(save) {
              this.saveCampaignPriorities();
            }
            if(sub) sub.unsubscribe();
          });
        });
    }
  } //end: addEmailToPriorityList()

  saveCampaignPriorities() {
    var priorityList = [];
    this.emailPriorities.forEach((campaign, i) => {
      //Reset campaign ranks
      campaign.rank = i;

      priorityList.push({
        campaignId: campaign.campaignId,
        rank: campaign.rank
      });
    });

    this.organizationService
      .updateCampaignPriority({ campaignPriorities: priorityList })
      .toPromise()
      .then(
        () => {
          console.log('Successfully updated priority list');
          //this.loggerService.logMessage('Campaign: Successfully updated priority list. n = ' + priorityList.length, 'info'); //debug
          this.checkCampaignInList();
        },
        //Error Handling...
        error => {
          console.log('Error updating campaign priority', error);
          this.loggerService.logMessage(`Error updating campaign priority. n = ${priorityList.length}`, 'info', error);
        }
      );
  } //end: saveCampaignPriorities()

  disableField() {
    if(this.loading) return true;
    if(typeof this.campaign !== 'undefined') {
      if(!this.campaignService.isUpdateable(this.campaign.status)) {
        return true;
      }
    }
    return false;
  }
}
