// @flow
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CampaignDatesService } from './campaign-dates.service';
import { LoggerService } from '../../shared/logger.service';

import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CampaignEmailService {
  baseUrl = '/api/messages';

  static parameters = [HttpClient, CampaignDatesService, LoggerService];
  constructor(http: HttpClient, campaignDatesService: CampaignDatesService, loggerService: LoggerService) {
    this.http = http;
    this.loggerService = loggerService;

    this.campaignDatesService = campaignDatesService;
  }

  buildHeroButtonData(campaign, campaignLocal, results, reportImages, user, whoReport, timeWindows) {
    const promise = new Promise((resolve, reject) => {
      // Get send window days and times
      var sendWindow = {};
      sendWindow.days = this.campaignDatesService.getSendDaysMessage(campaignLocal.restrictSendTimes);

      var startTime = '';
      var endTime = '';

      if(campaignLocal.restrictSendTimes.startHour == 0) {
        startTime = '12:00 AM';
        console.log('startTime', startTime);
      }
      else if(campaignLocal.restrictSendTimes.startHour == 12) {
        startTime = '12:00 PM';
      }
      else if(campaignLocal.restrictSendTimes.startHour < 12) {
        startTime = `${campaignLocal.restrictSendTimes.startHour}:00 AM`;
      }
      else {
        startTime = `${campaignLocal.restrictSendTimes.startHour - 12}:00 PM`;
      }

      if(campaignLocal.restrictSendTimes.endHour == 0) {
        endTime = '12:59 AM';
      }
      else if(campaignLocal.restrictSendTimes.endHour == 12) {
        endTime = '12:59 PM';
      }
      else if(campaignLocal.restrictSendTimes.endHour < 12) {
        endTime = `${campaignLocal.restrictSendTimes.endHour}:59 AM`;
      }
      else {
        endTime = `${campaignLocal.restrictSendTimes.endHour - 12}:59 PM`;
      }

      sendWindow.times = `${startTime} to ${endTime}`;

      //Build top performers
      var topPerformers = [];
      if(results && results.topPerformers) {
        results.topTreatments.forEach(treatment => {
          topPerformers.push({
            name: treatment.name,
            subject: treatment.subject,
            rate: campaign.optimizationCriterion === 'clickthroughs' ? (treatment.expectedValueCtRate * 100).toFixed(1) : (treatment.expectedValueOpenRate * 100).toFixed(1),
            confidence: campaign.optimizationCriterion === 'clickthroughs' ? (treatment.treatmentProbabilityClickthrough * 100).toFixed(1) : (treatment.treatmentProbabilityOpen * 100).toFixed(1)
          });
        });
      }

      //Build top performing contact attributes
      var topPerformingAttributes = [];
      if(whoReport.featureSets.data && whoReport.featureSets.data.length > 0) {
        whoReport.featureSets.data.forEach(attribute => {
          topPerformingAttributes.push({
            field: attribute.field,
            value: attribute.value,
            count: attribute[campaign.optimizationCriterion == 'clickthroughs' ? 'clicks' : 'opens'],
            percentOfPopulation: (attribute[campaign.optimizationCriterion == 'clickthroughs' ? 'clicks' : 'opens'] / results.latest.totals.sends * 100).toFixed(1)
          });
        });
      }
      var topPerformingAttributesSortedBy = whoReport.topFeaturesToggle === 'clickthroughs' ? 'Click' : 'Open';

      var topTimeSlots = [];
      //TODO: add top time slots
      if(timeWindows.sendTimeRateDifference > 0.02 && campaignLocal.topSlots) {
        topTimeSlots = cloneDeep(campaignLocal.topSlots);
        topTimeSlots.reverse();
      }

      // Get treatment details for legend
      var treatments = [];
      results.treatmentDetails.forEach(treatment => {
        if(campaignLocal.resultsToggle === 'all' || treatment.topPerformer) {
          treatments.push({
            name: treatment.name,
            color: treatment.color
          });
        }
      });

      // Get email preview
      var previewEmailData = {
        templateId: 'plugin-hero-button',
        images: reportImages,
        campaign,
        sendWindow,
        totals: {
          deliveries: results.latest.totals.deliveries.toLocaleString('en'),
          opens: results.latest.totals.opens.toLocaleString('en'),
          openRate: (results.latest.totals.openRate * 100).toFixed(1),
          clickthroughs: results.latest.totals.clickthroughs.toLocaleString('en'),
          clickthroughRate: (results.latest.totals.clickthroughRate * 100).toFixed(1),
          totalNumberOfContacts: campaignLocal.totalNumberOfContacts.toLocaleString('en'),
          percentSent: campaignLocal.percentSent,
          numberOfTreatments: campaign.emails.length
        },
        topPerformingAttributes,
        topPerformers,
        topTimeSlots,
        topPerformingAttributesSortedBy,
        showSections: {
          messageTesting: campaign.optimizationCriterion !== 'sendTime',
          sendTimeOptimization: true,
          topPerformingAttributes: !!(topPerformingAttributes && topPerformingAttributes.length > 0),
          overallStats: true
        }
      };

      var response = {
        previewEmailData
      };

      this.getHeroButtonEmailPreview(previewEmailData, user).then(
        preview => {
          response.preview = preview;
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });

    return promise;
  } // End: buildHeroButtonData

  getHeroButtonEmailPreview(data, user) {
    const promise = new Promise((resolve, reject) => {
      var params = {
        imageUris: data.images,
        campaignName: data.campaign.name,
        campaignId: data.campaign.campaignId,
        optimizationCriterion: data.campaign.optimizationCriterion,
        timezone: data.campaign.timezone,
        userName: user.name,
        userEmail: user.email,
        userId: user._id,
        note: data.note,
        sendWindow: data.sendWindow,
        totals: data.totals,
        topPerformers: data.topPerformers,
        topTimeSlots: data.topTimeSlots,
        topPerformingAttributes: data.topPerformingAttributes,
        topPerformingAttributesSortedBy: data.topPerformingAttributesSortedBy,
        showSections: data.showSections,
        treatments: data.treatments
      };

      this.http
        .post(`${this.baseUrl}/previewEmailReport`, params)
        .toPromise()
        .then(
          preview => {
            resolve(preview);
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  sendHeroEmail(emails, data, user) {
    const promise = new Promise((resolve, reject) => {
      var params = {
        emails,
        imageUris: data.images,
        campaignName: data.campaign.name,
        campaignId: data.campaign.campaignId,
        optimizationCriterion: data.campaign.optimizationCriterion,
        timezone: data.campaign.timezone,
        userName: user.name,
        userEmail: user.email,
        userId: user._id,
        note: data.note,
        sendWindow: data.sendWindow,
        totals: data.totals,
        topPerformers: data.topPerformers,
        topTimeSlots: data.topTimeSlots,
        topPerformingAttributes: data.topPerformingAttributes,
        topPerformingAttributesSortedBy: data.topPerformingAttributesSortedBy,
        showSections: data.showSections,
        treatments: data.treatments
      };

      this.http
        .post(`${this.baseUrl}/sendEmailReport`, params)
        .toPromise()
        .then(
          response => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  } //end: sendHeroEmail()

  /**
   * For manually sending the Campaign Error email.
   *
   */
  sendCampaignErrorEmail(campaignId, emails, errorType) {
    const promise = new Promise((resolve, reject) => {
      var params = { campaignId, emails, errorType };
      this.http
        .post(`${this.baseUrl}/sendCampaignError`, params)
        .toPromise()
        .then(
          response => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  /**
   * Send winner found email - for testing
   *
   */
  sendWinnerFoundEmail(campaignId, treatmentId) {
    const promise = new Promise((resolve, reject) => {
      var params = { campaignId, treatmentId };
      this.http
        .post(`${this.baseUrl}/sendWinnerFound`, params)
        .toPromise()
        .then(
          response => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  /**
   * Send Failed to Start email - for testing
   *
   */
  sendFailedToStartEmail(campaignId) {
    const promise = new Promise((resolve, reject) => {
      var params = { campaignId };
      this.http
        .post(`${this.baseUrl}/sendFailedToStart`, params)
        .toPromise()
        .then(
          response => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  /**
   * Send active campaigns email - for testing
   *
   */
  sendActiveCampaignsEmails() {
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(`${this.baseUrl}/sendActiveCampaigns`)
        .toPromise()
        .then(
          response => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
    });
    return promise;
  }
}
