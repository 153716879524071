import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'add-brand-voice',
  template: require('./add-brand-voice.html')
})
export class AddBrandVoiceComponent {
  static parameters = [BsModalRef];
  constructor(bsModalRef: BsModalRef) {
    this.bsModalRef = bsModalRef;

    this.okClicked = false;

    this.newBrandVoice = {
      _id: uuidv4(),
      show: true,
      name: null,
      description: '',
      inputs: []
    };

    this.newInput = {
      name: null,
      url: null
    };

    this.allowNewWorkingInput = false;
    this.readyToGenerate = false;
  }

  inputChanged() {
    this.allowNewWorkingInput = this.newInput.name !== null && this.newInput.url !== null;
    this.readyToGenerate = this.newBrandVoice.inputs.length > 0 && this.newBrandVoice.name !== null;
  }

  addInput() {
    this.newBrandVoice.inputs = [...this.newBrandVoice.inputs, this.newInput];
    this.readyToGenerate = this.newBrandVoice.inputs.length && this.newBrandVoice.name;

    this.newInput = {
      name: null,
      url: null
    };

    this.allowNewWorkingInput = false;
  }

  removeWorkingInput(index) {
    this.newBrandVoice.inputs.splice(index, 1);
    this.newBrandVoice.inputs = [...this.newBrandVoice.inputs];
  }

  generateBrandVoice() {
    this.okClicked = true;
    this.bsModalRef.hide();
  }

  cancel() {
    this.bsModalRef.hide();
  }
}
