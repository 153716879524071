import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'subject-line-analysis-modal',
  template: require('./subject-line-analysis-modal.html')
})
export class SubjectLineAnalysisModalComponent {
  static parameters = [BsModalRef];
  constructor(bsModalRef: BsModalRef) {
    this.bsModalRef = bsModalRef;
  }

  cancel() {
    this.bsModalRef.hide();
  }
}
