// @flow
import { Component, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { AuthService } from '../../../components/auth/auth.service';
import { GeneratorService } from '../shared/generator.service';

@Component({
  selector: 'subject-line-generator',
  template: require('./subject-line-generator.html')
})
export class SubjectLineGeneratorComponent implements OnInit {
  static parameters = [Clipboard, AuthService, GeneratorService];
  constructor(clipboard: Clipboard, authService: AuthService, generatorService: GeneratorService) {
    this.clipboard = clipboard;
    this.authService = authService;
    this.generatorService = generatorService;
  }

  ngOnInit() {
    this.body = null;
    this.loading = false;
    this.lockedContent = [];
    this.generatedContent = [];

    this.options = {
      n: 3,
      brand: null,
      language: 'english',
      tone: null
    };
    this.languages = this.generatorService.getLanguages();
    this.toneSelector = null;

    this.authService.getCurrentOrg().then(org => {
      this.currentOrg = org;
      this.options.brand = this.currentOrg ? this.currentOrg.name : '';
    });

    //If the page is loaded directly.
    this.orgSub = this.authService.currentOrgChanged.subscribe(org => {
      this.currentOrg = org;
      this.options.brand = this.currentOrg.name;
    });
  }

  ngOnDestroy() {
    if(this.orgSub) this.orgSub.unsubscribe();
  }

  generate() {
    //console.log('-----> generate() with options: ', this.options); //debug
    this.loading = true;
    this.generatedContent = [];
    this.generatorService.generateSubjectLineFromBody(this.body, this.options).then(response => {
      this.loading = false;
      //console.log('-----> generateSubjectLineFromBody response = ', response); //debug
      this.generatedContent = response;

      //Loop over and set each to !locked
      this.generatedContent.forEach(obj => {
        obj.locked = false;
        obj.copyMsg = '';
      });
    });
  }

  lockContentOption(content) {
    content.locked = !content.locked;

    if(content.locked) {
      this.lockedContent.push(content);
    }
    else {
      this.lockedContent = this.lockedContent.filter(c => {
        if(c.locked) return c;
      });
    }
  }

  copyContentOption(content) {
    //Copy the url to the clipboard
    this.clipboard.copy(content.message.content);
    content.copyMsg = 'Subject line copied to the clipboard.';

    //Hide msg after a bit
    setTimeout(() => {
      content.copyMsg = '';
    }, 1800);
  }

  toneSelected() {
    this.options.tone = this.toneSelector;
  }
}
