import { Component, Input } from '@angular/core';

import { Campaign } from '../campaign';
import { CampaignService } from '../campaign.service';
import { CampaignChartService } from '../campaign-chart.service';

import { LoggerService } from '../../../shared/logger.service';

@Component({
  selector: 'campaign-message-testing',
  template: require('./campaign-message-testing.html')
})
export class CampaignMessageTestingComponent {
  @Input() campaign: Campaign;
  @Input() campaignLocal;
  @Input() results;

  static parameters = [CampaignService, CampaignChartService, LoggerService];
  constructor(campaignService: CampaignService, campaignChartService: CampaignChartService, loggerService: LoggerService) {
    this.campaignService = campaignService;
    this.campaignChartService = campaignChartService;
    this.loggerService = loggerService;
  }

  ngOnInit() {
    console.log('---------> Init campaign-message-testing:', this.campaign, this.campaignLocal, this.results); //debug

    this.alerts = [];

    this.resultsToggle = 'topPerformer'; // 'topPerformer' | 'all'
    this.rateChartToggle = 'expectedValueOpenRate'; // 'expectedValueOpenRate' \ 'expectedValueCtRate'

    this.sortBy = 'treatmentProbabilityOpen'; // 'treatmentProbabilityOpen' | 'treatmentProbabilityClickthrough'
    this.sortOrder = 'desc'; // 'asc' | 'desc'

    //for the charts
    this.dailySends = null;
    this.dailyRates = null;

    this.hoverId = null;

    this.folderName = null;

    this.updateUi();
    this.displayMessages();
  }

  ngOnChanges() {
    this.alerts = [];
    this.updateUi();
    this.displayMessages();
  }

  updateUi() {
    //Set based on optimizer type.
    if(this.campaign.optimizationCriterion === 'opens' || this.campaign.optimizationCriterion === 'sendTime') {
      this.rateChartToggle = 'expectedValueOpenRate';
      this.sortBy = 'treatmentProbabilityOpen';
    }
    else {
      this.rateChartToggle = 'expectedValueCtRate';
      this.sortBy = 'treatmentProbabilityClickthrough';
    }

    //But if there are no top performers or if it's simple...
    if(!this.results.topPerformers || this.campaignLocal.isSimple) {
      this.resultsToggle = 'all';

      this.sortBy = 'name';
      this.sortOrder = 'asc';
    }

    //Build data structs for charts
    this.dailyRates = this.campaignChartService.convertResultsToDailyPerformance(this.results, this.campaign.optimizationCriterion, this.resultsToggle);
    this.dailySends = this.campaignChartService.convertResultsToDailySends(this.results, this.campaign.optimizationCriterion, this.resultsToggle, this.campaignLocal.isSimple);
  }

  updateResultsToggle() {
    this.dailyRates = this.campaignChartService.convertResultsToDailyPerformance(this.results, this.campaign.optimizationCriterion, this.resultsToggle);
    this.dailySends = this.campaignChartService.convertResultsToDailySends(this.results, this.campaign.optimizationCriterion, this.resultsToggle, this.campaignLocal.isSimple);

    //Toggle on any treatments that were turned off.
    this.results.treatmentDetails.forEach(treatmentDetail => {
      if(!treatmentDetail.showTrend) {
        this.toggleLegend(treatmentDetail);
      }
    });
  }

  updateSort(property) {
    if(this.sortBy !== property) {
      this.sortBy = property;
    }
    else if(this.sortOrder === 'asc') {
      this.sortOrder = 'desc';
    }
    else {
      this.sortOrder = 'asc';
    }
  }

  //Show alert messages explaining the current results
  // and display an intercom message depending on the situation.
  displayMessages() {
    var eventToTrack = null; //for Intercom message.

    if(this.campaign.status === 'completed') {
      eventToTrack = 'viewed-completed-campaign';
    }
    else {
      eventToTrack = 'viewed-report';
    }

    if(!this.results.topPerformers && !this.campaignService.isPossiblyDone(this.campaign.status) && !this.campaignLocal.isSimple) {
      this.addAlert(
        'Motiva AI is working to find the <strong>Top Performing Emails</strong>.',
        'info',
        'Motiva AI is receiving feedback and learning about the different options. It has not made any predictions yet.'
      );
    }

    if(this.results.topPerformers && this.results.treatmentDetails.length > 1 && !this.campaignLocal.isSimple && this.campaign.optimizationCriterion !== 'sendTime') {
      if(this.results.topTreatments[0][this.sortBy] > this.campaign.confidenceThreshold) {
        eventToTrack = 'viewed-results-with-one-winner';
        this.addAlert(
          'With high confidence, Motiva AI found the <strong>Top Performing Email</strong>.',
          'info',
          `Motiva AI found the best performing email out of the ${this.results.treatmentDetails.length} options.`
        );
      }
      else {
        eventToTrack = 'viewed-results-with-multiple-winners';
        this.addAlert(
          `With high confidence, Motiva AI found the <strong>Top ${this.results.topTreatments.length} Performing Emails</strong>.`,
          'info',
          `Motiva AI found the top ${this.results.topTreatments.length} performing emails out of the ${this.results.treatmentDetails.length} options.`
        );
      }
    }

    //Only display the message, if in certain statuses.
    if(
      eventToTrack
      && (this.campaign.status === 'running' || this.campaign.status === 'finishedExperimentNowCollecting' || this.campaign.status === 'completed')
      && this.results.show
      && !this.folderName
    ) {
      this.loggerService.trackEvent(eventToTrack, 2); //only send 1 event
    }
  } //end: displayMessages()

  addAlert(message, type, details) {
    this.alerts.push({ type, msg: message, details });
  }

  legendOver(treatmentId) {
    this.hoverId = treatmentId;
  }

  legendLeave() {
    this.hoverId = null;
  }

  toggleLegend(treatmentDetail) {
    treatmentDetail.showTrend = !treatmentDetail.showTrend;

    //Re-build data structs for charts
    this.dailyRates = this.campaignChartService.convertResultsToDailyPerformance(this.results, this.campaign.optimizationCriterion, this.resultsToggle);
    this.dailySends = this.campaignChartService.convertResultsToDailySends(this.results, this.campaign.optimizationCriterion, this.resultsToggle, this.campaignLocal.isSimple);
  }
}
