// @flow
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { AuthService } from '../../../components/auth/auth.service';
import { LoggerService } from '../../shared/logger.service';

@Injectable({
  providedIn: 'root'
})
export class GeneratorService {
  analyzerBaseUrl = '/analyze';
  generatorBaseUrl = '/api/generator';

  currentOrg = { showSubjectLineAnalyzer: true };

  static parameters = [HttpClient, AuthService, LoggerService];
  constructor(http: HttpClient, authService: AuthService, loggerService: LoggerService) {
    this.http = http;
    this.authService = authService;
    this.loggerService = loggerService;

    this.authService.getCurrentOrg().then(org => {
      if(typeof org !== 'undefined') {
        this.currentOrg = org;
      }
    });

    this.orgSub = this.authService.currentOrgChanged.subscribe(org => {
      if(typeof org !== 'undefined') {
        this.currentOrg = org;
      }
    });
  }

  getLanguages() {
    return [
      { value: 'german', display: 'Deutsch' },
      { value: 'english', display: 'English' },
      { value: 'spanish', display: 'Español' },
      { value: 'french', display: 'Français' },
      { value: 'italian', display: 'Italiano' },
      { value: 'portuguese', display: 'Português' },
      { value: 'japanese', display: '日本語' }
    ];
  }

  analyzeEmailSubjectLine(email, language) {
    if(this.currentOrg.showSubjectLineAnalyzer) {
      var params = { subjectLine: email.subject };
      if(language) {
        params.language = language;
      }
      this.http
        .post(`${this.analyzerBaseUrl}/analyze`, params)
        .toPromise()
        .then(
          response => {
            email.subjectLineAnalysis = this.processSubjectLineAnalysis(response, language);
            email.subjectLineAdviceMessages = this.getSubjectLineAdviceMessages(response, language);
          },
          error => {
            console.log('Error getting subject line analysis:', error);
          }
        );
    }
  }

  analyzeSubjectLine(subject, language) {
    var promise = new Promise((resolve, reject) => {
      var params = { subjectLine: subject };
      if(language) {
        params.language = language;
      }
      this.http
        .post(`${this.analyzerBaseUrl}/analyze`, params)
        .toPromise()
        .then(
          response => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  generateSubjectLineVariations(subject, language) {
    var promise = new Promise((resolve, reject) => {
      var params = { subjectLine: subject };
      if(language) {
        params.language = language;
      }
      this.http
        .post(`${this.analyzerBaseUrl}/generateVariations`, params)
        .toPromise()
        .then(
          response => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  processSubjectLineAnalysis(results, language) {
    var subjectLineAnalysis = results;

    var clarity = 'Clarity';
    var spelling = 'Spelling';
    var engagement = 'Engagement';
    var concision = 'Concision';

    //Handle different languages
    switch (language) {
    case 'french':
      clarity = 'Clarté';
      spelling = 'Orthographe';
      break;

    case 'german':
      clarity = 'Klarheit';
      spelling = 'Rechtschreibung';
      break;

    case 'spanish':
      clarity = 'Claridad';
      spelling = 'Ortografía';
      engagement = 'Interacción';
      concision = 'Concisión';
      break;

    case 'italian':
      clarity = 'Chiarezza';
      spelling = 'Ortografia';
      engagement = 'Fidanzamento';
      concision = 'Concisione';
      break;

    case 'portuguese':
      clarity = 'Clareza';
      spelling = 'Ortografia';
      engagement = 'Noivado';
      concision = 'Concisão';
      break;

    case 'japanese':
      clarity = '明瞭さ';
      spelling = 'つづり';
      engagement = '婚約';
      concision = '簡潔';
      break;
    }

    subjectLineAnalysis.chart = [
      {
        name: clarity,
        key: 'clarity',
        value: 0
      },
      {
        name: spelling,
        key: 'spelling',
        value: 0
      },
      {
        name: 'Spam',
        key: 'spam',
        value: 0
      },
      {
        name: engagement,
        key: 'engagement',
        value: 0
      },
      {
        name: concision,
        key: 'concision',
        value: 0
      }
    ];

    // Calculate grades for chart
    subjectLineAnalysis.chart.forEach(category => {
      category.grade = results.scores[category.key].score;

      switch (category.grade) {
      case 'A':
        category.value = 100;
        break;
      case 'B':
        category.value = 80;
        break;
      case 'C':
        category.value = 60;
        break;
      case 'D':
        category.value = 40;
        break;
      default:
        category.value = 20;
      }
    });

    return subjectLineAnalysis;
  } //end: processSubjectLineAnalysis()

  getSubjectLineAdviceMessages(subjectLineAnalysis, language) {
    var messages = {
      clarity: subjectLineAnalysis.scores.clarity.details,
      spelling: subjectLineAnalysis.scores.spelling.details,
      spam: subjectLineAnalysis.scores.spam.details,
      engagement: subjectLineAnalysis.scores.engagement.details,
      concision: subjectLineAnalysis.scores.concision.details,
      general: []
    };

    //Handle different languages
    var readabilityMessage = 'Readability: ';
    switch (language) {
    case 'french':
      readabilityMessage = 'Lisibilité: ';
      break;

    case 'german':
      readabilityMessage = 'Lesbarkeit: ';
      break;

    case 'spanish':
      readabilityMessage = 'Legibilidad: ';
      break;

    case 'italian':
      readabilityMessage = 'Leggibilità: ';
      break;

    case 'portuguese':
      readabilityMessage = 'Legibilidade: ';
      break;

    case 'japanese':
      readabilityMessage = '可読性: ';
      break;
    }

    readabilityMessage += `<span class="sla-details-general-advice">${subjectLineAnalysis.readability[0]}</span>`;
    messages.general.push(readabilityMessage);

    return messages;
  }

  generateSubjectLineFromBody(body, options) {
    var promise = new Promise((resolve, reject) => {
      var params = options;
      params.body = body;
      this.http
        .post(`${this.generatorBaseUrl}/subject-line`, params)
        .toPromise()
        .then(
          response => {
            resolve(response);
          },
          error => {
            this.loggerService.logMessage('Error generating subject line from body.', 'error', error);
            reject(error);
          }
        );
    });

    return promise;
  }

  generateMessageBodyFromBody(body, options) {
    var promise = new Promise((resolve, reject) => {
      var params = options;
      params.body = body;
      this.http
        .post(`${this.generatorBaseUrl}/message-body`, params)
        .toPromise()
        .then(
          response => {
            resolve(response);
          },
          error => {
            this.loggerService.logMessage('Error generating message body from body.', 'error', error);
            reject(error);
          }
        );
    });

    return promise;
  }

  //Strip html from text
  strip(text) {
    var promise = new Promise((resolve, reject) => {
      var params = { text };
      this.http
        .post(`${this.generatorBaseUrl}/strip`, params)
        .toPromise()
        .then(
          response => {
            resolve(response);
          },
          error => {
            this.loggerService.logMessage('Error stripping html from text.', 'error', error);
            reject(error);
          }
        );
    });

    return promise;
  }

  createBrandVoice(brandVoiceInputs) {
    var promise = new Promise((resolve, reject) => {
      var params = brandVoiceInputs;
      this.http
        .post(`${this.generatorBaseUrl}/create-brand-voice`, params)
        .toPromise()
        .then(
          response => {
            resolve(response);
          },
          error => {
            this.loggerService.logMessage('Error creating Brand Voice.', 'error', error);
            reject(error);
          }
        );
    });

    return promise;
  }
}
