// @flow
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Settings } from './settings';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  settings: Settings = new Settings();

  static parameters = [CookieService];
  constructor(cookieService: CookieService) {
    this.cookies = cookieService;
    this.intercomUpdateInterval = null;

    this.initialize();
  }

  /**
   * Initialize things that only need to be done once.
   *
   */
  initialize() {
    Sentry.init({ dsn: this.settings.sentryDsn });
  }

  /**
   * Set user Context for logging
   *
   * @param {Object}    user - current user
   */
  setUserContext(user) {
    if(user) {
      //Setup Sentry User
      Sentry.configureScope(scope => {
        scope.setUser({
          id: user._id,
          username: user.name,
          email: user.email
        });
      });

      //Setup Intercom
      window.Intercom('boot', {
        app_id: this.settings.intercomAppId,
        name: user.name,
        email: user.email,
        created_at: Math.round(new Date(user.created_at).getTime() / 1000)
      });

      //Setup Heap
      window.heap.identify(user.email.toLowerCase());
      window.heap.addUserProperties({ Name: user.name, Role: user.role, email: user.email.toLowerCase(), OrganizationId: user.organizationId });
    }
    else {
      window.Intercom('shutdown');
      window.heap.resetIdentity();
    }

    //Also add the version to Sentry
    var version = this.cookies.get('app-version');
    if(typeof version !== 'undefined') {
      Sentry.configureScope(scope => {
        scope.setExtra('version', version);
      });
    }
  }

  /**
   * Log a message
   *
   * @param {String}    msg   - message to capture
   * @param {String}    level - 'debug', 'info', 'warning', 'error'
   */
  logMessage(msg, level, response) {
    //default level is error
    if(typeof level === 'undefined') {
      level = 'error';
    }

    //Process response, if passed
    if(typeof response !== 'undefined') {
      if(typeof response.config !== 'undefined' && typeof response.config.headers !== 'undefined') {
        //Remove Authorization info, so the Sentry scrubber doesn't filter the message.
        if(typeof response.config.headers.Authorization !== 'undefined') {
          delete response.config.headers.Authorization;
        }
      }

      if(typeof response.status !== 'undefined' && response.status === 401) {
        //Handle 401 Errors as only informational.
        level = 'info';
        msg = '401 Unauthorized';
      }
    }

    console.log(msg);

    if(typeof response !== 'undefined') {
      console.log(JSON.stringify(response));
    }

    //Log to Sentry
    Sentry.captureMessage(msg, level);
  }

  /**
   * Parse an error response and return details.
   *
   * @param {Object}    response - error repsonse
   * @return {String}            - error details
   */
  parseResponse(response) {
    var details = '';

    if(typeof response !== 'undefined' && response !== null) {
      if(typeof response.status !== 'undefined') {
        details = response.status;
      }

      if(typeof response.statusText !== 'undefined') {
        details += (details !== '' ? ' ' : '') + response.statusText;
      }

      if(typeof response.data !== 'undefined' && response.data && typeof response.data.errors !== 'undefined') {
        details += (details === '' ? '' : '\n') + JSON.stringify(response.data.errors);
      }
    }

    return details;
  }

  /**
   * Get user message - sometimes we should display message from the backend.
   *
   * @param {String}    message  - current message
   * @param {Object}    response - error repsonse
   * @return {String}            - message to display to the user
   */
  userMessage(message, response) {
    var msg = message;

    if(typeof response !== 'undefined' && response !== null) {
      if(typeof response.status !== 'undefined' && response.status === 400 && typeof response.error !== 'undefined') {
        if(typeof response.error.error !== 'undefined') {
          msg = response.error.error;
        }
        else {
          msg = response.error;
        }
      }
    }

    return msg;
  }

  /**
   * Track an event -- currently in Intercom
   *
   */
  trackEvent(eventName, delaySeconds) {
    if(!delaySeconds) delaySeconds = 0;
    setTimeout(() => {
      Intercom('trackEvent', eventName);

      // Update message count in Intercom
      this.intercomUpdateInterval = setInterval(
        () => {
          Intercom('update');
        },
        1200,
        10
      );

      Intercom('onUnreadCountChange', () => {
        clearInterval(this.intercomUpdateInterval);
      });
    }, delaySeconds * 1000);
  }
}
