// @flow
import { Component, Input } from '@angular/core';
import { ngxCsv } from 'ngx-csv/ngx-csv';

import { AuthService } from '../../../../components/auth/auth.service';
import { ImpersonationService } from '../../../../components/auth/impersonation.service';

import { Campaign } from '../campaign';
import { CampaignService } from '../campaign.service';
import { CampaignChartService } from '../campaign-chart.service';

@Component({
  selector: 'campaign-ops-view',
  template: require('./campaign-ops-view.html')
})
export class CampaignOpsViewComponent {
  @Input() campaign: Campaign;
  @Input() campaignLocal;
  @Input() results;

  ngxCsv: ngxCsv;

  static parameters = [AuthService, ImpersonationService, CampaignService, CampaignChartService];
  constructor(authService: AuthService, impersonation: ImpersonationService, campaignService: CampaignService, campaignChartService: CampaignChartService) {
    this.authService = authService;
    this.impersonation = impersonation;
    this.campaignService = campaignService;
    this.campaignChartService = campaignChartService;
    this.isAdmin = false;
  }

  ngOnInit() {
    this.authService.isAdmin().then(is => {
      this.isAdmin = is;
    });

    if(this.campaign.optimizationCriterion === 'opens' || this.campaign.optimizationCriterion === 'sendTime') {
      this.overviewChartToggle = 'opens';
    }
    else {
      this.overviewChartToggle = 'clickthroughs';
    }

    this.updateOverviewChart();
    this.updateDeliveredChart();
    this.updateDistributionChart();

    this.isOpenDetails = false;
    this.isOpenSchedule = false;
    this.isOpenEloquaNotify = false;

    this.sortBy = 'name';
    this.sortOrder = 'asc';

    this.schedule = []; //TODO: figure out how to get schedule -- pass it in? query here?

    this.updateEloquaNotifications();
  } //end: ngOnInit()

  ngOnChanges() {
    this.updateOverviewChart();
    this.updateDeliveredChart();
    this.updateDistributionChart();
    this.updateEloquaNotifications();
  }

  updateSort(property) {
    if(this.sortBy !== property) {
      this.sortBy = property;
    }
    else if(this.sortOrder === 'asc') {
      this.sortOrder = 'desc';
    }
    else {
      this.sortOrder = 'asc';
    }
  }

  updateOverviewChart() {
    this.cumulativeActions = this.campaignChartService.convertResultsToCumulativeValues(this.results, this.overviewChartToggle);
  }

  updateDeliveredChart() {
    this.cumulativeDelivered = this.campaignChartService.convertResultsToCumulativeValues(this.results, 'totalDelivered');
  }

  updateDistributionChart() {
    this.cumulativeDistribution = this.campaignChartService.convertResultsToCumulativeValues(this.results, 'distribution');
  }

  updateEloquaNotifications() {
    this.eloquaNotifications = [];
    //Separate eloqua notifications by day
    if(this.campaign.recentActionServiceNotifyCount && this.campaign.recentActionServiceNotifyCount.length > 0) {
      //sort notifications by timestamp
      this.campaign.recentActionServiceNotifyCount.sort((a, b) => {
        var dateA = new Date(a.timestamp);
        var dateB = new Date(b.timestamp);
        if(dateA.getTime() < dateB.getTime()) {
          return -1;
        }
        if(dateA.getTime() > dateB.getTime()) {
          return 1;
        }
        return 0;
      });

      var defaultOpen = this.campaign.recentActionServiceNotifyCount.length < 20;
      var currentDate = new Date(this.campaign.recentActionServiceNotifyCount[0].timestamp);
      var dayCount = 0;
      this.eloquaNotifications = [
        {
          date: currentDate,
          notifications: [],
          isOpen: defaultOpen
        }
      ];
      this.campaign.recentActionServiceNotifyCount.forEach(notification => {
        var date = new Date(notification.timestamp);
        if(date.getDate() === currentDate.getDate()) {
          this.eloquaNotifications[dayCount].notifications.push(notification);
        }
        else {
          currentDate = date;
          dayCount++;
          this.eloquaNotifications[dayCount] = {
            date: currentDate,
            notifications: [notification],
            isOpen: defaultOpen
          };
        }
      });
    }
  }

  exportData() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      noDownload: false,
      headers: this.campaignService.getExportHeaders()
    };

    var date = new Date().toLocaleDateString('en-US');
    var filename = `${this.campaign.name} on ${date}`;
    var exportData = this.campaignService.getExportData(this.results.treatmentDetails);

    new ngxCsv(exportData, filename, options);
  }
}
