import { Component, ElementRef, HostListener, Input, SimpleChanges, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'viz-heatmap-calendar',
  template: '<div></div>'
})
export class HeatmapCalendarComponent implements OnInit {
  @Input() data;

  @HostListener('window:resize', ['$event'])
  onResize() {
    //If not vislble, don't resize.
    if(!(this.htmlElement.offsetParent !== null)) return;

    if(this.data) {
      this.render(this.data);
    }
  }

  htmlElement: HTMLElement;
  svg;

  static parameters = [ElementRef];
  constructor(element: ElementRef) {
    this.htmlElement = element.nativeElement;
    this.host = d3.select(element.nativeElement);
  }

  ngOnInit() {
    //Set this.width to 100% of parent container with a max.
    this.width = this.htmlElement.parentElement.clientWidth;
    this.height = this.height ? this.height : 150;

    //append the svg object
    this.host.html('');
    this.svg = this.host
      .append('svg')
      .attr('height', this.height)
      .attr('width', this.width)
      .attr('font-family', 'sans-serif')
      .attr('font-size', 12);

    this.chart = this.svg
      .append('g')
      .attr('width', this.width)
      .attr('height', this.height);

    this.tooltip = this.host
      .append('div')
      .attr('class', 'chart-tooltip')
      .style('opacity', 0);

    //Helpers
    this.paddingLeft = 55;
    this.paddingBetweenYears = 25;
    this.cellSize = parseInt(this.width / 60);
    this.weekday = 'monday'; //starting day of the week -- sunday or monday
    this.countDay = this.weekday === 'sunday' ? i => i : i => (i + 6) % 7;
    this.formatDay = (i) => 'SMTWTFS'[i];
    this.formatMonth = d3.utcFormat('%b');
    this.formatDate = d3.utcFormat('%x');
    this.formatValue = d3.format(',');
    this.timeWeek = this.weekday === 'sunday' ? d3.utcSunday : d3.utcMonday;

    this.render(this.data);
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'data' && this.data && this.chart) {
        this.render(this.data);
      }
    }
  }

  render(data) {
    this.chart.selectAll('*').remove();
    if(!this.data || this.data.length === 0) return;

    var newWidth = this.htmlElement.parentElement.clientWidth;
    this.cellSize = parseInt(newWidth / 60);

    this.height = this.cellSize * 7 + 20; //height of one year
    var newHeight = this.height * data.length + this.paddingBetweenYears * data.length; //height of all years

    this.svg.attr('width', newWidth);
    this.svg.attr('height', newHeight);

    //Find max value
    var max = 0;
    data.forEach(datum => {
      var tmpMax = d3.max(datum[1], d => d.count);
      if(tmpMax > max) max = tmpMax;

      //Convert text dates to js Date object.
      datum[1].forEach(o => {
        o.date = new Date(o.date);
      });
    });

    const color = d3.scaleLinear()
      .range(['#ffffdd', '#3e9583', '#1f2d86'])
      .domain([0, max / 2, max]);

    const year = this.chart.selectAll('g')
      .data(data)
      .join('g')
      .attr('transform', (d, i) => `translate(${this.paddingLeft},${this.height * i + this.cellSize * 1.5 + this.paddingBetweenYears * i})`);

    year.append('text')
      .attr('x', -5)
      .attr('y', -5)
      .attr('font-weight', 'bold')
      .attr('text-anchor', 'end')
      .text(([key]) => key);

    year.append('g')
      .attr('text-anchor', 'end')
      .selectAll('text')
      .data(d3.range(7))
      .join('text')
      .attr('x', -5)
      .attr('y', i => (this.countDay(i) + 0.5) * this.cellSize)
      .attr('dy', '0.31em')
      .text(this.formatDay);

    year.append('g')
      .selectAll('rect')
      .data(([, values]) => values)
      .join('rect')
      .attr('class', 'heatmap-box heatmapEntry')
      .attr('width', this.cellSize - 1)
      .attr('height', this.cellSize - 1)
      .attr('x', d => this.timeWeek.count(d3.utcYear(d.date), d.date) * this.cellSize + 0.5)
      .attr('y', d => this.countDay(d.date.getUTCDay()) * this.cellSize + 0.5)
      .attr('fill', d => color(d.count))
      .on('mouseover', d => {
        this.mouseOver(d);
      })
      .on('mouseout', () => {
        this.mouseOut();
      });

    const month = year.append('g')
      .selectAll('g')
      .data(([, values]) => d3.utcMonths(d3.utcMonth(values[0].date), values[values.length - 1].date))
      .join('g');

    month.filter((d, i) => i).append('path')
      .attr('fill', 'none')
      .attr('stroke', '#ddd')
      .attr('stroke-width', 3)
      .attr('d', this.pathMonth);

    month.append('text')
      .attr('x', d => this.timeWeek.count(d3.utcYear(d), this.timeWeek.ceil(d)) * this.cellSize + 2)
      .attr('y', -5)
      .text(this.formatMonth);
  } //end: render()

  pathMonth = (t) => {
    const n = 7;
    const d = Math.max(0, Math.min(n, this.countDay(t.getUTCDay())));
    const w = this.timeWeek.count(d3.utcYear(t), t);
    return `${d === 0 ? `M${w * this.cellSize},0`
      : d === n ? `M${(w + 1) * this.cellSize},0`
        : `M${(w + 1) * this.cellSize},0V${d * this.cellSize}H${w * this.cellSize}`}V${n * this.cellSize}`;
  }

  mouseOver(d) {
    this.tooltip
      .transition()
      .duration(1500)
      .style('opacity', 0.9);

    var html = `<div style="text-align:center">${this.formatDate(d.date)}<br/><b>${this.formatValue(d.count)}</b></div>`;

    this.tooltip
      .html(html)
      .style('left', `${d3.event.layerX + 20}px`)
      .style('top', `${d3.event.layerY - 28}px`);
  }

  mouseOut() {
    this.tooltip
      .transition()
      .duration(10)
      .style('opacity', 0);
  }
}
