import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { QuillModule } from 'ngx-quill';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { DirectivesModule } from '../../components/directives.module';

import { GlobalWhoComponent } from './global-who/global-who.component';
import { ContactAttributeSelectorComponent } from './shared/contact-attribute-selector/contact-attribute-selector.component';
import { PersonaDefinitionComponent } from './shared/persona-definition/persona-definition.component';
import { RemoveAttributeComponent } from './shared/persona-definition/remove-attribute/remove-attribute.component';
import { DeletePersonaComponent } from './shared/persona-definition/delete-persona/delete-persona.component';
import { PersonaReportComponent } from './shared/persona-report/persona-report.component';
import { PersonaVoiceComponent } from './shared/persona-voice/persona-voice.component';

const whoRoutes: Routes = [
  {
    path: 'who',
    component: GlobalWhoComponent
  }
];

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forChild(whoRoutes),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    CollapseModule.forRoot(),
    PopoverModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    DirectivesModule,
    FormsModule,
    InfiniteScrollModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline'/*, 'strike'*/],    // toggled buttons
          //['blockquote', 'code-block'],
          //[{ 'header': 1 }, { 'header': 2 }],             // custom button values
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          //[{ 'script': 'sub'}, { 'script': 'super' }],    // superscript/subscript
          [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
          //[{ 'direction': 'rtl' }],                       // text direction
          [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          //[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          //[{ 'color': [] }, { 'background': [] }],        // dropdown with defaults from theme
          [{ 'font': [] }],
          [{ 'align': [] }],
          ['clean'],                                        // remove formatting button
          ['link'/*, 'image', 'video'*/]                    // link and image, video
        ]
      }
    })
  ],
  declarations: [
    GlobalWhoComponent,
    ContactAttributeSelectorComponent,
    PersonaDefinitionComponent,
    RemoveAttributeComponent,
    PersonaReportComponent,
    DeletePersonaComponent,
    PersonaVoiceComponent
  ],
  entryComponents: [
    ContactAttributeSelectorComponent,
    PersonaDefinitionComponent,
    RemoveAttributeComponent,
    PersonaReportComponent,
    DeletePersonaComponent,
    PersonaVoiceComponent
  ]
})
export class WhoModule {}
