// @flow
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../../../components/auth/auth.service';
import { ImpersonationService } from '../../../../components/auth/impersonation.service';

import { DarkPoolService } from '../dark-pool.service';

@Component({
  selector: 'dark-pool-home',
  template: require('./dark-pool-home.html')
})
export class DarkPoolHomeComponent implements OnInit {
  currentOrg;

  static parameters = [Router, AuthService, ImpersonationService, DarkPoolService];
  constructor(router: Router, authService: AuthService, impersonation: ImpersonationService, darkPoolService: DarkPoolService) {
    this.router = router;
    this.authService = authService;
    this.impersonation = impersonation;
    this.darkPoolService = darkPoolService;

    this.loading = false;
    this.currentOrg = { darkPoolSetting: 'off' };
    this.data = {};
    this.settings = {};
  } //end: ctor

  ngOnInit() {
    this.isDemo = this.impersonation.isDemoMode();
    this.isAdmin = false;
    this.authService.isAdmin().then(is => {
      this.isAdmin = is;
    });

    this.authService.getCurrentOrg().then(org => {
      if(typeof org !== 'undefined') {
        this.currentOrg = org;
        this.init();
      }
    });

    this.orgSub = this.authService.currentOrgChanged.subscribe(org => {
      //Make sure we have an org and it acutally changed.
      if(typeof org !== 'undefined' && org._id !== this.currentOrg._id) {
        this.currentOrg = org;
        this.init();
      }
    });
  }

  init() {
    //get the most recent dark pool settings from the current Org.
    this.settings = this.darkPoolService.getLatestDarkPoolSettings(this.currentOrg);
    this.displayThreshold = this.settings.threshold;

    //Set threshold for display.
    if(this.darkPoolService.tempSettings) {
      this.displayThreshold = this.darkPoolService.tempSettings.threshold;
    }

    if(this.isDemo) {
      this.darkPoolService.getDemoData('summary').then(data => {
        this.data = data;
      });
      return;
    }

    //get the summary data.
    this.loading = true;
    this.darkPoolService.getDarkPoolTimeRange(this.currentOrg._id).then(timeRange => {
      this.darkPoolService.getSummaryData(timeRange.start_date, timeRange.end_date).then(data => {
        this.data = data;
        this.loading = false;
      });
    });
  }

  ngOnDestroy() {
    if(this.orgSub) this.orgSub.unsubscribe();
  }

  details = () => {
    this.router.navigate(['/darkpool']);
  }
}
