import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RouterModule } from '@angular/router';

import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { OauthButtonsComponent } from './oauth-buttons/oauth-buttons.component';
import { FilterSelectorComponent } from './filter-selector/filter-selector.component';

import { EmailPriorityComponent } from '../app/frequency/shared/email-priority/email-priority.component';
import { EmailPrioritySelectorComponent } from '../app/frequency/shared/email-priority-selector/email-priority-selector.component';
import { SegmentSelectorComponent } from '../app/shared/segment-selector/segment-selector.component';

import { DotMatrixComponent } from './data-viz/dot-matrix-chart/dot-matrix-chart.component';
import { FractalForestComponent } from './data-viz/fractal-forest/fractal-forest.component';
import { FunnelChartComponent } from './data-viz/funnel-chart/funnel-chart.component';
import { GaugeSemicircleBarComponent } from './data-viz/gauge-semicircle/gauge-semicircle.component';
import { HeatmapComponent } from './data-viz/heatmap/heatmap.component';
import { HeatmapCalendarComponent } from './data-viz/heatmap-calendar/heatmap-calendar.component';
import { HistogramComponent } from './data-viz/histogram/histogram.component';
import { RadarChartComponent } from './data-viz/radar-chart/radar-chart.component';
import { WhoReportComponent } from './data-viz/who-report/who-report.component';

import { IsVisibleDirective } from './is-visible.directive';
import { TabOrderDirective } from './tab-order.directive';
import { OrderByPipe } from './order-by.pipe.js';

@NgModule({
  imports: [
    CommonModule,
    AlertModule.forRoot(),
    BsDropdownModule.forRoot(),
    ButtonsModule.forRoot(),
    CollapseModule.forRoot(),
    PopoverModule.forRoot(),
    RouterModule,
    BrowserModule,
    FormsModule,
    DragDropModule,
    InfiniteScrollModule
  ],
  declarations: [
    NavbarComponent,
    FooterComponent,
    OauthButtonsComponent,
    IsVisibleDirective,
    TabOrderDirective,
    OrderByPipe,
    EmailPriorityComponent,
    EmailPrioritySelectorComponent,
    SegmentSelectorComponent,
    FilterSelectorComponent,
    DotMatrixComponent,
    FractalForestComponent,
    FunnelChartComponent,
    GaugeSemicircleBarComponent,
    HeatmapComponent,
    HeatmapCalendarComponent,
    HistogramComponent,
    RadarChartComponent,
    WhoReportComponent
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    OauthButtonsComponent,
    IsVisibleDirective,
    TabOrderDirective,
    OrderByPipe,
    EmailPriorityComponent,
    EmailPrioritySelectorComponent,
    SegmentSelectorComponent,
    FilterSelectorComponent,
    DotMatrixComponent,
    FractalForestComponent,
    FunnelChartComponent,
    GaugeSemicircleBarComponent,
    HeatmapComponent,
    HeatmapCalendarComponent,
    HistogramComponent,
    RadarChartComponent,
    WhoReportComponent
  ],
  entryComponents: [
    FilterSelectorComponent,
    EmailPrioritySelectorComponent,
    SegmentSelectorComponent
  ]
})
export class DirectivesModule {}
