import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'performance-benchmark',
  template: require('./performance-benchmark.html')
})
export class PerformanceBenchmarkComponent implements OnInit {
  @Output() action = new EventEmitter(true);

  static parameters = [BsModalRef];
  constructor(bsModalRef: BsModalRef) {
    this.bsModalRef = bsModalRef;
  }

  ngOnInit() {
    if(this.benchmark) {
      this.benchmark.openRate = this.benchmark.openRate * 100;
      this.benchmark.clickRate = this.benchmark.clickRate * 100;
      this.benchmark.clickToOpenRate = this.benchmark.clickToOpenRate * 100;
      this.benchmark.unsubRate = this.benchmark.unsubRate * 100;
    }
  }

  ok() {
    if(this.benchmark) {
      this.benchmark.openRate = this.benchmark.openRate / 100;
      this.benchmark.clickRate = this.benchmark.clickRate / 100;
      this.benchmark.clickToOpenRate = this.benchmark.clickToOpenRate / 100;
      this.benchmark.unsubRate = this.benchmark.unsubRate / 100;
    }

    this.action.emit(true);
    this.bsModalRef.hide();
  }

  cancel() {
    this.action.emit(false);
    this.bsModalRef.hide();
  }
}
