/*eslint no-process-env:0*/
export const env = process.env.NODE_ENV;
export const port = process.env.PORT || 9000;
export const s3Bucket = process.env.BOUNCEBACK_UPLOADS_S3_BUCKET || 'motiva-bouncebacks-uploads-dev';

export const userRoles = ['guest', 'viewer', 'user', 'admin']; // List of user roles

export const pilotContactLimit = 10000;
export const systemContactLimit = 500000;

export default {
  env,
  port,
  userRoles,
  pilotContactLimit,
  systemContactLimit
};
