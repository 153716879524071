// @flow
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { cloneDeep, isEqual } from 'lodash';

import { AuthService } from '../../../../components/auth/auth.service';
import { CampaignService } from '../../shared/campaign.service';
import { LoggerService } from '../../../shared/logger.service';

import { UpdateFrequencyComponent } from '../../../organization/shared/update-frequency/update-frequency.component';

import { startLoading, stopLoading } from '../../../../components/util';

@Component({
  selector: 'decision-service-frequency-management',
  template: require('./decision-service-frequency-management.html')
})
export class EloquaDecisionServiceFrequencyManagementComponent implements OnInit {
  static parameters = [ActivatedRoute, HttpClient, BsModalService, AuthService, CampaignService, LoggerService];
  constructor(route: ActivatedRoute, http: HttpClient, modalService: BsModalService, authService: AuthService, campaignService: CampaignService, loggerService: LoggerService) {
    this.http = http;
    this.route = route;
    this.modalService = modalService;
    this.authService = authService;
    this.loggerService = loggerService;
    this.campaignService = campaignService;

    this.saving = false;
    this.currentUser = null;
    this.currentOrg = {
      fmConfig: {
        rules: [],
        orgFmRule: {
          emailsPerTimePeriod: 1,
          timePeriodDays: 0
        }
      }
    };

    this.missingEloquaCampaignId = false;
    this.eloquaCampaignStatus = 'active';

    //Units for fmRetryWindow
    this.units = ['hours', 'days', 'weeks', 'months'];

    //Values for fmPriority
    this.priorities = [{
      value: 1,
      display: 'No FM Priority'
    }];

    //Init an FM decision entity.
    this.decision = {
      status: 'draft',
      dsType: 'frequencyManagement',
      config: {
        selectedFrequencyManagementRuleId: null,
        fmRetryWindow: {
          value: 7,
          unit: 'days'
        },
        fmPriority: null,
        autoSelectedFmPriority: null
      }
    };
    this.fmPriorityAutoSelected = false;
  }

  ngOnInit() {
    this.authService.getCurrentUser().then(user => {
      this.currentUser = user;
    });

    this.authService.getCurrentOrg().then(org => {
      this.currentOrg = org;
      this.authService.checkFmRules(this.currentOrg);

      //Let org's overwrite the default priorities.
      if(typeof this.currentOrg.fmConfig !== 'undefined' && this.currentOrg.fmConfig && this.currentOrg.fmConfig.priorities && typeof this.currentOrg.fmConfig.priorities !== 'undefined') {
        this.priorities = this.currentOrg.fmConfig.priorities;
        this.priorities.sort((a, b) => a.display > b.display ? 1 : b.display > a.display ? -1 : 0);
      }
    });

    //In case the currentOrg isn't set when the UI loads.
    this.orgSub = this.authService.currentOrgChanged.subscribe(org => {
      if(typeof org !== 'undefined') {
        this.currentOrg = org;
        this.authService.checkFmRules(this.currentOrg);

        //Let org's overwrite the default priorities.
        if(typeof this.currentOrg.fmConfig !== 'undefined' && this.currentOrg.fmConfig && this.currentOrg.fmConfig.priorities && typeof this.currentOrg.fmConfig.priorities !== 'undefined') {
          this.priorities = this.currentOrg.fmConfig.priorities;
          this.priorities.sort((a, b) => a.display > b.display ? 1 : b.display > a.display ? -1 : 0);
        }
      }
    });

    //Get the query parameters.
    this.paramSub = this.route.queryParams.subscribe(params => {
      if(typeof params.id === 'undefined' || params.id === '' || typeof params.type === 'undefined') {
        this.missingEloquaCampaignId = true;
        return;
      }

      //Get params
      this.instanceId = params.instance;
      this.decision.eloquaCampaignId = parseInt(params.id);

      //Get decision service from the db, if it exists.
      this.campaignService
        .getDecisionService(this.instanceId)
        .toPromise()
        .then(latestDecision => {
          //If an existing Decision exists...
          if(latestDecision) {
            this.decision = {
              instanceId: this.instanceId,
              status: latestDecision.status,
              dsType: latestDecision.dsType,
              config: latestDecision.config,
              eloquaCampaignId: latestDecision.eloquaCampaignId,
              organizationId: latestDecision.organizationId
            };

            this.decisionCopy = cloneDeep(this.decision); //Copy the decision.
          }

          //If the Eloqua campaign is active, set window to read-only. Also check FM Priority rule.
          this.checkEloquaCampaign();
        })
        .catch(error => {
          if(error.status.toString() === '404') {
            //If we didn't find an instance, create one automatically with the defaults.
            this.saveDecision();
          }
          else if(error.status.toString()[0] !== '4') {
            this.loggerService.logMessage('Error getting decision service', 'error', error);
          }
        });
    });
  } //end: ngOnInit()

  ngOnDestroy() {
    if(this.orgSub) this.orgSub.unsubscribe();
  }


  postSave() {
    stopLoading();
    this.saving = false;

    if(this.decision.status === 'draft'
       && this.decision.config.selectedFrequencyManagementRuleId
       && this.decision.config.fmPriority) {
      this.configurationComplete();
    }

    //Display a msg indicating FM Priority was automatically selected.
    if(this.decision.config.autoSelectedFmPriority && this.decision.config.autoSelectedFmPriority === this.decision.config.fmPriority) {
      this.fmPriorityAutoSelected = true;
    }
    else {
      this.fmPriorityAutoSelected = false;
    }

    this.decisionCopy = cloneDeep(this.decision); //Copy the decision.
  }

  saveDecision() {
    startLoading();
    this.saving = true;

    if(this.isChanged()) {
      if(!this.decision.hasOwnProperty('instanceId')) {
        this.decision.config.userId = this.currentUser._id; //set to the user that created the DS.
        this.campaignService
          .createDecisionService(this.instanceId, this.decision)
          .toPromise()
          .then(() => {
            this.decision.instanceId = this.instanceId;
            this.checkEloquaCampaign();
            this.postSave();
          })
          .catch(error => {
            stopLoading();
            this.saving = false;
            this.loggerService.logMessage('Error creating decision service', 'error', error);
          });
      }
      else {
        this.campaignService
          .updateDecisionService(this.instanceId, this.decision)
          .toPromise()
          .then(() => {
            this.postSave();
          })
          .catch(error => {
            stopLoading();
            this.saving = false;
            this.loggerService.logMessage('Error updating decision service', 'error', error);
          });
      }
    }
    else {
      stopLoading();
      this.saving = false;
    }
  } //end: saveDecision()

  autoSave() {
    if(this.saving || !this.isChanged()) return; //debounce
    if(this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.saveDecision();
    }, 1.2 * 1000); //wait X seconds before save
  }

  isChanged() {
    return !isEqual(this.decision, this.decisionCopy);
  }

  checkEloquaCampaign() {
    this.http
      .get(`/eloqua-api/api/campaign/${this.decision.eloquaCampaignId}?is-detailed=true`)
      .toPromise()
      .then(
        campaignDetails => {
          console.log('---- Eloqua campaign details:', campaignDetails); //debug

          //campaignDetails.currentStatus: draft, active...
          this.eloquaCampaignStatus = campaignDetails.currentStatus;

          //Also check if we need to automatically set the FM Priority rule.
          if(this.eloquaCampaignStatus === 'draft'
             && typeof this.currentOrg.fmConfig.priorities !== 'undefined'
             && this.currentOrg.fmConfig.priorities
             && this.decision.config.fmPriority === null) {
            var found = false;
            for(var i = 0; i < this.currentOrg.fmConfig.priorities.length; i++) {
              const priority = this.currentOrg.fmConfig.priorities[i];

              if(typeof priority.fieldMappings !== 'undefined' && !found) {
                for(var j = 0; j < priority.fieldMappings.length; j++) {
                  const fieldMapping = priority.fieldMappings[j];
                  console.log(' --> Checking fieldMapping:', fieldMapping); //debug

                  if((fieldMapping.product === '' || campaignDetails.defaultFieldValues.product === fieldMapping.product)
                     && (fieldMapping.region === '' || campaignDetails.defaultFieldValues.region === fieldMapping.region)
                     && (fieldMapping.campaignType === '' || campaignDetails.defaultFieldValues.campaignType === fieldMapping.campaignType)) {
                    console.log('  >>> FOUND!!!'); //debug
                    found = true;
                    this.decision.config.fmPriority = priority.value;
                    this.decision.config.autoSelectedFmPriority = priority.value;
                    this.autoSave();
                    break;
                  }
                }
              }
            }
          }

          //Display a msg indicating FM Priority was automatically selected.
          if(this.decision.config.autoSelectedFmPriority && this.decision.config.autoSelectedFmPriority === this.decision.config.fmPriority) {
            this.fmPriorityAutoSelected = true;
          }
        },
        //Error Handling...
        error => {
          console.log('Error getting Eloqua campaign details.', error);
          this.loggerService.logMessage('Error getting Eloqua campaign details', 'error', error);
        }
      );
  }

  //Set config-complete in Eloqua
  configurationComplete() {
    var url = `/auth/eloqua/decision-service/config-complete?instance=${this.instanceId}`;
    var data = {};

    this.saving = true;
    this.http
      .put(url, data, { responseType: 'text' })
      .toPromise()
      .then(
        () => {
          this.saving = false;
          //After configuration is complete, update status to 'active'.
          this.decision.status = 'active';
          this.saveDecision();
        },
        //Error Handling...
        error => {
          this.saving = false;
          this.loggerService.logMessage('Error configuring an FM Decision Service instance in Eloqua', 'error', error);
        }
      );
  } //end: configurationComplete()

  //Open dialog to update Org's FM settings
  updateFrequency() {
    const initialState = { organization: this.currentOrg };
    this.modalService.show(UpdateFrequencyComponent, { initialState, class: 'modal-lg' });
  }

  //Don't allow updates when campaign is active.
  disableField() {
    if(this.eloquaCampaignStatus === 'active') {
      return true;
    }
    return false;
  }
}
