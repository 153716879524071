// @flow
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LoggerService } from '../../shared/logger.service';

@Injectable({
  providedIn: 'root'
})
export class FunnelService {
  static parameters = [HttpClient, LoggerService];
  constructor(http: HttpClient, loggerService: LoggerService) {
    this.http = http;
    this.loggerService = loggerService;
  }

  getFilterOptions(orgId) {
    var response = {
      eloquaCampaigns: [],
      treatments: []
    };

    const promise = new Promise((resolve, reject) => {
      var params = {
        orgId
      };
      // Get Eloqua Campaigns with funnel
      this.http
        .get('/api/olap/funnelEloquaCampaigns', { params })
        .toPromise()
        .then(
          eloquaCampaigns => {
            var eloquaCampaignIds = [];
            var eloquaCampaignMap = {};
            eloquaCampaigns.forEach(eloquaCampaign => {
              if(eloquaCampaignIds.indexOf(eloquaCampaign.campaign_id) > -1) {
                if(eloquaCampaign.transient_id) {
                  eloquaCampaignMap[eloquaCampaign.campaign_id].transientIds.push(eloquaCampaign.transient_id);
                }
              }
              else {
                eloquaCampaignIds.push(eloquaCampaign.campaign_id);
                eloquaCampaignMap[eloquaCampaign.campaign_id] = {
                  transientIds: [],
                  name: eloquaCampaign.name
                };

                if(eloquaCampaign.transient_id) {
                  eloquaCampaignMap[eloquaCampaign.campaign_id].transientIds.push(eloquaCampaign.transient_id);
                }
              }
            });

            eloquaCampaignIds.forEach(id => {
              response.eloquaCampaigns.push({
                campaign_id: id,
                transientIds: eloquaCampaignMap[id].transientIds,
                name: eloquaCampaignMap[id].name
              });
            });

            // Get treatments with funnel
            this.http
              .get('/api/olap/funnelTreatments', { params })
              .toPromise()
              .then(
                treatments => {
                  response.treatments = treatments;
                  resolve(response);
                },
                err => {
                  reject(err);
                }
              );
          },
          error => {
            this.loggerService.logMessage('Error: retrieving Eloqua funnel data.', 'error', error);
            reject(error);
          }
        );
    });

    return promise;
  } //end: getFilterOptions

  getFunnelData(orgId, filterSettings) {
    var params = {
      orgId,
      filterBy: filterSettings.filterBy,
      ids: filterSettings.ids
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .get('/api/olap/funnel', { params })
        .toPromise()
        .then(
          funnelData => {
            if(funnelData.data && funnelData.data.length > 0) {
              //Ensure field order and names are correct.
              var newData = [];
              funnelData.data.forEach(o => {
                newData.push({
                  month: o.month,
                  noEngagement: o.no_engagement,
                  aware: o.aware,
                  considering: o.considering,
                  engaged: o.engaged
                });
              });
              funnelData.data = newData;
            }

            resolve(funnelData);
          },
          error => {
            this.loggerService.logMessage('Error: retrieving funnel data.', 'error', error);
            reject(error);
          }
        );
    });

    return promise;
  }

  getTimePeriodOptions(funnelData) {
    const promise = new Promise(resolve => {
      var options = [];

      funnelData.forEach(data => {
        var totalCount = data.noEngagement + data.aware + data.considering + data.engaged;
        // Only include months that have non-zero values
        if(totalCount > 0) {
          var date = new Date(data.month);
          date.setDate(date.getDate() + 2);
          options.push(date);
        }
      });

      // Sort options
      options.sort((a, b) => new Date(b) - new Date(a));

      // Default funnel to show the two most recent completed months
      var timePeriod;
      var baselineTimePeriod;

      var currentDate = new Date();
      if(options.length === 1) {
        timePeriod = options[0];
      }
      else if(options.length === 2) {
        timePeriod = options[0];
        baselineTimePeriod = options[1];
      }
      else if(options.length > 2) {
        options.forEach(option => {
          if(option.getMonth() !== currentDate.getMonth() && !timePeriod) {
            timePeriod = option;
          }
          else if(option.getMonth() !== currentDate.getMonth() && !baselineTimePeriod) {
            baselineTimePeriod = option;
          }
        });
      }

      resolve({
        timePeriodOptions: options,
        selectedTimePeriods: {
          timePeriod,
          baselineTimePeriod
        }
      });
    });

    return promise;
  } // end:getTimePeriodOptions

  processFunnelData(funnelData, funnelLocal) {
    var keys = ['noEngagement', 'aware', 'considering', 'engaged'];
    var funnelOrder = { noEngagement: 3, aware: 2, considering: 1, engaged: 0 };

    const promise = new Promise(resolve => {
      funnelLocal.data.totalCount = 0;
      funnelLocal.data.baselineTotalCount = 0;

      // Get months
      var mainIndex = 0;
      var startPeriod = funnelLocal.timePeriod;
      if(typeof startPeriod === 'string') startPeriod = new Date(startPeriod);

      var baselineIndex;
      var baselinePeriod;
      if(funnelLocal.baselineTimePeriod) {
        baselineIndex = 0;
        baselinePeriod = funnelLocal.baselineTimePeriod;
        if(typeof baselinePeriod === 'string') baselinePeriod = new Date(baselinePeriod);
      }

      if(funnelData.length > 1) {
        funnelData.forEach((data, i) => {
          var date = new Date(data.month);
          date.setDate(date.getDate() + 2);

          if(date.getMonth() === startPeriod.getMonth() && date.getYear() === startPeriod.getYear()) {
            mainIndex = i;
          }
          if(baselinePeriod && date.getMonth() === baselinePeriod.getMonth() && date.getYear() === baselinePeriod.getYear()) {
            baselineIndex = i;
          }
        });
      }
      else {
        mainIndex = 0;
      }

      funnelLocal.data.results = [];
      keys.forEach(key => {
        funnelLocal.data.results[funnelOrder[key]] = {
          engagementLevel: key,
          count: funnelData[mainIndex][key],
          baselineCount: funnelData.length > 1 ? funnelData[baselineIndex][key] : null
        };

        // Add to total counts
        funnelLocal.data.totalCount += funnelData[mainIndex][key];

        if(funnelData.length > 1) {
          funnelLocal.data.baselineTotalCount += funnelData[baselineIndex][key];
        }
      });

      funnelLocal.data.baselineTimePeriod = funnelLocal.baselineTimePeriod;
      funnelLocal.data.timePeriod = funnelLocal.timePeriod;

      resolve(funnelLocal);
    });

    return promise;
  }

  getEloquaCampaignTransientIds(orgId, eloquaCampaignId) {
    const promise = new Promise((resolve, reject) => {
      var params = {
        orgId,
        eloquaCampaignId
      };
      this.http
        .get('/api/olap/eloquaCampaignTransientIds', { params })
        .toPromise()
        .then(
          transientIds => {
            var ids = [];
            transientIds.forEach(transient => {
              ids.push(transient.transient_campaign_id);
            });

            resolve(ids);
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  getDemoFunnelData() {
    const promise = new Promise((resolve, reject) => {
      this.http
        .get('/assets/demo/global/funnel.json')
        .toPromise()
        .then(
          funnelData => {
            var yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            yesterday.setHours(12);

            var m1 = new Date();
            m1.setMonth(m1.getMonth() - 1);
            var m2 = new Date();
            m2.setMonth(m2.getMonth() - 2);

            funnelData.updatedAt = yesterday.toISOString();
            funnelData.data.forEach((item, i) => {
              var month; var year;
              if(i === 0) {
                month = m2.getUTCMonth() + 1;
                year = m2.getUTCFullYear();
              }
              else if(i === 1) {
                month = m1.getUTCMonth() + 1;
                year = m1.getUTCFullYear();
              }
              item.month = `${year}-${month}`;
            });

            resolve(funnelData);
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  getDemoFilteredFunnelData() {
    const promise = new Promise((resolve, reject) => {
      this.http
        .get('/assets/demo/global/funnel-filtered.json')
        .toPromise()
        .then(
          funnelData => {
            var yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            yesterday.setHours(12);

            var m1 = new Date();
            m1.setMonth(m1.getMonth() - 1);
            var month = m1.getUTCMonth() + 1;
            var year = m1.getUTCFullYear();

            funnelData.updatedAt = yesterday.toISOString();
            funnelData.data[0].month = `${year}-${month}`;

            resolve(funnelData);
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }
}
