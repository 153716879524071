import { Component, ElementRef, HostListener, Input, SimpleChanges, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import * as d3 from 'd3';

@Component({
  selector: 'viz-horizontal-bar-chart',
  template: '<div></div>'
})
export class HorizontalBarChartComponent implements OnInit {
  @Input() data;
  @Input() height;

  @HostListener('window:resize', ['$event'])
  onResize() {
    //If not vislble, don't resize.
    if(!(this.htmlElement.offsetParent !== null)) return;

    this.width = this.htmlElement.parentElement.clientWidth - 50;

    //Add margin - for width only.
    this.marginWidth = +this.width - this.margin.left - this.margin.right;

    this.svg.attr('width', this.width);
    this.x = d3.scaleLinear().range([0, this.marginWidth]);

    this.render(this.data);
  }

  htmlElement: HTMLElement;
  svg;

  static parameters = [DataService, ElementRef];
  constructor(dataService: DataService, element: ElementRef) {
    this.htmlElement = element.nativeElement;
    this.host = d3.select(element.nativeElement);
    this.dataService = dataService;
  }

  ngOnInit() {
    this.width = this.htmlElement.parentElement.clientWidth - 50;
    this.margin = { top: 10, left: 30, bottom: 40, right: 60 };
    this.xOffset = 35;

    //Create the svg using width 100% to fill the container.
    this.svg = this.host
      .append('svg')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
      .attr('height', this.height ? this.height : '170')
      .attr('width', this.width)
      .attr('id', 'frequency_lineAndBarChart');

    this.g = this.svg.append('g').attr('transform', `translate(${this.margin.left},${this.margin.top})`);

    // Tooltip div
    this.div = this.host
      .append('div')
      .attr('class', 'chart-tooltip')
      .style('opacity', 0);

    // Set up chart
    this.width = +this.svg.attr('width') - this.margin.left - this.margin.right;
    this.height = +this.svg.attr('height') - this.margin.top - this.margin.bottom;

    this.x = d3.scaleLinear().range([0, this.width]);
    this.y = d3.scaleBand().range([this.height, 0]);

    this.render(this.data);
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'data' && this.data && this.g) {
        this.render(this.data);
      }
    }
  }

  render(data) {
    if(!data) return;
    this.g.selectAll('*').remove();

    // Scale the range of the data in the domains
    var maxXValue = d3.max(data, d => d.numberOfContacts);
    this.x.domain([0, Math.ceil(maxXValue / 10) * 10]); // round up to nearest 10 to extend chart slightly
    this.y.domain(data.map(d => d.sendsPerContactPerWeek)).padding(0.1);

    // append the bars
    var bar = this.g
      .selectAll('.high-frequency-bar')
      .data(data)
      .enter()
      .append('g')
      .attr('class', 'high-frequency-bar')
      .attr('transform', `translate(${this.xOffset},0)`);

    bar
      .append('rect')
      .attr('class', function(d) {
        if(d.sendsPerContactPerWeek === data.selectedBar) return 'selected-high-frequency-bar';
      })
      .attr('fill', '#1680BA')
      .attr('x', 0)
      .attr('height', this.y.bandwidth())
      .attr('y', d => this.y(d.sendsPerContactPerWeek))
      .attr('width', d => this.x(d.numberOfContacts))
      .on('click', d => {
        data.selectedBar = d.sendsPerContactPerWeek;
        this.highlightBar();

        //Emit event to show contact details.
        this.dataService.showHighFreqContacts({
          sendsPerContactPerWeek: d.sendsPerContactPerWeek,
          numberOfContacts: d.numberOfContacts,
          bucketStart: d.bucketStart,
          bucketEnd: d.bucketEnd
        });
      })
      .on('mouseover', d => {
        this.hideTooltip();
        this.showTooltip(d);
      })
      .on('mouseout', () => {
        this.hideTooltip();
      });

    bar
      .append('text')
      .attr('fill', '#ffcc00')
      .attr('opacity', function(d) {
        if(d.sendsPerContactPerWeek === data.selectedBar) return 1;
        return 0;
      })
      .attr('x', d => {
        // Show # of contacts inside bar. If bar is too small, show number to the right
        if(this.x(d.numberOfContacts) > 18) return this.x(d.numberOfContacts) / 2;
        else return 25;
      })
      .attr('y', d => this.y(d.sendsPerContactPerWeek) + this.y.bandwidth() / 2)
      .attr('dy', '.35em') //vertical align middle
      .attr('text-anchor', 'middle')
      .text(d => {
        if(d.numberOfContacts > 0) return d.numberOfContacts;
      });

    this.g
      .append('g')
      .attr('class', 'frequency-axis x-axis')
      .attr('transform', `translate(${this.xOffset},${this.height})`)
      .call(d3.axisBottom(this.x).ticks(8))
      .selectAll('.tick:nth-child(2n)')
      .append('line')
      .attr('stroke', '#777')
      .attr('opacity', '0.2')
      .attr('y1', 6)
      .attr('y2', 0 - this.height);

    // text label for the x axis
    this.g
      .append('text')
      .attr('transform', `translate(${this.width / 2 + this.xOffset} ,${this.height + this.margin.top + 25})`)
      .attr('class', 'frequency-axis-label')
      .style('text-anchor', 'middle')
      .text('# of contacts');

    this.g
      .append('g')
      .attr('class', 'frequency-axis')
      .attr('transform', `translate(${this.xOffset},0)`)
      .call(d3.axisLeft(this.y))
      .selectAll('.tick')
      .attr('cursor', 'pointer')
      .on('click', d => {
        data.selectedBar = d;
        this.highlightBar();

        var selected;
        data.forEach(bucket => {
          if(bucket.sendsPerContactPerWeek === d) selected = bucket;
        });

        //Emit event to show contact details.
        this.dataService.showHighFreqContacts({
          sendsPerContactPerWeek: d,
          numberOfContacts: selected.numberOfContacts,
          bucketStart: selected.bucketStart,
          bucketEnd: selected.bucketEnd
        });
      })
      .on('mouseover', d => {
        var selected;
        data.forEach(bucket => {
          if(bucket.sendsPerContactPerWeek === d) selected = bucket;
        });
        this.hideTooltip();
        this.showTooltip(selected);
      })
      .on('mouseout', () => {
        this.hideTooltip();
      });

    // text label for the y axis
    this.g
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0 - this.margin.left)
      .attr('x', 0 - this.height / 2)
      .attr('dy', '1em')
      .attr('class', 'frequency-axis-label')
      .style('text-anchor', 'middle')
      .text('Sends per contact per week');
  } // end: render

  showTooltip(data) {
    this.div
      .transition()
      .duration(600)
      .style('opacity', 1);

    this.div
      .html(
        `<p class="result"><span class="faded vLabel">Median sends per week:</span>${data.sendsPerContactPerWeek}</p>`
          + `<p class="result"><span class="faded vLabel" style="text-transform: capitalize">Number of contacts:</span>${data.numberOfContacts}</p>`
      )
      .style('left', `${this.x(data.numberOfContacts) + 100}px`)
      .style('top', `${this.y(data.sendsPerContactPerWeek) + this.y.bandwidth() / 2}px`)
      .style('min-width', '220px');
  }

  hideTooltip() {
    this.div
      .transition()
      .duration(200)
      .style('opacity', 0);
  }

  widthChanged(data, newWidth) {
    this.svg.attr('width', newWidth);
    this.width = newWidth - this.margin.left - this.margin.right;
    this.x = d3.scaleLinear().range([0, this.width]);
    this.render(data);
  }

  highlightBar() {
    this.g
      .selectAll('.high-frequency-bar')
      .select('rect')
      .attr('class', d => {
        if(d.sendsPerContactPerWeek === this.data.selectedBar) return 'selected-high-frequency-bar';
        return '';
      });

    this.g
      .selectAll('.high-frequency-bar')
      .select('text')
      .attr('opacity', d => {
        if(d.sendsPerContactPerWeek === this.data.selectedBar) return 1;
        return 0;
      });
  }
}
