// @flow
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../components/auth/auth.service';
import { ImpersonationService } from '../../../components/auth/impersonation.service';
import { GeneratorService } from '../shared/generator.service';

@Component({
  selector: 'subject-line-analyzer',
  template: require('./subject-line-analyzer.html')
})
export class SubjectLineAnalyzerComponent implements OnInit {
  static parameters = [AuthService, ImpersonationService, GeneratorService];
  constructor(authService: AuthService, impersonation: ImpersonationService, generatorService: GeneratorService) {
    this.authService = authService;
    this.impersonation = impersonation;
    this.generatorService = generatorService;
  }

  ngOnInit() {
    this.isDemo = this.impersonation.isDemoMode();
    this.init();

    this.language = 'english';
    this.languages = this.generatorService.getLanguages();
    this.toneLabel = 'Tone'; //english
    this.generateButton = 'Generate Variations';

    this.loadingAnalysis = false;
    this.loadingVariations = {};

    this.authService.getCurrentUser().then(user => {
      this.currentUser = user;
    });

    //If the page is loaded directly.
    this.userSub = this.authService.currentUserChanged.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnDestroy() {
    if(this.userSub) this.userSub.unsubscribe();
  }

  init() {
    this.subject = '';
    this.subjectLinesAnalyzed = [];
    this.subjectLinesVisibleCount = 0;

    if(this.isDemo) {
      this.runAnalysis('Apple powr!').then(() => {
        this.runAnalysis('Apple power!').then(() => {
          this.runAnalysis('The power of Apple. Put to work.');
        });
      });
    }
  }

  analyzeSubjectLine() {
    if(this.subject) this.runAnalysis(this.subject);
  }

  runAnalysis(subject) {
    const promise = new Promise((resolve, reject) => {
      this.loadingAnalysis = true;
      this.setLanguage(this.language);
      var currentAnalysis = { subject };

      this.generatorService.analyzeSubjectLine(subject, this.language).then(analysis => {
        currentAnalysis.subjectLineAnalysis = this.generatorService.processSubjectLineAnalysis(analysis, this.language);
        currentAnalysis.subjectLineAdviceMessages = this.generatorService.getSubjectLineAdviceMessages(analysis, this.language);
        currentAnalysis.gpt3Analysis = analysis.explanation;
        currentAnalysis.hide = false;
        this.subjectLinesAnalyzed.unshift(currentAnalysis);
        this.subjectLinesVisibleCount++;
        this.loadingAnalysis = false;
        resolve();
      },
      error => {
        console.log('Error analyzing subject line', error);
        reject();
      });
    });

    return promise;
  }

  getVariations(subjectLine, i) {
    this.loadingVariations[i] = true;
    this.generatorService.generateSubjectLineVariations(subjectLine.subject, this.language).then(response => {
      subjectLine.generatedVariations = response.variations;
      this.loadingVariations[i] = false;
    });
  }

  setLanguage(language) {
    this.toneLabel = 'Tone';
    this.generateButton = 'Generate Variations';

    switch (language) {
    case 'french':
      this.toneLabel = 'Ton';
      this.generateButton = 'Créer des variantes';
      break;

    case 'german':
      this.toneLabel = 'Ton';
      this.generateButton = 'Variationen generieren';
      break;

    case 'spanish':
      this.toneLabel = 'Tono';
      this.generateButton = 'Generar variaciones';
      break;

    case 'italian':
      this.toneLabel = 'Tono';
      this.generateButton = 'Genera variazioni';
      break;

    case 'portuguese':
      this.toneLabel = 'Tom';
      this.generateButton = 'Gerar variações';
      break;

    case 'japanese':
      this.toneLabel = 'トーン';
      this.generateButton = 'バリエーションを生成する';
      break;
    }
  }
}
