// @flow
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { LoggerService } from '../../shared/logger.service';

import * as d3 from 'd3';
import { sortBy } from 'lodash';
import moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class TimeWindowsService {
  static parameters = [HttpClient, LoggerService];
  constructor(http: HttpClient, loggerService: LoggerService) {
    this.http = http;
    this.loggerService = loggerService;
  }

  buildCampaignTimeWindows(campaign, campaignLocal, timeWindows, delayDistribution) {
    timeWindows.loading = true;
    timeWindows.config.rowMap = {}; //for matrix

    var params = new HttpParams();
    params = params.append('campaigns', [campaign.campaignId]);
    params = params.append('timezone', campaign.timezone ? campaign.timezone : 'US/Pacific');

    const promise = new Promise((resolve, reject) => {
      this.http
        .get('/api/campaigns/timeWindows', { params })
        .toPromise()
        .then(
          olapData => {
            if(olapData) {
              this.processTimeWindowsData(campaign, campaignLocal, timeWindows, delayDistribution, olapData);
            }
            resolve();
          },
          //Error Handling...
          error => {
            timeWindows.loading = false;
            this.loggerService.logMessage('Error getting Time Windows data for campaign', 'error', error);
            console.error('There was an error!', error);
            reject(error);
          }
        );
    });

    return promise;
  } //end: buildCampaignTimeWindows()

  processTimeWindowsData(campaign, campaignLocal, timeWindows, delayDistribution, olapData) {
    //Build Matrix heatmap
    timeWindows.config.rowMap = olapData.matrix;
    timeWindows.heatMapData = this.buildHeatmapData(campaign, campaignLocal, timeWindows);

    //Build Delay Distribution
    if(olapData.graph && delayDistribution) {
      delayDistribution.data = olapData.graph;
      delayDistribution.axisValues = timeWindows.config.horizontalLabels; //show all hours (like the heatmap)

      var keys = Object.keys(olapData.graph);

      delayDistribution.keysCol1 = [];
      delayDistribution.keysCol2 = [];

      keys.forEach((key, index) => {
        if(index < Math.ceil(keys.length / 2)) {
          delayDistribution.keysCol1.push(key);
        }
        else {
          delayDistribution.keysCol2.push(key);
        }
      });
    }

    timeWindows.loading = false;

    if(timeWindows.config.rowMap && typeof timeWindows.config.rowMap['0'] !== 'undefined' && typeof timeWindows.config.rowMap['23'] !== 'undefined') {
      timeWindows.show = true;
    }
  }

  buildGlobalTimeWindows(orgId, timeWindows, currentFilter) {
    // Get send time data
    const promise = new Promise((resolve, reject) => {
      var params = {
        orgId,
        filterBy: currentFilter.filterBy,
        ids: currentFilter.ids
      };
      // Get Eloqua Campaigns with Who data
      this.http
        .get('/api/olap/globalSto', { params })
        .toPromise()
        .then(
          globalSto => {
            var timeZoneOffset = moment
              .tz(timeWindows.timezone)
              .format('Z')
              .slice(0, 3);
            var timeWindowsMatrix = {};

            globalSto.forEach(row => {
              var hour = Number(row.hour) + Number(timeZoneOffset);
              var day = Number(row.dayofweek);

              if(hour < 0) {
                if(day == 0) {
                  day = 6;
                  hour = 24 + hour;
                }
                else {
                  day = day - 1;
                  hour = 24 + hour;
                }
              }
              else if(hour > 23) {
                if(day == 6) {
                  day = 0;
                  hour = hour - 24;
                }
                else {
                  day = day + 1;
                  hour = hour - 24;
                }
              }

              if(!timeWindowsMatrix[hour]) {
                timeWindowsMatrix[hour] = {};
              }

              // Only show data where there are > 100 sends
              if(currentFilter.filterBy != 'all' || row.sends > 100) {
                timeWindowsMatrix[hour][day + 1] = {
                  sends: row.sends,
                  opens: row.opens,
                  clicks: row.clickthroughs,
                  openRate: row.opens / row.sends,
                  clickRate: row.clickthroughs / row.sends
                };
              }
            });

            //Check that all hours have data, and insert 0s for missing hours
            for(var hour = 0; hour < 24; hour++) {
              if(!timeWindowsMatrix[hour]) {
                timeWindowsMatrix[hour] = {};
              }

              for(var day = 1; day < 8; day++) {
                if(!timeWindowsMatrix[hour][day]) {
                  timeWindowsMatrix[hour][day] = {
                    sends: 0,
                    opens: 0,
                    clicks: 0,
                    openRate: 0,
                    clickRate: 0
                  };
                }
              }
            }

            timeWindows.config.rowMap = timeWindowsMatrix;
            var heatMapData = this.buildHeatmapData(null, null, timeWindows);

            // Calculate hourly and daily averages
            heatMapData.dailyAverages = this.calculateDailyAverages(heatMapData);
            heatMapData.hourlyAverages = this.calculateHourlyAverages(heatMapData);
            resolve(heatMapData);
          },
          error => {
            console.log('Error getting global sto', error);
            reject(error);
          }
        );
    });
    return promise;
  } //end: buildGlobalTimeWindows

  //Build heatmap data structure
  buildHeatmapData(campaign, campaignLocal, timeWindows) {
    var topVal = 0;
    var sendVals = [];
    var sendValsFiltered = [];
    var matrixData = [];

    var valueKey = timeWindows.toggle;
    var rowMap = timeWindows.config.rowMap;

    for(var rowKey in rowMap) {
      var row = rowMap[rowKey];
      for(var colKey in row) {
        // Display Monday - Sunday
        var col = row[colKey];
        var obj = {
          x: parseInt(rowKey), //hours are from 0 to 23
          y: parseInt(colKey) !== 1 ? parseInt(colKey - 1) : 7, //Convert from Sunday (1) - Saturday (7) to Monday (1) - Sunday (7)
          value: col[valueKey],
          units: valueKey === 'clickRate' ? 'Clickthrough Rate' : 'Open Rate',
          volume: col.sends,
          vUnits: 'Sends'
        };
        matrixData.push(obj);

        if(col.sends > 0) {
          if(obj.value > topVal) topVal = obj.value;
          sendVals.push(obj);
        }
      }
    }

    timeWindows.topSlots = [];
    timeWindows.bottomSlots = [];

    //If there are at least six values, remove low end outliers.
    if(sendVals.length >= 6) {
      sendValsFiltered = this.removeLowerOutliers(sendVals, 'volume');
    }

    //Only if there are still at least six values, show top and worst send times.
    if(sendValsFiltered.length >= 6) {
      timeWindows.topVal = topVal;
      timeWindows.colorScale = d3
        .scaleLinear()
        .domain([0, topVal / 2, topVal])
        .range(['#ffffdd', '#3e9583', '#1f2d86']); //yellow, green, blue (same as heatmap)

      timeWindows.textColorScale = d3
        .scaleQuantize()
        .domain([0, topVal])
        .range(['black', 'black', 'white']);

      //Find bottom 3 slots
      sendValsFiltered = sortBy(sendValsFiltered, 'value');

      timeWindows.bottomSlots = sendValsFiltered.slice(0, 3);
      timeWindows.bottomSlots.forEach(slot => {
        slot.hour = timeWindows.config.horizontalLabels[slot.x];
        slot.day = timeWindows.config.verticalLabels[slot.y - 1];
      });

      //Find top 3 slots.
      sendValsFiltered = sendValsFiltered.reverse();
      timeWindows.topSlots = sendValsFiltered.slice(0, 3);
      timeWindows.topSlots.forEach(slot => {
        slot.hour = timeWindows.config.horizontalLabels[slot.x];
        slot.day = timeWindows.config.verticalLabels[slot.y - 1];
      });

      // Used for hero button email
      timeWindows.sendTimeRateDifference = sendValsFiltered[0].value - sendValsFiltered[sendValsFiltered.length - 1].value;
    }

    var daysOfTheWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    if(campaignLocal) {
      var sendTimeRestrictions = [];
      daysOfTheWeek.forEach(day => {
        if(campaignLocal && campaignLocal.restrictSendTimes && campaignLocal.restrictSendTimes.daysOfTheWeek[day]) {
          sendTimeRestrictions.push([campaignLocal.restrictSendTimes.startHour, campaignLocal.restrictSendTimes.endHour]);
        }
        else {
          sendTimeRestrictions.push(null);
        }
      });
    }

    //Get current day & hour
    if(campaign) {
      var currentDate = moment(new Date()).tz(campaign.timezone);
    }

    var response = {
      reportData: matrixData,
      showNow: !!campaign && (campaign.status === 'running' || campaign.status === 'finishedExperimentNowCollecting')
    };

    if(sendTimeRestrictions) {
      response.sendTimeRestrictions = sendTimeRestrictions;
    }

    if(currentDate) {
      response.current = {
        day: currentDate.day(),
        hour: currentDate.hour()
      };
    }

    return response;
  } //end: buildHeatmapData

  //Try to remove the low end outlines from the origArray.
  removeLowerOutliers(origArray, field) {
    //First sort the array ascending by the "field".
    origArray = sortBy(origArray, field);

    //First, try the mean - (3 * standard deviation) method.
    var mean = d3.mean(origArray, d => d[field]);
    var sd = d3.deviation(origArray, d => d[field]);
    var lower = mean - 3 * sd;

    //debug: start
    //console.log(`===> Time Windows: Trying to remove low end outliers.`);
    //console.log(`---> mean = ${mean}`);
    //console.log(`---> sd = ${sd}`);
    //console.log(`---> lower (3 * sd) = ${lower}`);
    //debug: end

    //If lower is less than zero, try interquartile range (iqr) method.
    if(lower < 0) {
      var q1 = d3.quantile(origArray, 0.25, d => d[field]);
      var q3 = d3.quantile(origArray, 0.75, d => d[field]);
      var iqr = q3 - q1; // interquartile range
      lower = q1 - iqr * 1.5;
      //console.log(`---> lower (iqr) = ${lower}`); //debug
    }

    //If lower is still less than zero, just remove the lowest 5%.
    if(lower < 0) {
      lower = d3.quantile(origArray, 0.05, d => d[field]);
      //console.log(`---> lower (5% quantile) = ${lower}`); //debug
    }

    //Using the actual max, because we are just trying to remove small values.
    var maxValue = d3.max(origArray, d => d[field]);
    var minValue = lower;

    //Then filter anything above or below these values.
    var filteredArray = origArray.filter(d => d[field] <= maxValue && d[field] >= minValue);

    //debug: start
    //console.log('-----------------------------------');
    //console.log(`---> minValue = ${minValue}`);
    //console.log(`---> maxValue = ${maxValue}`);
    //console.log('-----> origArray:', origArray);
    //console.log('-----> filteredArray:', filteredArray);
    //debug: end

    return filteredArray;
  } //end: removeLowerOutliers()

  //Only used when data is read from the cache
  addColorScales(timeWindows) {
    timeWindows.colorScale = d3
      .scaleLinear()
      .domain([0, timeWindows.topVal / 2, timeWindows.topVal])
      .range(['#ffffdd', '#3e9583', '#1f2d86']); //yellow, green, blue (same as heatmap)

    timeWindows.textColorScale = d3
      .scaleQuantize()
      .domain([0, timeWindows.topVal])
      .range(['black', 'black', 'white']);
  }

  calculateDailyAverages(heatMapData) {
    var dailyAverages = [];
    var dailyRateSum = [];
    heatMapData.reportData.forEach(hour => {
      if(!dailyRateSum[hour.y - 1]) {
        dailyRateSum[hour.y - 1] = {
          rate: 0,
          count: 0
        };
      }

      if(hour.volume > 0) {
        dailyRateSum[hour.y - 1].rate += hour.value;
        dailyRateSum[hour.y - 1].count++;
      }
    });

    dailyRateSum.forEach((day, index) => {
      if(day.count > 0) {
        dailyAverages[index] = day.rate / day.count;
      }
      else {
        dailyAverages[index] = '--';
      }
    });

    return dailyAverages;
  } //end: calculateDailyAverages()

  calculateHourlyAverages(heatMapData) {
    var hourlyAverages = [];
    var hourlyRateSum = [];
    heatMapData.reportData.forEach(hour => {
      if(!hourlyRateSum[hour.x]) {
        hourlyRateSum[hour.x] = {
          rate: 0,
          count: 0
        };
      }

      if(hour.volume > 0) {
        hourlyRateSum[hour.x].rate += hour.value;
        hourlyRateSum[hour.x].count++;
      }
    });

    hourlyRateSum.forEach((hour, index) => {
      if(hour.count > 0) {
        hourlyAverages[index] = hour.rate / hour.count;
      }
      else {
        hourlyAverages[index] = '--';
      }
    });
    return hourlyAverages;
  }

  getFilterOptions(orgId) {
    var response = {
      eloquaCampaigns: [],
      treatments: []
    };

    const promise = new Promise((resolve, reject) => {
      var params = {
        orgId
      };
      // Get Eloqua Campaigns with Who data
      this.http
        .get('/api/olap/globalStoEloquaCampaigns', { params })
        .toPromise()
        .then(eloquaCampaigns => {
          var eloquaCampaignIds = [];
          var eloquaCampaignMap = {};
          eloquaCampaigns.forEach(eloquaCampaign => {
            if(eloquaCampaignIds.indexOf(eloquaCampaign.campaign_id) > -1) {
              if(eloquaCampaign.transient_id) {
                eloquaCampaignMap[eloquaCampaign.campaign_id].transientIds.push(eloquaCampaign.transient_id);
              }
            }
            else {
              eloquaCampaignIds.push(eloquaCampaign.campaign_id);
              eloquaCampaignMap[eloquaCampaign.campaign_id] = {
                transientIds: [],
                name: eloquaCampaign.name
              };

              if(eloquaCampaign.transient_id) {
                eloquaCampaignMap[eloquaCampaign.campaign_id].transientIds.push(eloquaCampaign.transient_id);
              }
            }
          });

          eloquaCampaignIds.forEach(id => {
            response.eloquaCampaigns.push({
              campaign_id: id,
              transientIds: eloquaCampaignMap[id].transientIds,
              name: eloquaCampaignMap[id].name
            });
          });

          // Get treatments with Who data
          this.http
            .get('/api/olap/globalStoTreatments', { params })
            .toPromise()
            .then(
              treatments => {
                response.treatments = treatments;
                resolve(response);
              },
              error => {
                reject(error);
              }
            );
        });
    });

    return promise;
  } //end: getFilterOptions

  getDemoGlobalTimeWindowsData(timeWindows) {
    const promise = new Promise((resolve, reject) => {
      this.http
        .get('/assets/demo/global/sendTimes.json')
        .toPromise()
        .then(
          demoSto => {
            var timeZoneOffset = moment
              .tz(timeWindows.timezone)
              .format('Z')
              .slice(0, 3);
            var timeWindowsMatrix = {};

            demoSto.forEach(row => {
              var hour = Number(row.hour) + Number(timeZoneOffset);
              var day = Number(row.dayofweek);

              if(hour < 0) {
                if(day == 0) {
                  day = 6;
                  hour = 24 + hour;
                }
                else {
                  day = day - 1;
                  hour = 24 + hour;
                }
              }
              else if(hour > 23) {
                if(day == 6) {
                  day = 0;
                  hour = hour - 24;
                }
                else {
                  day = day + 1;
                  hour = hour - 24;
                }
              }

              if(!timeWindowsMatrix[hour]) {
                timeWindowsMatrix[hour] = {};
              }

              timeWindowsMatrix[hour][day + 1] = {
                sends: row.sends,
                opens: row.opens,
                clicks: row.clickthroughs,
                openRate: row.opens / row.sends,
                clickRate: row.clickthroughs / row.sends
              };
            });

            timeWindows.config.rowMap = timeWindowsMatrix;
            var heatMapData = this.buildHeatmapData(null, null, timeWindows);

            // Calculate hourly and daily averages
            heatMapData.dailyAverages = this.calculateDailyAverages(heatMapData);
            heatMapData.hourlyAverages = this.calculateHourlyAverages(heatMapData);

            resolve(heatMapData);
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }
}
