// @flow
import { Injectable } from '@angular/core';

import { campaignKpis } from './campaign-kpis';

import { cloneDeep, findIndex } from 'lodash';
import * as d3 from 'd3';

@Injectable({
  providedIn: 'root'
})
export class CampaignChartService {
  getChartLabel(series) {
    return campaignKpis[series].name;
  }

  //----------------------------------------------------------------------------
  //Build the daily incremental sends chart
  convertResultsToDailySends(results, optimizeOn, resultsToggle, isSimple) {
    var dailySends = [];
    dailySends.columns = ['resultDateTime'];
    dailySends.attrToChart = 'incrementSends';
    dailySends.showRangeSelector = true;
    dailySends.isSimple = isSimple;

    // Build treatment info and list of treatments to show
    dailySends.info = {};
    var showKeys = [];

    results.treatmentDetails.forEach(treatment => {
      dailySends.info[treatment.id] = {
        subject: treatment.subject,
        name: treatment.name,
        color: results.colors[treatment.id]
      };

      if(treatment.showTrend) {
        showKeys.push(treatment.id);
      }
    });

    // Build program info
    dailySends.programDuration = {
      value: results.params.lengthDays + results.params.postCampaignDays,
      unit: 'days'
    };

    dailySends.optimizeOn = optimizeOn === 'clickthroughs' ? 'click' : 'open';

    var allResults = [];
    var keys = Object.keys(results.trends[0]);

    // Determine if first result is empty
    var showFirstResult = false;
    keys.forEach(key => {
      if(results.trends[0][key].expectedValueCtRate || results.trends[0][key].expectedValueOpenRate) {
        showFirstResult = true;
      }
    });

    results.trends.forEach((result, i) => {
      var add = false;
      var date = new Date(result.resultDateTime.getTime());
      date.setHours(12, 0, 0, 0);

      var obj = {
        resultDateTime: date,
        winnerFound: !!result.winnerFound
      };

      keys.forEach(function(key) {
        if(key !== 'resultDateTime' && key !== 'winnerFound' && (resultsToggle !== 'topPerformer' || findIndex(results.topTreatments, ['id', key]) > -1) && showKeys.indexOf(key) > -1) {
          obj[key] = {
            sends: result[key].sends,
            incrementSends: i > 0 ? result[key].sends - results.trends[i - 1][key].sends : result[key].sends
          };

          obj[key].open = {
            rate: result[key].expectedValueOpenRate,
            confIntervalMin: result[key].credibleIntervalMinOpen,
            confIntervalMax: result[key].credibleIntervalMaxOpen
          };

          obj[key].click = {
            rate: result[key].expectedValueCtRate,
            confIntervalMin: result[key].credibleIntervalMinCt,
            confIntervalMax: result[key].credibleIntervalMaxCt
          };

          add = true;

          if(i === 0) {
            dailySends.columns.push(key);
          }
        }
      });

      if(add) allResults.push(obj);
    });

    //Consolidate down to a single entry per day.
    if(allResults.length > 1 || showFirstResult) {
      // Start with date of the second result since the first is empty
      var curDate = showFirstResult ? allResults[0].resultDateTime : allResults[1].resultDateTime;
      var totalObject = {
        winnerFound: false,
        resultDateTime: curDate
      };

      allResults.forEach((result, i) => {
        if(i > 0 || showFirstResult) {
          // Skip the initial empty result
          if(result.resultDateTime.getDate() === curDate.getDate()) {
            //good enough, since results are ordered?
            totalObject.winnerFound = totalObject.winnerFound || result.winnerFound;

            keys.forEach(key => {
              if(key !== 'resultDateTime' && key !== 'winnerFound') {
                if(!totalObject[key]) {
                  totalObject[key] = result[key];
                }
                else if(key !== 'resultDateTime') {
                  totalObject[key].incrementSends += result[key].incrementSends;

                  //Also, take other latest values...
                  totalObject[key].sends = result[key].sends;

                  totalObject[key].open.rate = result[key].open.rate;
                  totalObject[key].open.confIntervalMin = result[key].open.confIntervalMin;
                  totalObject[key].open.confIntervalMax = result[key].open.confIntervalMax;

                  totalObject[key].click.rate = result[key].click.rate;
                  totalObject[key].click.confIntervalMin = result[key].click.confIntervalMin;
                  totalObject[key].click.confIntervalMax = result[key].click.confIntervalMax;
                }
              }
            });
          }
          else {
            dailySends.push(totalObject);

            // If there are days with no results, fill in gaps
            var dateDifference = result.resultDateTime.getDate() - curDate.getDate();
            if(dateDifference > 1) {
              var date = new Date(curDate);
              for(var i = dateDifference - 1; i > 0; i--) {
                date = new Date(date.getTime() + 24 * 60 * 60 * 1000);
                var obj = {
                  resultDateTime: date
                };
                keys.forEach(key => {
                  if(key !== 'resultDateTime' && key !== 'totals') {
                    obj[key] = {
                      sends: 0,
                      incrementSends: 0,
                      click: {
                        rate: null,
                        confIntervalMin: null,
                        confIntervalMax: null
                      },
                      open: {
                        rate: null,
                        confIntervalMin: null,
                        confIntervalMax: null
                      }
                    };
                  }
                });

                dailySends.push(obj);
              }
            }

            curDate = result.resultDateTime;
            totalObject = { winnerFound: result.winnerFound };

            keys.forEach(key => {
              if(!totalObject[key]) totalObject[key] = result[key];
            });
          }
        }
      });

      if(totalObject) {
        dailySends.push(totalObject);
      }
    }

    // If the program duration is longer than result data, add empty days
    var daysToAdd = 0;
    if(dailySends.length <= dailySends.programDuration.value) {
      daysToAdd = dailySends.programDuration.value - dailySends.length + 1;
    }

    for(var i = 0; i < daysToAdd; i++) {
      var obj = {
        resultDateTime: d3.timeDay.offset(dailySends[dailySends.length - 1].resultDateTime, 1)
      };

      var treatments = dailySends.columns.slice(1);
      for(var j = 0; j < treatments.length; j++) {
        obj[treatments[j]] = {
          click: {
            num: 0,
            rate: 0,
            bestArmConfidence: 0,
            confIntervalMin: 0,
            confIntervalMax: 0
          },
          open: {
            num: 0,
            rate: 0,
            bestArmConfidence: 0,
            confIntervalMin: 0,
            confIntervalMax: 0
          },
          totalSends: 0,
          incrementSends: 0
        };
      }

      dailySends.push(obj);
    }

    return dailySends;
  } //end: convertResultsToDailySends()

  //----------------------------------------------------------------------------
  // For rate chart on message testing tab
  convertResultsToDailyPerformance(results, optimizeOn, resultsToggle) {
    var dailyPerformance = {
      days: [],
      results: [],
      info: {},
      programDuration: {},
      reloadChart: true,
      attrToChart: optimizeOn === 'clickthroughs' ? 'click' : 'open'
    };

    var attr = optimizeOn === 'clickthroughs' ? 'expectedValueCtRate' : 'expectedValueOpenRate';
    var minAttr = optimizeOn === 'clickthroughs' ? 'credibleIntervalMinCt' : 'credibleIntervalMinOpen';
    var maxAttr = optimizeOn === 'clickthroughs' ? 'credibleIntervalMaxCt' : 'credibleIntervalMaxOpen';

    var keys = Object.keys(results.trends[0]);
    var showKeys = [];

    // Build info and list of treatments to show
    results.treatmentDetails.forEach(treatment => {
      dailyPerformance.info[treatment.id] = {
        subject: treatment.subject,
        name: treatment.name,
        color: results.colors[treatment.id]
      };

      if(treatment.showTrend) {
        showKeys.push(treatment.id);
      }
    });

    dailyPerformance.programDuration = {
      value: results.params.lengthDays + results.params.postCampaignDays,
      unit: 'days'
    };

    if(results.params.endDate) {
      dailyPerformance.programDuration.endDate = new Date(results.params.endDate.getTime() + results.params.postCampaignDays * 24 * 60 * 60 * 1000);
    }

    // Determine if first result is empty
    var showFirstResult = false;
    keys.forEach(key => {
      if(results.trends[0][key].expectedValueCtRate || results.trends[0][key].expectedValueOpenRate) {
        showFirstResult = true;
      }
    });

    // Build results
    keys.forEach((key, index) => {
      if(key !== 'resultDateTime' && (resultsToggle !== 'topPerformer' || findIndex(results.topTreatments, ['id', key]) > -1) && showKeys.indexOf(key) > -1) {
        var obj = {
          id: key,
          series: [],
          display: true
        };

        results.trends.forEach((result, i) => {
          if(i > 0 || showFirstResult) {
            // Make list of days
            var date = result.resultDateTime;
            var lastDay = dailyPerformance.days.length > 0 ? new Date(dailyPerformance.days[dailyPerformance.days.length - 1].getTime()) : null;

            if(dailyPerformance.days.length <= 0 || index === 0 && (date.getDate() !== lastDay.getDate() || date.getMonth() !== lastDay.getMonth() || date.getYear() !== lastDay.getYear())) {
              var newDay = new Date(date.getTime());
              newDay.setHours(12, 0, 0, 0);
              dailyPerformance.days.push(newDay);
            }

            //Stop charting inactive treatments
            if(result[key].active || result[key].incrementSends > 0 || results.treatmentDetails.length == 1) {
              //Make sure the value is not null. This ensures we only start showing data after we receive the minimum # of contacts per treatment.
              if(result[key][attr]) {
                var data = {
                  value: result[key][attr],
                  min: result[key][minAttr],
                  max: result[key][maxAttr],
                  date
                };

                if(result[key].sends > 0) {
                  // Dont show values before anything was sent
                  obj.series.push(data);
                }
              }
            }
          }
        });

        dailyPerformance.results.push(obj);
      }
    });

    // If there are gaps in results, add missing days
    for(var i = 0; i < dailyPerformance.programDuration.value; i++) {
      var day = dailyPerformance.days[i];
      var nextDay = dailyPerformance.days[i + 1];

      if(nextDay) {
        if(nextDay.getTime() - day.getTime() > 24 * 60 * 60 * 1000) {
          dailyPerformance.days.splice(i + 1, 0, new Date(day.getTime() + 24 * 60 * 60 * 1000));
        }
      }
      // If there is no next day, add extra days until programDuration is reached
      else {
        var d = new Date(day.getTime() + 24 * 60 * 60 * 1000);
        dailyPerformance.days.push(d);
      }
    }

    /* If there is only one point for a treatment, duplicate the value 1 hour later
     (1 day later if showing days) to make it more visible */
    dailyPerformance.results.forEach(treatment => {
      if(treatment.series.length === 1) {
        var result = cloneDeep(treatment.series[0]);
        if(results.trends && results.trends.length > 700) {
          result.date = new Date(result.date.getTime() + 24 * 60 * 60 * 1000);
        }
        else {
          result.date = new Date(result.date.getTime() + 1 * 60 * 60 * 1000);
        }
        treatment.series.push(result);
      }
    });

    return dailyPerformance;
  } //end: convertResultsToDailyPerformance()

  //----------------------------------------------------------------------------
  // For Ops View data
  convertResultsToCumulativeValues(results, attrToChart) {
    var cumulativeValues = [];

    cumulativeValues.columns = ['resultDateTime', attrToChart];
    cumulativeValues.showRangeSelector = false;

    if(attrToChart === 'opens') cumulativeValues.attrToChart = 'totalOpens';
    else if(attrToChart === 'clickthroughs') cumulativeValues.attrToChart = 'totalClicks';
    else cumulativeValues.attrToChart = attrToChart;

    cumulativeValues.info = {};
    cumulativeValues.info[attrToChart] = {
      subject: '',
      body: '',
      color: '#0086d6'
    };

    var keys = Object.keys(results.trends[0]);

    // Determine if first result is empty
    var showFirstResult = false;
    keys.forEach(key => {
      if(results.trends[0][key].expectedValueCtRate || results.trends[0][key].expectedValueOpenRate) {
        showFirstResult = true;
      }
    });

    // Build program info
    cumulativeValues.programDuration = {
      value: results.params.lengthDays + results.params.postCampaignDays,
      unit: 'days',
      endDate: results.params.endDate
    };

    var obj = {};
    var curDate = showFirstResult ? results.trends[0].resultDateTime : results.trends[1].resultDateTime;

    results.trends.forEach(function(result, i) {
      if(i > 0 || showFirstResult) {
        var resultDate = new Date(result.resultDateTime.getTime());
        resultDate.setHours(12, 0, 0, 0);

        if(curDate.getDate() === resultDate.getDate()) {
          //good enough, since results are ordered?
          obj = {
            resultDateTime: resultDate,
            totalSends: result.totals.sends,
            totalOpens: result.totals.opens,
            totalClicks: result.totals.clickthroughs,
            totalDelivered: result.totals.deliveries
          };
        }
        else {
          cumulativeValues.push(obj);

          // If there are days with no results, fill in gaps
          var dateDifference = result.resultDateTime.getDate() - curDate.getDate();
          if(dateDifference > 1) {
            var date = new Date(curDate);
            for(var i = dateDifference - 1; i > 0; i--) {
              date = new Date(date.getTime() + 24 * 60 * 60 * 1000);
              date.setHours(12, 0, 0, 0);
              var res = {
                resultDateTime: date,
                totalSends: result.totals.sends,
                totalOpens: result.totals.opens,
                totalClicks: result.totals.clickthroughs,
                totalDelivered: result.totals.deliveries
              };

              cumulativeValues.push(res);
            }
          }

          curDate = result.resultDateTime;

          obj = {
            resultDateTime: resultDate,
            totalSends: result.totals.sends,
            totalOpens: result.totals.opens,
            totalClicks: result.totals.clickthroughs,
            totalDelivered: result.totals.deliveries
          };
        }
      }
    });

    cumulativeValues.push(obj);

    // If the program duration is longer than result data, add empty days to chart
    if(cumulativeValues.length < cumulativeValues.programDuration.value) {
      for(var i = cumulativeValues.length; i < cumulativeValues.programDuration.value; i++) {
        obj = {
          resultDateTime: d3.timeDay.offset(cumulativeValues[cumulativeValues.length - 1].resultDateTime, 1),
          totalSends: 0,
          totalOpens: null,
          totalClicks: null,
          totalDelivered: 0
        };
        cumulativeValues.push(obj);
      }
    }

    // Calculate total distribution rates
    var total = {
      clicks: results.latest.totals.clickthroughs,
      opens: results.latest.totals.opens,
      deliveries: results.latest.totals.deliveries
    };

    cumulativeValues.distribution = [
      {
        name: 'Non-Response',
        total: total.deliveries - total.opens,
        rate: (total.deliveries - total.opens) / total.deliveries,
        color: '#fdca4f'
      },
      {
        name: 'Open, No Click',
        total: total.opens - total.clicks,
        rate: (total.opens - total.clicks) / total.deliveries,
        color: '#0086d6'
      },
      {
        name: 'Click',
        total: total.clicks,
        rate: total.clicks / total.deliveries,
        color: '#00965e'
      }
    ];

    return cumulativeValues;
  } //end: convertResultsToCumulativeValues()
}
