// @flow
import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  @Output() complete = new EventEmitter(true);

  baseUrl = '/api/files';

  //Local (private) vars
  _files = [];
  upload = [];
  promises = [];

  static parameters = [HttpClient];
  constructor(http: HttpClient) {
    this.http = http;
  }

  getFiles() {
    return this._files;
  }

  cancelUpload(index) {
    if(this._files[index]) {
      this._files[index].cancel();
    }
  }

  uploadFile(item, uploadedFiles, prefix) {
    var promise = new Promise((resolve, reject) => {
      var file = item.file;
      item.progress = parseInt(0);

      //Get a signed url for uploading based on the content-type.
      var params = new HttpParams();
      params = params.append('prefix', prefix);
      params = params.append('isProblem', item.isProblem ? item.isProblem : 'false');
      params = params.append('name', file.name);
      params = params.append('mimeType', file.type);
      this.http
        .get(`${this.baseUrl}/upload`, { params })
        .toPromise()
        .then(result => {
          item.url = result.url; //the pre-signed url generated by server
          item.method = 'PUT';
          item.headers = [{ name: 'Content-Type', value: file.type }];
          item.withCredentials = false;
          item.upload();

          item.onComplete = (response: string, status: number) => {
            if(status === 200) {
              //Save attachment info, so we can retrieve later.
              var obj = {
                aType: 'S3',
                name: file.name,
                url: result.key //we save the key, not the location
              };
              uploadedFiles.push(obj);
            }
            resolve();
          };
        })
        .catch(e => {
          console.log('Exception getting S3 signed url:', e);
          reject();
        });
    });

    return promise;
  } //end: uploadFile()

  uploadFilesFromComputer(files, uploadedFiles, prefix) {
    if(files && files.length) {
      this._files = files;
      this.promises = [];

      this._files.forEach(file => {
        this.promises.push(this.uploadFile(file, uploadedFiles, prefix));
      });

      Promise.all(this.promises).then(() => {
        //Notify us (Motiva) of file uploads.
        //Currently we only upload DP bounceback report files, so if we
        //start uploading other file types, we need to re-work this notify.
        this.notify(files);
        this.complete.emit();
      });
    }
  }

  getFileUploadedFromComputer(fileKey) {
    var params = new HttpParams();
    params = params.append('fileKey', fileKey);
    this.http
      .get(`${this.baseUrl}/download`, { params })
      .toPromise()
      .then(result => {
        window.open(result.url); //Open browser tab with the response.
      });
  }

  notify(items) {
    var params = { files: [] };
    items.forEach(item => {
      params.files.push({
        name: item.file.name.replace(/(\/|:)/g, '-'), //replace '/' and ':' with '-'
        isProblem: !!item.isProblem
      });
    });

    this.http
      .post(`${this.baseUrl}/notify`, params)
      .toPromise()
      .then(() => {
        //console.log('Notify success.'); //debug
      },
      error => {
        console.log('Notify error:', error); //debug
      });
  }
}
