import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { AuthService } from '../../../../components/auth/auth.service';
import { CampaignService } from '../../../campaign/shared/campaign.service';

import { cloneDeep } from 'lodash';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import moment from 'moment-timezone';

@Component({
  selector: 'fm-report',
  template: require('./fm-report.html')
})
export class FmReportComponent implements OnInit {
  @ViewChild('fmReportTable', { static: true }) fmReportTable: any;
  @ViewChild('campaignTmpl', { static: true }) campaignTmpl: TemplateRef<any>;
  @ViewChild('integerTmpl', { static: true }) integerTmpl: TemplateRef<any>;
  @ViewChild('dateTmpl', { static: true }) dateTmpl: TemplateRef<any>;

  static parameters = [HttpClient, AuthService, CampaignService];
  constructor(http: HttpClient, authService: AuthService, campaignService: CampaignService) {
    this.http = http;
    this.authService = authService;
    this.campaignService = campaignService;
    this.Math = Math;

    this.rows = [];
    this.rowsCopy = [];
    this.page = {
      number: 0,
      numberPlusOne: 1,
      size: 15,
      sizes: [10, 15, 25, 50, 100, 250]
    };

    this.totals = null;
    this.loading = false;
    this.exporting = false;

    this.filter = {
      searchString: '',
      startDate: null,
      endDate: null
    };
    this.errorMsg = null;
  }

  ngOnInit() {
    this.columns = [
      //{ prop: 'fmRuleName', name: 'FM Rule' },
      { prop: 'eloquaCampaignName', name: 'Name', cellClass: 'leftPadded', cellTemplate: this.campaignTmpl },
      { prop: 'total', name: 'Contacts Received', cellClass: 'rightAlign', headerClass: 'frequencyRightAlign', cellTemplate: this.integerTmpl, minWidth: 120, width: 120, maxWidth: 120 },
      { prop: 'pending', name: 'Pending', cellClass: 'rightAlign', headerClass: 'frequencyRightAlign', cellTemplate: this.integerTmpl, minWidth: 120, width: 120, maxWidth: 120 },
      { prop: 'yes', name: 'Sent', cellClass: 'rightAlign', headerClass: 'frequencyRightAlign', cellTemplate: this.integerTmpl, minWidth: 120, width: 120, maxWidth: 120 },
      { prop: 'no', name: 'Blocked', cellClass: 'rightAlign', headerClass: 'frequencyRightAlign', cellTemplate: this.integerTmpl, minWidth: 120, width: 120, maxWidth: 120 },
      { prop: 'fmPriority', name: 'FM Priority', cellClass: 'rightAlign', headerClass: 'frequencyRightAlign', cellTemplate: this.integerTmpl, minWidth: 120, width: 120, maxWidth: 120 },
      { prop: 'last_update', name: 'Last Update', cellTemplate: this.dateTmpl, minWidth: 230, width: 230, maxWidth: 230 }
    ];

    this.initDate();

    //Min date is 12-months ago
    var minDateFull = moment().subtract(12, 'months')
      .startOf('month')
      .format('YYYY-MM-DD HH:mm:ss');

    this.minDate = new Date(minDateFull);
    this.maxDate = new Date(); //today

    this.authService.getCurrentUser().then(user => {
      this.currentUser = user;
    });

    this.userSub = this.authService.currentUserChanged.subscribe(user => {
      this.currentUser = user;
    });

    this.authService.getCurrentOrg().then(org => {
      if(typeof org !== 'undefined') {
        this.currentOrg = org;
        this.getFmReportResults();
      }
    });

    this.orgSub = this.authService.currentOrgChanged.subscribe(org => {
      if(typeof org !== 'undefined') {
        this.currentOrg = org;
        this.getFmReportResults();
      }
    });
  }

  ngOnDestroy() {
    if(this.orgSub) this.orgSub.unsubscribe();
    if(this.userSub) this.userSub.unsubscribe();
  }

  initDate() {
    //Default date range to previous 3 months.
    this.filter.endDate = moment().format('YYYY-MM-DD HH:mm:ss'); //today
    this.filter.startDate = moment().subtract(3, 'months')
      .startOf('month')
      .format('YYYY-MM-DD HH:mm:ss');

    this.newStartDate = new Date(this.filter.startDate);
    this.newEndDate = new Date(this.filter.endDate);
  }

  changeDate(event) {
    this.totals = null;
    this.errorMsg = null;

    //Clear search string when date changes.
    this.filter.searchString = '';
    this.filter.startDate = this.newStartDate.toISOString();
    this.filter.endDate = this.newEndDate.toISOString();
    this.getFmReportResults();
  }

  filterRows() {
    this.rows = [];

    //Filter by search string
    var searchString = this.filter.searchString.toLowerCase();
    if(searchString !== '') {
      function searchFilter(row) {
        return (
          row.fmRuleName && row.fmRuleName.toLowerCase().indexOf(searchString) > -1
          || row.eloquaCampaignName && row.eloquaCampaignName.toLowerCase().indexOf(searchString) > -1
        );
      }
      this.rows = this.rowsCopy.filter(searchFilter);
    }
    else {
      this.rows = this.rowsCopy;
    }
    this.calculateTotals();
  }

  clearFilter() {
    this.filter = {
      searchString: '',
      startDate: null,
      endDate: null,
    };

    this.initDate();

    //Re-run the queries
    this.getFmReportResults();
  }

  getRuleToolTip(key) {
    var entry = this.rows.find(row => row.fmRuleName == key);
    if(entry) return entry.fmRuleDetail;
  }

  //TODO: Needed until we upgrade to core-js v3, which has Promise.allSettled().
  allSettled = promises =>
    Promise.all(promises.map(promise => promise
      .then(value => ({ status: 'fulfilled', value }))
      .catch(reason => ({ status: 'rejected', reason }))
    ));

  getFmReportResults(personaId) {
    if(this.loading) return; //debounce
    this.loading = true;

    this.rows = [];
    this.rowsCopy = [];
    this.page.number = 0;
    this.page.numberPlusOne = 1;

    var params = {
      orgId: this.currentOrg._id,
      startDate: this.filter.startDate,
      endDate: this.filter.endDate
    };

    this.http
      .get('/api/decision-services/frequency-management/report', { params })
      .toPromise()
      .then(data => {
        var promises = [];
        var eloquaCampaignIds = [];

        data.forEach(entry => {
          entry.eloquaCampaignName = entry.eloquaCampaignId; //If we don't find the name below, use the id.

          //Check if we already have this eloquaCampaignId.
          if(!eloquaCampaignIds.find(e => e == entry.eloquaCampaignId)) {
            eloquaCampaignIds.push(entry.eloquaCampaignId);
            promises.push(this.campaignService.getEloquaCampaignName(this.currentOrg._id, entry.eloquaCampaignId));
          }

          //Add the fmRuleName
          var fmRule = this.currentOrg.fmConfig.rules.find(r => r.id == entry.fmRuleId);
          if(fmRule) {
            entry.fmRuleName = fmRule.name;
            entry.fmRuleDetail = `No more than ${fmRule.emailsPerTimePeriod} email${fmRule.emailsPerTimePeriod > 1 ? 's' : ''} every ${fmRule.timePeriodDays} day${fmRule.timePeriodDays > 1 ? 's' : ''}`;
          }
          else {
            entry.fmRuleName = entry.fmRuleId;
          }

          //Add link to Eloqua campaign
          if(this.currentUser.eloqua) {
            entry.link = `${this.currentUser.eloqua._json.urls.base}/Main.aspx#campaigns&id=${entry.eloquaCampaignId}`;
          }
        });

        //Wait for all queries to run before updating.
        this.allSettled(promises).then(eloquaCampaignNames => {
          for(var i = 0; i < eloquaCampaignIds.length; i++) {
            if(eloquaCampaignNames[i].status === 'fulfilled') {
              if(eloquaCampaignNames[i].value) {
                //There can be more than one FM DS in the same Eloqua campaign.
                var matches = data.filter(e => e.eloquaCampaignId == eloquaCampaignIds[i]);
                matches.forEach(d => {
                  d.eloquaCampaignName = eloquaCampaignNames[i].value;
                });
              }
              else {
                console.log(`getEloquaCampaignName(): for Eloqua Campaign #${eloquaCampaignIds[i]} not found.`); //DEBUG
              }
            }
            else {
              console.log(`getEloquaCampaignName(): for Eloqua Campaign #${eloquaCampaignIds[i]} failed with reason: ${eloquaCampaignNames[i].reason}`); //DEBUG
            }
          }

          this.loading = false;
          this.rows = data;
          this.rowsCopy = cloneDeep(this.rows);

          this.onPageSizeChange();
          this.filterRows();
        });
      });
  }

  calculateTotals() {
    this.totals = {
      contacts: 0,
      pending: 0,
      yes: 0,
      no: 0
    };

    this.rows.forEach(row => {
      this.totals.contacts += parseInt(row.total);
      this.totals.pending += parseInt(row.pending);
      this.totals.yes += parseInt(row.yes);
      this.totals.no += parseInt(row.no);
    });
  }

  onPageChange(event) {
    this.page.number = event.offset;
    this.page.numberPlusOne = this.page.number + 1;
    this.onPageSizeChange();
  }

  onPageSizeChange() {
    this.page.totalPages = Math.ceil(this.rows.length / this.page.size);
  }

  onPageNumberChange() {
    this.page.number = this.page.numberPlusOne - 1;
  }

  toggleExpandGroup(group) {
    this.fmReportTable.groupHeader.toggleExpandGroup(group);
  }

  getWidth(rowProperty) {
    let val = this.columns.find(col => col.prop == rowProperty).width;
    return { width: `${val}px` };
  }

  onResize(event) {
    this.columns.find(col => col.prop == event.column.prop).width = event.newValue;
  }

  onSort(event) {
    const rows = [...this.rows];
    const sort = event.sorts[0];

    rows.sort((a, b) => {
      var result = 0;

      //Different sorts depending on type of column.
      switch (sort.prop) {
      case 'eloquaCampaignName':
        const nameA = a[sort.prop].toLowerCase().trim();
        const nameB = b[sort.prop].toLowerCase().trim();
        if(nameA < nameB) {
          result = -1;
        }
        if(nameA > nameB) {
          result = 1;
        }
        break;

      case 'total':
      case 'pending':
      case 'yes':
      case 'no':
      case 'fmPriority':
        const valA = parseInt(a[sort.prop]);
        const valB = parseInt(b[sort.prop]);
        if(valA < valB) {
          result = -1;
        }
        if(valA > valB) {
          result = 1;
        }
        break;

      case 'last_update':
        const dateA = new Date(a[sort.prop]);
        const dateB = new Date(b[sort.prop]);
        result = dateA.getTime() - dateB.getTime();
        break;

      default:
      }

      return result * (sort.dir === 'desc' ? -1 : 1);
    });

    this.rows = rows;
  }

  sumProperty(group, property) {
    var sum = 0;
    group.forEach(item => {
      sum += parseInt(item[property]);
    });
    return sum;
  }

  openEloquaCampaign(link) {
    if(link) window.open(link, 'eloqua');
  }

  export() {
    this.exporting = true;
    var headers = ['FM Rule', 'Campaign ID', 'Campaign Name', 'Contacts Received', 'Pending', 'Yes', 'No', 'FM Priority', 'Last Update'];
    var options = {
      fieldSeparator: ',',
      showLabels: true,
      noDownload: false,
      headers
    };

    var exportRows = this.rows.map(o => ({
      campaignName: o.fmRuleName,
      campaignId: o.eloquaCampaignId,
      emailName: o.eloquaCampaignName,
      emailId: o.total,
      sends: o.pending,
      opens: o.yes,
      openRate: o.no,
      clicks: o.fmPriority,
      lastUpdate: o.last_update
    }));

    var filename = `${this.currentOrg.name} FM Report`;
    new ngxCsv(exportRows, filename, options);
    this.exporting = false;
  }
}
