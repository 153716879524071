// @flow
import { Component, Input, SimpleChanges } from '@angular/core';

import { Campaign } from '../campaign';
import { CampaignService } from '../campaign.service';
import { CampaignFilesService } from '../campaign-files.service';
import { TimeWindowsService } from '../../../time-windows/shared/time-windows.service';

import { ImpersonationService } from '../../../../components/auth/impersonation.service';

@Component({
  selector: 'campaign-time-windows',
  template: require('./campaign-time-windows.html')
})
export class CampaignTimeWindowsComponent {
  @Input() campaign: Campaign;
  @Input() campaignLocal;
  @Input() results;
  @Input() visible;
  @Input() folderName;

  static parameters = [CampaignService, CampaignFilesService, TimeWindowsService, ImpersonationService];
  constructor(campaignService: CampaignService, campaignFilesService: CampaignFilesService, timeWindowsService: TimeWindowsService, impersonationService: ImpersonationService) {
    this.campaignService = campaignService;
    this.campaignFilesService = campaignFilesService;
    this.timeWindowsService = timeWindowsService;
    this.impersonation = impersonationService;
  }

  timeWindows;

  ngOnInit() {
    this.init();
    this.isOpenDelay = false;
    this.isOpenSchedule = false;
    this.isDemo = this.impersonation.isDemoMode();
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'visible') {
        if(this.visible) {
          this.init();

          if(!this.folderName) {
            this.timeWindowsService.buildCampaignTimeWindows(this.campaign, this.campaignLocal, this.timeWindows, this.delayDistribution);
            this.timeWindows.heatMapData.colorScheme = this.selectedColorScheme.colors;
            this.timeWindows.heatMapData.color = this.colorToggle;
          }
          else {
            this.campaignFilesService.getTimeWindowsData(this.folderName).then(response => {
              if(!response.delayDistribution) response.delayDistribution = {};
              this.timeWindowsService.processTimeWindowsData(this.campaign, this.campaignLocal, this.timeWindows, response.delayDistribution, response.timeWindows);
              this.timeWindows.heatMapData.colorScheme = this.selectedColorScheme.colors;
              this.timeWindows.heatMapData.color = this.colorToggle;
            });
          }
        }
      }
    }
  }

  init() {
    if(!this.timeWindows) {
      this.timeWindows = {
        open: false,
        show: false,
        loading: true,
        toggle: this.campaign.optimizationCriterion === 'opens' || this.campaign.optimizationCriterion === 'sendTime' ? 'openRate' : 'clickRate',
        config: {
          rowMap: {},
          verticalLabels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          horizontalLabels: ['12a', '1a', '2a', '3a', '4a', '5a', '6a', '7a', '8a', '9a', '10a', '11a', '12p', '1p', '2p', '3p', '4p', '5p', '6p', '7p', '8p', '9p', '10p', '11p']
        },
        topSlots: [],
        bottomSlots: [],
        colorScale: null,
        textColorScale: null,
        heatMapData: {
          color: 'circles'
        }
      };

      this.delayDistribution = {};
    }

    this.colorSchemes = [{
      gradient: 'linear-gradient(to right, #ffffdd, #3e9583, #1f2d86)',
      colors: ['#ffffdd', '#3e9583', '#1f2d86']
    }, {
      gradient: 'linear-gradient(to right, rgba(1, 147, 207, 0.1), rgba(1, 147, 207, 1))',
      colors: ['rgba(1, 147, 207, 0.1)', 'rgba(1, 147, 207, 1)']
    }, {
      gradient: 'linear-gradient(to right, #ea5044, #af7889, #0193cf)',
      colors: ['#ea5044', '#af7889', '#0193cf']
    }];

    this.selectedColorScheme = this.colorSchemes[0];
    this.colorToggle = 'circles';

    this.timeWindows.heatMapData.colorScheme = this.selectedColorScheme.colors;
    this.timeWindows.heatMapData.color = this.colorToggle;
  }

  changeColorScheme(colorScheme) {
    this.timeWindows.loading = true;
    this.selectedColorScheme = colorScheme;

    if(this.timeWindows.colorScale) {
      this.timeWindows.colorScale.range(colorScheme.colors);
    }
    this.timeWindows.heatMapData.colorScheme = colorScheme.colors;

    setTimeout(() => {
      this.timeWindows.loading = false;
    });
  }

  timeWindowsColorToggleChanged() {
    this.timeWindows.loading = true;
    this.timeWindows.heatMapData.color = this.colorToggle;
    setTimeout(() => {
      this.timeWindows.loading = false;
    });
  }
}
