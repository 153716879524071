import { Component, ElementRef, HostListener, Input, SimpleChanges, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'viz-schedule-chart',
  template: '<div></div>'
})
export class ScheduleChartComponent implements OnInit {
  @Input() data;
  @Input() height;
  @Input() visible;

  @HostListener('window:resize', ['$event'])
  onResize() {
    //If not vislble, don't resize.
    if(!(this.htmlElement.offsetParent !== null)) return;
    var newWidth = this.htmlElement.parentElement.clientWidth;

    this.svg.attr('width', newWidth);
    this.width = +this.svg.attr('width') - this.margin.left - this.margin.right;

    if(this.data) {
      this.x0 = d3.scaleLinear().range([20, this.width - 30]);
      this.xAxis = d3.axisBottom(d3.scaleLinear().range([0, this.width]));
      this.g.attr('width', this.width);
      this.render(this.data);
    }
  }

  margin = { top: 20, left: 45, bottom: 20, right: 60 };

  htmlElement: HTMLElement;
  svg;

  static parameters = [ElementRef];
  constructor(element: ElementRef) {
    this.htmlElement = element.nativeElement;
    this.host = d3.select(element.nativeElement);
  }

  ngOnInit() {
    //Set width to 100% of parent container.
    this.width = this.htmlElement.parentElement.clientWidth;

    //append the svg object
    this.host.html('');
    this.svg = this.host
      .append('svg')
      .attr('height', this.height ? this.height : '250')
      .attr('width', this.width);

    //Add margin
    this.width = +this.svg.attr('width') - this.margin.left - this.margin.right;
    this.height = +this.svg.attr('height') - this.margin.top - this.margin.bottom;

    this.x0 = d3.scaleLinear().range([20, this.width - 30]);
    this.x1 = d3.scaleBand().padding(0);
    this.y = d3.scaleLinear().rangeRound([this.height, 0]);

    this.xAxis = d3.axisBottom(d3.scaleLinear().range([0, this.width]));

    this.g = this.svg
      .append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('overflow', 'hidden');

    this.tooltip = this.host
      .append('div')
      .attr('class', 'chart-tooltip')
      .style('opacity', 0);

    this.render(this.data);
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'data' && this.data && this.g) {
        this.render(this.data);
      }
      else if(propName === 'visible') {
        if(this.visible) {
          setTimeout(() => {
            this.onResize();
          });
        }
      }
    }
  }

  render(data) {
    this.g.selectAll('*').remove();
    if(!data || !data.results || data.results.length === 0) return;

    var keys = ['scheduledSends', 'sent'];
    var xName = 'date';

    var format = d3.timeFormat('%a, %b %e');

    if(data.length > 16) {
      format = d3.timeFormat('%b %e');
    }

    var x1Width = this.width / data.results.length / 2;

    this.x0.domain([0, data.results.length]);
    this.x1.domain(keys).rangeRound([0, x1Width]);

    this.y
      .domain([
        0,
        d3.max(data.results, d => d3.max(keys, key => d[key]))
      ])
      .nice();

    var xAxisValues = this.getxAxisValues(data.results, xName);

    this.g
      .append('g')
      .attr('class', 'xAxis')
      .attr('transform', `translate(0,${this.height})`)
      .call(
        this.xAxis
          .tickValues(xAxisValues)
          .tickFormat(format)
          .tickSizeOuter(0)
      )
      .selectAll('.tick')
      .attr('transform', (d, index) => `translate(${this.x0(index) + x1Width / 2}, 0)`);

    this.g
      .append('g')
      .attr('class', 'yAxis')
      .call(
        d3
          .axisLeft(this.y)
          .ticks(5)
          .tickFormat(d => d3.format(',')(d))
      )
      .selectAll('.tick:not(:first-of-type) line')
      .attr('stroke', '#777')
      .attr('opacity', '0.2')
      .attr('x2', this.width);

    this.g
      .append('g')
      .attr('class', 'bars')
      .selectAll('g')
      .data(data.results)
      .enter()
      .append('g')
      .attr('transform', (d, index) => `translate(${this.x0(index)},0)`)
      .selectAll('rect')
      .data((d, index) => keys.map(key => ({ key, value: { num: d[key], index } })))
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('id', d => `bar_${d.key}`)
      .attr('x', d => this.x1(d.key))
      .attr('y', d => this.y(d.value.num))
      .attr('fill', d => data.colors[d.key])
      .attr('width', this.x1.bandwidth())
      .attr('height', d => this.height - this.y(d.value.num))
      .attr('cursor', 'pointer')
      .on('mouseover', d => {
        this.hideTooltip();
        this.showTooltip(d.key, d.value.index, d.value.num);
      })
      .on('mouseout', () => {
        this.hideTooltip();
      });
  } //end: render()

  //----------------------------------------------------------------------------
  getxAxisValues(data, xName) {
    var xAxisValues = data.map(d => new Date(d[xName]));

    xAxisValues = this.removeNegativeAxisValues(xAxisValues);
    return xAxisValues;
  }

  removeNegativeAxisValues(xAxisValues) {
    //Remove negative axis values
    var values = [];
    xAxisValues.forEach(value => {
      if(this.x0(value) >= 0) values.push(value);
    });

    return values;
  }

  convertCamelCase(str) {
    var result = str.replace(/([A-Z])/g, ' $1');
    result = result.charAt(0).toUpperCase() + result.slice(1);
    return result;
  }

  showTooltip(key, index, value) {
    if(index >= 0 && this.data && this.data.results && this.data.results[index]) {
      var valFormat = d3.format(',');
      var format = d3.timeFormat('%A, %B %d, %Y');

      this.tooltip
        .transition()
        .duration(200)
        .style('opacity', 1);

      this.tooltip
        .html(
          `<p class="date"><strong>${format(this.data.results[index].date)}</strong></p>`
            + `<p class="result"><span class="faded vLabel">${this.convertCamelCase(key)}:</span><strong>${valFormat(value)}</strong></p>`
        )
        .style('left', `${this.x0(index) + this.x1(key) - 30}px`)
        .style('top', '-50px');
    }
  }

  hideTooltip() {
    this.tooltip
      .transition()
      .duration(200)
      .style('opacity', 0);
  }
}
