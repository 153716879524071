import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ngxCsv } from 'ngx-csv/ngx-csv';

import { CampaignService } from '../campaign/shared/campaign.service';
import { CampaignEmailService } from '../campaign/shared/campaign-email.service';

import { cloneDeep, sortBy } from 'lodash';

@Component({
  selector: 'active',
  template: require('./active.html')
})
export class ActiveComponent implements OnInit {
  @ViewChild('campaignTmpl', { static: true }) campaignTmpl: TemplateRef<any>;
  @ViewChild('checkBoxTmpl', { static: true }) checkBoxTmpl: TemplateRef<any>;
  @ViewChild('integerTmpl', { static: true }) integerTmpl: TemplateRef<any>;
  @ViewChild('percentTmpl', { static: true }) percentTmpl: TemplateRef<any>;
  @ViewChild('dateTmpl', { static: true }) dateTmpl: TemplateRef<any>;

  ngxCsv: ngxCsv;

  static parameters = [CampaignService, CampaignEmailService, HttpClient];
  constructor(campaignService: CampaignService, campaignEmailService: CampaignEmailService, http: HttpClient) {
    this.campaignService = campaignService;
    this.campaignEmailService = campaignEmailService;
    this.http = http;

    this.allActiveCampaigns = [];

    this.showStatuses = [];
    this.allStatuses = [];
    this.searchString = '';
    this.lookBack = 7;

    this.rows = [];
    this.loading = false;

    this.sentActiveEmailsMsg = '';
  }

  ngOnInit() {
    this.campaignService.allStatuses().forEach(status => {
      this.allStatuses.push({
        name: this.campaignService.decodeStatus(status),
        value: true
      });
      this.showStatuses.push(this.campaignService.decodeStatus(status));
    });

    this.allStatuses = sortBy(this.allStatuses, 'name');

    //Column defs for the grid
    this.columns = [
      { prop: 'organizationName', name: 'Organization' },
      { prop: 'name', cellTemplate: this.campaignTmpl },
      { prop: 'statusName', name: 'Status', width: 70 },
      { prop: 'displayOptimization', name: 'Optimization AI', width: 90 },
      { prop: 'emailCount', name: 'Options', cellClass: 'centerAlign', headerClass: 'centerAlign', width: 29 },
      { prop: 'isMotionControlled', name: 'End Date', cellClass: 'centerAlign', headerClass: 'centerAlign', cellTemplate: this.checkBoxTmpl, width: 36 },
      { prop: 'overrideSendFrequencyCheck', name: 'Override FM', cellClass: 'centerAlign', headerClass: 'centerAlign', cellTemplate: this.checkBoxTmpl, width: 50 },
      { prop: 'uniqueContactCount', name: 'Contacts', cellClass: 'rightAlign', headerClass: 'rightAlign', cellTemplate: this.integerTmpl, width: 60 },
      { prop: 'sends', name: 'Sends', cellClass: 'rightAlign', headerClass: 'rightAlign', cellTemplate: this.integerTmpl, width: 60 },
      { prop: 'openRate', name: 'Open Rate', cellClass: 'rightAlign', headerClass: 'rightAlign', cellTemplate: this.percentTmpl, width: 50 },
      { prop: 'clickthroughRate', name: 'Click Rate', cellClass: 'rightAlign', headerClass: 'rightAlign', cellTemplate: this.percentTmpl, width: 50 },
      { prop: 'statusDate', name: 'Last Update', cellTemplate: this.dateTmpl }
    ];

    this.getActiveCampaigns();
  } //end: ngOnInit()

  getActiveCampaigns() {
    this.loading = true;
    this.campaignService
      .active(this.lookBack, false)
      .toPromise()
      .then(campaigns => {
        console.log('active campaigns', campaigns);
        campaigns.forEach(campaign => {
          campaign.statusName = this.campaignService.decodeStatus(campaign.status);

          //Add nice version of the optimizer type.
          if(
            campaign.optimizationCriterion !== 'sendTime'
            && campaign.emailCount === 1
            && typeof campaign.sendWinnerToAllRemainingImmediately !== 'undefined'
            && campaign.sendWinnerToAllRemainingImmediately
          ) {
            campaign.displayOptimization = 'Simple';
          }
          else if(campaign.optimizationCriterion === 'sendTime') {
            campaign.displayOptimization = 'Send Time';
          }
          else {
            campaign.displayOptimization = 'Message Testing';
          }

          //Calculate the sends and rates to show
          var sends = 0, opens = 0, clicks = 0, found = false;
          for(const emailId in campaign.latestResults) {
            found = true;
            sends += campaign.latestResults[emailId].sends;
            opens += (campaign.latestResults[emailId].sends * campaign.latestResults[emailId].openRate);
            clicks += (campaign.latestResults[emailId].sends * campaign.latestResults[emailId].clickthroughRate);
          }

          if(found) {
            campaign.sends = sends;
            campaign.openRate = (sends > 0 ? opens/sends : 0);
            campaign.clickthroughRate = (sends > 0 ? clicks/sends : 0);
          }
        });

        this.campaignService.addOrganizationInfo(campaigns).then(() => {
          this.allActiveCampaigns = cloneDeep(campaigns); //Make a copy.
          this.rows = campaigns;
          this.loading = false;
        });
      });
  }

  filterChanged() {
    this.rows = [];
    this.showStatuses = [];
    this.allStatuses.forEach(status => {
      if(status.value) {
        this.showStatuses.push(status.name);
      }
    });

    var tempFilteredCampaigns = [];
    this.allActiveCampaigns.forEach(campaign => {
      this.showStatuses.forEach(status => {
        if(campaign.statusName === status) {
          tempFilteredCampaigns.push(campaign);
        }
      });
    });

    // Filter by search string
    var searchString = this.searchString.toLowerCase();
    if(searchString !== '') {
      function searchFilter(campaign) {
        return campaign.name && campaign.name.toLowerCase().indexOf(searchString) > -1 || campaign.organizationName && campaign.organizationName.toLowerCase().indexOf(searchString) > -1;
      }
      this.rows = tempFilteredCampaigns.filter(searchFilter);
    }
    else {
      this.rows = tempFilteredCampaigns;
    }
  } //end: filterChanged()

  clearFilter() {
    this.allStatuses.forEach(statusObj => {
      statusObj.value = true;
    });
    this.searchString = '';
    this.filterChanged();
  }

  changeLookBack(days) {
    //This need a requery...
    this.lookBack = days;
    this.clearFilter();
    this.getActiveCampaigns();
  }

  //For sending the Active Campaigns Email -- mostly for testing
  sendActiveEmails() {
    if(window.confirm('Are you sure you want to send the Active Emails emails?  (You could potentially send a bunch of emails to our customers.)')) {
      this.campaignEmailService.sendActiveCampaignsEmails().then(
        () => {
          this.sentActiveEmailsMsg = 'Successfully started the process to send active campaign emails on:';
          this.sentActiveEmailsDate = new Date();
        },
        error => {
          this.sentActiveEmailsMsg = 'Failed to start the process to send active campaign emails.';
          console.log('Error sending active campaigns emails', error);
        }
      );
    }
  }

  getActiveContactsForOrg(params) {
    var promise = new Promise(resolve => {
      // Get number of active contacts for all campaigns
      this.http
        .get('/api/olap/allCampaignActiveContacts', { params })
        .toPromise()
        .then(activeContactCounts => {
          activeContactCounts.forEach(campaignActiveContactCount => {
            this.allCampaignActiveContactCounts[campaignActiveContactCount.motiva_campaign_id] = campaignActiveContactCount.active_contact_count;
          });
          resolve();
        }, err => {
          console.log(`Error getting all campaign active contacts for org: ${params.orgId}`, err);
          // We want to continue the export with any data we do get, so resolve
          // instead of failing here.
          resolve();
        });
    });
    return promise;
  }

  getCampaignStatsForOrg(params) {
    var promise = new Promise(resolve => {
      // Get total open and click counts and deliveries for all campaigns
      this.http
        .get('/api/olap/allCampaignAdminViewStats', { params })
        .toPromise()
        .then(stats => {
          stats.forEach(campaignStats => {
            this.allCampaignStats[campaignStats.motiva_campaign_id] = {
              sends: campaignStats.total_sends,
              opens: campaignStats.total_opens,
              clicks: campaignStats.total_clicks,
              deliveries: campaignStats.total_sends - campaignStats.total_bouncebacks
            };
          });
          resolve();
        }, err => {
          console.log(`Error getting all campaign stats for org: ${params.orgId}`, err);
          // We want to continue the export with any data we do get, so resolve
          // instead of failing here.
          resolve();
        });
    });
    return promise;
  }

  getAllExportDataForOrg(params) {
    var promises = [];

    promises.push(this.getActiveContactsForOrg(params));
    promises.push(this.getCampaignStatsForOrg(params));

    return Promise.all(promises);
  }

  buildExportDataForOrg(orgId) {
    var promise = new Promise(resolve => {
      var params = {
        orgId
      };

      this.allCampaignActiveContactCounts = {};
      this.allCampaignStats = {};

      this.getAllExportDataForOrg(params).then(() => {
        this.rows.forEach(row => {
          if(row.organizationId == orgId) {
            if(this.allCampaignActiveContactCounts[row.campaignId] && row.uniqueContactCount > 0) {
              var activeContactCount = parseInt(this.allCampaignActiveContactCounts[row.campaignId], 10);
            }

            if(this.allCampaignStats[row.campaignId]) {
              var sends = this.allCampaignStats[row.campaignId].sends;
              var totalUniqueOpens = this.allCampaignStats[row.campaignId].opens;
              var totalUniqueClicks = this.allCampaignStats[row.campaignId].clicks;
              var deliveries = this.allCampaignStats[row.campaignId].deliveries;
            }

            this.dataToExport.push({
              organization: row.organizationName,
              name: row.name,
              optimization: row.displayOptimization,
              //approximateStartDate: approximateStartDate ? approximateStartDate : '',
              status: row.statusName,
              emailCount: row.emailCount ? row.emailCount : '',
              isMotionControlled: row.isMotionControlled,
              overrideSendFrequencyCheck: row.overrideSendFrequencyCheck,
              uniqueContactCount: row.uniqueContactCount,
              percentActiveContacts: !isNaN(activeContactCount) && row.uniqueContactCount ? `${(activeContactCount / row.uniqueContactCount * 100).toFixed(2)}%` : '',
              percentInactiveContacts: !isNaN(activeContactCount) && row.uniqueContactCount ? `${((row.uniqueContactCount - activeContactCount) / row.uniqueContactCount * 100).toFixed(2)}%` : '',
              sends: !isNaN(sends) ? sends : '',
              deliveries: !isNaN(deliveries) ? deliveries : '',
              totalUniqueOpens: !isNaN(totalUniqueOpens) ? totalUniqueOpens : '',
              openRate: !isNaN(row.openRate) ? `${(row.openRate * 100).toFixed(2)}%` : '',
              totalUniqueClicks: !isNaN(totalUniqueClicks) ? totalUniqueClicks : '',
              clickthroughRate: !isNaN(row.clickthroughRate) ? `${(row.clickthroughRate * 100).toFixed(2)}%` : '',
              statusDate: row.statusDate
            });
          }
        });

        resolve();
      });
    });
    return promise;
  }

  buildExportData() {
    this.dataToExport = [];
    var promises = [];
    var orgIds = [];

    this.rows.forEach(row => {
      if(orgIds.indexOf(row.organizationId) < 0) {
        orgIds.push(row.organizationId);

        promises.push(this.buildExportDataForOrg(row.organizationId));
      }
    });

    return Promise.all(promises);
  }

  exportData() {
    this.loadingExportData = true;
    var headers = [
      'Organization',
      'Name',
      'Optimization AI',
      //'Approx. Start Date',
      'Status',
      'Options',
      'End Date',
      'Override FM',
      'Contacts',
      'Active Contacts %',
      'Inactive Contacts %',
      'Sends',
      'Deliveries',
      'Total Unique Opens',
      'Open Rate',
      'Total Unique Clicks',
      'Click Rate',
      'Last Update'];

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      noDownload: false,
      headers: headers
    };

    var date = new Date().toLocaleDateString('en-US');
    var filename = `Active Campaigns on ${date}`;

    this.buildExportData().then(() => {
      new ngxCsv(this.dataToExport, filename, options);
      this.loadingExportData = false;
    });
  }
}
