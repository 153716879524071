// @flow
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../components/auth/auth.service';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'generator',
  template: require('./generator.html')
})
export class GeneratorComponent implements OnInit {
  @ViewChild('generatorTabs', { static: false }) generatorTabs: TabsetComponent;

  static parameters = [AuthService];
  constructor(authService: AuthService) {
    this.authService = authService;
    this.currentOrg = { enableGenerator: true, enableBrandVoices: true };
    this.generatorType = 'subjectLine';
  }

  ngOnInit() {
    this.authService.getCurrentOrg().then(org => {
      this.currentOrg = org;
      this.checkTabs();
    });

    //If the page is loaded directly.
    this.orgSub = this.authService.currentOrgChanged.subscribe(org => {
      this.currentOrg = org;
      this.checkTabs();
    });    
  }

  changeGeneratorType(generatorType) {
    this.generatorType = generatorType;
  }

  checkTabs() {
    if(this.currentOrg && !this.currentOrg.enableBrandVoices && this.generatorTabs.tabs.length > 1) {
      this.generatorTabs.tabs[1].active = true;
      this.generatorTabs.tabs.pop(); //last tab
    }

    if(this.currentOrg && !this.currentOrg.enableGenerator && this.generatorTabs.tabs.length > 1) {
      this.generatorTabs.tabs[1].active = true;
      this.generatorTabs.tabs.splice(0, 1); //first tab
    }
  }
}
