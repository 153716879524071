import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { DirectivesModule } from '../../components/directives.module';

import { GlobalTimeWindowsComponent } from './global-time-windows/global-time-windows.component';

const timeWindowsRoutes: Routes = [
  {
    path: 'timewindows',
    component: GlobalTimeWindowsComponent
  }
];

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forChild(timeWindowsRoutes),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    CollapseModule.forRoot(),
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    DirectivesModule,
    FormsModule
  ],
  declarations: [
    GlobalTimeWindowsComponent
  ]
})
export class TimeWindowsModule {}
