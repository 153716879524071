import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Clipboard } from '@angular/cdk/clipboard';
import { BsModalService } from 'ngx-bootstrap/modal';

import { AuthService } from '../../components/auth/auth.service';
import { OrgService } from '../../components/auth/org.service';
import { ImpersonationService } from '../../components/auth/impersonation.service';
import { PersonaService } from '../who/shared/persona.service';

import { EmailDetailsComponent } from './email-details/email-details.component';
import { PerformanceBenchmarkComponent } from './performance-benchmark/performance-benchmark.component';
import { PerformanceFiltersComponent } from './performance-filters/performance-filters.component';

import { cloneDeep } from 'lodash';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import moment from 'moment-timezone';

@Component({
  selector: 'performance',
  template: require('./performance.html'),
  styles: [require('./performance.scss')]
})
export class PerformanceComponent implements OnInit {
  @ViewChild('performanceTable', { static: true }) table: any;
  @ViewChild('integerTmpl', { static: true }) integerTmpl: TemplateRef<any>;
  @ViewChild('uorTmpl', { static: true }) uorTmpl: TemplateRef<any>;
  @ViewChild('uctrTmpl', { static: true }) uctrTmpl: TemplateRef<any>;
  @ViewChild('unsubTmpl', { static: true }) unsubTmpl: TemplateRef<any>;
  @ViewChild('dateTmpl', { static: true }) dateTmpl: TemplateRef<any>;
  @ViewChild('emailTmpl', { static: true }) emailTmpl: TemplateRef<any>;

  static parameters = [ActivatedRoute, Router, HttpClient, Clipboard, BsModalService, AuthService, OrgService, ImpersonationService, PersonaService];
  constructor(route: ActivatedRoute, router: Router, http: HttpClient, clipboard: Clipboard, modalService: BsModalService, authService: AuthService, orgService: OrgService, impersonation: ImpersonationService, personaService: PersonaService) {
    this.route = route;
    this.router = router;
    this.http = http;
    this.clipboard = clipboard;
    this.modalService = modalService;
    this.authService = authService;
    this.orgService = orgService;
    this.impersonation = impersonation;
    this.personaService = personaService;
    this.Math = Math;

    this.rows = [];
    this.rowsCopy = [];
    this.page = {
      number: 0,
      numberPlusOne: 1,
      size: 15,
      sizes: [10, 15, 25, 50, 100, 250]
    };

    this.totals = null;
    this.loading = false;
    this.exporting = false;

    this.filters = [];
    this.filter = {
      searchString: '',
      startDate: null,
      endDate: null,
      persona: 'all',
      type: 'baseline',
      benchmark: null
    };
    this.filterNameParam = null;
    this.loadingFilters = false;

    this.compare = null;
    this.performanceToggle = 'baseline';
    this.benchmark = null;
    this.baseline = null;
    this.loadingBaseline = false;

    this.personas = [];
    this.loadingPersonas = false;

    this.isShareOpen = false;
    this.errorMsg = null;

    this.AND_STR = ' AND ';
    this.OR_STR = ' OR ';
    this.NOT_STR = 'NOT ';
  }

  ngOnInit() {
    this.authService.isAdmin().then(isAdmin => {
      this.columns = [
        //{ prop: 'eloqua_campaign_name', name: 'Campaign' },
        { prop: 'email_name', name: 'Name', cellClass: 'leftPadded' },
        //{ prop: 'sends', name: 'Total Sent', cellClass: 'rightAlign', headerClass: 'rightAlign', cellTemplate: this.integerTmpl, minWidth: 120, width: 120, maxWidth: 120 },
        { prop: 'delivered', name: 'Total Delivered', cellClass: 'rightAlign', headerClass: 'rightAlign', cellTemplate: this.integerTmpl, minWidth: 120, width: 120, maxWidth: 120 },
        //{ prop: 'opens', name: 'Opens', cellClass: 'rightAlign', headerClass: 'rightAlign', cellTemplate: this.integerTmpl },
        { prop: 'openRate', name: 'UOR', cellClass: 'rightAlign', headerClass: 'rightAlign', cellTemplate: this.uorTmpl, minWidth: 100, width: 100, maxWidth: 100 },
        //{ prop: 'clicks', name: 'Clicks', cellClass: 'rightAlign', headerClass: 'rightAlign', cellTemplate: this.integerTmpl },
        { prop: 'clickRate', name: 'UCTR', cellClass: 'rightAlign', headerClass: 'rightAlign', cellTemplate: this.uctrTmpl, minWidth: 100, width: 100, maxWidth: 100 },
        //{ prop: 'unsubscribes', name: 'Unsubs', cellClass: 'rightAlign', headerClass: 'rightAlign', cellTemplate: this.integerTmpl },
        { prop: 'unsubRate', name: 'UNSUB', cellClass: 'rightAlign', headerClass: 'rightAlign', cellTemplate: this.unsubTmpl, minWidth: 100, width: 100, maxWidth: 100 },
        { prop: 'last_update', name: 'Last Update', cellTemplate: this.dateTmpl, minWidth: 220, width: 220, maxWidth: 220 },
        { prop: 'email_id', name: '', cellTemplate: this.emailTmpl, minWidth: 120, width: 120, maxWidth: 120 }
      ];
    });

    this.isDemo = this.impersonation.isDemoMode();
    if(this.isDemo) {
      this.loadDemoData();
      return;
    }

    //Default date range to previous 3 months.
    this.filter.endDate = moment().subtract(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD HH:mm:ss');
    this.filter.startDate = moment().subtract(3, 'months')
      .startOf('month')
      .format('YYYY-MM-DD HH:mm:ss');

    this.newStartDate = new Date(this.filter.startDate);
    this.newEndDate = new Date(this.filter.endDate);

    //Baseline is previous 12-months.
    this.endDateBaseline = this.filter.endDate;
    this.startDateBaseline = moment().subtract(12, 'months')
      .startOf('month')
      .format('YYYY-MM-DD HH:mm:ss');

    //Min is 24 months ago
    var minDateString = moment().subtract(24, 'months')
      .startOf('month')
      .format('YYYY-MM-DD HH:mm:ss');

    this.minDate = new Date(minDateString);

    //Max is today
    var maxDateString = moment()
      .format('YYYY-MM-DD HH:mm:ss');

    this.maxDate = new Date(maxDateString);

    //debug
    //console.log(`--- filter.startDate = ${this.filter.startDate}`);
    //console.log(`--- filter.endDate = ${this.filter.endDate}`);
    //console.log('------------------');
    //console.log(`--- startDateBaseline = ${this.startDateBaseline}`);
    //console.log(`--- endDateBaseline = ${this.endDateBaseline}`);
    //console.log('------------------');
    //console.log(`--- minDateString = ${minDateString}`);
    //console.log(`--- maxDateString = ${maxDateString}`);

    this.authService.getCurrentOrg().then(org => {
      if(typeof org !== 'undefined') {
        this.currentOrg = org;
        this.getPersonas();
        this.getBaselinePerformance(true);
        this.getPerformanceResults();
        this.getPerformanceFilters();
      }
    });

    this.orgSub = this.authService.currentOrgChanged.subscribe(org => {
      if(typeof org !== 'undefined') {
        this.currentOrg = org;
        this.getPersonas();
        this.getBaselinePerformance(true);
        this.getPerformanceResults();
        this.getPerformanceFilters();
      }
    });

    //Get the query parameters.
    this.paramSub = this.route.queryParams.subscribe(params => {
      this.pageUrl = window.location.href;
      if(typeof params.name === 'undefined' || params.name === '') {
        return;
      }
      this.filterNameParam = params.name;
    });
  }

  ngOnDestroy() {
    if(this.orgSub) this.orgSub.unsubscribe();
    if(this.paramSub) this.paramSub.unsubscribe();
  }

  getPerformanceFilters() {
    if(this.loadingFilters) return; //debounce
    this.loadingFilters = true;
    this.orgService.getPerformanceFilters(this.currentOrg._id).toPromise()
      .then(filters => {
        filters.sort((a, b) => a.name > b.name ? 1 : b.name > a.name ? -1 : 0);
        this.filters = filters;
        this.checkFilterParam();
        this.loadingFilters = false;
      });
  }

  checkFilterParam() {
    if(this.filterNameParam) {
      var savedFilter = this.filters.find(f => f.name === this.filterNameParam);
      if(savedFilter) {
        this.selectFilter(savedFilter);
      }
      else {
        //Remove the url query param
        this.router.navigate([], { queryParams: { name: null }, queryParamsHandling: 'merge' });
        this.errorMsg = `Saved filter not found: ${this.filterNameParam}`;
      }
      this.filterNameParam = null;
    }
  }

  toggleShare() {
    this.pageUrl = window.location.href;
    this.isShareOpen = !this.isShareOpen;
    if(this.isShareOpen) this.copyMsg = '';
  }

  copyUrl() {
    //Copy the url to the clipboard
    this.clipboard.copy(this.pageUrl);
    this.copyMsg = 'URL copied to the clipboard.';

    //Close popover after a bit
    setTimeout(() => {
      this.isShareOpen = false;
    }, 1800);
  }

  selectFilter(filter) {
    this.totals = null;
    this.baseline = null;
    this.benchmark = null;
    this.compare = null;
    this.errorMsg = null;

    this.filter = JSON.parse(JSON.stringify(filter)); //deep copy

    this.newStartDate = new Date(this.filter.startDate);
    this.newEndDate = new Date(this.filter.endDate);

    //Set the type: baseline or benchmark
    this.performanceToggle = this.filter.type ? this.filter.type : 'baseline';

    //If benchmark, set the benchmark fields.
    if(this.performanceToggle === 'benchmark' && typeof this.filter.benchmark !== 'undefined' && this.filter.benchmark) {
      this.benchmark = this.filter.benchmark;
      this.compare = this.benchmark;
    }

    this.getPerformanceResults(this.filter.persona === 'all' ? null : this.filter.persona.personaId);
    this.getBaselinePerformance(false, this.filter.persona === 'all' ? null : this.filter.persona.personaId);

    //Add url query param
    this.router.navigate([], { queryParams: { name: this.filter.name }, queryParamsHandling: 'merge' });
  }

  getPersonas() {
    if(this.loadingPersonas) return; //debounce
    this.loadingPersonas = true;
    this.personaService.getPersonas(this.currentOrg._id).then(personas => {
      //console.log('---- got personas:', personas); //debug
      this.personas = personas;
      this.loadingPersonas = false;
    });
  }

  changeDate(event) {
    this.totals = null;
    this.errorMsg = null;

    //Clear search string when date changes.
    this.filter.searchString = '';
    this.filter.name = null;

    //Remove the url query param
    this.router.navigate([], { queryParams: { name: null }, queryParamsHandling: 'merge' });

    this.filter.startDate = this.newStartDate.toISOString();
    this.filter.endDate = this.newEndDate.toISOString();
    this.getPerformanceResults();
  }

  filterRowsAndBaseline() {
    this.filterRows(true); //baseline
    this.filterRows(false);
  }

  filterRows(isBaseline) {
    if(isBaseline) {
      this.rowsBaseline = [];
    }
    else {
      this.rows = [];
    }

    //Filter by search string
    if(this.filter.searchString !== '') {
      var searchString;
      //Base search function with no operators -- can be overloaded below
      var searchFilter = (row) =>
        row.email_name && row.email_name.toLowerCase().indexOf(searchString) > -1
          || row.eloqua_campaign_name && typeof row.eloqua_campaign_name.toLowerCase === 'function' && row.eloqua_campaign_name.toLowerCase().indexOf(searchString) > -1
        ;

      //Support AND, OR, and NOT operators
      var hasAND = this.filter.searchString.includes(this.AND_STR);
      var hasOR = this.filter.searchString.includes(this.OR_STR);
      var hasNOT = this.filter.searchString.includes(this.NOT_STR);

      //If doesn't have AND, OR, or NOT operators.
      if(!hasAND && !hasOR && !hasNOT) {
        searchString = this.filter.searchString.toLowerCase();
      }
      //If only has NOT operator.
      else if(!hasAND && !hasOR && hasNOT) {
        searchString = this.filter.searchString.substring(this.NOT_STR.length).toLowerCase();
        var searchFilter = (row) =>
          row.email_name && row.email_name.toLowerCase().indexOf(searchString) === -1
            && row.eloqua_campaign_name && typeof row.eloqua_campaign_name.toLowerCase === 'function' && row.eloqua_campaign_name.toLowerCase().indexOf(searchString) === -1
          ;
      }
      //If only OR operator.
      else if(!hasAND && hasOR) {
        searchString = this.filter.searchString.slice(); //make a copy
        var onlyOrParts = searchString.split(this.OR_STR);

        searchFilter = (row) => {
          var found = false;
          //Must find one of these (OR)
          for(var i = 0; i < onlyOrParts.length; i++) {
            var onlyOrPart = onlyOrParts[i];
            var isNOT = onlyOrPart.includes(this.NOT_STR); //handle NOT
            onlyOrPart = isNOT ? onlyOrPart.substring(this.NOT_STR.length).toLowerCase() : onlyOrPart.toLowerCase();
            if(onlyOrPart !== '') {
              if(isNOT) {
                if(row.email_name && row.email_name.toLowerCase().indexOf(onlyOrPart) === -1
                   && row.eloqua_campaign_name && typeof row.eloqua_campaign_name.toLowerCase === 'function' && row.eloqua_campaign_name.toLowerCase().indexOf(onlyOrPart) === -1) {
                  found = true;
                  break;
                }
              }
              else {
                if(row.email_name && row.email_name.toLowerCase().indexOf(onlyOrPart) > -1
                   || row.eloqua_campaign_name && typeof row.eloqua_campaign_name.toLowerCase === 'function' && row.eloqua_campaign_name.toLowerCase().indexOf(onlyOrPart) > -1) {
                  found = true;
                  break;
                }                
              }
            }
          }
          return found;
        };
      }
      //If only AND operator.
      else if(hasAND && !hasOR) {
        searchString = this.filter.searchString.slice(); //make a copy
        var onlyAndParts = searchString.split(this.AND_STR);

        searchFilter = (row) => {
          var found = false;
          //Must find all of these (AND)
          for(var i = 0; i < onlyAndParts.length; i++) {
            var onlyAndPart = onlyAndParts[i];
            var isNOT = onlyAndPart.includes(this.NOT_STR); //handle NOT
            onlyAndPart = isNOT ? onlyAndPart.substring(this.NOT_STR.length).toLowerCase() : onlyAndPart.toLowerCase();

            if(isNOT) {
              if(row.email_name && row.email_name.toLowerCase().indexOf(onlyAndPart) === -1
                 && row.eloqua_campaign_name && typeof row.eloqua_campaign_name.toLowerCase === 'function' && row.eloqua_campaign_name.toLowerCase().indexOf(onlyAndPart) === -1) {
                found = true;
              }
              else {
                found = false;
                break;
              }
            }
            else {
              if(row.email_name && row.email_name.toLowerCase().indexOf(onlyAndPart) > -1
                 || row.eloqua_campaign_name && typeof row.eloqua_campaign_name.toLowerCase === 'function' && row.eloqua_campaign_name.toLowerCase().indexOf(onlyAndPart) > -1) {
                found = true;
              }
              else {
                found = false;
                break;
              }              
            }
          }
          return found;
        };
      }
      //Else both AND and OR operators.
      else {
        searchString = this.filter.searchString.slice(); //make a copy
        var andParts = searchString.split(this.AND_STR);

        searchFilter = (row) => {
          var found = false;
          //Must find all of these (AND)
          for(var i = 0; i < andParts.length; i++) {
            var andPart = andParts[i];
            if(andPart !== '') {
              var orParts = andPart.split(this.OR_STR);
              var foundOr = false;
              //Must find one of these (OR)
              for(var j = 0; j < orParts.length; j++) {
                var orPart = orParts[j];
                var isNOT = orPart.includes(this.NOT_STR); //handle NOT
                orPart = isNOT ? orPart.substring(this.NOT_STR.length).toLowerCase() : orPart.toLowerCase();
                if(orPart !== '') {
                  if(isNOT) {
                    if(row.email_name && row.email_name.toLowerCase().indexOf(orPart) === -1
                       && row.eloqua_campaign_name && typeof row.eloqua_campaign_name.toLowerCase === 'function' && row.eloqua_campaign_name.toLowerCase().indexOf(orPart) === -1) {
                      foundOr = true;
                      break;
                    }
                  }
                  else {
                    if(row.email_name && row.email_name.toLowerCase().indexOf(orPart) > -1
                       || row.eloqua_campaign_name && typeof row.eloqua_campaign_name.toLowerCase === 'function' && row.eloqua_campaign_name.toLowerCase().indexOf(orPart) > -1) {
                      foundOr = true;
                      break;
                    }                    
                  }
                }
              }

              if(foundOr) {
                found = true;
              }
              else {
                found = false;
                break;
              }
            }
          }
          return found;
        };
      }

      if(isBaseline) {
        this.rowsBaseline = this.rowsBaselineCopy.filter(searchFilter);
      }
      else {
        this.rows = this.rowsCopy.filter(searchFilter);
      }
    }
    else if(isBaseline) {
      this.rowsBaseline = this.rowsBaselineCopy;
    }
    else {
      this.rows = this.rowsCopy;
    }

    if(isBaseline) {
      this.calculateBaseline();

      //If baseline, set the UI.
      if(this.performanceToggle === 'baseline') {
        this.compare = this.baseline;
        this.benchmark = this.baseline; //TODO: Should we also reset the benchmark here?
      }
    }
    else {
      this.calculateTotals();
    }
  }

  filterPersonaChanged(persona) {
    this.totals = null;
    this.baseline = null;
    this.errorMsg = null;

    //Clear search string when persona changes.
    this.filter.searchString = '';
    this.filter.name = null;

    //Remove the url query param
    this.router.navigate([], { queryParams: { name: null }, queryParamsHandling: 'merge' });

    //Only use personaId and name
    this.filter.persona = {
      personaId: persona.personaId,
      name: persona.name
    };
    this.getPerformanceResults(persona.personaId);
    this.getBaselinePerformance(false, persona.personaId);
  }

  clearFilter() {
    this.filter = {
      searchString: '',
      startDate: null,
      endDate: null,
      persona: 'all'
    };

    //Remove the url query param
    this.router.navigate([], { queryParams: { name: null }, queryParamsHandling: 'merge' });

    //Default date range to previous quarter.
    var prevQuarter = moment().subtract(1, 'Q');
    this.filter.startDate = prevQuarter.startOf('quarter').format('YYYY-MM-DD HH:mm:ss');
    this.filter.endDate = prevQuarter.endOf('quarter').format('YYYY-MM-DD HH:mm:ss');

    this.newStartDate = new Date(this.filter.startDate);
    this.newEndDate = new Date(this.filter.endDate);

    //Re-run the queries
    this.getBaselinePerformance(false);
    this.getPerformanceResults();
  }

  getPerformanceResults(personaId) {
    if(this.loading) return; //debounce
    this.loading = true;

    this.rows = [];
    this.rowsCopy = [];
    this.page.number = 0;
    this.page.numberPlusOne = 1;

    var params = {
      orgId: this.currentOrg._id,
      startDate: this.filter.startDate,
      endDate: this.filter.endDate
    };

    if(personaId) params.personaId = personaId;

    this.http
      .get('/api/olap/orgPerformanceResults', { params })
      .toPromise()
      .then(data => {
        this.loading = false;
        this.rows = data;
        this.rowsCopy = cloneDeep(this.rows);

        this.onPageSizeChange();
        this.filterRows(false);
      });
  }

  calculateTotals() {
    this.totals = {
      sends: 0,
      delivered: 0,
      opens: 0,
      unsubscribes: 0,
      clicks: 0,
      openRate: 0,
      clickRate: 0,
      clickToOpenRate: 0,
      unsubRate: 0
    };

    this.rows.forEach(row => {
      this.totals.sends += parseInt(row.sends);
      this.totals.delivered += parseInt(row.delivered);
      this.totals.opens += parseInt(row.opens);
      this.totals.clicks += parseInt(row.clicks);
      this.totals.unsubscribes += parseInt(row.unsubscribes);
    });

    this.totals.openRate = this.totals.delivered ? this.totals.opens / this.totals.delivered : 0;
    this.totals.clickRate = this.totals.delivered ? this.totals.clicks / this.totals.delivered : 0;
    this.totals.clickToOpenRate = this.totals.opens ? this.totals.clicks / this.totals.opens : 0;
    this.totals.unsubRate = this.totals.delivered ? this.totals.unsubscribes / this.totals.delivered : 0;

    this.totals.openRate = parseFloat(this.totals.openRate.toFixed(4));
    this.totals.clickRate = parseFloat(this.totals.clickRate.toFixed(4));
    this.totals.clickToOpenRate = parseFloat(this.totals.clickToOpenRate.toFixed(4));
    this.totals.unsubRate = parseFloat(this.totals.unsubRate.toFixed(4));

    this.calculatePercentImprovement();
  }

  getBaselinePerformance(firstTime, personaId) {
    if(this.loadingBaseline) return; //debounce
    this.loadingBaseline = true;

    var params = {
      orgId: this.currentOrg._id,
      startDate: this.startDateBaseline,
      endDate: this.endDateBaseline
    };

    if(personaId) params.personaId = personaId;

    this.http
      .get('/api/olap/orgPerformanceResults', { params })
      .toPromise()
      .then(data => {
        this.loadingBaseline = false;
        this.rowsBaseline = data;
        this.rowsBaselineCopy = cloneDeep(this.rowsBaseline);

        this.calculateBaseline();
        this.filterRows(true);

        if(this.performanceToggle === 'baseline') {
          this.compare = this.baseline;
        }

        if(firstTime) {
          this.benchmark = this.baseline; //set benchmark to the current baseline
        }
      });

    /* TODO: uncomment when the endpoint is ready.
    this.http
      .get('/api/olap/orgPerformanceChart', { params })
      .toPromise()
      .then(data => {
        this.performanceChart = data;
        console.log('----- performanceChart:', this.performanceChart); //debug
      });
    */
  }

  calculateBaseline() {
    this.baseline = {
      sends: 0,
      delivered: 0,
      opens: 0,
      clicks: 0,
      unsubscribes: 0,
      openRate: 0,
      clickRate: 0,
      clickToOpenRate: 0,
      unsubRate: 0
    };

    //Calcalute baseline...
    this.rowsBaseline.forEach(row => {
      //Totals ---
      this.baseline.sends += parseInt(row.sends);

      //Calculate Delivered -- can't be less than zero or less than opens.
      var delivered = parseInt(row.bouncebacks) < parseInt(row.sends) ? parseInt(row.sends) - parseInt(row.bouncebacks) : parseInt(row.sends);
      delivered = delivered < parseInt(row.opens) ? parseInt(row.opens) : delivered;
      this.baseline.delivered += delivered;

      //Other baseline values
      this.baseline.opens += parseInt(row.opens);
      this.baseline.clicks += parseInt(row.clicks);
      this.baseline.unsubscribes += parseInt(row.unsubscribes);
    });

    //Rates for Totals
    this.baseline.openRate = this.baseline.delivered ? this.baseline.opens / this.baseline.delivered : 0;
    this.baseline.clickRate = this.baseline.delivered ? this.baseline.clicks / this.baseline.delivered : 0;
    this.baseline.clickToOpenRate = this.baseline.opens ? this.baseline.clicks / this.baseline.opens : 0;
    this.baseline.unsubRate = this.baseline.delivered ? this.baseline.unsubscribes / this.baseline.delivered : 0;

    this.baseline.openRate = parseFloat(this.baseline.openRate.toFixed(4));
    this.baseline.clickRate = parseFloat(this.baseline.clickRate.toFixed(4));
    this.baseline.clickToOpenRate = parseFloat(this.baseline.clickToOpenRate.toFixed(4));
    this.baseline.unsubRate = parseFloat(this.baseline.unsubRate.toFixed(4));

    this.calculatePercentImprovement();
  }

  calculatePercentImprovement() {
    if(this.totals && this.compare) {
      //Calculate improvement % for Total Rates vs Baseline
      this.totals.openImprovement = this.compare.openRate > 0 ? (this.totals.openRate - this.compare.openRate) / this.compare.openRate : 0;
      this.totals.clickImprovement = this.compare.clickRate > 0 ? (this.totals.clickRate - this.compare.clickRate) / this.compare.clickRate : 0;
      this.totals.clickToOpenImprovement = this.compare.clickToOpenRate > 0 ? (this.totals.clickToOpenRate - this.compare.clickToOpenRate) / this.compare.clickToOpenRate : 0;
      this.totals.unsubImprovement = this.compare.unsubRate > 0 ? (this.totals.unsubRate - this.compare.unsubRate) / this.compare.unsubRate : 0;

      //Calculate improvement % for Individual Rates vs Baseline
      this.rows.forEach(row => {
        row.openImprovement = this.compare.openRate > 0 ? (row.openRate - this.compare.openRate) / this.compare.openRate : 0;
        row.clickImprovement = this.compare.clickRate > 0 ? (row.clickRate - this.compare.clickRate) / this.compare.clickRate : 0;
        row.clickToOpenImprovement = this.compare.clickToOpenRate > 0 ? (row.clickToOpenRate - this.compare.clickToOpenRate) / this.compare.clickToOpenRate : 0;
        row.unsubImprovement = this.compare.unsubRate > 0 ? (row.unsubRate - this.compare.unsubRate) / this.compare.unsubRate : 0;
      });
    }
  }

  togglePerformance() {
    this.filter.type = this.performanceToggle;

    if(this.performanceToggle === 'baseline') {
      this.compare = this.baseline;
      this.filter.benchmark = null;
    }
    else if(this.performanceToggle === 'benchmark') {
      this.compare = this.benchmark;
      this.filter.benchmark = this.compare;
    }
  }

  updateBenchmark() {
    const initialState = {
      benchmark: cloneDeep(this.benchmark)
    };

    //Remove unnecessary benchmark fields
    delete initialState.benchmark.sends;
    delete initialState.benchmark.delivered;
    delete initialState.benchmark.opens;
    delete initialState.benchmark.clicks;
    delete initialState.benchmark.unsubscribes;

    this.bsModalRef = this.modalService.show(PerformanceBenchmarkComponent, { initialState });
    var sub = this.bsModalRef.content.action.subscribe(save => {
      if(save) {
        this.benchmark = this.bsModalRef.content.benchmark;
        this.compare = this.benchmark;
        this.filter.benchmark = this.compare;
        this.calculatePercentImprovement();
      }
      if(sub) sub.unsubscribe();
    });
  }

  saveCurrentFilter() {
    const initialState = {
      currentOrg: this.currentOrg,
      mode: 'save',
      filter: this.filter,
      filters: this.filters
    };

    this.bsModalRef = this.modalService.show(PerformanceFiltersComponent, { initialState });
    var sub = this.bsModalRef.content.action.subscribe(save => {
      if(save) {
        this.filters.sort((a, b) => a.name > b.name ? 1 : b.name > a.name ? -1 : 0); //re-sort
        //Add url query param
        this.router.navigate([], { queryParams: { name: this.filter.name }, queryParamsHandling: 'merge' });
      }
      if(sub) sub.unsubscribe();
    });
  }

  removeFilters() {
    const initialState = {
      currentOrg: this.currentOrg,
      mode: 'remove',
      filters: this.filters
    };

    this.bsModalRef = this.modalService.show(PerformanceFiltersComponent, { initialState });
    var sub = this.bsModalRef.content.action.subscribe(update => {
      if(update) {
        this.getPerformanceFilters();
      }
      if(sub) sub.unsubscribe();
    });
  }

  onPageChange(event) {
    this.page.number = event.offset;
    this.page.numberPlusOne = this.page.number + 1;
    this.onPageSizeChange();
  }

  onPageSizeChange() {
    this.page.totalPages = Math.ceil(this.rows.length / this.page.size);
  }

  onPageNumberChange() {
    this.page.number = this.page.numberPlusOne - 1;
  }

  toggleExpandGroup(group) {
    this.table.groupHeader.toggleExpandGroup(group);
  }

  getWidth(rowProperty) {
    let val = this.columns.find(col => col.prop == rowProperty).width;
    return { width: `${val}px` };
  }

  onResize(event) {
    this.columns.find(col => col.prop == event.column.prop).width = event.newValue;
  }

  onSort(event) {
    const rows = [...this.rows];
    const sort = event.sorts[0];

    rows.sort((a, b) => {
      var result = 0;

      //Different sorts depending on type of column.
      switch (sort.prop) {
      case 'email_name':
        const nameA = a[sort.prop].toLowerCase().trim();
        const nameB = b[sort.prop].toLowerCase().trim();
        if(nameA < nameB) {
          result = -1;
        }
        if(nameA > nameB) {
          result = 1;
        }
        break;

      case 'sends':
      case 'delivered':
      case 'openRate':
      case 'clickRate':
      case 'unsubRate':
        const valA = parseFloat(a[sort.prop]);
        const valB = parseFloat(b[sort.prop]);
        if(valA < valB) {
          result = -1;
        }
        if(valA > valB) {
          result = 1;
        }
        break;

      case 'last_update':
        const dateA = new Date(a[sort.prop]);
        const dateB = new Date(b[sort.prop]);
        result = dateA.getTime() - dateB.getTime();
        break;

      default:
      }

      return result * (sort.dir === 'desc' ? -1 : 1);
    });

    this.rows = rows;
  }

  sumProperty(group, property) {
    var sum = 0;
    group.forEach(item => {
      sum += parseInt(item[property]);
    });
    return sum;
  }

  rateForProperty(group, property) {
    var delivered = 0;
    group.forEach(item => {
      delivered += parseInt(item.delivered);
    });

    var sum = 0;
    group.forEach(item => {
      sum += parseInt(item[property]);
    });

    return delivered ? sum / delivered : 0;
  }

  export() {
    this.exporting = true;
    var headers = ['Campaign', 'Campaign ID', 'Email', 'Email ID', 'Sends', 'Delivered', 'Opens', 'UOR', 'Clicks', 'UCTR', 'Unsubs', 'Unsub Rate', 'Last Update'];
    var options = {
      fieldSeparator: ',',
      showLabels: true,
      noDownload: false,
      headers
    };

    var exportRows = this.rows.map(o => ({
      campaignName: o.eloqua_campaign_name,
      campaignId: o.eloqua_campaign_id,
      emailName: o.email_name,
      emailId: o.email_id,
      sends: o.sends,
      delivered: o.delivered,
      opens: o.opens,
      openRate: o.openRate,
      clicks: o.clicks,
      clickRate: o.clickRate,
      unsubscribes: o.unsubscribes,
      unsubscribeRate: o.unsubRate,
      lastUpdate: o.last_update
    }));

    var filename = `${this.currentOrg.name} Performance`;
    new ngxCsv(exportRows, filename, options);
    this.exporting = false;
  }

  showEmailDetails(emailId) {
    //Open a modal with the Email details.
    const initialState = {
      emailId,
      orgId: this.currentOrg._id
    };
    this.modalService.show(EmailDetailsComponent, { initialState, class: 'modal-lg' });
  }

  loadDemoData() {
    this.authService.getCurrentOrg().then(org => {
      if(typeof org !== 'undefined') {
        this.currentOrg = org;
      }
    });

    //Fixed dates for the Demo.
    this.filter.startDate = '2023-09-01 00:00:00';
    this.filter.endDate = '2023-11-30 23:59:59';

    this.newStartDate = new Date(this.filter.startDate);
    this.newEndDate = new Date(this.filter.endDate);

    this.startDateBaseline = '2022-12-01 00:00:00';
    this.endDateBaseline = '2023-11-30 23:59:59';

    const minDateString = '2021-12-01 00:00:00';
    const maxDateString = '2023-12-18 09:58:24';

    this.minDate = new Date(minDateString);
    this.maxDate = new Date(maxDateString);

    this.loading = true;
    this.rows = [];
    this.rowsCopy = [];
    this.page.number = 0;
    this.page.numberPlusOne = 1;

    this.http
      .get('/assets/demo/global/performance-report-results.json')
      .toPromise()
      .then(
        data => {
          this.loading = false;
          this.rows = data;
          this.rowsCopy = cloneDeep(this.rows);

          this.onPageSizeChange();
          this.filterRows(false);
        }
      );

    this.loadingBaseline = true;

    this.http
      .get('/assets/demo/global/performance-report-baseline.json')
      .toPromise()
      .then(data => {
        this.loadingBaseline = false;
        this.baseline = data;
      });
  }
}
