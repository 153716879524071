import { Component, ElementRef, HostListener, Input, SimpleChanges, OnInit } from '@angular/core';
import * as d3 from 'd3';

//fractal settings
const angle = 30;
const branchAngle = angle * Math.PI / 180;
const branchSize = 8;
const proportion = 0.7;
const iterations = 12;
const batchSize = 2; //number of trees in each drawing batch
const waitTime = 10; //time in ms to wait between each batch

function* drawTrees(context, width, height, numberOfTrees, scaleColor) {
  var i = 0;
  while(i < numberOfTrees) {
    var x = 0;
    var y = 0;

    //Keep trees in the box.
    while(x < 15 || x > width - 60) x = Math.random() * width;
    while(y < 35 || y > height - 10) y = Math.random() * height;

    recursiveDraw(context, scaleColor, x, y);

    i++;
    if(i % batchSize === 0) yield i; //draw in batches
  }
  return numberOfTrees;
}

function recursiveDraw(context, scaleColor, x0, y0, angle = 0, it = iterations) {
  if(it === 0) return;
  const length = branchSize * Math.pow(proportion, iterations - it);
  drawLine(context, x0, y0, angle, length, scaleColor(it));
  recursiveDraw(context, scaleColor, 0, -length, branchAngle, it - 1);
  recursiveDraw(context, scaleColor, 0, -length, -branchAngle, it - 1);
  context.restore();
}

function drawLine(context, x0, y0, angle, length, color = '#000') {
  context.beginPath();
  context.save();
  context.translate(x0, y0);
  context.rotate(angle);
  context.moveTo(0, 0);
  context.lineWidth = 2;
  context.lineTo(0, -length);
  context.strokeStyle = color;
  context.strokeWidth = '5px';
  context.stroke();
}

function wait() {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, waitTime);
  });
}

@Component({
  selector: 'viz-fractal-forest',
  template: '<div></div>'
})
export class FractalForestComponent implements OnInit {
  @Input() numberOfTrees;
  @Input() height;

  @HostListener('window:resize', ['$event'])
  onResize() {
    //If not vislble, don't resize.
    if(!(this.htmlElement.offsetParent !== null)) return;
    const newWidth = this.htmlElement.parentElement.clientWidth ? this.htmlElement.parentElement.clientWidth : 1150;

    //Only redraw if width changes.
    if(this.width === newWidth) {
      return;
    }
    else {
      this.width = newWidth;
    }

    this.canvas.attr('width', this.width);
    this.canvas.attr('height', this.height);
    this.context = this.canvas.node().getContext('2d');

    if(this.numberOfTrees) {
      this.render();
    }
  }

  htmlElement: HTMLElement;

  static parameters = [ElementRef];
  constructor(element: ElementRef) {
    this.htmlElement = element.nativeElement;
    this.host = d3.select(element.nativeElement);
  }

  ngOnInit() {
    //Set width to 100% of parent container or 1500
    this.width = this.htmlElement.parentElement.clientWidth ? this.htmlElement.parentElement.clientWidth : 1150;
    this.height = this.height ? this.height : 250;

    //append the canvas object
    this.host.html('');
    this.canvas = this.host
      .append('canvas')
      .attr('width', this.width)
      .attr('height', this.height);

    this.context = this.canvas.node().getContext('2d');
    this.render();
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'numberOfTrees' && this.numberOfTrees && this.context) {
        this.render();
      }
    }
  }

  async render() {
    this.context.clearRect(0, 0, this.width, this.height);
    if(!this.numberOfTrees || this.numberOfTrees <= 0) return;

    const scaleColor = d3
      .scaleLinear()
      .domain([0, iterations])
      .range(['#0CAE5B', '#613B16']);
    //greens- lighter: #40EE95, darker: #0CAE5B
    //browns- lighter: #A67B51, darker: #613B16

    //Setup an iterator to draw trees in batches.
    var itr = drawTrees(this.context, this.width, this.height, this.numberOfTrees, scaleColor);

    while(true) {
      var val = itr.next().value;
      await wait(); //wait for canvas to refresh after each batch of trees is drawn.
      if(val === this.numberOfTrees) break;
    }
  } //end: render()
}
