import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'remove-attribute',
  template: require('./remove-attribute.html')
})
export class RemoveAttributeComponent {
  @Output() action = new EventEmitter(true);

  static parameters = [BsModalRef];
  constructor(bsModalRef: BsModalRef) {
    this.bsModalRef = bsModalRef;
  }

  ok() {
    this.action.emit(true);
    this.bsModalRef.hide(3);
  }

  cancel() {
    this.bsModalRef.hide(3);
  }
}
