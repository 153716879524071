import { Directive, Input } from '@angular/core';
import { TabDirective } from 'ngx-bootstrap/tabs';

@Directive({
  selector: '[tabOrder]'
})
export class TabOrderDirective {
  @Input() tabOrder = 0;

  constructor(tab: TabDirective) {
    this.tab = tab;
  }

  ngOnChanges() {
    this.tab.__tabOrder = +this.tabOrder;
    this.tab.tabset.tabs.sort((a: any, b: any) => a.__tabOrder - b.__tabOrder);
  }
}
