// @flow
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

type Organization = {
  // TODO: use Mongoose model
  id?: string,
  _id?: string,
  name?: string
};

@Injectable({
  providedIn: 'root'
})
export class OrgService {
  baseUrl = '/api/organizations';

  static parameters = [HttpClient];
  constructor(http: HttpClient) {
    this.http = http;
  }

  query(): Observable<Organization[]> {
    return this.http.get(`${this.baseUrl}/`);
  }

  get(id): Observable<Organization> {
    return this.http.get(`${this.baseUrl}/${id}`);
  }

  update(org): Observable<Object> {
    return this.http.put(`${this.baseUrl}/${org.id || org._id}`, org);
  }

  getPerformanceFilters(id) {
    return this.http.get(`${this.baseUrl}/${id}/performance-filters`);
  }

  updatePerformanceFilters(id, performanceFilters) {
    return this.http.put(`${this.baseUrl}/${id}/performance-filters`, performanceFilters);
  }
}
