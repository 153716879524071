import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { AuthGuard } from '../../components/auth/auth-guard.service';

import { DirectivesModule } from '../../components/directives.module';
import { OrganizationModule } from '../organization/organization.module';

import { CampaignComponent } from './campaign/campaign.component';
import { CampaignListComponent } from './campaign-list/campaign-list.component';
import { CampaignConfigComponent } from './shared/campaign-config/campaign-config.component';
import { CampaignDetailsComponent } from './shared/campaign-details/campaign-details.component';
import { CampaignMessageTestingComponent } from './shared/campaign-message-testing/campaign-message-testing.component';
import { CampaignScheduleComponent } from './shared/campaign-schedule/campaign-schedule.component';
import { CampaignTimeWindowsComponent } from './shared/campaign-time-windows/campaign-time-windows.component';
import { CampaignOpsViewComponent } from './shared/campaign-ops-view/campaign-ops-view.component';
import { CampaignWhoComponent } from './shared/campaign-who/campaign-who.component';
import { CampaignPersonasComponent } from './shared/campaign-personas/campaign-personas.component';
import { EloquaCampaignComponent } from './shared/eloqua-campaign/eloqua-campaign.component';

import { CampaignSelectorComponent } from './shared/campaign-selector/campaign-selector.component';
import { DeleteCancelComponent } from './campaign/delete-cancel/delete-cancel.component';
import { EmailsSelectorComponent } from './shared/campaign-config/emails-selector/emails-selector.component';
import { ErrorEmailComponent } from './campaign/error-email/error-email.component';
import { HeroEmailComponent } from './campaign/hero-email/hero-email.component';
import { SubjectLineAnalysisModalComponent } from '../generator/subject-line-analysis-modal/subject-line-analysis-modal.component';
import { PersonaSelectorComponent } from './shared/campaign-personas/persona-selector/persona-selector.component';

import { EloquaMessageTestingComponent } from './eloqua/message-testing/message-testing.component';
import { EloquaSendTimeOptimizerComponent } from './eloqua/send-time-optimizer/send-time-optimizer.component';
import { EloquaDecisionServiceComponent } from './eloqua/decision-service/decision-service.component';
import { EloquaDecisionServiceFrequencyManagementComponent } from './eloqua/decision-service-frequency-management/decision-service-frequency-management.component';
import { EloquaDecisionServiceSmartSuppressComponent } from './eloqua/decision-service-smart-suppress/decision-service-smart-suppress.component';

import { AreaChartComponent } from '../../components/data-viz/area-chart/area-chart.component';
import { BarChartComponent } from '../../components/data-viz/bar-chart/bar-chart.component';
import { DonutChartComponent } from '../../components/data-viz/donut-chart/donut-chart.component';
import { LineChartComponent } from '../../components/data-viz/line-chart/line-chart.component';
import { ScatterChartComponent } from '../../components/data-viz/scatter-chart/scatter-chart.component';
import { ScheduleChartComponent } from '../../components/data-viz/schedule-chart/schedule-chart.component';

const campaignRoutes: Routes = [
  {
    path: 'campaigns',
    component: CampaignListComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: ':campaignId',
        component: CampaignComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'eloqua/:eloquaCampaignId',
        component: EloquaCampaignComponent,
        canActivate: [AuthGuard]
      },
    ],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'action-service-config',
    component: EloquaMessageTestingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'action-service-sto-config',
    component: EloquaSendTimeOptimizerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'decision-service-config',
    component: EloquaDecisionServiceComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'decision-service-frequency-management-config',
    component: EloquaDecisionServiceFrequencyManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'decision-service-smart-suppress-config',
    component: EloquaDecisionServiceSmartSuppressComponent,
    canActivate: [AuthGuard]
  }
  //TODO: Add this route for an edge case, where Eloqua user doesn't have a Motiva user yet.
  /*
  {
    path: 'motivaServiceConfig',
    component: NoMotivaAccountComponent
  }
  */
];

@NgModule({
  imports: [
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forChild(campaignRoutes),
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ButtonsModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    ProgressbarModule.forRoot(),
    TabsModule.forRoot(),
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    NgxDatatableModule,
    InfiniteScrollModule,
    DirectivesModule,
    OrganizationModule
  ],
  declarations: [
    CampaignComponent,
    CampaignListComponent,
    CampaignConfigComponent,
    CampaignDetailsComponent,
    CampaignMessageTestingComponent,
    CampaignScheduleComponent,
    CampaignTimeWindowsComponent,
    CampaignOpsViewComponent,
    CampaignWhoComponent,
    CampaignSelectorComponent,
    DeleteCancelComponent,
    EmailsSelectorComponent,
    ErrorEmailComponent,
    HeroEmailComponent,
    SubjectLineAnalysisModalComponent,
    EloquaMessageTestingComponent,
    EloquaSendTimeOptimizerComponent,
    EloquaDecisionServiceComponent,
    EloquaDecisionServiceFrequencyManagementComponent,
    EloquaDecisionServiceSmartSuppressComponent,
    AreaChartComponent,
    BarChartComponent,
    DonutChartComponent,
    LineChartComponent,
    ScatterChartComponent,
    ScheduleChartComponent,
    EloquaCampaignComponent,
    CampaignPersonasComponent,
    PersonaSelectorComponent
  ],
  entryComponents: [
    CampaignSelectorComponent,
    DeleteCancelComponent,
    EmailsSelectorComponent,
    ErrorEmailComponent,
    HeroEmailComponent,
    SubjectLineAnalysisModalComponent,
    PersonaSelectorComponent
  ]
})
export class CampaignModule {}
