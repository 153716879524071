// @flow
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import moment from 'moment-timezone';

import { AuthService } from '../../../../components/auth/auth.service';
import { Campaign } from '../../shared/campaign';
import { CampaignService } from '../../shared/campaign.service';
import { CampaignDatesService } from '../../shared/campaign-dates.service';

import { isLoading } from '../../../../components/util';

@Component({
  selector: 'send-time-optimizer',
  template: require('./send-time-optimizer.html')
})
export class EloquaSendTimeOptimizerComponent implements OnInit {
  campaign: Campaign = new Campaign();

  static parameters = [ActivatedRoute, AuthService, CampaignService, CampaignDatesService];
  constructor(route: ActivatedRoute, authService: AuthService, campaignService: CampaignService, campaignDatesService: CampaignDatesService) {
    this.route = route;
    this.authService = authService;
    this.campaignService = campaignService;
    this.campaignDatesService = campaignDatesService;
    this.isLoading = isLoading;

    this.campaign.optimizationCriterion = 'sendTime';
    this.campaignLocal = {
      isSimple: false,
      currentDate: moment.tz()
    };

    this.missingEloquaCampaignId = false;
    this.showResults = false;
  }

  ngOnInit() {
    //Get the query parameters.
    this.paramSub = this.route.queryParams.subscribe(params => {
      if(typeof params.id === 'undefined' || params.id === '' || typeof params.type === 'undefined' || params.type !== 'Campaign') {
        this.missingEloquaCampaignId = true;
        return;
      }

      this.instanceId = params.instance;
      this.eloquaCampaignId = parseInt(params.id);

      //Get campaign from the db, if it exists.
      this.campaignService
        .get(this.instanceId)
        .toPromise()
        .then(campaign => {
          this.campaign = campaign;
          this.updateUi();
        })
        .catch(err => {
          if(err.status === 404) {
            //Didn't find an existing campaign, so we're creating a new one.
            this.campaign.instanceId = this.instanceId;
            this.campaign.eloquaCampaignId = this.eloquaCampaignId;
            this.campaign.timezone = this.campaignDatesService.guessTimezone();
            this.updateUi();
          }
          console.log('Error retrieving campaign.', err);
        });
    });
  } //end: ngOnInit()

  ngOnDestroy() {
    if(this.paramSub) this.paramSub.unsubscribe();
  }

  updateUi() {
    this.campaignLocal = this.campaignService.initCampaignLocal(this.campaign);
    this.campaignLocal.isSimple = this.simpleComponent;
    this.showResults = !this.campaignService.isUpdateable(this.campaign.status);
    this.campaignDatesService.setDateOptions(this.campaign.timezone, this.campaignLocal);
  }
}
