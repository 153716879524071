import { Component, ElementRef, Input, SimpleChanges, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'viz-gauge-semicircle',
  template: '<div></div>'
})
export class GaugeSemicircleBarComponent implements OnInit {
  @Input() value;
  @Input() height;
  @Input() width;
  @Input() showLabels;

  htmlElement: HTMLElement;
  svg;

  static parameters = [ElementRef];
  constructor(element: ElementRef) {
    this.htmlElement = element.nativeElement;
    this.host = d3.select(element.nativeElement);
  }

  ngOnInit() {
    this.margin = { top: 5, left: 0, bottom: 2, right: 2 };

    this.showLabels = this.showLabels ? this.showLabels : false;
    this.labelPadding = 20;

    this.width = this.width ? this.width : this.showLabels ? '250' : '200';
    this.height = this.height ? this.height : '125';

    this.arcMin = -Math.PI / 2;
    this.arcMax = Math.PI / 2;

    this.innerRadius = 90;
    this.outerRadius = 100;

    this.colorStops = [0, 50, 100];
    this.colorOptions = ['#d63031', '#fdcb6e', '#00b894'];

    //Create the svg
    this.svg = this.host
      .append('svg')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
      .attr('height', this.height)
      .attr('width', this.width);

    //Add a bit of padding
    var addlPadding = this.outerRadius + (this.showLabels ? this.labelPadding * 2 : 0);
    this.g = this.svg.append('g')
      .attr('class', 'arc')
      .attr('transform', `translate(${addlPadding}, ${addlPadding})`);

    this.render();
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'value' && this.value && this.g) {
        this.render();
      }
    }
  }

  render() {
    if(!this.value) {
      this.value = 0;
    }
    this.g.selectAll('*').remove();

    var arcScale = d3.scaleLinear().domain(this.colorStops)
      .range([this.arcMin, 0, this.arcMax]);

    var colorScale = d3.scaleLinear().domain(this.colorStops)
      .range(this.colorOptions);

    var arc = d3.arc().innerRadius(this.innerRadius)
      .outerRadius(this.outerRadius)
      .startAngle(this.arcMin);

    //Background arc (gray)
    this.g.append('path').attr('class', 'bg-arc');
    this.g.select('g.arc .bg-arc')
      .datum({endAngle: this.arcMax})
      .style('fill', '#ddd')
      .attr('d', arc);

    //Tween helper function
    function arcTween(a) {
      var i = d3.interpolate(this._current, a);
      this._current = i(0);
      return t => arc(i(t));
    }

    //Colorful arc representing the value
    this.g.append('path').attr('class', 'data-arc')
      .datum({endAngle: this.arcMin, startAngle: this.arcMin, value: this.colorStops[0]})
      .attr('d', arc)
      .style('fill', colorScale(this.colorStops[0]))
      .each(function(d) { //<< do not use arrow function here.
        this._current = d;
      });

    this.g.select('g.arc .data-arc')
      .datum({value: this.value, startAngle: this.arcMin, endAngle: arcScale(this.value)})
      .transition()
      .duration(800)
      .style('fill', d => colorScale(d.value))
      .style('opacity', d => d.value < this.colorStops[0] ? 0 : 1)
      .attrTween('d', arcTween);

    //Main value in the middle
    if(this.value !== 0) {
      const arcCentroid = arc.centroid({endAngle: this.arcMax, startAngle: this.arcMin, value: 0});
      this.g.append('text').attr('class', 'arc-label');
      this.g.select('text.arc-label')
        .datum({value: this.value})
        .attr('x', arcCentroid[0])
        .attr('y', -25)
        .style('alignment-baseline', 'central')
        .style('text-anchor', 'middle')
        .style('font-size', '36px')
        .style('font-weight', '500')
        .style('font-family', '"Helvetica Neue", Helvetica, Arial, sans-serif')
        .style('fill', '#4a5255')
        .text(d => d3.format('.0f')(d.value));
    }

    if(this.showLabels) {
      const markerLine = d3.radialLine()
        .angle(d => arcScale(d))
        .radius((d, i) => this.innerRadius + i % 2 * ((this.outerRadius - this.innerRadius)));

      this.g.selectAll('.lines').data(arcScale.ticks(5).map(value => ({ value })))
        .enter()
        .append('path')
        .attr('class', 'lines');

      this.g.selectAll('.lines')
        .attr('d', d => markerLine([d.value, d.value]))
        .style('fill', 'none')
        .style('stroke-width', 2.5)
        .style('stroke', '#fff');

      this.g.selectAll('.ticks').data(arcScale.ticks(5))
        .enter()
        .append('text')
        .attr('class', 'ticks')
        .style('font-size', '16px')
        .style('text-anchor', 'middle');

      this.g.selectAll('.ticks')
        .attr('x', d => Math.cos(arcScale(d) + this.arcMin) * (this.outerRadius + this.labelPadding))
        .attr('y', d => {
          const yVal = Math.sin(arcScale(d) + this.arcMin) * (this.outerRadius + this.labelPadding);
          return yVal < -1 ? yVal : -7;
        })
        .text(d => d);
    }
  } //end: render
}
