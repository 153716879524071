import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DomSanitizer } from '@angular/platform-browser';

import { AuthService } from '../../../../components/auth/auth.service';
import { CampaignEmailService } from '../../shared/campaign-email.service';
import { WhoService } from '../../../who/shared/who.service';
import { TimeWindowsService } from '../../../time-windows/shared/time-windows.service';

import { cloneDeep } from 'lodash';
import * as svg from 'save-svg-as-png';

@Component({
  selector: 'hero-email',
  template: require('./hero-email.html')
})
export class HeroEmailComponent implements OnInit {
  campaign: {};
  campaignLocal: {};
  reportImages: [];
  results: {};

  static parameters = [BsModalRef, DomSanitizer, AuthService, CampaignEmailService, WhoService, TimeWindowsService];
  constructor(
    bsModalRef: BsModalRef,
    domSanitizer: DomSanitizer,
    authService: AuthService,
    campaignEmailService: CampaignEmailService,
    whoService: WhoService,
    timeWindowsService: TimeWindowsService
  ) {
    this.bsModalRef = bsModalRef;
    this.domSanitizer = domSanitizer;
    this.authService = authService;
    this.campaignEmailService = campaignEmailService;
    this.whoService = whoService;
    this.timeWindowsService = timeWindowsService;

    this.emails = [];
    this.emailString = '';
    this.optional = {
      emailNote: ''
    };

    this.inputCount = new Array(2);
    this.errors = {};
    this.submitted = false;

    this.autoParams = {
      timer: null,
      saving: false
    };

    this.previewEmailData = {
      showSections: {}
    };
    this.preview = {};

    this.authService.getCurrentUser().then(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    this.loadingPreview = true;

    this.previewEmailData.showSections = {
      note: false,
      messageTesting: this.campaign.optimizationCriterion !== 'sendTime',
      sendTimeOptimization: true,
      topPerformingAttributes: false,
      overallStats: true
    };

    this.timeWindows = {
      config: {
        verticalLabels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        horizontalLabels: ['12a', '1a', '2a', '3a', '4a', '5a', '6a', '7a', '8a', '9a', '10a', '11a', '12p', '1p', '2p', '3p', '4p', '5p', '6p', '7p', '8p', '9p', '10p', '11p']
      },
      toggle: this.campaign.optimizationCriterion == 'clicks' ? 'clickRate' : 'openRate'
    };

    this.whoReport = {
      featureSets: {
        totalCountMap: {}
      }
    };

    this.buildEmailPreview();
  }

  // Check if email is valid
  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  buildEmailPreview() {
    // Get top attributes
    this.whoService.getContactAttributes(this.whoReport).then(
      response => {
        this.orgContactFieldsLocal = cloneDeep(response.orgContactFields);
        this.orgSettings = response.orgSettings;
        // Get top performing features
        var filterSettings = {
          filterBy: 'motivaCampaign',
          ids: [this.campaign.campaignId]
        };

        this.whoService.getTopPerformingFeatures(this.campaign.organizationId, this.whoReport, this.orgContactFieldsLocal, filterSettings).then(
          () => {
            this.timeWindowsService.buildCampaignTimeWindows(this.campaign, this.campaignLocal, this.timeWindows).then(() => {
              // Get heatmap image
              console.log('Getting heatmap for hero email', document.getElementById('sendTimes_heatmap'));
              if(!this.reportImages.sendTimes_heatmap && document.getElementById('sendTimes_heatmap')) {
                svg.svgAsPngUri(document.getElementById('sendTimes_heatmap'), {}, uri => {
                  this.reportImages.sendTimes_heatmap = uri;

                  this.campaignEmailService
                    .buildHeroButtonData(this.campaign, this.campaignLocal, this.results, this.reportImages, this.currentUser, this.whoReport, this.timeWindows)
                    .then(responseHero => {
                      this.previewEmailData = responseHero.previewEmailData;
                      this.preview = responseHero.preview;

                      this.subject = this.preview.results.subject;

                      //Swap in image URIs for preview
                      var html = this.preview.results.html.replace('cid:rates_lineChart', this.reportImages.rates_lineChart);
                      html = html.replace('cid:sendTimes_heatmap', this.reportImages.sendTimes_heatmap);
                      this.previewHtml = this.domSanitizer.bypassSecurityTrustHtml(html);

                      this.previewEmailData.showSections = {
                        note: false,
                        messageTesting: this.previewEmailData.campaign.optimizationCriterion !== 'sendTime',
                        sendTimeOptimization: true,
                        topPerformingAttributes: this.previewEmailData.topPerformingAttributes && this.previewEmailData.topPerformingAttributes.length > 0,
                        overallStats: true
                      };

                      this.loadingPreview = false;
                    });
                });
              }
              else {
                this.campaignEmailService.buildHeroButtonData(this.campaign, this.campaignLocal, this.results, this.reportImages, this.currentUser, this.whoReport, this.timeWindows).then(responseHero2 => {
                  this.previewEmailData = responseHero2.previewEmailData;
                  this.preview = responseHero2.preview;

                  this.subject = this.preview.results.subject;

                  //Swap in image URIs for preview
                  var html = this.preview.results.html.replace('cid:rates_lineChart', this.reportImages.rates_lineChart);
                  html = html.replace('cid:sendTimes_heatmap', this.reportImages.sendTimes_heatmap);

                  this.previewHtml = this.domSanitizer.bypassSecurityTrustHtml(html);

                  this.previewEmailData.showSections = {
                    note: false,
                    messageTesting: this.previewEmailData.campaign.optimizationCriterion !== 'sendTime',
                    sendTimeOptimization: true,
                    topPerformingAttributes: this.previewEmailData.topPerformingAttributes && this.previewEmailData.topPerformingAttributes.length > 0,
                    overallStats: true
                  };

                  this.loadingPreview = false;
                });
              }
            });
          },
          err => {
            console.log('Error getting top attributes', err);
          }
        );
      },
      error => {
        console.log('Error getting contact attributes', error);
      }
    );
  }

  reloadPreview() {
    this.errors.sections = '';
    this.errors.submit = '';
    this.previewEmailData.note = this.optional.emailNote.replace(/\n/g, '<br/>');

    this.campaignEmailService.getHeroButtonEmailPreview(this.previewEmailData, this.currentUser).then(
      preview => {
        this.preview = preview;
        this.previewHtml = this.domSanitizer.bypassSecurityTrustHtml(preview.results.html);
      },
      error => {
        console.log('Error getting email preview', error);
      }
    );
  }

  changeField() {
    this.autoPreview();
  }

  autoPreview() {
    if(this.autoParams.saving) return;
    if(this.autoParams.timer) {
      clearTimeout(this.autoParams.timer);
    }
    this.autoParams.timer = setTimeout(
      () => {
        this.reloadPreview();
      },
      1.8 * 1000,
      true
    ); //wait X seconds before save
  }

  ok() {
    this.emailData = this.previewEmailData;
    this.errors = {};
    this.submitted = true;
    this.okClicked = true;

    this.emails = this.emailString.split(',');

    // Make sure emails are valid
    this.emails.forEach(email => {
      email = email.trim();
      if(!this.validateEmail(email)) {
        this.errors.emails = 'Please enter valid email addresses separated by commas.';
        this.errors.submit = this.errors.emails;
      }
    });

    if(!this.errors.emails) {
      // At least one section must be selected
      if(
        !this.previewEmailData.showSections.messageTesting
        && !this.previewEmailData.showSections.sendTimeOptimization
        && !this.previewEmailData.showSections.overallStats
        && !this.previewEmailData.showSections.topPerformingAttributes
      ) {
        this.errors.sections = 'Please select at least one section.';
        this.errors.submit = this.errors.sections;
      }
      else {
        // Close modal and send email
        this.previewEmailData.note = this.optional.emailNote.replace(/\n/g, '<br/>');

        this.response = {
          emails: this.emails,
          emailData: this.previewEmailData
        };
      }
    }
    this.bsModalRef.hide();
  }

  cancel() {
    this.okClicked = false;
    this.bsModalRef.hide();
  }
}
