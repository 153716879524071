// @flow
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CampaignService } from './campaign.service';
import { CampaignDatesService } from './campaign-dates.service';
import { WhoService } from '../../who/shared/who.service';
import { CampaignScheduleService } from './campaign-schedule/campaign-schedule.service';

import { startLoading, stopLoading } from '../../../components/util';
import moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class CampaignFilesService {
  days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  static parameters = [HttpClient, CampaignService, CampaignDatesService, WhoService, CampaignScheduleService];
  constructor(http: HttpClient, campaignService: CampaignService, campaignDatesService: CampaignDatesService, whoService: WhoService, campaignScheduleService: CampaignScheduleService) {
    this.http = http;
    this.campaignService = campaignService;
    this.campaignDatesService = campaignDatesService;
    this.whoService = whoService;
    this.campaignScheduleService = campaignScheduleService;

    this.startLoading = startLoading;
    this.stopLoading = stopLoading;
  }

  getSampleDataFolders() {
    const promise = new Promise((resolve, reject) => {
      this.http
        .get('/api/olap/sampleData')
        .toPromise()
        .then(
          folders => {
            resolve(folders);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  loadDataFiles(folder) {
    var response = {};
    var folderName = encodeURIComponent(folder);
    const promise = new Promise((resolve, reject) => {
      // Get campaign
      this.http
        .get(`/assets/demo/campaigns/${folderName}/campaign.json`)
        .toPromise()
        .then(
          campaign => {
            this.updateDemoCampaignDates(campaign);
            response.campaign = campaign;
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
    });
    return promise;
  } // end: loadDataFiles()

  getLastDay(day, date) {
    var date = new Date(date);
    var index = this.days.indexOf(day);
    var difference = date.getDay() - index;
    if(difference <= 0) {
      difference = -7 - difference;
    }
    date.setDate(date.getDate() + difference);
    date.setHours(12); //noon
    return date;
  }

  updateDemoCampaignDates(campaign) {
    var today = new Date();

    /**
     * Campaign 44444444-4444-4444-4444-444444444444
     *  - Message Testing with no end date (aka Drip)
     *  - 16 days of entries in the MT report.
     *  - Thursday is the first day.
     */
    if(campaign.campaignId === '44444444-4444-4444-4444-444444444444') {
      campaign.statusDate = today;
      campaign.updatedAt = today;
    }
  } //end: updateDemoCampaignDates()

  updateDemoReportDates(folderName, report) {
    /**
     * Campaign 44444444-4444-4444-4444-444444444444
     *  - Message Testing with no end date (aka Drip)
     *  - 16 days of entries in the MT report.
     *  - Thursday is the first day.
     */
    if(folderName === '44444444-4444-4444-4444-444444444444') {
      var currentDay = moment()
        .day(4)
        .add(-3, 'weeks');

      currentDay.set('hour', 12).set('minute', 0);
      report.multiArmedBandit.forEach(item => {
        item.resultDateTime = currentDay.toISOString();
        currentDay.add(1, 'days');
      });
    }
    /**
     * Campaign 22222222-2222-2222-2222-222222222222
     *  - Simple with no end date (aka Drip)
     *  - Showing off FM
     *  - Monday is the first day.
     */
    else if(folderName === '22222222-2222-2222-2222-222222222222') {
      var currentDay = moment().day(1)
        .add(-1, 'weeks');
      currentDay.set('hour', 12).set('minute', 0);
      report.multiArmedBandit.forEach(item => {
        item.resultDateTime = currentDay.toISOString();
        currentDay.add(1, 'days');
      });
    }
  } //end: updateDemoReportDates()


  getTimeWindowsData(folderName) {
    var response = {};
    const promise = new Promise((resolve, reject) => {
      // Get time windows
      this.http
        .get(`/assets/demo/campaigns/${folderName}/timeWindows.json`)
        .toPromise()
        .then(
          timeWindows => {
            response.timeWindows = timeWindows;

            // Get delayDistribution
            this.http
              .get(`/assets/demo/campaigns/${folderName}/delayDistribution.json`)
              .toPromise()
              .then(
                delayDistribution => {
                  response.delayDistribution = delayDistribution;
                  resolve(response);
                },
                err => {
                  resolve(response);
                  console.log(`No delayDistribution file found for ${folderName}`, err);
                }
              );
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  getSchedule(campaign, schedule, totals, folderName) {
    const promise = new Promise((resolve, reject) => {
      // Get schedule
      this.http
        .get(`/assets/demo/campaigns/${folderName}/schedule.json`)
        .toPromise()
        .then(
          scheduleFromFile => {
            /**
             * Campaign 22222222-2222-2222-2222-222222222222
             *  - Simple with no end date (aka Drip)
             *  - Showing off FM
             *  - Monday is the first day.
             */
            if(folderName === '22222222-2222-2222-2222-222222222222') {
              var currentDay = moment().day(1)
                .add(-1, 'weeks');
              currentDay.set('hour', 9).set('minute', 0);
              var count = 0;

              scheduleFromFile.forEach(item => {
                item.scheduledSendDateTime = currentDay.toISOString();
                item.processingStarted = currentDay.toISOString();
                item.processingEnded = currentDay.toISOString();
                currentDay.add(1, 'hours');

                count++;
                if(count === 8) {
                  count = 0;
                  currentDay.add(1, 'days');
                  currentDay.set('hour', 9);
                }
              });
            }

            this.campaignScheduleService.processSchedule(campaign, schedule, scheduleFromFile, totals);
            resolve(schedule);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  getResults(campaign, campaignLocal, folderName) {
    this.startLoading();

    var isSimple = this.campaignService.isSimple(campaign);
    var show = false;

    const promise = new Promise((resolve, reject) => {
      // Get report
      this.http
        .get(`/assets/demo/campaigns/${folderName}/report.json`)
        .toPromise()
        .then(
          report => {
            this.updateDemoReportDates(folderName, report);
            this.stopLoading();
            //Make sure we have report data...
            if(!report || typeof report.multiArmedBandit === 'undefined' || report.multiArmedBandit === null || report.multiArmedBandit.length === 0) {
              resolve({ show: false });
              return;
            }
            //First entry is just the initialization.
            else if(report.multiArmedBandit.length > 1) {
              show = true;
              this.campaignDatesService.setResultsDateParams(report.multiArmedBandit, campaign, campaignLocal);
            }
            else {
              show = false;
            }

            // If there are more than 30 days of results, reduce to 1 entry per day
            var tmpReport = [];
            if(report.multiArmedBandit.length > 700) {
              var count = 0;
              var currentDate = new Date(report.multiArmedBandit[0].resultDateTime);
              report.multiArmedBandit.forEach(result => {
                var date = new Date(result.resultDateTime);
                if(date.getDate() === currentDate.getDate()) {
                  tmpReport[count] = result;
                }
                else {
                  count++;
                  currentDate = date;
                  tmpReport[count] = result;
                }
              });
            }
            else {
              tmpReport = report.multiArmedBandit;
            }

            if(typeof campaign.frequencyManagedCount !== 'undefined') {
              campaignLocal.frequencyManagedCount = campaign.frequencyManagedCount;
            }
            if(typeof campaign.blockedByEloquaCount !== 'undefined') {
              campaignLocal.blockedByEloquaCount = campaign.blockedByEloquaCount;
            }

            var results = this.campaignService.transformResults(
              tmpReport,
              report.emailInfo,
              campaignLocal.currentDate,
              campaignLocal.scheduledStartLocal,
              campaignLocal.scheduledEndLocal,
              campaign.timezone,
              campaignLocal.lengthDays,
              campaign.optimizationCriterion,
              campaign.confidenceThreshold,
              isSimple
            );

            // If any treatment has less than MIN_CONTACTS_PER_TREATMENT_PER_BATCH contacts, hide results
            if(campaign.optimizationCriterion !== 'sendTime') {
              results.treatmentDetails.forEach(treatment => {
                if(treatment.hideMyRates) {
                  show = false;
                  if(treatment.sends < this.campaignService.MIN_CONTACTS_PER_TREATMENT_PER_BATCH) campaignLocal.notEnoughContactsPerTreatment = true;
                }
              });
            }

            results.show = show;
            resolve(results);
          },
          error => {
            console.log('Error loading report.json', error);
            this.stopLoading();
            reject(error);
          }
        )
        .catch(err => {
          console.log('Exception loading report.json', err);
          this.stopLoading();
          reject(err);
        });
    });

    return promise;
  } //end: getResults()

  getTopPerformingFeatures(whoReportLocal, folderName) {
    const promise = new Promise((resolve, reject) => {
      var data = [];
      this.http
        .get(`/assets/demo/campaigns/${folderName}/whoTotal-${whoReportLocal.topFeaturesToggle}.json`)
        .toPromise()
        .then(
          topFeatures => {
            if(topFeatures && topFeatures.length > 0) {
              for(var i = 0; i < topFeatures.length; i++) {
                if(topFeatures[i]) {
                  var key = topFeatures[i].key;
                  var name = key.replace(/([A-Z])/g, ' $1');
                  name = name.charAt(0).toUpperCase() + name.slice(1);

                  data.push({
                    key: topFeatures[i].key,
                    field: name,
                    value: topFeatures[i].value,
                    opens: topFeatures[i].opens,
                    clicks: topFeatures[i].clicks
                  });

                  if(data.length >= this.whoService.MAX_FEATURE_SETS) break;
                }
              }
              if(Number(data[0].opens) > Number(whoReportLocal.featureSets.highestCount)) {
                whoReportLocal.featureSets.highestCount = Number(data[0].opens);
              }

              whoReportLocal.featureSets.data = data;
              resolve();
            }
            else {
              whoReportLocal.featureSets.data = [];
              resolve();
            }
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  buildWhoReport(whoReportLocal, selectedContactFields, treatmentDetails, folderName) {
    whoReportLocal.config = [];

    var whoReportData = {};
    var attributeValueOpens = {};
    var maxOpens = 0;
    var response = {
      reports: []
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .get(`/assets/demo/campaigns/${folderName}/whoFeatures.json`)
        .toPromise()
        .then(
          relevantFeatures => {
            if(relevantFeatures && relevantFeatures.length > 0) {
              var features = [];
              relevantFeatures.forEach(feature => {
                features.push(feature.key);
              });

              whoReportLocal.featureSets.relevantFeatures = features;

              relevantFeatures.forEach(contactField => {
                whoReportLocal.config.push({
                  name: contactField.name,
                  key: contactField.key,
                  show: true
                });

                whoReportLocal.keys.push(contactField.key);
              });
            }

            this.http
              .get(`/assets/demo/campaigns/${folderName}/who.json`)
              .toPromise()
              .then(
                whoData => {
                  if(whoData && whoData.openAndClickData && whoData.openAndClickData.length > 0) {
                    this.whoService.processWhoData(whoData, whoReportData, whoReportLocal, attributeValueOpens, maxOpens, treatmentDetails, response).then(processedWhoData => {
                      response = processedWhoData;
                      resolve(response);
                    });
                  }
                  else {
                    resolve(response);
                  }
                },
                error => {
                  reject(error);
                }
              );
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  } // end: buildWhoReport


  getDemoCampaignList() {
    var today = new Date();
    var yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    var oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    var twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
    var oneMonthAgo = new Date();
    oneMonthAgo.setDate(oneMonthAgo.getDate() - 30);

    return [{
      campaignId: '44444444-4444-4444-4444-444444444444',
      createdBy: 'Matt Hill',
      displayOptimization: 'Message Testing',
      eloquaCampaignId: 126614,
      emailCount: 4,
      name: 'Apple at Work_iPhone 13_Teaser_Canada_MT_CTA',
      organizationId: 1,
      overrideSendFrequencyCheck: false,
      status: 'completed',
      statusDate: today,
      uniqueContactCount: 1475000,
      uid: 2,
      updatedAt: today
    },
    {
      campaignId: '33333333-3333-3333-3333-333333333333',
      createdBy: 'Charlie O\'Hanlon',
      displayOptimization: 'Send Time',
      eloquaCampaignId: 127709,
      emailCount: 1,
      name: 'Apple at Work_AppleCare_What\'s New_Canada_STO',
      organizationId: 1,
      overrideSendFrequencyCheck: true,
      status: 'running',
      statusDate: today,
      uniqueContactCount: 888888,
      uid: 2,
      updatedAt: today
    },
    {
      campaignId: '22222222-2222-2222-2222-222222222222',
      createdBy: 'Frank Flavell',
      displayOptimization: 'Simple',
      eloquaCampaignId: 127710,
      emailCount: 1,
      name: 'Apple at Work_AppleCare_What\'s New_Canada_FM',
      organizationId: 1,
      overrideSendFrequencyCheck: false,
      status: 'completed',
      statusDate: yesterday,
      uniqueContactCount: 888888,
      uid: 2,
      updatedAt: yesterday
    }, {
      campaignId: '11111111-1111-1111-1111-111111111111',
      createdBy: 'Frank Flavell',
      displayOptimization: 'Message Testing',
      eloquaCampaignId: 126285,
      emailCount: 8,
      name: 'Apple at Work_iPhone 12_Release_Canada_MT_Subject Line',
      organizationId: 1,
      overrideSendFrequencyCheck: false,
      status: 'completed',
      statusDate: oneWeekAgo,
      uniqueContactCount: 1385421,
      uid: 2,
      updatedAt: oneWeekAgo
    },
    {
      campaignId: '55555555-5555-5555-5555-555555555555',
      createdBy: 'Matt Hill',
      displayOptimization: 'Message Testing',
      eloquaCampaignId: 1190321,
      emailCount: 12,
      name: 'Apple at Work_iPhone 12_Teaser_Canada_MT_CTA_SL',
      organizationId: 1,
      overrideSendFrequencyCheck: false,
      status: 'completed',
      statusDate: twoWeeksAgo,
      uniqueContactCount: 1259434,
      uid: 2,
      updatedAt: twoWeeksAgo
    },
    {
      campaignId: '66666666-6666-6666-6666-666666666666',
      createdBy: 'Charlie O\'Hanlon',
      displayOptimization: 'Send Time',
      eloquaCampaignId: 1173210,
      emailCount: 1,
      name: 'Apple at Work_AppleCare_2021 Update_Canada_STO',
      organizationId: 1,
      overrideSendFrequencyCheck: true,
      status: 'running',
      statusDate: twoWeeksAgo,
      uniqueContactCount: 873132,
      uid: 2,
      updatedAt: twoWeeksAgo
    },
    {
      campaignId: '77777777-7777-7777-7777-777777777777',
      createdBy: 'Frank Flavell',
      displayOptimization: 'Simple',
      eloquaCampaignId: 1048392,
      emailCount: 1,
      name: 'Apple at Work_AppleCare_Terms Update_Canada_FM',
      organizationId: 1,
      overrideSendFrequencyCheck: false,
      status: 'completed',
      statusDate: oneMonthAgo,
      uniqueContactCount: 869431,
      uid: 2,
      updatedAt: oneMonthAgo
    }, {
      campaignId: '88888888-8888-8888-8888-888888888888',
      createdBy: 'Frank Flavell',
      displayOptimization: 'Message Testing',
      eloquaCampaignId: 1019921,
      emailCount: 16,
      name: 'Apple at Work_iPhone Product_Update_Canada_MT_CTA_SL',
      organizationId: 1,
      overrideSendFrequencyCheck: false,
      status: 'completed',
      statusDate: oneMonthAgo,
      uniqueContactCount: 1301989,
      uid: 2,
      updatedAt: oneMonthAgo
    }];
  }

  getDemoGlobalFilter() {
    var filters = {
      eloquaCampaigns: [],
      treatments: [],
      currentFilterSettings: {
        filterBy: 'all',
        ids: []
      }
    };

    filters.eloquaCampaigns = [{
      campaign_id: 126614,
      name: 'Apple at Work_iPhone 13_Teaser_Canada_MT_CTA',
      transientIds: [],
      selected: false
    },
    {
      campaign_id: 127709,
      name: 'Apple at Work_AppleCare_What\'s New_Canada_STO',
      transientIds: [],
      selected: false
    },
    {
      campaign_id: 127710,
      name: 'Apple at Work_AppleCare_What\'s New_Canada_FM',
      transientIds: [],
      selected: false
    },
    {
      campaign_id: 126285,
      name: 'Apple at Work_iPhone 12_Release_Canada_MT_Subject Line',
      transientIds: [],
      selected: false
    }];

    filters.treatments = [{
      name: 'Apple at Work_iPhone 13_CTA #1',
      subject: 'The power of Apple. Put to work.',
      treatment_id: 3024,
      selected: false
    },
    {
      name: 'Apple at Work_iPhone 13_CTA #2',
      subject: 'The power of Apple. Put to work.',
      treatment_id: 3025,
      selected: false
    }, {
      name: 'Apple at Work_iPhone 13_CTA #3',
      subject: 'The power of Apple. Put to work.',
      treatment_id: 3026,
      selected: false
    },
    {
      name: 'Apple at Work_iPhone 13_CTA #4',
      subject: 'The power of Apple. Put to work.',
      treatment_id: 3027,
      selected: false
    },
    {
      name: 'Apple at Work_AppleCare_What\'s New',
      subject: 'What\'s new with AppleCare',
      treatment_id: 3127,
      selected: false
    },
    {
      name: 'Apple at Work_AppleCare_What\'s New_Q3 Update',
      subject: 'At your service, every step of the way',
      treatment_id: 3128,
      selected: false
    },
    {
      name: 'Apple at Work_iPhone 12_Subject Line #1',
      subject: 'Check out the new iPhone 12',
      treatment_id: 2292,
      selected: false
    },
    {
      name: 'Apple at Work_iPhone 12_Subject Line #2',
      subject: 'Apple iPhone 12 is here',
      treatment_id: 2293,
      selected: false
    }, {
      name: 'Apple at Work_iPhone 12_Subject Line #3',
      subject: 'The new iPhone 12 has arrived',
      treatment_id: 2294,
      selected: false
    },
    {
      name: 'Apple at Work_iPhone 12_Subject Line #4',
      subject: 'Our latest iPhone is here',
      treatment_id: 2295,
      selected: false
    },
    {
      name: 'Apple at Work_iPhone 12_Subject Line #5',
      subject: 'Checkout the new iPhone 12',
      treatment_id: 2296,
      selected: false
    },
    {
      name: 'Apple at Work_iPhone 12_Subject Line #6',
      subject: 'iPhone 12',
      treatment_id: 2297,
      selected: false
    }, {
      name: 'Apple at Work_iPhone 12_Subject Line #7',
      subject: 'Get the iPhone 12 NOW!',
      treatment_id: 2298,
      selected: false
    },
    {
      name: 'Apple at Work_iPhone 12_Subject Line #8',
      subject: 'The power of Apple in iPhone 12',
      treatment_id: 2299,
      selected: false
    }];

    return filters;
  }

  downloadDataFiles(campaign) {
    // Get report.json
    this.http
      .get(`/core-api/api/campaign/${campaign.campaignId}/report`)
      .toPromise()
      .then(
        report => {
          this.downloadObjectAsJson(report, 'report');
        },
        error => {
          console.log('Error downloading report', error);
        }
      );

    // Download campaign.json
    this.downloadObjectAsJson(campaign, 'campaign');

    // Get schedule.json
    this.http
      .get(`/core-api/api/campaign/${campaign.campaignId}/schedule`)
      .toPromise()
      .then(
        campaignSchedule => {
          this.downloadObjectAsJson(campaignSchedule, 'schedule');
        },
        error => {
          console.log('Error downloading schedule', error);
        }
      );

    // Get timeWindows.json
    this.http
      .get('/api/campaigns/timeWindows', {
        params: {
          campaigns: [campaign.campaignId],
          timezone: campaign.timezone ? campaign.timezone : 'US/Pacific'
        }
      })
      .toPromise()
      .then(
        timeWindows => {
          this.downloadObjectAsJson(timeWindows, 'timeWindows');
        },
        error => {
          console.log('Error downloading time windows', error);
        }
      );

    // Who data
    var keys = [];
    //Read selected who report fields from the campaign.
    campaign.contactFields.forEach(function(contactField) {
      if(contactField && contactField.isSelected) {
        //Add keys for the OLAP query
        keys.push(contactField.key);
      }
    });

    this.http
      .get('/api/olap/who', {
        params: {
          orgId: campaign.organizationId,
          keys,
          filterBy: 'motivaCampaign',
          ids: campaign.campaignId,
          maxNumberOfValues: 20
        }
      })
      .toPromise()
      .then(
        whoData => {
          this.downloadObjectAsJson(whoData, 'who');
        },
        error => {
          console.log('Error downloading who data', error);
        }
      );

    this.http
      .get('/api/olap/whoFeatures', {
        params: {
          orgId: campaign.organizationId,
          filterBy: 'motivaCampaign',
          ids: campaign.campaignId
        }
      })
      .toPromise()
      .then(
        whoFeatures => {
          this.downloadObjectAsJson(whoFeatures, 'whoFeatures');
        },
        error => {
          console.log('Error downloading who features', error);
        }
      );

    var reportKpis = ['opens', 'clickthroughs'];
    reportKpis.forEach(kpi => {
      var params = {
        orgId: campaign.organizationId,
        activity: kpi,
        filterBy: 'motivaCampaign',
        ids: campaign.campaignId,
        pageSize: 50,
        offset: 0
      };

      this.http
        .get('/api/olap/whoTotal', { params })
        .toPromise()
        .then(
          topFeatures => {
            this.downloadObjectAsJson(topFeatures, `whoTotal-${kpi}`);
          },
          error => {
            console.log('Error downloading who total', error);
          }
        );
    });
  } //end: downloadDataFiles

  downloadObjectAsJson(object, fileName) {
    var dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(object))}`;
    var downloadElement = document.createElement('a');

    downloadElement.setAttribute('href', dataStr);
    downloadElement.setAttribute('download', `${fileName}.json`);
    document.body.appendChild(downloadElement);

    downloadElement.click();
    downloadElement.remove();
  } //end: downloadObjectAsJson
}
