import { AfterViewInit, Directive, TemplateRef, ViewContainerRef } from '@angular/core';

/**
 * Load a component only when it becomes visible in the browser.
 * Usage: <div *isVisible>I am visible in the view port!</div>
 */
@Directive({
  selector: '[isVisible]',
})
export class IsVisibleDirective implements AfterViewInit {
  static parameters = [ViewContainerRef, TemplateRef];
  constructor(vcRef: ViewContainerRef, tplRef: TemplateRef) {
    this.vcRef = vcRef;
    this.tplRef = tplRef;
    this.observer = null;

    //We are using this to lazy load components, so only want to load once.
    this.visible = false;
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  ngAfterViewInit() {
    const observedElement = this.vcRef.element.nativeElement.parentElement;
    this.observer = new IntersectionObserver(([entry]) => {
      this.renderContents(entry.isIntersecting);
    });
    this.observer.observe(observedElement);
  }

  renderContents(isIntersecting: boolean) {
    //this.vcRef.clear();
    if(isIntersecting && !this.visible) {
      this.vcRef.createEmbeddedView(this.tplRef);
      this.visible = true; //only do this one.
    }
  }
}
