import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../components/auth/auth.service';
import { ImpersonationService } from '../../components/auth/impersonation.service';
import { CampaignService } from '../campaign/shared/campaign.service';
import { CampaignFilesService } from '../campaign/shared/campaign-files.service';
import { DarkPoolService } from '../dark-pool/shared/dark-pool.service';
import { LoggerService } from '../shared/logger.service';

@Component({
  selector: 'main',
  template: require('./main.html'),
  styles: [require('./main.scss')]
})
export class MainComponent implements OnInit {
  static parameters = [AuthService, ImpersonationService, CampaignService, CampaignFilesService, DarkPoolService, LoggerService];
  constructor(authService: AuthService, impersonation: ImpersonationService, campaignService: CampaignService, campaignFilesService: CampaignFilesService, darkPoolService: DarkPoolService, loggerService: LoggerService) {
    this.authService = authService;
    this.impersonation = impersonation;
    this.campaignService = campaignService;
    this.campaignFilesService = campaignFilesService;
    this.darkPoolService = darkPoolService;
    this.loggerService = loggerService;

    this.currentOrg = { active: false };
    this.motivaSteps = [];
    this.loadingMotivaSteps = true;
    this.isDemo = false;
  }

  ngOnInit() {
    this.isAdmin = false;
    this.authService.isAdmin().then(is => {
      this.isAdmin = is;
    });

    this.authService.getCurrentOrg().then(org => {
      if(typeof org !== 'undefined') {
        this.currentOrg = org;
      }
    });

    this.orgSub = this.authService.currentOrgChanged.subscribe(org => {
      if(typeof org !== 'undefined') {
        this.currentOrg = org;
      }
    });

    this.isDemo = this.impersonation.isDemoMode();

    if(!this.isDemo) {
      this.loadRecentCampaigns();
    }
    else {
      this.loadDemo();
    }
  }

  ngOnDestroy() {
    if(this.orgSub) this.orgSub.unsubscribe();
  }

  loadRecentCampaigns() {
    this.campaignService
      .home()
      .toPromise()
      .then(campaigns => {
        this.loadingMotivaSteps = false;
        this.motivaSteps = campaigns;
        this.motivaSteps.forEach(function(email) {
          //Add display status
          //Both Simple Bulk and Drip have sendWinnerToAllRemainingImmediately set to true.
          if(email.optimizationCriterion !== 'sendTime' && email.emailCount === 1 && typeof email.sendWinnerToAllRemainingImmediately !== 'undefined' && email.sendWinnerToAllRemainingImmediately) {
            email.displayOptimization = 'Simple';
          }
          else if(email.optimizationCriterion === 'sendTime') {
            //<< null is STO
            email.displayOptimization = 'Send Time';
          }
          else {
            email.displayOptimization = 'Message Testing';
          }
        });
      },
      error => {
        this.loggerService.logMessage('Error retrieving campaigns for home screen.', 'error', error);
        this.loadingMotivaSteps = false;
      })
      .catch(e => {
        this.loggerService.logMessage('Exception retrieving campaigns for home screen.', 'error', e);
        this.loadingMotivaSteps = false;
      });
  }

  loadDemo() {
    this.loadingMotivaSteps = false;
    this.motivaSteps = this.campaignFilesService.getDemoCampaignList();
  }
}
