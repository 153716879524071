import { Component } from '@angular/core';

@Component({
  selector: 'app',
  template: `<navbar></navbar>
    <router-outlet></router-outlet>
    <footer></footer>`

  /* TODO: add cookie consent and browser warning directives.
<navbar></navbar>
<section cookie-consent></section>
<section browser-warning></section>
<div ui-view=""></div>
<footer></footer>
*/
})
export class AppComponent {}
