// @flow
import { Component } from '@angular/core';
import { AuthService } from '../../../components/auth/auth.service';

@Component({
  selector: 'login',
  template: require('./login.html')
})
export class LoginComponent {
  static parameters = [AuthService];
  constructor(authService: AuthService) {
    if(authService.isLoggedInSync()) {
      authService.logout();
    }
  }
}
