// @flow
import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { AuthService } from '../../../components/auth/auth.service';
import { ImpersonationService } from '../../../components/auth/impersonation.service';

import { TimeWindowsService } from '../shared/time-windows.service';
import { OrganizationService } from '../../organization/shared/organization.service';

import { FilterSelectorComponent } from '../../../components/filter-selector/filter-selector.component';

import { getTimezones, guessTimezone, getCacheValue, setCacheValue, removeCacheValue } from '../../../components/util.js';

@Component({
  selector: 'timewindows',
  template: require('./global-time-windows.html')
})
export class GlobalTimeWindowsComponent implements OnInit {
  modalRef: BsModalRef;
  currentOrg;

  static parameters = [AuthService, ImpersonationService, TimeWindowsService, BsModalService, OrganizationService];
  constructor(authService: AuthService, impersonation: ImpersonationService, timeWindowsService: TimeWindowsService, modalService: BsModalService, organizationService: OrganizationService) {
    this.authService = authService;
    this.impersonation = impersonation;
    this.timeWindowsService = timeWindowsService;
    this.modalService = modalService;
    this.organizationService = organizationService;

    this.guessTimezone = guessTimezone;
    this.getTimezones = getTimezones;

    this.getCacheValue = getCacheValue;
    this.setCacheValue = setCacheValue;
    this.removeCacheValue = removeCacheValue;

    this.loadingFilterOptions = true;
    this.isDemo = false;

    this.filters = {
      eloquaCampaigns: [],
      treatments: [],
      currentFilterSettings: {
        filterBy: 'all',
        ids: []
      }
    };

    this.restrictSendTimesLocal = {
      daysOfTheWeek: {}
    };

    this.daysOfTheWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  }

  ngOnInit() {
    this.init();
    this.isDemo = this.impersonation.isDemoMode();
    this.authService.getCurrentOrg().then(org => {
      if(typeof org !== 'undefined') {
        this.currentOrg = org;
        if(!this.isDemo) {
          this.reset();
        }
        else {
          this.loadDemo();
        }
      }
    });

    this.isAdmin = false;
    this.authService.isAdmin().then(is => {
      this.isAdmin = is;
    });
  }

  ngOnDestroy() {
    //Cache report data
    if(this.timeWindows && this.filters && !this.loadingHeatmap && !this.loadingFilterOptions && !this.isDemo) {
      this.setCacheValue('globalTimeWindows', this.timeWindows);
      this.setCacheValue('globalTimeWindowsFilter', this.filters);
    }
  }

  init() {
    this.timeWindows = {
      loading: true,
      toggle: 'openRate',
      config: {
        rowMap: {},
        verticalLabels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        horizontalLabels: ['12a', '1a', '2a', '3a', '4a', '5a', '6a', '7a', '8a', '9a', '10a', '11a', '12p', '1p', '2p', '3p', '4p', '5p', '6p', '7p', '8p', '9p', '10p', '11p']
      },
      topSlots: [],
      bottomSlots: [],
      colorScale: null,
      textColorScale: null,
      timezone: this.guessTimezone(),
      heatMapData: {
        sendTimeRestrictions: [],
        color: 'circles'
      }
    };

    this.timezones = this.getTimezones();

    this.colorSchemes = [
      {
        gradient: 'linear-gradient(to right, #ffffdd, #3e9583, #1f2d86)',
        colors: ['#ffffdd', '#3e9583', '#1f2d86']
      },
      {
        gradient: 'linear-gradient(to right, rgba(1, 147, 207, 0.1), rgba(1, 147, 207, 1))',
        colors: ['rgba(1, 147, 207, 0.1)', 'rgba(1, 147, 207, 1)']
      },
      {
        gradient: 'linear-gradient(to right, #ea5044, #af7889, #0193cf)',
        colors: ['#ea5044', '#af7889', '#0193cf']
      }
    ];

    this.selectedColorScheme = this.colorSchemes[0];
  }

  reset() {
    //Check cache
    var timeWindows = this.getCacheValue('globalTimeWindows');
    var timeWindowsFilter = this.getCacheValue('globalTimeWindowsFilter');

    if(timeWindows && timeWindowsFilter) {
      //Found data in the cache
      this.timeWindows = timeWindows;
      this.timeWindowsService.addColorScales(this.timeWindows);
      this.timeWindows.loading = false;
      this.filters = timeWindowsFilter;
      this.loadingFilterOptions = false;
    }
    else {
      this.timeWindowsService.getFilterOptions(this.currentOrg._id).then(
        filterOptions => {
          this.filters.eloquaCampaigns = filterOptions.eloquaCampaigns;
          this.filters.treatments = filterOptions.treatments;
          this.loadingFilterOptions = false;

          this.timeWindowsService.buildGlobalTimeWindows(this.currentOrg._id, this.timeWindows, this.filters.currentFilterSettings).then(heatMapData => {
            this.timeWindows.heatMapData.reportData = heatMapData.reportData;
            this.timeWindows.heatMapData.showNow = heatMapData.showNow;
            this.timeWindows.heatMapData.dailyAverages = heatMapData.dailyAverages;
            this.timeWindows.heatMapData.hourlyAverages = heatMapData.hourlyAverages;
            this.timeWindows.heatMapData.colorScheme = this.selectedColorScheme.colors;
            this.timeWindows.loading = false;
          });
        },
        error => {
          console.log('Error getting filter options for global send times', error);
          this.timeWindows.loading = false;
        }
      );

      // this.organizationService.getRecommendedSendTimeRestrictions(this.currentOrg._id, this.restrictSendTimesLocal, this.timeWindows.timezone).then(() => {
      //   this.daysOfTheWeek.forEach(day => {
      //     if(this.restrictSendTimesLocal && this.restrictSendTimesLocal.daysOfTheWeek[day]) {
      //       this.timeWindows.heatMapData.sendTimeRestrictions.push([this.restrictSendTimesLocal.startHour, this.restrictSendTimesLocal.endHour]);
      //     }
      //     else {
      //       this.timeWindows.heatMapData.sendTimeRestrictions.push(null);
      //     }
      //   });
      // });
    }
  }

  changeTimezone(newTimezone) {
    this.timeWindows.heatMapData.sendTimeRestrictions = [];
    this.timeWindows.timezone = newTimezone;
    this.timeWindows.loading = true;

    if(!this.isDemo) {
      this.timeWindowsService.buildGlobalTimeWindows(this.currentOrg._id, this.timeWindows, this.filters.currentFilterSettings).then(heatMapData => {
        this.timeWindows.heatMapData.reportData = heatMapData.reportData;
        this.timeWindows.heatMapData.showNow = heatMapData.showNow;
        this.timeWindows.heatMapData.dailyAverages = heatMapData.dailyAverages;
        this.timeWindows.heatMapData.hourlyAverages = heatMapData.hourlyAverages;
        this.timeWindows.heatMapData.colorScheme = this.selectedColorScheme.colors;

        this.timeWindows.loading = false;

        // this.organizationService.getRecommendedSendTimeRestrictions(this.currentOrg._id, this.restrictSendTimesLocal, this.timeWindows.timezone).then(
        //   () => {
        //     this.timeWindows.loading = false;
        //     this.daysOfTheWeek.forEach(day => {
        //       if(this.restrictSendTimesLocal && this.restrictSendTimesLocal.daysOfTheWeek[day]) {
        //         this.timeWindows.heatMapData.sendTimeRestrictions.push([this.restrictSendTimesLocal.startHour, this.restrictSendTimesLocal.endHour]);
        //       }
        //       else {
        //         this.timeWindows.heatMapData.sendTimeRestrictions.push(null);
        //       }
        //     });
        //   },
        //   error => {
        //     this.timeWindows.loading = false;
        //     console.log('Error getting recommended send time restrictions', error);
        //   }
        // );
      });
    }
    else {
      this.loadDemo();
    }
  }

  timeWindowsToggleChanged() {
    this.loadingHeatmap = true;
    var sendTimeRestrictions = this.timeWindows.heatMapData.sendTimeRestrictions;

    if(!this.isDemo) {
      this.timeWindowsService.buildGlobalTimeWindows(this.currentOrg._id, this.timeWindows, this.filters.currentFilterSettings).then(heatMapData => {
        this.loadingHeatmap = false;
        this.timeWindows.heatMapData.reportData = heatMapData.reportData;
        this.timeWindows.heatMapData.showNow = heatMapData.showNow;
        this.timeWindows.heatMapData.dailyAverages = heatMapData.dailyAverages;
        this.timeWindows.heatMapData.hourlyAverages = heatMapData.hourlyAverages;
        this.timeWindows.heatMapData.sendTimeRestrictions = sendTimeRestrictions;
        this.timeWindows.heatMapData.colorScheme = this.selectedColorScheme.colors;
      });
    }
    else if(this.filters.currentFilterSettings.filterBy === 'all') {
      this.loadDemo();
    }
  }

  openFilterSelector() {
    const initialState = {
      eloquaCampaigns: this.filters.eloquaCampaigns,
      treatments: this.filters.treatments,
      currentFilter: this.filters.currentFilterSettings,
      report: 'globalSto'
    };

    this.modalRef = this.modalService.show(FilterSelectorComponent, { initialState });

    var sub = this.modalService.onHidden.subscribe(() => {
      if(this.modalRef.content.okClicked) {
        this.filters.currentFilterSettings = this.modalRef.content.response;
        this.timeWindows.loading = true;
        var sendTimeRestrictions = this.timeWindows.heatMapData.sendTimeRestrictions;

        this.timeWindowsService.buildGlobalTimeWindows(this.currentOrg._id, this.timeWindows, this.filters.currentFilterSettings).then(heatMapData => {
          this.timeWindows.heatMapData.reportData = heatMapData.reportData;
          this.timeWindows.heatMapData.showNow = heatMapData.showNow;
          this.timeWindows.heatMapData.dailyAverages = heatMapData.dailyAverages;
          this.timeWindows.heatMapData.hourlyAverages = heatMapData.hourlyAverages;
          this.timeWindows.heatMapData.sendTimeRestrictions = sendTimeRestrictions;
          this.timeWindows.heatMapData.colorScheme = this.selectedColorScheme.colors;
          this.timeWindows.loading = false;
        });
      }
      if(sub) sub.unsubscribe();
    });
  }

  clearFilter() {
    this.timeWindows.loading = true;
    this.filters.currentFilterSettings = {
      filterBy: 'all',
      ids: []
    };

    this.filters.eloquaCampaigns.forEach(campaign => {
      campaign.selected = false;
    });

    this.filters.treatments.forEach(treatment => {
      treatment.selected = false;
    });

    this.timeWindows.heatMapData.sendTimeRestrictions = [];

    // Build report unfiltered
    if(!this.isDemo) {
      this.timeWindowsService.buildGlobalTimeWindows(this.currentOrg._id, this.timeWindows, this.filters.currentFilterSettings).then(heatMapData => {
        this.timeWindows.heatMapData.reportData = heatMapData.reportData;
        this.timeWindows.heatMapData.showNow = heatMapData.showNow;
        this.timeWindows.heatMapData.dailyAverages = heatMapData.dailyAverages;
        this.timeWindows.heatMapData.hourlyAverages = heatMapData.hourlyAverages;
        this.timeWindows.heatMapData.colorScheme = this.selectedColorScheme.colors;

        this.timeWindows.loading = false;

        // this.organizationService.getRecommendedSendTimeRestrictions(this.currentOrg._id, this.restrictSendTimesLocal, this.timeWindows.timezone).then(
        //   () => {
        //     this.timeWindows.loading = false;
        //     this.daysOfTheWeek.forEach(day => {
        //       if(this.restrictSendTimesLocal && this.restrictSendTimesLocal.daysOfTheWeek[day]) {
        //         this.timeWindows.heatMapData.sendTimeRestrictions.push([this.restrictSendTimesLocal.startHour, this.restrictSendTimesLocal.endHour]);
        //       }
        //       else {
        //         this.timeWindows.heatMapData.sendTimeRestrictions.push(null);
        //       }
        //     });
        //   },
        //   error => {
        //     this.timeWindows.loading = false;
        //     console.log('Error getting recommended send time restrictions', error);
        //   }
        // );
      });
    }
    else {
      this.loadDemo();
    }
  }

  changeColorScheme(colorScheme) {
    this.timeWindows.loading = true;
    this.selectedColorScheme = colorScheme;
    this.timeWindows.colorScale.range(colorScheme.colors);
    this.timeWindows.heatMapData.colorScheme = colorScheme.colors;

    setTimeout(() => {
      this.timeWindows.loading = false;
    });
  }

  timeWindowsColorToggleChanged() {
    this.loadingHeatmap = true;
    setTimeout(() => {
      this.loadingHeatmap = false;
    });
  }

  loadDemo() {
    this.timeWindowsService.getDemoGlobalTimeWindowsData(this.timeWindows, this.restrictSendTimesLocal).then(heatMapData => {
      this.timeWindows.heatMapData.reportData = heatMapData.reportData;
      this.timeWindows.heatMapData.showNow = heatMapData.showNow;
      this.timeWindows.heatMapData.dailyAverages = heatMapData.dailyAverages;
      this.timeWindows.heatMapData.hourlyAverages = heatMapData.hourlyAverages;
      this.timeWindows.heatMapData.colorScheme = this.selectedColorScheme.colors;

      // Get demo recommended send window
      this.organizationService.getDemoRecommendedSendWindow(this.restrictSendTimesLocal, this.timeWindows.timezone).then(() => {
        this.timeWindows.heatMapData.sendTimeRestrictions = [];
        this.daysOfTheWeek.forEach(day => {
          if(this.restrictSendTimesLocal && this.restrictSendTimesLocal.daysOfTheWeek[day]) {
            this.timeWindows.heatMapData.sendTimeRestrictions.push([this.restrictSendTimesLocal.startHour, this.restrictSendTimesLocal.endHour]);
          }
          else {
            this.timeWindows.heatMapData.sendTimeRestrictions.push(null);
          }
        });

        this.loadingFilterOptions = false;
        this.timeWindows.loading = false;
        this.loadingHeatmap = false;
      });
    });
  }
}
