// @flow
import { Injectable } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { GeneratorService } from '../../generator/shared/generator.service';
import { AuthService } from '../../../components/auth/auth.service';

import { cloneDeep, reject } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PersonaService {
  static parameters = [HttpClient, DecimalPipe, GeneratorService, AuthService];
  constructor(http: HttpClient, decimalPipe: DecimalPipe, generatorService: GeneratorService, authService: AuthService) {
    this.http = http;
    this.decimalPipe = decimalPipe;
    this.generatorService = generatorService;
    this.authService = authService;

    this.authService.getCurrentUser().then(user => {
      this.currentUser = user;
    });
  }

  initAttributes(personaLocal, selectedContactFields, orgId, pageSize, selectedKeyValueMap) {
    const promise = new Promise((resolve, reject) => {
      var keys = [];

      if(!personaLocal.attributes || personaLocal.attributes.length == 0) {
        selectedContactFields.forEach(field => {
          personaLocal.attributes.push({
            name: field.name,
            key: field.key,
            values: [],
            search: {
              text: '',
              pageNum: 0,
              busy: false
            }
          });

          keys.push(field.key);
        });
      }

      this.getAttributeValues(keys, orgId, pageSize).then(
        keyValueMap => {
          personaLocal.attributes.forEach(attribute => {
            attribute.values = [];
            // If loading a current persona, add the selected values
            if(selectedKeyValueMap && selectedKeyValueMap[attribute.key]) {
              // Sort selected values
              selectedKeyValueMap[attribute.key].sort((a, b) => {
                var aVal = !isNaN(a) ? Number(a, 10) : a;
                var bVal = !isNaN(b) ? Number(b, 10) : b;

                return aVal - bVal;
              });

              selectedKeyValueMap[attribute.key].forEach(value => {
                attribute.values.push({
                  value: !isNaN(value) ? Number(value, 10) : value,
                  displayValue: this.formatValue(value),
                  isSelected: true
                });
              });
            }

            if(keyValueMap[attribute.key]) {
              keyValueMap[attribute.key].forEach(value => {
                // If value isn't already added as a selected value, add to list
                const found = selectedKeyValueMap ? selectedKeyValueMap[attribute.key].find(el => el == value.value) : false;
                if(!found) {
                  attribute.values.push(value);
                }
              });

              attribute.valuesFiltered = cloneDeep(attribute.values);

              // Sort values
              attribute.valuesFiltered.sort((a, b) => {
                if(a.isSelected === b.isSelected) {
                  if(a.value < b.value) return -1;
                  else return 1;
                }
                else if(a.isSelected) return -1;
                else return 1;
              });
            }
          });

          resolve();
        },
        err => {
          reject(err);
        }
      );
    });

    return promise;
  }

  getAttributeValues(keys, orgId, pageSize) {
    const promise = new Promise(resolve => {
      var keyValueMap = {};
      var promises = [];
      keys.forEach(key => {
        promises.push(this.getAttributeValuePage(keyValueMap, orgId, key, pageSize, 0));
      });

      Promise.all(promises).then(() => {
        resolve(keyValueMap);
      });
    });
    return promise;
  }

  getAttributeValuePage(keyValueMap, orgId, key, pageSize, pageNum, searchString) {
    const promise = new Promise((resolve, reject) => {
      var params = {
        orgId,
        key,
        pageSize,
        pageNum
      };

      if(searchString) params.searchString = searchString;

      this.http
        .get('/api/olap/attributeValuePage', { params })
        .toPromise()
        .then(
          values => {
            keyValueMap[key] = [];

            values.forEach(val => {
              keyValueMap[key].push({
                value: val.value,
                displayValue: val.displayValue ? val.displayValue : val.value,
                isSelected: false
              });
            });
            resolve();
          },
          err => {
            console.log(`Error getting values for key ${key}`, err);
            reject(err);
          }
        );
    });

    return promise;
  }

  getPersonaContacts(selectedAttributes, orgId) {
    const promise = new Promise((resolve, reject) => {
      var attributes = Object.keys(selectedAttributes);
      var params = {
        orgId,
        attributes
      };

      attributes.forEach(attribute => {
        params[attribute] = selectedAttributes[attribute];
      });

      this.http
        .get('/api/olap/personaContacts', { params })
        .toPromise()
        .then(
          contacts => {
            resolve(contacts);
          },
          err => {
            console.log('Error getting contacts for Persona', err);
            reject(err);
          }
        );
    });

    return promise;
  }

  getPersonaStats(selectedAttributes, orgId) {
    const promise = new Promise((resolve, reject) => {
      var attributes = Object.keys(selectedAttributes);
      var params = {
        orgId,
        attributes
      };

      attributes.forEach(attribute => {
        params[attribute] = selectedAttributes[attribute];
      });

      this.http
        .get('/api/olap/personaStats', { params })
        .toPromise()
        .then(
          response => {
            resolve(response);
          },
          err => {
            console.log('Error getting stats for Persona', err);
            reject(err);
          }
        );
    });

    return promise;
  }

  getPersonas(orgId) {
    const promise = new Promise((resolve, reject) => {
      this.http
        .get(`/trans-am-api/api/personas/organization/${orgId}`)
        .toPromise()
        .then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  getTopEmailsForPersona(personaId, orgId, rateToggle, contactCountThreshold, limit) {
    const promise = new Promise((resolve, reject) => {
      var params = {
        orgId,
        personaId,
        limit,
        rate: rateToggle,
        contactCountThreshold
      };

      this.http
        .get('/api/olap/personaTopEmails', { params })
        .toPromise()
        .then(
          response => {
            resolve(response);
          },
          err => {
            console.log('Error getting stats for Persona', err);
            reject(err);
          }
        );
    });

    return promise;
  }

  processEmailDetails(emailDetails) {
    var processedEmailDetails = {}
    //If we have subject
    if(typeof emailDetails.subject !== 'undefined') {
      processedEmailDetails.subject = emailDetails.subject;
    }
    
    //If we have plainText
    if(typeof emailDetails.plainText !== 'undefined') {
      processedEmailDetails.plainText = emailDetails.plainText;

      this.generatorService.strip(emailDetails.plainText).then(result => {
        processedEmailDetails.strippedPlainText = result.strippedText;
      });
    }

    //If we have htmlContent.html
    if(typeof emailDetails.htmlContent.html !== 'undefined') {
      processedEmailDetails.htmlText = emailDetails.htmlContent.html;
    }

    //If we have htmlContent.htmlBody
    if(typeof emailDetails.htmlContent.htmlBody !== 'undefined') {
      processedEmailDetails.htmlText = emailDetails.htmlContent.htmlBody;
    }

    //Strip the htmlText, if we have it.
    if(processedEmailDetails.htmlText) {
      this.generatorService.strip(processedEmailDetails.htmlText).then(result => {
        processedEmailDetails.strippedHtmlText = result.strippedText;
      });
    }

    return processedEmailDetails;
  }

  getTopEmailDetailsForPersona(personaId, orgId) {
    return new Promise((resolve, reject) => {
      var rateToggle = 'clickRate';
      var contactCountThreshold = 0.5;
  
      // get the top 10 emails
      this.getTopEmailsForPersona(personaId, orgId, rateToggle, contactCountThreshold, 10)
        .then(emails => {
          // Create an array of promises for each email request
          const emailPromises = emails.map(email => this.getEmailDetails(email.treatment_id, orgId));
  
          // Wait for all promises to resolve
          Promise.all(emailPromises)
            .then(topEmailBodies => {
              console.log('topEmailBodies: ', topEmailBodies);
              // Resolve the outer promise with the top email bodies
              resolve(topEmailBodies);
            })
            .catch(err => {
              console.log('Error getting email details', err);
              // Reject the outer promise if there's an error
              reject(err);
            });
        })
        .catch(err => {
          console.log('Error getting top emails for Persona', err);
          // Reject the outer promise if there's an error
          reject(err);
        });
    });
  }

  getEmailDetails(emailId, orgId) {
    return new Promise((resolve, reject) => {
      this.http
        .get(`/eloqua-api/api/assets/email/${emailId}?depth=complete&orgId=${orgId}`)
        .toPromise()
        .then(
          email => {
            console.log('email: ', email)
            var processedEmailDetails = this.processEmailDetails(email);
            resolve(processedEmailDetails);
          },
          //Error Handling...
          error => {
            //this.loading = false;
            console.log('Error getting email', error);
  
            // Try refreshing eloqua token and trying again
            this.authService
              .verifyAuth(this.currentUser._id)
              .toPromise()
              .then(
                () => {
                  // Token refreshed successfully, so try getting email asset again
                  this.http
                    .get(`/eloqua-api/api/assets/email/${emailId}?depth=complete&orgId=${orgId}`)
                    .toPromise()
                    .then(
                      email => {
                        var processedEmailDetails = this.processEmailDetails(email);
                        resolve(processedEmailDetails);
                      },
                      error => {
                        console.log('Error getting email', error);
                        reject(error);
                    })
                },
                //Error Handling...
                error => {
                  console.log('Error verifying auth', error);
                  reject(error);
                }
              );
          });
    });
  }

  getOverallOrgStats(orgId) {
    const promise = new Promise((resolve, reject) => {
      var params = {
        orgId
      };

      this.http
        .get('/api/olap/orgOverallRates', { params })
        .toPromise()
        .then(
          response => {
            var overallOrgStats = {
              sends: response.sends,
              opens: response.opens,
              clicks: response.clicks,
              openRate: response.open_rate,
              clickthroughRate: response.click_rate,
              clickToOpenRate: response.click_to_open_rate
            };

            resolve(overallOrgStats);
          },
          err => {
            console.log('Error getting overall stats for Org', err);
            reject(err);
          }
        );
    });

    return promise;
  }

  getPersonaModelForSave(personaLocal) {
    var model = {
      name: personaLocal.name,
      definitionType: personaLocal.definitionType
    };

    if(personaLocal.definitionType == 'attributes') {
      model.attributes = [];

      personaLocal.attributes.forEach(attribute => {
        attribute.values.forEach(val => {
          if(val.isSelected) {
            model.attributes.push({
              key: attribute.key,
              value: val.value.toString()
            });
          }
        });
      });
    }
    else {
      model.segmentId = personaLocal.segment.id;
    }

    return model;
  }

  savePersona(orgId, persona) {
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(`/trans-am-api/api/personas/organization/${orgId}`, persona)
        .toPromise()
        .then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  updatePersona(orgId, personaId, persona) {
    const promise = new Promise((resolve, reject) => {
      this.http
        .put(`/trans-am-api/api/personas/${personaId}`, persona)
        .toPromise()
        .then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  deletePersona(orgId, personaId) {
    const promise = new Promise((resolve, reject) => {
      this.http
        .delete(`/trans-am-api/api/personas/${personaId}`)
        .toPromise()
        .then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  setRateComparisonArrows(overallStats, personas) {
    var rates = ['openRate', 'clickthroughRate', 'clickToOpenRate'];
    personas.forEach(persona => {
      if(!persona.rateComparison) {
        persona.rateComparison = {};
      }

      // Demo data
      if(persona.personaId == 'e09bed2c-40f4-4360-8da3-61981115ee7b') {
        persona.rateComparison.openRate = 'higher';
        persona.rateComparison.clickthroughRate = 'higher';
        persona.rateComparison.clickToOpenRate = 'higher';
      }
      else if(persona.personaId == 'e824a0dc-b1ef-463c-bee1-b2d153c35f95') {
        persona.rateComparison.openRate = 'higher';
        persona.rateComparison.clickthroughRate = 'higher';
        persona.rateComparison.clickToOpenRate = 'higher';
      }
      else if(persona.personaId == '6b729562-6adb-41fd-9616-2d220475d8e5') {
        persona.rateComparison.openRate = 'equal';
        persona.rateComparison.clickthroughRate = 'equal';
        persona.rateComparison.clickToOpenRate = 'equal';
      }
      else if(persona.personaId == '20702349-4329-4bd1-a8de-a854b5f166db') {
        persona.rateComparison.openRate = 'lower';
        persona.rateComparison.clickthroughRate = 'lower';
        persona.rateComparison.clickToOpenRate = 'lower';
      }
      else {
        rates.forEach(rate => {
          var rateDiff = Math.abs(persona.personaStats[rate] - overallStats[rate]);
          if(rateDiff < 0.001 || persona.personaStats[rate] < 0.0005 && overallStats[rate] < 0.0005) {
            persona.rateComparison[rate] = 'equal';
          }
          else if(persona.personaStats[rate] < overallStats[rate]) {
            persona.rateComparison[rate] = 'lower';
          }
          else {
            persona.rateComparison[rate] = 'higher';
          }
        });
      }
    });
  }

  getPersonaContactIds(personaId) {
    const promise = new Promise((resolve, reject) => {
      var params = {
        personaId
      };

      this.http
        .get('/api/olap/personaContactIds', {params})
        .toPromise()
        .then(
          contactIds => {
            resolve(contactIds);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  getDemoPersonas() {
    const promise = new Promise((resolve, reject) => {
      this.http
        .get('/assets/demo/global/personas.json')
        .toPromise()
        .then(
          demoPersonas => {
            resolve(demoPersonas);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  getDemoTopEmails(rateToggle, personaId) {
    const promise = new Promise((resolve, reject) => {
      var sortByRate = '';
      if(rateToggle == 'openRate') sortByRate = 'open_rate';
      else sortByRate = 'click_rate';

      this.http
        .get(`/assets/demo/global/personaTopEmails/persona${personaId}.json`)
        .toPromise()
        .then(
          topEmails => {
            topEmails.sort((a, b) => {
              if(a[sortByRate] > b[sortByRate]) return -1;
              else if(a[sortByRate] < b[sortByRate]) return 1;
              return 0;
            });
            resolve(topEmails);
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  // Campaign Personas
  getCampaignPersonas(campaignId, orgId) {
    const promise = new Promise((resolve, reject) => {
      var params = {
        campaignId
      };

      this.http
        .get('/api/olap/campaignPersonas', { params })
        .toPromise()
        .then(
          campaignPersonas => {
            var personas = [];

            // Get all personas for org, and return personas that match the campaign persona ids
            this.getPersonas(orgId).then(allOrgPersonas => {
              allOrgPersonas.forEach(orgPersona => {
                campaignPersonas.forEach(campaignPersona => {
                  if(campaignPersona.persona_id == orgPersona.personaId) {
                    personas.push({
                      name: orgPersona.name,
                      personaId: orgPersona.personaId,
                      attributes: orgPersona.attributes,
                      contactCount: parseInt(campaignPersona.contact_count),
                      personaStats: {}
                    });
                  }
                });
              });

              var reportPromises = [];
              personas.forEach(persona => {
                reportPromises.push(this.getCampaignPersonaReport(persona, campaignId));
              });

              // Make sure we get report data for all personas before resolving
              Promise.all(reportPromises).then(() => {
                resolve(personas);
              });
            });
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  getCampaignPersonaReport(persona, campaignId) {
    const promise = new Promise((resolve, reject) => {
      var personaReportParams = {
        personaId: persona.personaId,
        campaignId
      };

      this.http
        .get('/api/olap/campaignPersonaReport', { params: personaReportParams })
        .toPromise()
        .then(
          personaReport => {
            var stats = {
              totalDistinctSends: 0,
              totalDistinctOpens: 0,
              totalDistinctClickthroughs: 0,
              treatments: {}
            };

            personaReport.forEach(treatment => {
              stats.totalDistinctSends += parseInt(treatment.sends);
              stats.totalDistinctOpens += parseInt(treatment.opens);
              stats.totalDistinctClickthroughs += parseInt(treatment.clickthroughs);

              stats.treatments[treatment.treatment_id] = {
                sends: treatment.sends,
                opens: treatment.opens,
                clickthroughs: treatment.clickthroughs,
                openRate: treatment.opens / treatment.sends,
                clickthroughRate: treatment.clickthroughs / treatment.sends,
                clickToOpenRate: treatment.clickthroughs / treatment.opens
              };
            });

            stats.openRate = stats.totalDistinctOpens / stats.totalDistinctSends;
            stats.clickthroughRate = stats.totalDistinctClickthroughs / stats.totalDistinctSends;
            stats.clickToOpenRate = stats.totalDistinctClickthroughs / stats.totalDistinctOpens;
            stats.contactCount = persona.contactCount;

            persona.personaStats = stats;

            resolve();
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  getExportData(personas, populationSize) {
    const promise = new Promise(resolve => {
      var exportData = [];
      personas.forEach(persona => {
        var row = {
          name: persona.name,
          contactCount: persona.personaStats.contactCount,
          percentOfPopulation: persona.personaStats.contactCount / populationSize,
          sends: persona.personaStats.totalDistinctSends,
          opens: persona.personaStats.totalDistinctOpens,
          clickthroughs: persona.personaStats.totalDistinctClickthroughs,
          openRate: persona.personaStats.openRate,
          clickthroughRate: persona.personaStats.clickthroughRate,
          clickToOpenRate: persona.personaStats.clickToOpenRate
        };

        exportData.push(row);
      });

      resolve(exportData);
    });

    return promise;
  }

  saveSelectedPersonasForCampaign(personas, campaignId) {
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(`/trans-am-api/api/personas/campaign/${campaignId}`, personas)
        .toPromise()
        .then(
          () => {
            resolve();
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  getSelectedPersonasForCampaign(campaignId) {
    const promise = new Promise((resolve, reject) => {
      this.http
        .get(`/trans-am-api/api/personas/campaign/${campaignId}`)
        .toPromise()
        .then(
          selectedPersonas => {
            resolve(selectedPersonas);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Formatting for attribute values
  formatNumberUSA(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  formatValue(value) {
    var displayValue = value;
    if(typeof value !== 'undefined') {
      //Replace [min, max) and [min, max] notation.
      if(value.toString().charAt(0) === '[') {
        var tmp = value.toString().split(',');
        if(tmp.length > 1) {
          var rightVal = tmp[1];
          var lastChar = rightVal.substr(rightVal.length - 1);
          rightVal = rightVal.trim().slice(0, -1);

          if(rightVal !== 'inf') {
            displayValue = (lastChar === ')' ? '< ' : '<= ') + this.formatNumberUSA(Number(rightVal));
          }
          else {
            var leftVal = tmp[0];
            var firstChar = leftVal.substr(0, 1);
            leftVal = leftVal.trim().substr(1, leftVal.length - 1);

            displayValue = (firstChar === '(' ? '> ' : '>= ') + this.formatNumberUSA(Number(leftVal));
          }
        }
      }
    }
    return displayValue;
  }

  getCampaignPersonasFromFile(folderName) {
    const promise = new Promise((resolve, reject) => {
      this.http
        .get(`/assets/demo/campaigns/${folderName}/personas.json`)
        .toPromise()
        .then(
          personas => {
            resolve(personas);
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  } // End: getCampaignPersonasFromFile

  getEloquaCampaignPersonaResults(orgId, eloquaCampaignId) {
    const promise = new Promise((resolve, reject) => {
      var params = {
        orgId,
        eloquaCampaignId
      };

      this.http
        .get('/api/olap/personaCampaignResults', { params })
        .toPromise()
        .then(results => {
          resolve(results);
        },
        err => {
          reject(err);
        });
    });

    return promise;
  }
}
