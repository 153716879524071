import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';

import { AlertModule } from 'ngx-bootstrap/alert';

import { AuthGuard } from '../../components/auth/auth-guard.service';
import { DirectivesModule } from '../../components/directives.module';

import { LoginComponent } from './login/login.component';
import { SettingsComponent } from './settings/settings.component';
import { NoAuthComponent } from './noauth/noauth.component';
import { NoStorageComponent } from './nostorage/nostorage.component';

const accountRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'noAuth',
    component: NoAuthComponent
  },
  {
    path: 'noStorage',
    component: NoStorageComponent
  }
];

@NgModule({
  imports: [FormsModule, BrowserModule, RouterModule.forChild(accountRoutes), AlertModule.forRoot(), DirectivesModule],
  declarations: [LoginComponent, SettingsComponent, NoAuthComponent, NoStorageComponent]
})
export class AccountModule {}
