import { Component, ElementRef, HostListener, Input, SimpleChanges, OnInit } from '@angular/core';
import * as d3 from 'd3';
import moment from 'moment-timezone';

@Component({
  selector: 'viz-area-chart',
  template: '<div></div>'
})
export class AreaChartComponent implements OnInit {
  @Input() data;
  @Input() height;

  @HostListener('window:resize', ['$event'])
  onResize() {
    //If not vislble, don't resize.
    if(!(this.htmlElement.offsetParent !== null)) return;
    var newWidth = this.htmlElement.parentElement.clientWidth;

    this.svg.attr('width', newWidth);
    this.width = +this.svg.attr('width') - this.margin.left - this.margin.right;

    if(this.data) {
      this.chart.attr('width', this.width);
      this.x = d3.scaleTime().range([50, this.width - 60]);
      this.render(this.data);
    }
  }

  margin = { top: 20, left: 60, bottom: 20, right: 40 };
  htmlElement: HTMLElement;
  svg;

  static parameters = [ElementRef];
  constructor(element: ElementRef) {
    this.htmlElement = element.nativeElement;
    this.host = d3.select(element.nativeElement);
  }

  ngOnInit() {
    //Set width to 100% of parent container.
    this.width = this.htmlElement.parentElement.clientWidth;

    //append the svg object
    this.host.html('');
    this.svg = this.host
      .append('svg')
      .attr('height', this.height ? this.height : '250')
      .attr('width', this.width)
      .attr('id', 'cumulative_areaChart');

    //Add margin
    this.width = +this.svg.attr('width') - this.margin.left - this.margin.right;
    this.height = +this.svg.attr('height') - this.margin.top - this.margin.bottom;

    this.q = d3.quadtree();

    // set the ranges
    this.x = d3.scaleTime().range([0, this.width]);
    this.y = d3.scaleLinear().range([this.height, 0]);

    // define the area
    this.area = d3
      .area()
      .y1(d => this.y(d[this.data.attrToChart]))
      .y0(this.height)
      .defined(d => {
        // Omit empty values.
        if(d[this.data.attrToChart] !== null) {
          return d[this.data.attrToChart];
        }
      })
      .x(d => this.x(d.resultDateTime));

    // define the line
    this.valueline = d3
      .line()
      .y(d => this.y(d[this.data.attrToChart]))
      .defined(d => {
        // Omit empty values.
        if(d[this.data.attrToChart] !== null) {
          return d[this.data.attrToChart];
        }
      })
      .x(d => this.x(d.resultDateTime));

    this.chart = this.svg
      .append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
      .attr('width', this.width)
      .attr('height', this.height);

    this.tooltip = this.host
      .append('div')
      .attr('class', 'area-chart-tooltip')
      .style('opacity', 0);

    this.render(this.data);
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'data' && this.data && this.chart) {
        this.x = d3.scaleTime().range([50, this.width - 60]);
        this.render(this.data);
      }
    }
  }

  render(data) {
    if(!data || data.length === 0) return;
    this.chart.selectAll('*').remove();

    var attr = data.attrToChart;

    // Get x axis values
    var dates = [];
    data.forEach(d => {
      dates.push(d.resultDateTime);
    });

    // If more than 14 dates, reduce number of ticks
    var xAxisValues = [];
    if(dates.length > 14) {
      var maxNum = 14;

      var num = dates.length / maxNum;
      if(num % 1 > 0) {
        num = Math.trunc(dates.length / maxNum) + 1;
      }

      dates.forEach((date, index) => {
        if(index % num === 0) {
          xAxisValues.push(date);
        }
      });
    }
    else {
      xAxisValues = dates.map(d => d);
    }

    // scale the range of the data
    this.x = d3.scaleTime().range([50, this.width - 60]);

    var xDomain = d3.extent(data, d => d.resultDateTime);
    var startDate = new Date(xDomain[0]);
    var endDate = new Date(xAxisValues[xAxisValues.length - 1]);
    endDate.setHours(23, 59, 59);

    this.x.domain([startDate, endDate]);

    // If y max is less than 10 , make max = 10
    this.y
      .domain([
        0,
        d3.max(data, d => d[attr]) > 10
          ? d3.max(data, d => d[attr])
          : 10
      ])
      .nice();

    data.forEach(e => {
      e.x = Math.floor(this.x(e.resultDateTime));
      e.y = Math.floor(this.y(e[data.attrToChart]));

      // add this point to quadtree, so we can find it with UI
      this.q.add([e.x, e.y, e]);
    });

    // add the valueline path.
    this.chart
      .append('path')
      .data([data])
      .attr('class', 'line')
      .attr('stroke-width', '1px')
      .attr('stroke', '#0086d6')
      .attr('fill', 'none')
      .attr('opacity', 1)
      .attr('d', this.valueline);

    // add the area
    this.chart
      .append('path')
      .data([data])
      .attr('class', 'path-area')
      .attr('fill', '#0086d6')
      .attr('opacity', 0.2)
      .attr('d', this.area);

    //TODO: Style the axes with external styles.

    // add the X Axis
    this.chart
      .append('g')
      .attr('transform', `translate(0,${this.height})`)
      .call(
        d3
          .axisBottom(this.x)
          .tickValues(xAxisValues)
          .tickFormat(d3.timeFormat('%a %m/%d'))
          .tickSize(-this.height)
          .tickSizeOuter(0)
          .tickPadding(9)
      )
      .selectAll('.tick line')
      .style('stroke', '#aaa')
      .style('shape-rendering', 'crispEdges')
      .style('stroke-dasharray', '3')
      .style('stroke-width', '1px');

    // add the Y Axis
    this.chart
      .append('g')
      .attr('class', 'yAxis')
      .call(d3.axisLeft(this.y))
      .selectAll('.tick:not(:first-of-type) line')
      .style('stroke', '#aaa')
      .style('shape-rendering', 'crispEdges')
      .style('stroke-dasharray', '3')
      .style('stroke-width', '1px')
      .attr('x2', this.width);

    this.chart
      .select('.yAxis')
      .selectAll('.tick:first-of-type line')
      .style('stroke', '#000')
      .attr('x2', this.width);

    this.chart
      .selectAll('dot')
      .data(data)
      .enter()
      .append('circle')
      .attr('class', 'dot')
      .attr('r', 3)
      .attr('cx', d => this.x(d.resultDateTime))
      .attr('cy', d => this.y(d[attr]))
      .attr('fill', '#0086d6')
      .attr('opacity', 1);

    this.chart.on('mousemove', () => {
      // get mouse position
      var mouseXy = d3.mouse(d3.event.currentTarget);
      // find closest data point to mouse position
      var dot = this.q.find(mouseXy[0], mouseXy[1]);
      this.mouseOver(dot[2]);
    });

    this.chart.on('mouseout', () => {
      // get mouse position
      var mouseXy = d3.mouse(d3.event.currentTarget);
      // find closest data point to mouse position
      var dot = this.q.find(mouseXy[0], mouseXy[1]);
      this.mouseOut(dot[2]);
    });
  } //end: render()

  mouseOver(d) {
    var value = d[this.data.attrToChart];

    d3.select(d3.event.srcElement).attr('r', 5);

    this.tooltip
      .transition()
      .duration(200)
      .style('opacity', 0.9);
    this.tooltip
      .html(`<b>${moment(d.resultDateTime).format('ddd, MMM D, YYYY')}</b><br/><b>Total: </b>${d3.format(',')(value)}`)
      .style('left', `${this.x(d.resultDateTime)}px`)
      .style('top', `${this.y(value) + 10}px`);
  }

  mouseOut() {
    d3.select(d3.event.srcElement).attr('r', 3);

    this.tooltip
      .transition()
      .duration(500)
      .style('opacity', 0);
  }
}
