import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DarkPoolService } from '../dark-pool.service';

@Component({
  selector: 'dark-pool-help',
  template: require('./dark-pool-help.html')
})
export class DarkPoolHelpComponent implements OnInit {
  static parameters = [BsModalRef, DarkPoolService];
  constructor(bsModalRef: BsModalRef, darkPoolService: DarkPoolService) {
    this.bsModalRef = bsModalRef;
    this.darkPoolService = darkPoolService;

    this.title = 'Dark Pool Help';
    this.alerts = [];

    this.help = null;
    this.bouncebackHelp = null;
  }

  ngOnInit() {
    this.loading = true;

    if(this.motivaTag) {
      this.bouncebackHelp = this.darkPoolService.getMotivaTagInfo(this.motivaTag);
    }

    this.loading = false;
  }

  done() {
    this.bsModalRef.hide();
  }
}
