import { Component } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'footer',
  template: require('./footer.html')
})
export class FooterComponent {
  version;
  year;

  static parameters = [CookieService];
  constructor(cookies: CookieService) {
    this.version = cookies.get('app-version');
    this.year = new Date().getFullYear();
  }
}
