import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {
  //*ngFor="let c of arrayOfObjects | orderBy:'propertyName':'asc|desc'"
  //*ngFor="let c of arrayOfObjects | orderBy:['propertyName1', 'propertyName2']:['asc|desc', 'asc|desc']"
  transform(value, properties, orders): any[] {
    // no array
    if(!value) {
      return value;
    }

    // array with only one item
    if(value.length <= 1) {
      return value;
    }

    if(typeof properties === 'string') {
      properties = [properties];
    }

    if(typeof orders === 'string') {
      orders = [orders];
    }

    return orderBy(value, properties, orders);
  }
}
