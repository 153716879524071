import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { AuthService } from '../../../components/auth/auth.service';
import { UserService } from '../../../components/auth/user.service';
import { userRoles } from '../../app.constants';

@Component({
  selector: 'update-user',
  template: require('./update-user.html')
})
export class UpdateUserComponent implements OnInit {
  user = { userOptions: {}, Organization: {} };
  checkingAuth = false;

  static parameters = [ActivatedRoute, Location, AuthService, UserService];
  constructor(route: ActivatedRoute, location: Location, authService: AuthService, userService: UserService) {
    this.route = route;
    this.location = location;
    this.authService = authService;
    this.userService = userService;
    this.userRoles = userRoles;
  }

  ngOnInit() {
    this.alerts = [];
    this.userIdSub = this.route.paramMap.subscribe(params => {
      var userId = params.get('userId');
      this.userService.get({ id: userId }).subscribe(user => {
        this.user = user;
        this.eloquaDisplay = JSON.stringify(this.user.eloqua, null, 4);

        // If user has no userOptions, add and save.
        if(!this.user.userOptions) {
          this.user.userOptions = {
            receiveEmails: true
          };
          this.update();
        }
      });
    });
  }

  ngOnDestroy() {
    if(this.userIdSub) this.userIdSub.unsubscribe();
  }

  goBack() {
    this.location.back();
  }

  roleChanged(role) {
    this.user.role = role;
  }

  verifyAuth() {
    this.alerts = [];
    this.checkingAuth = true;
    this.authService
      .verifyAuth(this.user._id)
      .toPromise()
      .then(
        () => {
          this.alerts.push({ type: 'success', msg: 'This user\'s Eloqua oAuth token is in good shape.' });
          this.checkingAuth = false;
        },
        //Error Handling...
        error => {
          console.log('Error verifying auth', error);
          this.alerts.push({ type: 'danger', msg: 'There\'s a problem with this user\'s Eloqua oAuth token.' });
          this.checkingAuth = false;
        }
      );
  }

  update() {
    this.alerts = [];
    this.userService
      .update(this.user)
      .toPromise()
      .then(
        () => {
          this.alerts.push({ type: 'success', msg: 'User successfully updated.' });
        },
        //Error Handling...
        error => {
          this.alerts.push({ type: 'warning', msg: 'Error updating user.' });
          console.log('Error updating user', error);
        }
      );
  }
}
