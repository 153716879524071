import { Component, ElementRef, HostListener, Input, SimpleChanges, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'viz-dot-matrix-chart',
  template: '<div></div>'
})
export class DotMatrixComponent implements OnInit {
  @Input() data;
  @Input() height;

  @HostListener('window:resize', ['$event'])
  onResize() {
    //If not vislble, don't resize.
    if(!(this.htmlElement.offsetParent !== null)) return;
    /*
    this.width = this.htmlElement.parentElement.clientWidth;

    this.svg.attr('width', this.width);
    this.width = +this.svg.attr('width') - this.margin.left - this.margin.right;
    */

    if(this.data) {
      this.render(this.data);
    }
  }

  matrixSize = 180;
  margin = { top: 0, left: 0, bottom: 0, right: 0 };

  htmlElement: HTMLElement;
  svg;

  static parameters = [ElementRef];
  constructor(element: ElementRef) {
    this.htmlElement = element.nativeElement;
    this.host = d3.select(element.nativeElement);
    this.format = d3.format(',');
  }

  ngOnInit() {
    this.width = this.matrixSize + this.margin.left + this.margin.right;
    this.height = this.matrixSize + this.margin.top + this.margin.bottom;

    //append the svg object
    this.host.html('');
    this.svg = this.host
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height);

    this.g = this.svg.append('g').attr('transform', `translate(${this.margin.left},${this.margin.top})`);

    //Start loading animation while waiting for data...
    this.randomizer();
    this.randomizerID = setInterval(() => {
      this.randomizer();
    }, 170);
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'data') {
        if(this.data) {
          this.render(this.data);
        }
        else if(typeof this.data !== 'undefined') {
          //Also show loading animation when data changes to null (for reload)
          this.randomizer();
          this.randomizerID = setInterval(() => {
            this.randomizer();
          }, 170);
        }
      }
    }
  }

  render(data) {
    if(this.randomizerID) clearInterval(this.randomizerID);
    if(!data || typeof data.value === 'undefined' || typeof data.total === 'undefined' || typeof this.svg === 'undefined') return;

    //Prep matrix data
    var currDot = 0;
    var matrix = [];
    var ratio = data.value / data.total * 100;

    for(let y = 9; y >= 0; y--) {
      for(let x = 0; x < 10; x++) {
        matrix.push({x, y, index: currDot < Math.round(ratio) ? 1 : -1});
        currDot++;
      }
    }

    this.g.selectAll('*').remove();

    var sequence = (length) => Array.apply(null, {length}).map((d, i) => i);

    var scale = d3.scaleBand()
      .domain(sequence(10))
      .range([0, this.matrixSize])
      .padding(0.2);

    const cellSize = scale.bandwidth();
    const half = cellSize / 2;
    const cells = this.g.append('g')
      .selectAll('circle')
      .data(matrix)
      .join('circle')
      .attr('fill', d => d.index === -1 ? '#d9d9d9' : '#f92d16' /*color(d.index)*/);

    cells.attr('cx', d => scale(d.x) + half)
      .attr('cy', d => scale(d.y) + half)
      .attr('r', half);
    //.style('cursor', 'pointer');
  } //end: render()


  randomizer() {
    //Prep matrix data
    var matrix = [];

    for(let y = 9; y >= 0; y--) {
      for(let x = 0; x < 10; x++) {
        matrix.push({x, y, index: Math.random() > 0.80 ? 1 : -1});
      }
    }

    this.g.selectAll('*').remove();

    var sequence = (length) => Array.apply(null, {length}).map((d, i) => i);

    var scale = d3.scaleBand()
      .domain(sequence(10))
      .range([0, this.matrixSize])
      .padding(0.2);

    const cellSize = scale.bandwidth();
    const half = cellSize / 2;
    const cells = this.g.append('g')
      .selectAll('circle')
      .data(matrix)
      .join('circle')
      .attr('fill', d => d.index === -1 ? '#d9d9d9' : '#f92d16');

    cells.attr('cx', d => scale(d.x) + half)
      .attr('cy', d => scale(d.y) + half)
      .attr('r', half);
    //.style('cursor', 'pointer');
  } //end: randomizer()


  /*
  mouseover(item) {
    this.svg.selectAll('.donut-path').style('opacity', 0.2);

    d3.select(`[id='path_${item.name}']`).style('opacity', 1);

    this.tooltip
      .transition()
      .duration(200)
      .style('opacity', 0.9);

    this.tooltip
      .html(`<b>${item.name}</b><br/><b>Total: </b>${item.total}`)
      .style('left', `${d3.event.layerX}px`)
      .style('top', `${d3.event.layerY - 20}px`);
  }

  mouseout() {
    this.svg.selectAll('.donut-path').style('opacity', 1);
    this.tooltip
      .transition()
      .duration(500)
      .style('opacity', 0);
  }
  */
}
