import { Component, ElementRef, HostListener, Input, SimpleChanges, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'viz-histogram',
  template: '<div></div>'
})
export class HistogramComponent implements OnInit {
  @Input() data;
  @Input() height;
  @Input() label;
  @Input() format;

  @HostListener('window:resize', ['$event'])
  onResize() {
    //If not vislble, don't resize.
    if(!(this.htmlElement.offsetParent !== null)) return;
    var newWidth = this.htmlElement.parentElement.clientWidth;

    this.svg.attr('width', newWidth);
    this.width = +this.svg.attr('width') - this.margin.left - this.margin.right;

    if(this.data) {
      this.chart.attr('width', this.width);
      this.render(this.data);
    }
  }

  margin = { top: 20, left: 60, bottom: 20, right: 40 };

  htmlElement: HTMLElement;
  svg;

  static parameters = [ElementRef];
  constructor(element: ElementRef) {
    this.htmlElement = element.nativeElement;
    this.host = d3.select(element.nativeElement);
  }

  ngOnInit() {
    if(!this.format) this.format = ',d';
    if(!this.label) this.label = 'Bucket';

    //Set width to 100% of parent container.
    this.width = this.htmlElement.parentElement.clientWidth;

    //append the svg object
    this.host.html('');
    this.svg = this.host
      .append('svg')
      .attr('height', this.height ? this.height : '250')
      .attr('width', this.width);

    //Add margin
    this.width = +this.svg.attr('width') - this.margin.left - this.margin.right;
    this.height = +this.svg.attr('height') - this.margin.top - this.margin.bottom;

    this.tooltip = this.host
      .append('div')
      .attr('class', 'chart-tooltip')
      .style('opacity', 0);

    this.chart = this.svg
      .append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('overflow', 'hidden');

    this.render(this.data);
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'data' && this.data && this.chart) {
        if(this.width <= 0) {
          this.ngOnInit();
        }
        else {
          this.render(this.data);
        }
      }
    }
  }

  render(data) {
    this.chart.selectAll('*').remove();
    if(!data || data.values.length === 0) return;

    //X axis: scale and draw.
    this.x = d3.scaleBand()
      .domain(data.columns)
      .range([0, this.width])
      .paddingInner(0.2)
      .paddingOuter(0.2);

    var xAxis = d3.axisBottom(this.x);

    //If there are more than X columns, remove some labels.
    if(data.columns.length > 32) {
      xAxis.tickValues(this.x.domain().filter((d, i) => !(i % 10)));
    }

    this.chart.append('g')
      .attr('class', 'xaxis')
      .attr('transform', `translate(0,${this.height})`)
      .call(xAxis);

    //Y axis: scale and draw
    var format = d3.format(this.format);
    var maxY = d3.max(data.values);

    this.y = d3.scaleLinear()
      .domain([0, maxY])
      .nice()
      .range([this.height, 0]);

    var yAxis = d3.axisLeft(this.y);

    if(maxY < 10) {
      yAxis.ticks(maxY);
    }

    this.chart.append('g')
      .call(yAxis.tickFormat(format));

    //append the bars
    this.chart.selectAll('rect')
      .data(data.values)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (d, i) => this.x(data.columns[i]))
      .attr('width', this.x.bandwidth())
      .attr('y', d => this.y(d))
      .attr('height', d => this.height - this.y(d))
      .attr('fill', '#0086d6')
      .on('mouseover', (d, i) => {
        this.showTooltip(d, i);
      })
      .on('mouseout', () => {
        this.hideTooltip();
      });
  } //end: render()


  showTooltip(value, index) {
    this.hideTooltip();

    if(value && index >= 0) {
      this.tooltip
        .transition()
        .duration(600)
        .style('opacity', 1);

      this.tooltip
        .html(
          `${`<p class="name">${this.data.name}</p>`
            + `<p class="result"><span class="faded vLabel">${this.label}:</span><strong>${this.data.columns[index]}</strong></p>`
            + `<p class="result"><span class="faded vLabel">Value:</span><strong>${d3.format(this.format)(value)}</strong></p>`
          }`
        )
        .style('left', `${this.x(this.data.columns[index]) + this.x.bandwidth() / 2}px`);
    }
  } //end: showTooltip()

  hideTooltip() {
    this.tooltip
      .transition()
      .duration(200)
      .style('opacity', 0);
  }

  highlightBars() {
    d3.selectAll('rect').style('opacity', '');
  }
}
