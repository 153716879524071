import { Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { AuthService } from '../../../../components/auth/auth.service';
import { CampaignService } from '../../../campaign/shared/campaign.service';
import { LoggerService } from '../../../shared/logger.service';
import { WhoService } from '../who.service';

@Component({
  selector: 'contact-attribute-selector-modal',
  template: require('./contact-attribute-selector.html')
})
export class ContactAttributeSelectorComponent {
  searchString = '';
  campaign = null;
  totalNum = 0;
  totalCountMap = {};
  persona = null;

  constructor(bsModalRef: BsModalRef, authService: AuthService, campaignService: CampaignService, loggerService: LoggerService, whoService: WhoService, bsModalService: BsModalService) {
    this.bsModalRef = bsModalRef;
    this.bsModalService = bsModalService;
    this.authService = authService;
    this.campaignService = campaignService;
    this.loggerService = loggerService;
    this.whoService = whoService;

    this.contactFieldsLoading = true;
  }

  ngOnInit() {
    var promises = [];

    //debug: start -------------------------------------------------------------
    //console.log('>>>>>> initialState values:');
    //console.log('persona', this.persona);
    //console.log('campaign', this.campaign);
    //console.log('totalNum', this.totalNum);
    //console.log('totalCountMap', this.totalCountMap);
    //console.log('selectedContactFieldsLocal', this.selectedContactFieldsLocal);
    //debug: end ---------------------------------------------------------------

    //Query the Org settings
    promises.push(
      this.whoService.getOrgSettings().then(orgSettings => {
        this.orgSettings = orgSettings;
      })
    );

    promises.push(
      this.authService.getCurrentUser().then(user => {
        if(user._id !== '') {
          this.orgId = user.organizationId;
        }
      })
    );

    //Get available contact fields from the latest active campaigns.
    this.campaignContactFields = [];
    var promise = new Promise((resolve) => {
      this.campaignService
        .active(30, true)
        .toPromise()
        .then(campaigns => {
          //If a campaign was passed, also add it.
          if(this.campaign) {
            const found = campaigns.find(el => el.campaignId === this.campaign.campaignId);
            if(!found) {
              //console.log('Adding passed campaign: ' + this.campaign.campaignId); //debug -----------
              campaigns.push(this.campaign);
            }
          }

          var count = campaigns.length;
          if(count <= 0) resolve();
          campaigns.forEach(campaign => {
            this.campaignService
              .get(campaign.campaignId)
              .toPromise()
              .then(c => {
                if(c.contactFields.length > 0) {
                  if(c.contactFields.length > this.campaignContactFields.length) {
                    this.campaignContactFields = c.contactFields;
                    //console.log('Set campaignContactFields:', this.campaignContactFields); //debug ------------
                  }
                }

                count--;
                if(count <= 0) {
                  resolve();
                }
              });
          });
        });
    });
    promises.push(promise);

    Promise.all(promises).then(() => {
      this.getContactAttributes();
    });
  }

  getContactAttributes() {
    var filterSettings = null;

    //Add any missing campaignContactFields to orgSettings.visibleContactAttributes.
    this.campaignContactFields.forEach(field => {
      const exists = this.orgSettings.visibleContactAttributes.find(el => el.key === field.key);
      if(!exists) {
        this.orgSettings.visibleContactAttributes.push(field);
      }
    });

    //For the campaign Who, we have the ability to filter by a campaign by
    //passing filterSettings, but this does not make sense now that we only
    //store selected attributes in the Organization.
    //Now, we just show all fields for the org everytime.
    /*
    if(this.campaign) {
      filterSettings = {
        filterBy: 'motivaCampaign',
        ids: this.campaign.campaignId
      };
    }*/

    this.whoService.findRelevantFeatures(this.orgId, filterSettings).then(attributes => {
      this.orgAttributes = [];

      //console.log('Attributes in who_report table:', attributes); //debug -------

      //Only show contact fields that actually have data in the select list.
      for(var i = 0; i < this.orgSettings.visibleContactAttributes.length; i++) {
        var contactField = this.orgSettings.visibleContactAttributes[i];
        const found = attributes.find(el => el === contactField.key);
        if(found) {
          this.orgAttributes.push(contactField);
        }
      }

      //Special case for Personas that uses selectedContactFieldsLocal.
      if(this.selectedContactFieldsLocal && this.selectedContactFieldsLocal.length > 0) {
        this.orgAttributes.forEach(item => {
          const selected = this.selectedContactFieldsLocal.find(el => el.key === item.key);
          if(selected) {
            item.isSelected = true;
          }
          else {
            item.isSelected = false;
          }
        });
      }

      this.orgAttributesFiltered = this.orgAttributes;
      this.contactFieldsLoading = false;
    });
  }

  filterAttributes() {
    var searchString = this.searchString.toLowerCase();
    function searchFilter(attribute) {
      return attribute.name && attribute.name.toLowerCase().indexOf(searchString) > -1;
    }
    this.orgAttributesFiltered = this.orgAttributes.filter(searchFilter);
  }

  ok() {
    this.okClicked = true;

    //Return only the selected values.
    this.selectedContactFields = [];

    for(var i = 0; i < this.orgAttributes.length; i++) {
      var contactField = this.orgAttributes[i];
      if(contactField.isSelected) {
        this.selectedContactFields.push(contactField);
      }
    }

    if(this.persona) {
      //Don't save back to Org when selecting attributes for a Persona.
      this.bsModalService.hide(2);
    }
    else {
      this.whoService.updateOrgSettings(this.orgSettings).then(
        () => {
          console.log('updated org settings');
        },
        error => {
          console.log('Error updating org settings', error);
        }
      );

      this.bsModalRef.hide();
    }
  }

  cancel() {
    this.okClicked = false;
    if(this.persona) {
      this.bsModalService.hide(2);
    }
    else {
      this.bsModalRef.hide();
    }
  }
}
