import { Component, ElementRef, HostListener, Input, SimpleChanges, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'viz-who-report',
  template: '<div></div>'
})
export class WhoReportComponent implements OnInit {
  @Input() data;
  @HostListener('window:resize', ['$event'])
  onResize() {
    //If not vislble, don't resize.
    if(!(this.htmlElement.offsetParent !== null)) return;

    if(this.htmlElement.parentElement.clientWidth && this.htmlElement.parentElement.clientWidth > 0) {
      this.width = this.htmlElement.parentElement.clientWidth - this.margin.left - this.margin.right;
    }

    this.barSvg.attr('width', '100%');
    this.dotSvg.attr('width', '100%');

    this.x = d3
      .scalePoint()
      .padding(1)
      .range([0, this.width]);

    if(this.data) {
      this.render(this.data);
    }
  }

  paddingLeftRight = 50;
  htmlElement: HTMLElement;
  svg;

  static parameters = [ElementRef];
  constructor(element: ElementRef) {
    this.htmlElement = element.nativeElement;
    this.host = d3.select(element.nativeElement);
  }

  ngOnInit() {
    this.margin = { top: 20, right: 20, bottom: 30, left: 140 };

    this.width = this.htmlElement.parentElement.clientWidth > 0 ? this.htmlElement.parentElement.clientWidth - this.margin.left - this.margin.right : 1000;
    this.height = 400 - this.margin.top - this.margin.bottom;
    this.barsHeight = 150 - this.margin.top - this.margin.bottom;

    this.x = d3
      .scalePoint()
      .padding(1)
      .range([0, this.width]);

    this.y = d3
      .scalePoint()
      .padding(1)
      .range([this.height, 0]);

    this.y2 = d3.scaleLinear().range([this.barsHeight, 0]);

    this.r = d3.scaleSqrt().range([0, 30]); //TODO: Do we need to vary the max radius?

    this.rSent = d3.scaleLinear().range([0, this.barsHeight]);

    // for dots
    this.yAxis = d3.axisLeft(this.y).tickSizeOuter(0);
    // for bars
    this.yAxis2 = d3.axisLeft(this.y2).tickSizeOuter(0);

    this.tooltip = this.host
      .append('div')
      .attr('class', 'who-report-tooltip')
      .style('opacity', 0);

    this.barSvg = this.host
      .append('svg')
      .style('width', '100%')
      //.attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.barsHeight + this.margin.top + this.margin.bottom);

    this.barChart = this.barSvg
      .append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`);

    this.dotSvg = this.host
      .append('svg')
      //.attr('width', width + margin.left + margin.right)
      .style('width', '100%')
      .attr('height', this.height + this.margin.top + this.margin.bottom);

    this.dotChart = this.dotSvg
      .append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
      .attr('class', 'dot-chart');

    this.dots = [];

    this.render(this.data);
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'data' && this.data & this.barSvg) {
        this.render(this.data);
      }
    }
  }

  render(data) {
    if(!data) return;
    if(this.dotChart) this.dotChart.selectAll('*').remove();
    if(this.barChart) this.barChart.selectAll('*').remove();
    this.dots = [];

    var results = data.results;
    var treatments = [];
    var xAxisLabels = [];
    var maxOpen = data.maxOpens;
    var maxSent = 0;

    // these are the y-axis categories
    var values = data.valueOrder;
    this.y.domain(values);

    results.forEach(d => {
      // treatment ID gives us the x-position
      d.treatmentId = d.treatment_id;
      treatments.push(d.treatmentId);
      xAxisLabels.push(d.name);

      // total sent is the y-value for bar chart that accompanies the dot-chart
      d.sent = +d.totalSent;
      maxSent = maxSent > d.sent ? maxSent : d.sent;
    });

    this.rSent.domain([0, maxSent]);
    this.y2.domain([0, maxSent]).nice();
    this.x.domain(treatments);
    this.r.domain([0, maxOpen]); //Scale the radius domain to the max open

    // now get the positions for our dots
    var addToRadius = 0;
    results.forEach(d => {
      d.results.forEach(e => {
        var openRadius = this.r(e.opens);
        var clickRadius = this.r(e.clicks);

        // if any radius is < 2px, increase all to make sure the dots are visible
        if(openRadius !== 0 && openRadius < 2 || clickRadius !== 0 && clickRadius < 2) {
          addToRadius = 1;
        }

        var openRate = e.opens / d.totalSent * 100;
        var clickRate = e.clicks / d.totalSent * 100;

        var dot = {
          x: this.x(String(d.treatmentId)),
          y: this.y(e.value),
          r1: openRadius > 0 ? openRadius + addToRadius : 0,
          r2: clickRadius > 0 ? clickRadius + addToRadius : 0,
          opens: e.opens < e.clicks ? e.clicks : e.opens, //if opens are less than clicks, show click count.
          openRate: Math.round(openRate * 10) / 10,
          clicks: e.clicks,
          clickRate: Math.round(clickRate * 10) / 10,
          treatment: d.treatmentId,
          group: e.value
        };

        this.dots.push(dot);
      });
    });

    // draw bar chart for total Sends for each treatment
    var barWidth = this.width / (treatments.length * 2);

    this.barChart
      .selectAll('.who-report-bar')
      .data(results)
      .enter()
      .append('rect')
      .attr('class', 'who-report-bar')
      .attr('x', d => this.x(String(d.treatmentId)) - barWidth / 2)
      .attr('width', barWidth)
      .attr('y', d => this.barsHeight - this.rSent(d.sent))
      .attr('height', d => this.rSent(d.sent))
      .on('mouseover', d => {
        this.barMouseOver(d);
      })
      .on('mouseout', () => {
        this.mouseOut();
      });

    this.barChart
      .append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', `translate(0,${this.barsHeight})`)
      .call(
        d3
          .axisBottom(this.x)
          .tickSizeOuter(0)
          .tickFormat(function(d, i) {
            return treatments[i];
          })
          .tickPadding(15)
      )
      .selectAll('.tick')
      .on('mouseover', d => {
        this.barAxisMouseOver(d);
      })
      .on('mouseout', () => {
        this.mouseOut();
      });

    this.barChart
      .append('g')
      .attr('class', 'axis axis--y')
      .call(this.yAxis2.ticks(4));

    // draw bubble chart

    // gridlines
    this.dotChart
      .append('g')
      .attr('class', 'grid')
      .call(
        this.makeYGridlines()
          .tickSize(-this.width)
          .tickFormat('')
      );

    // opens
    this.dotChart
      .selectAll('.who-report-dot')
      .data(this.dots)
      .enter()
      .append('circle')
      .attr('class', 'who-report-dot')
      .attr('r', function(d) {
        return d.r1;
      })
      .attr('cx', function(d) {
        return d.x;
      })
      .attr('cy', function(d) {
        return d.y;
      })
      .on('mouseover', d => {
        this.dotMouseOver(d);
      })
      .on('mouseout', () => {
        this.mouseOut();
      });

    // clicks
    this.dotChart
      .selectAll('.who-report-dot2')
      .data(this.dots)
      .enter()
      .append('circle')
      .attr('class', 'who-report-dot2')
      .attr('r', function(d) {
        return d.r2;
      })
      .attr('cx', function(d) {
        return d.x;
      })
      .attr('cy', function(d) {
        return d.y;
      })
      .on('mouseover', d => {
        this.dotMouseOver(d);
      })
      .on('mouseout', () => {
        this.mouseOut();
      });

    this.dotChart
      .append('g')
      .attr('class', 'axis axis--x')
      .call(
        d3
          .axisTop(this.x)
          .tickSizeOuter(0)
          .tickFormat('')
      );

    this.dotChart
      .append('g')
      .attr('class', 'axis axis--y')
      .call(this.yAxis)
      .selectAll('.tick');
  } //end: render()

  barMouseOver(d) {
    this.tooltip
      .transition()
      .duration(200)
      .style('opacity', 0.9);

    this.tooltip
      .html(`<b class="faded vLabel">Name:</b>&nbsp;&nbsp;${d.name}<br/><b class="faded vLabel">Subject:</b>&nbsp;&nbsp;${d.subject}<br/><b class="faded vLabel">Sent:</b>&nbsp;&nbsp;${d.sent.toLocaleString()}<br/>`)
      .style('left', `${this.x(String(d.treatmentId)) + 60}px`)
      .style('top', '-30px')
      .style('z-index', 9999);
  }

  barAxisMouseOver(d) {
    var name = '';
    var subject = '';

    this.data.results.forEach(result => {
      if(result.treatment_id === d) {
        name = result.name;
        subject = result.subject;
      }
    });

    this.tooltip
      .transition()
      .duration(200)
      .style('opacity', 0.9);

    this.tooltip
      .html(`<b class="faded vLabel">Name:</b>&nbsp;&nbsp;${name}<br/><b class="faded vLabel">Subject:</b>&nbsp;&nbsp;${subject}<br/>`)
      .style('left', `${this.x(String(d)) + 130}px`)
      .style('top', '100px')
      .style('z-index', 9999);
  }

  dotMouseOver(d) {
    this.tooltip
      .transition()
      .duration(200)
      .style('opacity', 0.9);

    this.tooltip
      .html(
        `<span class="faded vLabel">${this.data.key}:</span>&nbsp;&nbsp;${d.group}<br/>
                      <span class="faded vLabel">Opens:</span>&nbsp;&nbsp;${d.opens.toLocaleString()}&nbsp;&nbsp;(${d.openRate}%)<br/>
                      <span class="faded vLabel">Clicks:</span>&nbsp;&nbsp;${d.clicks.toLocaleString()}&nbsp;&nbsp;(${d.clickRate}%)`
      )
      .style('left', `${d.x + 180}px`)
      .style('top', `${d3.event.layerY}px`);
  }

  fieldMouseOver(d) {
    var totalSends = 0;
    this.data.results.forEach(treatment => {
      totalSends += treatment.totalSent;
    });

    this.tooltip
      .transition()
      .duration(200)
      .style('opacity', 0.9);

    this.tooltip
      .html(
        `<span class="faded vLabel">${this.data.name}:</span>&nbsp;&nbsp;${d}<br/>`
          + `<span class="faded vLabel">Number of contacts:</span>&nbsp;&nbsp;${this.data.attributeCounts[d]}<br/>`
          + `${(this.data.attributeCounts[d] / totalSends * 100).toFixed(1)}%<span class="faded vLabel"> of total sends</span><br/>`
      )
      .style('left', '0px')
      .style('top', `${d3.event.layerY - 80}px`);
  }

  mouseOut() {
    this.tooltip
      .transition()
      .duration(500)
      .style('opacity', 0);
  }

  // gridlines in y axis function
  makeYGridlines() {
    return d3.axisLeft(this.y).ticks(5);
  }
}
