import { Component, Input, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ngxCsv } from 'ngx-csv/ngx-csv';

import { AuthService } from '../../../../components/auth/auth.service';
import { ImpersonationService } from '../../../../components/auth/impersonation.service';

import { DarkPoolService } from '../dark-pool.service';

import { cloneDeep, filter, find } from 'lodash';
import * as d3 from 'd3';

@Component({
  selector: 'dark-pool-domain-analysis',
  template: require('./dark-pool-domain-analysis.html')
})
export class DarkPoolDomainAnalysis implements OnInit {
  @Input() visible;
  @Input() currentOrg;
  @Input() showUnresponsive;

  @ViewChild('domainTmpl', { static: true }) domainTmpl: TemplateRef<any>;
  @ViewChild('exportTmpl', { static: true }) exportTmpl: TemplateRef<any>;
  @ViewChild('integerTmpl', { static: true }) integerTmpl: TemplateRef<any>;
  @ViewChild('percentTmpl', { static: true }) percentTmpl: TemplateRef<any>;
  @ViewChild('catchAllHeaderTmpl', { static: true }) catchAllHeaderTmpl: TemplateRef<any>;
  @ViewChild('correctionsHeaderTmpl', { static: true }) correctionsHeaderTmpl: TemplateRef<any>;
  @ViewChild('subpopulationTmpl', { static: true }) subpopulationTmpl: TemplateRef<any>;
  @ViewChild('subpopulationHeaderTmpl', { static: true }) subpopulationHeaderTmpl: TemplateRef<any>;

  static parameters = [AuthService, DarkPoolService, ImpersonationService];
  constructor(authService: AuthService, darkPoolService: DarkPoolService, impersonation: ImpersonationService) {
    this.authService = authService;
    this.darkPoolService = darkPoolService;
    this.impersonation = impersonation;

    this.isDemo = false;

    this.loading = true;
    this.exporting = false;

    //General UI controls
    this.contactCountTotal = null;
    this.contactCountFiltered = null;
    this.searchString = '';
    this.filterActive = 'all';
    this.onlyCatchAll = false;
    this.filterType = 'both';
    this.page = {
      number: 0,
      numberPlusOne: 1,
      size: 15,
      sizes: [10, 15, 25, 50, 100, 250],
      totalPages: 1
    };
    this.darkPoolTimeRange = {};

    //Specific settings below
    this.rowsUnresponsive = [];
    this.rowsUnresponsiveCopy = [];
    this.selectedRowsUnresponsive = [];
    this.filterActiveUnresponsive = 'all';
    this.onlyCatchAllUnresponsive = false;
    this.filterTypeUnresponsive = 'both';

    this.rowsCatchall = [];
    this.rowsCatchallCopy = [];
    this.selectedRowsCatchall = [];
    this.filterTypeCatchall = 'both';

    this.rowsMisspelled = [];
    this.rowsMisspelledCopy = [];
    this.selectedRowsMisspelled = [];

    this.domainAnalysisTab = 'unresponsive';
    this.contactBreakdownLabels = this.darkPoolService.getContactBreakdownLabels();
    this.contactBreakdownDescriptions = this.darkPoolService.getContactBreakdownDescriptions();
  }

  ngOnInit() {
    this.isDemo = this.impersonation.isDemoMode();

    this.columnsUnresponsive = [];
    this.unresponsiveSort = '';
    this.unresponsiveProps = { currentSort: [{ prop: 'unresponsiveContacts', dir: 'desc'}] };

    this.columnsCatchall = [
      {
        prop: 'selected',
        name: '',
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizable: false,
        headerCheckboxable: true,
        checkboxable: true,
        width: 40
      },
      { prop: 'domain', name: 'Domain', cellTemplate: this.domainTmpl },
      { prop: 'isCatchAll', name: 'Catch All', cellClass: 'centerAlign', headerClass: 'centerAlign', headerTemplate: this.catchAllHeaderTmpl, width: 85 },
      { prop: 'uniqueopenrate', name: 'Open Rate', cellClass: 'rightAlign', headerClass: 'rightAlign', cellTemplate: this.percentTmpl, width: 85 },
      { prop: 'clickrate', name: 'Click Rate', cellClass: 'rightAlign', headerClass: 'rightAlign', cellTemplate: this.percentTmpl, width: 85 },
      { prop: 'totalContactsAnalyzed', name: 'Total Contacts', cellClass: 'rightAlign', headerClass: 'rightAlign', cellTemplate: this.integerTmpl },
      { prop: 'unresponsiveContacts', name: 'Unresponsive Contacts', cellClass: 'rightAlign', headerClass: ' darkPoolHeaderAttention rightAlign', cellTemplate: this.integerTmpl },
      { prop: 'unresponsivePercent', name: 'Unresponsive Percent', cellClass: 'rightAlign', headerClass: 'darkPoolHeaderAttention rightAlign', cellTemplate: this.percentTmpl },
      { prop: 'domain', name: 'Export Contacts', headerClass: 'darkPoolHeaderAttention', cellTemplate: this.exportTmpl, sortable: false }
    ];

    this.columnsMisspelled = [
      {
        prop: 'selected',
        name: '',
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizable: false,
        headerCheckboxable: true,
        checkboxable: true,
        width: 40
      },
      { prop: 'domain', name: 'Domain', cellTemplate: this.domainTmpl },
      { prop: 'domainCorrections', name: 'Corrections', headerClass: 'darkPoolHeaderAttention', headerTemplate: this.correctionsHeaderTmpl },
      { prop: 'sends', name: 'Emails Sent', cellClass: 'rightAlign', headerClass: ' darkPoolHeaderAttention rightAlign', cellTemplate: this.integerTmpl, maxWidth: 125 },
      { prop: 'contacts', name: 'Total Contacts', cellClass: 'rightAlign', headerClass: 'darkPoolHeaderAttention rightAlign', cellTemplate: this.integerTmpl, maxWidth: 125 },
      { prop: 'domain', name: 'Export Contacts', cellTemplate: this.exportTmpl, headerClass: 'darkPoolHeaderAttention', sortable: false, minWidth: 180, maxWidth: 180 }
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'visible') {
        //Lazy load.
        if(this.visible) {
          this.darkPoolService.getDarkPoolTimeRange(this.currentOrg._id).then(timeRange => {
            this.darkPoolTimeRange.startDate = timeRange.start_date;
            this.darkPoolTimeRange.endDate = timeRange.end_date;

            this.setColumnsUnresponsive();
            this.loadUnresponsive();
          });
        }
      }
      else if(propName === 'showUnresponsive') {
        if(this.showUnresponsive) {
          this.domainAnalysisTab = 'unresponsive';
          this.showUnresponsive = false;
        }
      }
    }
  }

  loadData() {
    if(this.domainAnalysisTab === 'unresponsive') {
      this.loadUnresponsive();
    }
    else if(this.domainAnalysisTab === 'catchall') {
      this.loadCatchall();
    }
    else if(this.domainAnalysisTab === 'misspelled') {
      this.loadMisspelled();
    }
  }

  countContactsTotal() {
    this.contactCountTotal = 0;
    if(this.domainAnalysisTab === 'unresponsive') {
      this.rowsUnresponsiveCopy.forEach(item => {
        this.contactCountTotal += item.unresponsiveContacts;
      });
    }
    else if(this.domainAnalysisTab === 'catchall') {
      this.rowsCatchallCopy.forEach(item => {
        this.contactCountTotal += item.totalContactsAnalyzed;
      });
    }
    else if(this.domainAnalysisTab === 'misspelled') {
      this.rowsMisspelledCopy.forEach(item => {
        this.contactCountTotal += item.contacts;
      });
    }
  }

  countContactsFiltered() {
    this.contactCountFiltered = 0;
    if(this.domainAnalysisTab === 'unresponsive') {
      this.rowsUnresponsive.forEach(item => {
        this.contactCountFiltered += item.unresponsiveContacts;
      });
    }
    else if(this.domainAnalysisTab === 'catchall') {
      this.rowsCatchall.forEach(item => {
        this.contactCountFiltered += item.totalContactsAnalyzed;
      });
    }
    else if(this.domainAnalysisTab === 'misspelled') {
      this.rowsMisspelled.forEach(item => {
        this.contactCountFiltered += item.contacts;
      });
    }
  }

  tabChanged() {
    if(this.loading || this.exporting) return;
    if(this.domainAnalysisTab === 'unresponsive') {
      this.filterActive = this.filterActiveUnresponsive;
      this.onlyCatchAll = this.onlyCatchAllUnresponsive;
      this.filterType = this.filterTypeUnresponsive;
    }
    if(this.domainAnalysisTab === 'catchall') {
      this.filterActive = 'exclude';
      this.onlyCatchAll = true;
      this.filterType = this.filterTypeCatchall;
      if(this.rowsCatchallCopy.length === 0) {
        this.loadCatchall();
      }
    }
    else if(this.domainAnalysisTab === 'misspelled' && this.rowsMisspelledCopy.length === 0) {
      this.loadMisspelled();
    }

    this.searchString = '';
    this.countContactsTotal();
    this.filterChanged();
  }

  filterChanged() {
    if(this.domainAnalysisTab === 'unresponsive') {
      this.filterActiveUnresponsive = this.filterActive;
      this.onlyCatchAllUnresponsive = this.onlyCatchAll;
      this.filterTypeUnresponsive = this.filterType;
      this.filterChangedUnresponsive();
    }
    else if(this.domainAnalysisTab === 'catchall') {
      this.filterTypeCatchall = this.filterType;
      this.filterChangedCatchall();
    }
    else if(this.domainAnalysisTab === 'misspelled') {
      this.filterChangedMisspelled();
    }

    this.countContactsFiltered();
    this.onPageSizeChange();
  }

  exportContactIDs(domain) {
    if(this.domainAnalysisTab === 'unresponsive') {
      this.exportUnresponsiveContactIDs(domain);
    }
    else if(this.domainAnalysisTab === 'catchall') {
      this.exportCatchallContactIDs(domain);
    }
    else if(this.domainAnalysisTab === 'misspelled') {
      this.exportMisspelledContactIDs(domain);
    }
  }

  exportSelectedContactIDs() {
    if(this.domainAnalysisTab === 'unresponsive') {
      this.exportSelectedUnresponsiveContactIDs();
    }
    else if(this.domainAnalysisTab === 'catchall') {
      this.exportSelectedCatchallContactIDs();
    }
    else if(this.domainAnalysisTab === 'misspelled') {
      this.exportSelectedMisspelledContactIDs();
    }
  }

  exportAllContactIDs() {
    if(this.domainAnalysisTab === 'unresponsive') {
      this.exportAllUnresponsiveContactIDs();
    }
    else if(this.domainAnalysisTab === 'catchall') {
      this.exportAllCatchallContactIDs();
    }
    else if(this.domainAnalysisTab === 'misspelled') {
      this.exportAllMisspelledContactIDs();
    }
  }

  onPageChange(event) {
    this.page.number = event.offset;
    this.page.numberPlusOne = this.page.number + 1;
    this.onPageSizeChange();
  }

  onPageSizeChange() {
    if(this.domainAnalysisTab === 'unresponsive') {
      this.page.totalPages = Math.ceil(this.rowsUnresponsive.length / this.page.size);
    }
    else if(this.domainAnalysisTab === 'catchall') {
      this.page.totalPages = Math.ceil(this.rowsCatchall.length / this.page.size);
    }
    else if(this.domainAnalysisTab === 'misspelled') {
      this.page.totalPages = Math.ceil(this.rowsMisspelled.length / this.page.size);
    }
  }

  onPageNumberChange() {
    this.page.number = this.page.numberPlusOne - 1;
  }

  /**
   * Unresponsive area
   *
   */
  setColumnsUnresponsive() {
    this.columnsUnresponsive = [
      {
        prop: 'selected',
        name: '',
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizable: false,
        headerCheckboxable: true,
        checkboxable: true,
        width: 40
      },
      { prop: 'domain', name: 'Domain', cellTemplate: this.domainTmpl },
      { prop: 'rollupScore', name: 'Score', cellClass: 'rightAlign', headerClass: 'rightAlign', cellTemplate: this.integerTmpl, width: 65 },
      { prop: 'isCatchAll', name: 'Catch All', cellClass: 'centerAlign', headerClass: 'centerAlign', headerTemplate: this.catchAllHeaderTmpl, width: 85 },
      { prop: 'contactBreakdownCounts', name: 'Sub-populations', cellClass: 'overflowViz', cellTemplate: this.subpopulationTmpl, headerTemplate: this.subpopulationHeaderTmpl, width: 150, sortable: false, canAutoResize: false, draggable: false, resizable: false },
      { prop: 'uniqueopenrate', name: 'Open Rate', cellClass: 'rightAlign', headerClass: 'rightAlign', cellTemplate: this.percentTmpl, width: 85 },
      { prop: 'clickrate', name: 'Click Rate', cellClass: 'rightAlign', headerClass: 'rightAlign', cellTemplate: this.percentTmpl, width: 85 },
      { prop: 'totalContactsAnalyzed', name: 'Total Contacts', cellClass: 'rightAlign', headerClass: 'rightAlign', cellTemplate: this.integerTmpl },
      { prop: 'unresponsiveContacts', name: 'Unresponsive Contacts', cellClass: 'rightAlign', headerClass: ' darkPoolHeaderAttention rightAlign', cellTemplate: this.integerTmpl },
      { prop: 'unresponsivePercent', name: 'Unresponsive Percent', cellClass: 'rightAlign', headerClass: 'darkPoolHeaderAttention rightAlign', cellTemplate: this.percentTmpl },
      { prop: 'domain', name: 'Export Contacts', headerClass: 'darkPoolHeaderAttention', cellTemplate: this.exportTmpl, sortable: false }
    ];
  }

  changeSortUnresponsive(prop) {
    //If prop is the same as the current sort, remove the sort and go back to default.
    if(prop === this.unresponsiveSort) {
      this.unresponsiveSort = '';
      this.unresponsiveProps.currentSort = [{ prop: 'unresponsiveContacts', dir: 'desc'}];

      //Wait and then sort by default.
      setTimeout(() => {
        this.rowsUnresponsive.sort((a, b) => {
          if(a.unresponsiveContacts < b.unresponsiveContacts) return 1;
          if(a.unresponsiveContacts > b.unresponsiveContacts) return -1;
          return 0;
        });
        this.rowsUnresponsive = [...this.rowsUnresponsive];
      }, 10);

      return;
    }

    //Remove current sort first.
    this.unresponsiveSort = prop;
    this.unresponsiveProps.currentSort = [];

    //Wait and then sort by passed property.
    setTimeout(() => {
      this.rowsUnresponsive.sort((a, b) => {
        if(a.contactBreakdownCounts[0][prop] < b.contactBreakdownCounts[0][prop]) return 1;
        if(a.contactBreakdownCounts[0][prop] > b.contactBreakdownCounts[0][prop]) return -1;
        return 0;
      });
      this.rowsUnresponsive = [...this.rowsUnresponsive];
    }, 10);
  }

  loadUnresponsive() {
    this.page.number = 0;
    this.page.numberPlusOne = 1;

    if(this.isDemo) {
      this.loadDemoUnresponsiveData();
      return;
    }
    this.loading = true;
    this.contactCountTotal = null;
    this.contactCountFiltered = null;
    this.unresponsiveSort = '';
    this.rowsUnresponsive = [];
    //Start with unresponsive without other activity
    this.darkPoolService.getBreakdownByDomain(this.darkPoolTimeRange.startDate, this.darkPoolTimeRange.endDate, this.filterActive).then(data => {
      this.rowsUnresponsive = data;
      this.rowsUnresponsiveCopy = cloneDeep(this.rowsUnresponsive);
      this.filterChanged();
      this.countContactsTotal();
      this.countContactsFiltered();
      this.loading = false;
    });
  }

  //Active contacts filter requires new query.
  filterActiveContacts() {
    this.filterActiveUnresponsive = this.filterActive;
    this.loadUnresponsive();
  }

  filterChangedUnresponsive() {
    this.unresponsiveSort = '';
    this.rowsUnresponsive = [];

    //First, filter by type (b2b/b2c)
    if(this.filterType === 'both') {
      this.rowsUnresponsive = cloneDeep(this.rowsUnresponsiveCopy);
    }
    else {
      this.rowsUnresponsive = filter(this.rowsUnresponsiveCopy, o => o.type === this.filterType);
    }

    //Then, filter by Catch all
    if(this.onlyCatchAll) {
      this.rowsUnresponsive = filter(this.rowsUnresponsive, o => o.isCatchAll === 'X');
    }

    //Finally, filter by search string
    var searchString = this.searchString.toLowerCase();
    if(searchString !== '') {
      function searchFilter(row) {
        return row.domain && row.domain.toLowerCase().indexOf(searchString) > -1;
      }
      this.rowsUnresponsive = this.rowsUnresponsive.filter(searchFilter);
    }
  }

  exportUnresponsiveContactIDs(domain) {
    this.exporting = true;
    this.darkPoolService.exportUnresponsiveContactIDs(this.darkPoolTimeRange.startDate, this.darkPoolTimeRange.endDate, domain, this.filterActive).then(contactIDs => {
      var headers = ['Eloqua Contact ID', 'Domain', 'Catch All', 'Other Activity', 'Score'];
      var options = {
        fieldSeparator: ',',
        showLabels: true,
        noDownload: false,
        headers
      };

      var filename = 'Unresponsive contacts';

      switch (this.filterActive) {
      case 'only':
        filename += ' with other activity';
        break;

      case 'exclude':
        filename += ' without other activity';
        break;

      default:
      }

      filename += `-${domain}`;

      if(this.currentOrg.darkPoolSetting === 'freemium') {
        filename += '-FREEMIUM';
      }

      this.darkPoolService.updateScores(contactIDs);
      new ngxCsv(contactIDs, filename, options);
      this.exporting = false;
    });

    return false;
  }

  onSelectUnresponsive({ selected }) {
    this.selectedRowsUnresponsive.splice(0, this.selectedRowsUnresponsive.length);
    this.selectedRowsUnresponsive.push(...selected);
  }

  exportSelectedUnresponsiveContactIDs() {
    this.exporting = true;
    var domains = this.selectedRowsUnresponsive.map(x => x.domain);

    this.darkPoolService.exportDomainsUnresponsiveContactIDs(this.darkPoolTimeRange.startDate, this.darkPoolTimeRange.endDate, domains, this.filterActive).then(contactIDs => {
      var headers = ['Eloqua Contact ID', 'Domain', 'Catch All', 'Other Activity', 'Score'];
      var options = {
        fieldSeparator: ',',
        showLabels: true,
        noDownload: false,
        headers
      };

      var filename = 'Unresponsive contacts';

      switch (this.filterActive) {
      case 'only':
        filename += ' with other activity';
        break;

      case 'exclude':
        filename += ' without other activity';
        break;

      default:
      }

      //Add the number of domains to the filename.
      filename += `-for ${d3.format(',')(domains.length)} domains`;

      if(this.currentOrg.darkPoolSetting === 'freemium') {
        filename += '-FREEMIUM';
      }

      this.darkPoolService.updateScores(contactIDs);
      new ngxCsv(contactIDs, filename, options);
      this.exporting = false;
    });
  }

  exportAllUnresponsiveContactIDs() {
    this.exporting = true;
    this.darkPoolService.exportAllUnresponsiveContactIDs(this.darkPoolTimeRange.startDate, this.darkPoolTimeRange.endDate, this.filterActive).then(contactIDs => {
      var headers = ['Eloqua Contact ID', 'Domain', 'Catch All', 'Other Activity', 'Score'];
      var options = {
        fieldSeparator: ',',
        showLabels: true,
        noDownload: false,
        headers
      };

      var filename = 'All Unresponsive contacts';

      switch (this.filterActive) {
      case 'only':
        filename += ' with other activity';
        break;

      case 'exclude':
        filename += ' without other activity';
        break;

      default:
      }

      if(this.currentOrg.darkPoolSetting === 'freemium') {
        filename += '-FREEMIUM';
      }

      this.darkPoolService.updateScores(contactIDs);
      new ngxCsv(contactIDs, filename, options);
      this.exporting = false;
    });
  }

  loadDemoUnresponsiveData() {
    this.loading = true;
    this.contactCountTotal = null;
    this.contactCountFiltered = null;
    this.rowsUnresponsive = [];
    this.darkPoolService.getDemoData('unresponsive-by-domain').then(data => {
      this.rowsUnresponsive = data;
      this.rowsUnresponsiveCopy = cloneDeep(this.rowsUnresponsive);
      this.filterChanged();
      this.countContactsTotal();
      this.countContactsFiltered();
      this.loading = false;
    });
  }


  /**
   * Catch All area
   *
   */
  loadCatchall() {
    this.page.number = 0;
    this.page.numberPlusOne = 1;

    if(this.isDemo) {
      this.loadDemoCatchallData();
      return;
    }
    this.loading = true;
    this.contactCount = null;
    this.contactCountFiltered = null;
    this.rowsCatchall = [];
    this.filterActive = 'exclude';
    this.onlyCatchAll = true;
    //Start with unresponsive without other activity
    this.darkPoolService.getBreakdownByDomain(this.darkPoolTimeRange.startDate, this.darkPoolTimeRange.endDate, this.filterActive).then(data => {
      this.rowsCatchall = [];
      data.forEach(item => {
        if(item.isCatchAll && item.unresponsivePercent === 1.0) {
          this.rowsCatchall.push(item);
        }
      });

      this.rowsCatchallCopy = cloneDeep(this.rowsCatchall);
      this.filterChanged();
      this.countContactsTotal();
      this.countContactsFiltered();
      this.loading = false;
    });
  }

  filterChangedCatchall() {
    this.rowsCatchall = [];

    //First, filter by type (b2b/b2c)
    if(this.filterType === 'both') {
      this.rowsCatchall = cloneDeep(this.rowsCatchallCopy);
    }
    else {
      this.rowsCatchall = filter(this.rowsCatchallCopy, o => o.type === this.filterType);
    }

    //Filter by search string
    var searchString = this.searchString.toLowerCase();
    if(searchString !== '') {
      function searchFilter(row) {
        return row.domain && row.domain.toLowerCase().indexOf(searchString) > -1;
      }
      this.rowsCatchall = this.rowsCatchallCopy.filter(searchFilter);
    }
  }

  exportCatchallContactIDs(domain) {
    this.exporting = true;
    this.darkPoolService.exportUnresponsiveContactIDs(this.darkPoolTimeRange.startDate, this.darkPoolTimeRange.endDate, domain, this.filterActive).then(contactIDs => {
      var headers = ['Eloqua Contact ID', 'Domain', 'Catch All', 'Other Activity', 'Score'];
      var options = {
        fieldSeparator: ',',
        showLabels: true,
        noDownload: false,
        headers
      };

      var filename = `Catch All domain contacts without other activity-${domain}`;

      if(this.currentOrg.darkPoolSetting === 'freemium') {
        filename += '-FREEMIUM';
      }

      new ngxCsv(contactIDs, filename, options);
      this.exporting = false;
    });

    return false;
  }

  onSelectCatchall({ selected }) {
    this.selectedRowsCatchall.splice(0, this.selectedRowsCatchall.length);
    this.selectedRowsCatchall.push(...selected);
  }

  exportSelectedCatchallContactIDs() {
    this.exporting = true;
    var domains = this.selectedRowsCatchall.map(x => x.domain);

    this.darkPoolService.exportDomainsUnresponsiveContactIDs(this.darkPoolTimeRange.startDate, this.darkPoolTimeRange.endDate, domains, this.filterActive).then(contactIDs => {
      var headers = ['Eloqua Contact ID', 'Domain', 'Catch All', 'Other Activity', 'Score'];
      var options = {
        fieldSeparator: ',',
        showLabels: true,
        noDownload: false,
        headers
      };

      var filename = `Catch All domain contacts without other activity-for ${d3.format(',')(domains.length)} domains`;

      if(this.currentOrg.darkPoolSetting === 'freemium') {
        filename += '-FREEMIUM';
      }

      new ngxCsv(contactIDs, filename, options);
      this.exporting = false;
    });

    return false;
  }

  exportAllCatchallContactIDs() {
    this.exporting = true;
    this.darkPoolService.exportAllUnresponsiveContactIDs(this.darkPoolTimeRange.startDate, this.darkPoolTimeRange.endDate, 'exclude').then(contactIDs => {
      var headers = ['Eloqua Contact ID', 'Domain', 'Catch All', 'Other Activity', 'Score'];
      var options = {
        fieldSeparator: ',',
        showLabels: true,
        noDownload: false,
        headers
      };

      var filename = 'All catch-all domain contacts without other activity';

      if(this.currentOrg.darkPoolSetting === 'freemium') {
        filename += '-FREEMIUM';
      }

      var filteredResults = [];
      contactIDs.forEach(contact => {
        //Make sure we've got the right domains by comparing to the domains in the list.
        var found = find(this.rowsCatchallCopy, { domain: contact.domain });
        if(found) {
          filteredResults.push(contact);
        }
      });

      new ngxCsv(filteredResults, filename, options);
      this.exporting = false;
    });

    return false;
  }

  loadDemoCatchallData() {
    this.loading = true;
    this.contactCount = null;
    this.contactCountFiltered = null;
    this.rowsCatchall = [];
    this.filterActive = 'exclude';
    this.onlyCatchAll = true;
    //Start with unresponsive without other activity
    this.darkPoolService.getDemoData('catch-all-no-activity').then(data => {
      this.rowsCatchall = [];
      data.forEach(item => {
        if(item.isCatchAll && item.unresponsivePercent === 1.0) {
          this.rowsCatchall.push(item);
        }
      });

      this.rowsCatchallCopy = cloneDeep(this.rowsCatchall);
      this.filterChanged();
      this.countContactsTotal();
      this.countContactsFiltered();
      this.loading = false;
    });
  }


  /**
   * Misspelled Section
   *
   */
  loadMisspelled() {
    this.page.number = 0;
    this.page.numberPlusOne = 1;

    if(this.isDemo) {
      this.loadDemoMisspelledData();
      return;
    }
    this.loading = true;
    this.contactCount = null;
    this.contactCountFiltered = null;
    this.rowsMisspelled = [];
    this.darkPoolService.getDomainsWithTypos(this.darkPoolTimeRange.startDate, this.darkPoolTimeRange.endDate).then(results => {
      this.rowsMisspelled = results;
      this.rowsMisspelledCopy = cloneDeep(this.rowsMisspelled);
      this.filterChanged();
      this.countContactsTotal();
      this.countContactsFiltered();
      this.loading = false;
    });
  }

  filterChangedMisspelled() {
    this.rowsMisspelled = [];

    //Filter by search string
    var searchString = this.searchString.toLowerCase();
    if(searchString !== '') {
      function searchFilter(row) {
        return row.domain && row.domain.toLowerCase().indexOf(searchString) > -1
            || row.domainCorrections && row.domainCorrections.toLowerCase().indexOf(searchString) > -1;
      }
      this.rowsMisspelled = this.rowsMisspelledCopy.filter(searchFilter);
    }
    else {
      this.rowsMisspelled = cloneDeep(this.rowsMisspelledCopy);
    }
  }

  exportMisspelledContactIDs(domain) {
    this.exporting = true;
    this.darkPoolService.exportDomainsWithTypos(this.darkPoolTimeRange.startDate, this.darkPoolTimeRange.endDate, domain).then(results => {
      var headers = ['Eloqua Contact ID', 'Domain', 'Correction'];
      var options = {
        fieldSeparator: ',',
        showLabels: true,
        noDownload: false,
        headers
      };

      var filename = `Misspelled Domain contacts-${domain}`;

      if(this.currentOrg.darkPoolSetting === 'freemium') {
        filename += '-FREEMIUM';
      }

      new ngxCsv(results, filename, options);
      this.exporting = false;
    });

    return false;
  }

  onSelectMisspelled({ selected }) {
    this.selectedRowsMisspelled.splice(0, this.selectedRowsMisspelled.length);
    this.selectedRowsMisspelled.push(...selected);
  }

  exportSelectedMisspelledContactIDs() {
    this.exporting = true;
    var domains = this.selectedRowsMisspelled.map(x => x.domain);

    this.darkPoolService.exportDomainsWithTypos(this.darkPoolTimeRange.startDate, this.darkPoolTimeRange.endDate, null, domains).then(results => {
      var headers = ['Eloqua Contact ID', 'Domain', 'Correction'];
      var options = {
        fieldSeparator: ',',
        showLabels: true,
        noDownload: false,
        headers
      };

      var filename = `Misspelled Domain contacts-for ${d3.format(',')(domains.length)} domains`;

      if(this.currentOrg.darkPoolSetting === 'freemium') {
        filename += '-FREEMIUM';
      }

      new ngxCsv(results, filename, options);
      this.exporting = false;
    });

    return false;
  }

  exportAllMisspelledContactIDs() {
    this.exporting = true;
    this.darkPoolService.exportDomainsWithTypos(this.darkPoolTimeRange.startDate, this.darkPoolTimeRange.endDate).then(results => {
      var headers = ['Eloqua Contact ID', 'Domain', 'Correction'];
      var options = {
        fieldSeparator: ',',
        showLabels: true,
        noDownload: false,
        headers
      };

      var filename = 'All Misspelled Domain contacts';

      if(this.currentOrg.darkPoolSetting === 'freemium') {
        filename += '-FREEMIUM';
      }

      new ngxCsv(results, filename, options);
      this.exporting = false;
    });

    return false;
  }

  loadDemoMisspelledData() {
    this.loading = true;
    this.contactCount = null;
    this.contactCountFiltered = null;
    this.rowsMisspelled = [];
    this.darkPoolService.getDemoData('domains-with-typos').then(results => {
      this.rowsMisspelled = results;
      this.rowsMisspelledCopy = cloneDeep(this.rowsMisspelled);
      this.filterChanged();
      this.countContactsTotal();
      this.countContactsFiltered();
      this.loading = false;
    });
  }
}
