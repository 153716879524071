import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  @Output() highFreqContacts = new EventEmitter(true);

  showHighFreqContacts(data) {
    this.highFreqContacts.emit(data);
  }
}
