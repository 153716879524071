class Email {
  name: string;
  senderName: string;
  createdBy: number; //userId
  createdAt: string; //date?
  updatedAt: string; //date?
}

export class Campaign {
  campaignId: string = null;
  campaignType: string;
  name: string = '';
  emails: Email[] = [];

  status: string = '';
  statusDate: string; //date?

  createdAt: string; //date?
  updatedAt: string; //date?

  signatureRuleId = null;
  signatureRuleName = null;
  onEloquaCanvas = true;
  isMotionControlled = false;
  optimizationCriterion = 'opens';
  overrideOrgContactFields = false;
  allowEmailResendToPastRecipients = false;
  includeListUnsubscribeHeader = true;
  allowSendToMasterExclude = false;
  allowSendToUnsubscribed = false;
  timezone = 'US/Pacific';
  confidenceThreshold = 0.99;
  overrideSendFrequencyCheck = true;
  fmRetryWindow = 168;
  sendWinnerToAllRemainingImmediately = false;
  letMotivaDecideSendTimeWindow = false;
}

/*
contactFields: array
eloquaCampaignId: 93722

organizationId: 1
uid: 2

recentActionServiceNotifyCount: [{…}]
uniqueContactCount: 8400

scheduledEnd: "2020-07-03T19:22:46Z"
scheduledStart: "2020-06-29T19:22:46Z"

sendTimeConstraints: (7) [Array(24), Array(24), Array(24), Array(24), Array(24), Array(24), Array(24)]
*/
