import { Component, ElementRef, HostListener, Input, SimpleChanges, OnInit } from '@angular/core';
import * as d3 from 'd3';
import moment from 'moment-timezone';

@Component({
  selector: 'viz-bar-chart',
  template: '<div></div>'
})
export class BarChartComponent implements OnInit {
  @Input() data;
  @Input() height;
  @Input() extHighlightId; //highlight bar externally

  @HostListener('window:resize', ['$event'])
  onResize() {
    //If not vislble, don't resize.
    if(!(this.htmlElement.offsetParent !== null)) return;
    var newWidth = this.htmlElement.parentElement.clientWidth;

    this.svg.attr('width', newWidth);
    this.width = +this.svg.attr('width') - this.margin.left - this.margin.right;

    if(this.data) {
      this.x0 = d3.scaleTime().range([20, this.width - 30]);
      this.x2 = d3.scaleTime().range([20, this.width - 30]);
      this.xAxis = d3.axisBottom(d3.scaleTime().range([0, this.width]));

      this.brush.extent([
        [0, 0],
        [this.width, this.height2]
      ]);
      this.topLayer.attr('width', this.width);
      this.render(this.data);
    }
  }

  margin = { top: 20, left: 60, bottom: 20, right: 40 };

  //For Brush?
  margin2 = { top: 190, left: 60, bottom: 30, right: 20 };
  height2 = 30;

  htmlElement: HTMLElement;
  svg;

  static parameters = [ElementRef];
  constructor(element: ElementRef) {
    this.htmlElement = element.nativeElement;
    this.host = d3.select(element.nativeElement);
  }

  ngOnInit() {
    //Set width to 100% of parent container.
    this.width = this.htmlElement.parentElement.clientWidth;

    //append the svg object
    this.host.html('');
    this.svg = this.host
      .append('svg')
      .attr('height', this.height ? this.height : '250')
      .attr('width', this.width);

    //Add margin
    this.width = +this.svg.attr('width') - this.margin.left - this.margin.right;
    this.height = +this.svg.attr('height') - this.margin.top - this.margin.bottom;

    this.x0 = d3.scaleTime().range([20, this.width - 30]);
    this.x1 = d3.scaleBand().padding(0);

    this.y = d3.scaleLinear().rangeRound([this.height, 0]);

    this.x2 = d3.scaleTime().range([20, this.width - 30]);
    this.y2 = d3.scaleLinear().range([this.height2, 0]);

    this.xAxis = d3.axisBottom(d3.scaleTime().range([0, this.width]));

    this.tooltip = this.host
      .append('div')
      .attr('class', 'chart-tooltip')
      .style('opacity', 0);

    this.brush = d3
      .brushX()
      .extent([
        [0, 0],
        [this.width, this.height2]
      ])
      .on('brush end', () => {
        // Broadcast that brush changed, to update all charts
        // var brushChangedEvent = new Event('brushChanged');
        // brushChangedEvent.data = {
        //   attr: this.data.attrToChart,
        //   x: d3.select('.selection').attr('x'),
        //   width: d3.select('.selection').attr('width')
        // };
        //document.dispatchEvent(brushChangedEvent);
        this.brushed();
      });

    this.bottomLayer = this.svg.append('g').attr('transform', `translate(${this.margin.left},${this.margin.top})`);

    this.topLayer = this.svg
      .append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('overflow', 'hidden');

    this.context = this.svg
      .append('g')
      .attr('class', 'context')
      .attr('transform', `translate(${this.margin2.left},${this.margin2.top})`);

    this.render(this.data);
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'data' && this.data && this.topLayer) {
        this.render(this.data);
      }
      else if(propName === 'extHighlightId') {
        this.highlightBars();
      }
    }
  }

  render(data) {
    this.bottomLayer.selectAll('*').remove();
    this.topLayer.selectAll('*').remove();
    this.context.selectAll('*').remove();

    if(!data || data.length === 0) return;

    // If showing date range selector, reduce height of chart to make room
    if(data.length > 16 && data.showRangeSelector) {
      this.height = +this.svg.attr('height') - this.margin.top - this.margin.bottom - 80;
      this.y = d3.scaleLinear().rangeRound([this.height, 0]);
      this.topLayer.attr('height', this.height);
    }

    var xName = data.columns[0];
    var keys = data.columns.slice(1);
    var attr = data.attrToChart;
    var optimizeOn = data.optimizeOn;
    var info = data.info;

    this.svg.attr('id', `${data.attrToChart}_barChart`);

    var x1Width = this.width / data.length / 2;
    var xDomain = d3.extent(data, function(d) {
      return d[xName];
    });

    var start = new Date(xDomain[0]);
    var end = new Date(xDomain[1]);
    end.setHours(23, 59, 59);

    this.x0.domain([start, end]);
    this.x1.domain(keys).rangeRound([0, x1Width]);
    this.y
      .domain([
        0,
        d3.max(data, function(d) {
          return d3.max(keys, function(key) {
            if(attr === 'rate') {
              return d[key][optimizeOn].rate;
            }
            else if(attr === 'totalDelivered') {
              return d.totalDelivered;
            }
            return d[key][attr];
          });
        })
      ])
      .nice();

    this.x2.domain(this.x0.domain());
    this.y2.domain(this.y.domain());

    var xAxisValues = this.getxAxisValues(data, xName);

    if(data && data.length > 16 && data.showRangeSelector) {
      this.context.selectAll('*').remove();
      this.addDateRangeSelector(data);
    }

    this.topLayer
      .append('g')
      .attr('class', 'xAxis')
      .attr('transform', `translate(0,${this.height})`)
      .call(
        this.xAxis
          .tickValues(xAxisValues)
          .tickFormat(d3.timeFormat('%a, %b %d'))
          .tickSizeOuter(0)
      )
      .selectAll('.tick')
      .attr('transform', d => `translate(${this.x0(d) + x1Width / 2}, 0)`);

    this.topLayer
      .append('g')
      .attr('class', 'yAxis')
      .call(
        d3
          .axisLeft(this.y)
          .ticks(5)
          .tickFormat(function(d) {
            if(attr === 'rate') {
              return `${d3.format('.1f')(d * 100)}%`;
            }
            else {
              return d3.format(',')(d);
            }
          })
      )
      .selectAll('.tick:not(:first-of-type) line')
      .attr('stroke', '#777')
      .attr('opacity', '0.2')
      .attr('x2', this.width);

    this.topLayer
      .append('g')
      .attr('class', 'bars')
      .selectAll('g')
      .data(data)
      .enter()
      .append('g')
      .attr('transform', d => `translate(${this.x0(d[xName])},0)`)
      .selectAll('rect')
      .data(function(d, index) {
        return keys.map(function(key) {
          if(attr === 'rate') {
            return { key, value: { num: d[key][optimizeOn][attr], index } };
          }
          else if(attr === 'totalDelivered') {
            return { key, value: { num: d.totalDelivered, index } };
          }
          return { key, value: { num: d[key][attr], index } };
        });
      })
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('id', d => `bar_${d.key}`)
      .attr('x', d => this.x1(d.key))
      .attr('y', d => {
        if(typeof d.value.num == 'number') return this.y(d.value.num);
        return 0;
      })
      .attr('width', this.x1.bandwidth())
      .attr('height', d => {
        if(typeof d.value.num == 'number') return this.height - this.y(d.value.num);
        return 0;
      })
      .attr('fill', d => info[d.key].color)
      .on('mouseover', d => {
        this.showTooltip(d.key, d.value.index, d.value.num);
      })
      .on('mouseout', () => {
        this.hideTooltip();
      });

    if(!data.isSimple) {
      var winnerData = [];
      for(var x = 0; x < data.length; x++) {
        if(data[x].winnerFound) {
          winnerData.push(data[x]);
          break; //only take the first one
        }
      }

      this.bottomLayer
        .selectAll('.winner-bar')
        .data(winnerData)
        .enter()
        .append('rect')
        .attr('class', 'winner-bar')
        .attr('x', d => this.x0(d[xName]))
        .attr('width', this.x1.bandwidth() * (data.columns.length - 1))
        .attr('height', this.height);

      this.bottomLayer
        .selectAll('.winner-label')
        .data(winnerData)
        .enter()
        .append('text')
        .attr('transform', 'translate(0,-3)')
        .attr('class', 'winner-label')
        .attr('text-anchor', 'middle')
        .attr('x', d => this.x0(d[xName]) + this.x1.bandwidth() * (data.columns.length - 1) / 2)
        .text('Winner found');
    }
  } //end: render()

  brushed(xVal, selectionWidth, attr) {
    if(d3.event.sourceEvent && d3.event.sourceEvent.type === 'zoom') return; // ignore brush-by-zoom

    var s = d3.event.selection || this.x2.range();
    this.x0.domain(s.map(this.x2.invert, this.x2));

    this.topLayer
      .selectAll('rect')
      .attr('x', d => this.x1(d.key))
      .attr('y', d => this.y(d.value.num))
      .attr('width', this.x1.bandwidth());

    if(this.data && attr && this.data.attrToChart !== attr && xVal && selectionWidth) {
      this.svg
        .selectAll('.selection')
        .attr('x', xVal)
        .attr('width', selectionWidth);
    }

    if(this.data) this.update(this.data);
  } //end: brushed()

  update(data) {
    if(!data || data.length === 0) return;
    d3.selectAll('.winner-bar').remove();
    d3.selectAll('.winner-label').remove();

    var xName = data.columns[0];
    var keys = data.columns.slice(1);
    var attr = data.attrToChart;
    var optimizeOn = data.optimizeOn;
    var info = data.info;

    // If adding date range selector, reduce height to make room
    if(data.length > 16 && data.showRangeSelector) {
      this.height = +this.svg.attr('height') - this.margin.top - this.margin.bottom - 80;
      this.y = d3.scaleLinear().rangeRound([this.height, 0]);
      this.topLayer.attr('height', this.height);
    }

    // If the program duration is longer than result data, add empty days to chart
    var daysToAdd = 0;
    if(data.length < data.programDuration.value) {
      daysToAdd = data.programDuration.value - data.length;
    }

    for(var i = 0; i < daysToAdd; i++) {
      var obj = {
        resultDateTime: d3.timeDay.offset(data[data.length - 1].resultDateTime, 1)
      };

      for(var j = 0; j < keys.length; j++) {
        obj[keys[j]] = {
          click: {
            num: 0,
            rate: 0,
            bestArmConfidence: 0,
            confIntervalMin: 0,
            confIntervalMax: 0
          },
          open: {
            num: 0,
            rate: 0,
            bestArmConfidence: 0,
            confIntervalMin: 0,
            confIntervalMax: 0
          },
          totalSends: 0,
          incrementSends: 0
        };
      }

      data.push(obj);
    }

    var xAxisValues = this.getxAxisValues(data, xName);

    var dataShowing = 0;
    data.forEach(d => {
      if(this.x0(d[xName]) > 0 && this.x0(d[xName]) < this.width) dataShowing++;
    });

    var x1Width = this.width / dataShowing / 2;

    this.x1.domain(keys).rangeRound([0, x1Width]);
    this.y
      .domain([
        0,
        d3.max(data, d => d3.max(keys, function(key) {
          if(attr === 'rate') {
            return d[key][optimizeOn].rate;
          }
          else if(attr === 'totalDelivered') {
            return d.totalDelivered;
          }
          return d[key][attr];
        }))
      ])
      .nice();

    this.topLayer
      .select('.xAxis')
      .attr('transform', `translate(0,${this.height})`)
      .call(
        this.xAxis
          .tickValues(xAxisValues)
          .tickFormat(d3.timeFormat('%a, %b %d'))
          .tickSizeOuter(0)
      )
      .selectAll('.tick')
      .attr('transform', d => `translate(${this.x0(d) + x1Width / 2}, 0)`)
      .attr('opacity', d => {
        if(this.x0(d) > this.width - 10 || this.x0(d) < 0) return 0;
        return 1;
      });

    this.topLayer
      .select('.yAxis')
      .call(
        d3
          .axisLeft(this.y)
          .ticks(5)
          .tickFormat(d => {
            if(attr === 'rate') {
              return `${d3.format('.1f')(d * 100)}%`;
            }
            else {
              return d3.format(',')(d);
            }
          })
      )
      .selectAll('.tick:not(:first-of-type) line')
      .attr('stroke', '#777')
      .attr('opacity', '0.2')
      .attr('x2', this.width);

    var rect = this.topLayer.selectAll('rect').data(data, function(d) {
      return d.key;
    });

    rect.exit().remove();
    rect
      .enter()
      .append('g')
      .attr('transform', d => `translate(${this.x0(d[xName])},0)`)
      .attr('opacity', d => {
        if(this.x0(d[xName]) < 0 || this.x0(d[xName]) > this.width - x1Width) {
          return 0;
        }
        return 1;
      })
      .selectAll('rect')
      .data((d, index) => keys.map(function(key) {
        if(attr === 'rate') {
          return { key, value: { num: d[key][optimizeOn][attr], index } };
        }
        else if(attr === 'totalDelivered') {
          return { key, value: { num: d.totalDelivered, index } };
        }
        return { key, value: { num: d[key][attr], index } };
      }))
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('id', d => `bar_${d.key}`)
      .attr('x', d => this.x1(d.key))
      .attr('y', d => this.y(d.value.num))
      .attr('width', this.x1.bandwidth())
      .attr('height', d => this.height - this.y(d.value.num))
      .attr('fill', d => info[d.key].color)
      .on('mouseover', d => {
        this.showTooltip(d.key, d.value.index, d.value.num);
      })
      .on('mouseout', () => {
        this.hideTooltip();
      });

    //Show winner line
    if(!data.isSimple) {
      var winnerData = [];
      for(var x = 0; x < data.length; x++) {
        if(data[x].winnerFound) {
          winnerData.push(data[x]);
          break; //only take the first one
        }
      }

      this.bottomLayer
        .selectAll('.winner-bar')
        .data(winnerData)
        .enter()
        .append('rect')
        .attr('class', 'winner-bar')
        .attr('x', d => this.x0(d[xName]))
        .attr('width', this.x1.bandwidth() * (data.columns.length - 1))
        .attr('height', () => this.height);

      this.bottomLayer
        .selectAll('.winner-label')
        .data(winnerData)
        .enter()
        .append('text')
        .attr('transform', 'translate(0,-3)')
        .attr('class', 'winner-label')
        .attr('text-anchor', 'middle')
        .attr('x', d => this.x0(d[xName]) + this.x1.bandwidth() * (data.columns.length - 1) / 2)
        .text('Winner found');
    }
  } //end: update()

  getxAxisValues(data, xName) {
    // If more than 16 dates, reduce number of ticks shown
    var xAxisValues = [];
    if(data.length > 16 || this.width < 1000) {
      var maxNum = 16;
      if(this.width < 1000) {
        maxNum = maxNum / 2;
      }
      var num = data.length / maxNum;

      if(num % 1 > 0) {
        num = Math.trunc(data.length / maxNum) + 1;
      }

      data
        .map(d => d[xName])
        .forEach((date, index) => {
          if(index % num === 0) {
            xAxisValues.push(date);
          }
        });
    }
    else {
      xAxisValues = data.map(d => d[xName]);
    }

    xAxisValues = this.removeNegaiveAxisValues(xAxisValues);

    return xAxisValues;
  } //end: getxAxisValues()

  removeNegaiveAxisValues(xAxisValues) {
    //Remove negative axis values
    var values = [];
    xAxisValues.forEach(value => {
      if(this.x0(value) >= 0) values.push(value);
    });

    return values;
  }

  addDateRangeSelector(data) {
    var xName = data.columns[0];
    var keys = data.columns.slice(1);
    var attr = data.attrToChart;
    var optimizeOn = data.optimizeOn;
    var info = data.info;

    var x1Width = this.width / data.length / 2;
    this.x1.domain(keys).rangeRound([0, x1Width]);

    this.context
      .append('g')
      .attr('clip-path', 'url(#clip)')
      .attr('class', 'bars')
      .selectAll('g')
      .data(data)
      .enter()
      .append('g')
      .attr('transform', d => `translate(${this.x2(d[xName])}, 0)`)
      .selectAll('rect')
      .data((d, index) =>
        keys.map(function(key) {
          if(attr === 'rate') {
            return { key, value: { num: d[key][optimizeOn][attr], index } };
          }
          else if(attr === 'totalDelivered') {
            return { key, value: { num: d.totalDelivered, index } };
          }
          return { key, value: { num: d[key][attr], index } };
        })
      )
      .enter()
      .append('rect')
      .attr('x', d => this.x1(d.key))
      .attr('y', d => {
        if(typeof d.value.num == 'number') return this.y2(d.value.num);
        else return 0;
      })
      .attr('width', this.x1.bandwidth())
      .attr('height', d => {
        if(typeof d.value.num == 'number') return this.height2 - this.y2(d.value.num);
        else return 0;
      })
      .attr('fill', d => info[d.key].color)
      .attr('opacity', 0.6);

    this.context
      .append('g')
      .attr('class', 'brush')
      .attr('id', `brush_${attr}`)
      .call(this.brush)
      .call(this.brush.move, [0, this.width]);
  } //end: addDateRangeSelector()

  showTooltip(key, index) {
    this.hideTooltip();

    if(index >= 0 && this.data && this.data[index] && this.data[index][key]) {
      var info = this.data.info;
      var xName = this.data.columns[0];
      var dateFormat = d3.timeFormat('%A, %b %d, %Y');
      var optimizeOn = this.data.optimizeOn;

      var value = 0;
      if(this.data.attrToChart === 'rate') {
        value = this.data[index][key][optimizeOn].rate;
      }
      else if(this.data.attrToChart === 'incrementSends') {
        value = this.data[index][key].incrementSends;
      }
      else {
        value = this.data[index].totalDelivered;
      }

      this.tooltip
        .transition()
        .duration(600)
        .style('opacity', 1);

      if(this.data.attrToChart === 'rate') {
        this.tooltip
          .html(
            `${`<p class="name"><span class="faded">Name &nbsp;</span>${info[key].name}</p>`
              + `<p class="result"><span class="faded">Subj &nbsp;</span>${info[key].subject}</p>`
              + `<p>${dateFormat(this.data[index][xName])}</p>`
              + '<div><p class="result faded"><span class="faded">Max:</span><strong>'
              + `${(this.data[index][key][optimizeOn].confIntervalMax * 100).toFixed(1)}%</strong></p></div>`}${
              optimizeOn === 'click' ? '<p class="result"><span class="faded">Clickthrough:</span><strong>' : '<p class="result"><span class="faded">Open Rate:</span><strong>'
            }${(value * 100).toFixed(1)}%</strong></p>`
              + '<p class="result faded"><span class="faded">Min:</span><strong>'
              + `${(this.data[index][key][optimizeOn].confIntervalMin * 100).toFixed(1)}%</strong></p>`
          )
          .style('left', `${this.x0(this.data[index][xName]) + this.x1(key)}px`)
          .style('top', '-80px')
          .style('height', '160px');
      }
      else if(this.data.attrToChart === 'totalDelivered') {
        this.tooltip
          .attr('class', 'area-chart-tooltip')
          .html(`<b>${moment(this.data[index][xName]).format('ddd, MMM D, YYYY')}</b><br/><b>Total: </b>${d3.format(',')(value)}`)
          .style('left', `${this.x0(this.data[index][xName]) + 40}px`)
          .style('top', `${this.y(value) + 275}px`);
      }
      else {
        this.tooltip
          .html(
            `${`<p class="name">${info[key].name}</p>`
              + `<p class="result smaller"><span class="faded vLabel">Subject:</span>${info[key].subject}</p><hr/>`
              + `<p class="date"><strong>${dateFormat(this.data[index][xName])}</strong></p>`
              + `<div><p class="result"><span class="faded vLabel">Sends:</span><strong>${value.toLocaleString()}</strong></p>`}${
              optimizeOn === 'click'
                ? `<p class="result"><span class="faded vLabel">Clickthrough:</span><strong>${(this.data[index][key].click.rate * 100).toFixed(1)}%</strong></p></div>`
                : `<p class="result"><span class="faded vLabel">Open rate:</span><strong>${(this.data[index][key].open.rate * 100).toFixed(1)}%</strong></p>`
            }`
          )
          .style('left', `${this.x0(this.data[index][xName]) + this.x1(key)}px`)
          .style('top', '-80px');
      }
    }
  } //end: showTooltip()

  hideTooltip() {
    this.tooltip
      .transition()
      .duration(200)
      .style('opacity', 0);
  }

  highlightBars() {
    if(!this.extHighlightId) {
      d3.selectAll('rect').style('opacity', '');
    }
    else {
      d3.selectAll('rect').style('opacity', 0.2);
      d3.selectAll(`[id='bar_${this.extHighlightId}']`).style('opacity', 1);
    }
  }
}
