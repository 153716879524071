import { Component, ElementRef, HostListener, Input, SimpleChanges, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'viz-funnel-chart',
  template: '<div></div>'
})
export class FunnelChartComponent implements OnInit {
  @Input() data;

  @HostListener('window:resize', ['$event'])
  onResize() {
    //If not vislble, don't resize.
    if(!(this.htmlElement.offsetParent !== null)) return;

    // //TODO: This needs some work...
    this.width = this.htmlElement.parentElement.clientWidth - 50;

    this.svg.attr('width', this.width);
    this.width = this.width - this.margin.left - this.margin.right - 125;
    this.x = d3.scaleLinear().range([0, this.width]);

    if(this.data) {
      this.render(this.data);
    }
  }

  margin = { top: 30, left: 60, bottom: 30, right: 100 };

  htmlElement: HTMLElement;
  svg;

  numCols = 24; //24 hours
  numRows = 7; //7 days

  static parameters = [ElementRef];
  constructor(element: ElementRef) {
    this.htmlElement = element.nativeElement;
    this.host = d3.select(element.nativeElement);
  }

  ngOnInit() {
    this.width = this.htmlElement.parentElement.clientWidth - 50 - this.margin.left - this.margin.right;
    this.height = this.height ? this.height : '170';

    this.margin = { top: 5, left: 0, bottom: 5, right: 100 };
    this.xOffset = 75;

    //Create the svg using width 100% to fill the container.
    this.svg = this.host
      .append('svg')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
      .attr('height', this.height)
      .attr('width', this.width)
      .attr('id', 'frequency_lineAndBarChart');

    this.g = this.svg
      .append('g')
      .attr('transform', `translate(170,${this.margin.top})`)
      .attr('width', this.width);

    // Tooltip div
    this.div = this.host
      .append('div')
      .attr('class', 'chart-tooltip')
      .style('opacity', 0);

    // Set up chart
    this.width = +this.svg.attr('width') - this.margin.left - this.margin.right - 125;
    this.height = +this.svg.attr('height') - this.margin.top - this.margin.bottom;

    this.x = d3.scaleLinear().range([0, this.width]);
    this.y = d3.scaleBand().range([this.height, 0]);
    this.y1 = d3.scaleBand().padding(0);

    this.render(this.data);
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'data' && this.data && this.g) {
        this.render(this.data);
      }
    }
  }

  render(data) {
    if(!data) return;
    this.g.selectAll('*').remove();

    var keys = [];
    var barsPerGroup = 1;
    var showBaseline = false;

    // Add baseline
    if(data.baselineTotalCount) {
      showBaseline = true;
      keys.push('baselineCount');
      barsPerGroup = 2;
      // adjust hieght
      this.height = 285;
      this.svg.attr('height', this.height);
      this.height = +this.svg.attr('height') - this.margin.top - this.margin.bottom;
      this.y = d3.scaleBand().range([this.height, 0]);
    }
    else {
      // adjust hieght
      this.height = 200;
      this.svg.attr('height', this.height);
      this.height = +this.svg.attr('height') - this.margin.top - this.margin.bottom;
      this.y = d3.scaleBand().range([this.height, 0]);
    }

    keys.push('count');

    // Scale the range of the data in the domains
    var maxXValue = d3.max(data.results, function(d) {
      return d3.max(keys, function(key) {
        return d[key];
      });
    });
    this.x.domain([0, Math.ceil(maxXValue)]);
    this.y.domain(data.results.map(d => d.engagementLevel)).padding(0.3);
    this.y1.domain(keys).rangeRound([this.y.bandwidth() + 5, 0]);

    // append the bars
    var bar = this.g
      .selectAll('.funnel-bar')
      .data(data.results)
      .enter()
      .append('g')
      .attr('class', 'funnel-bar')
      .attr('transform', d => `translate(${this.xOffset},${this.y(d.engagementLevel)})`)
      .selectAll('rect')
      .data(function(d) {
        return keys.map(function(key) {
          return { key, value: { count: d[key], engagementLevel: d.engagementLevel } };
        });
      })
      .enter()
      .append('rect')
      .attr('fill', function(d) {
        if(d.key === 'count') {
          return data.colors[d.value.engagementLevel];
        }
        else {
          return data.baselineColors[d.value.engagementLevel];
        }
      })
      .attr('x', 0)
      .attr('height', this.y.bandwidth() / barsPerGroup)
      .attr('y', d => this.y1(d.key))
      .attr('width', d => this.x(d.value.count))
      .attr('opacity', function(d) {
        if(d.key === 'baselineCount') {
          return 0.4;
        }
        return 1;
      })
      .style('cursor', 'pointer')
      .on('mouseover', d => {
        this.hideTooltip();
        this.showBarTooltip(d);
      })
      .on('mouseout', () => {
        this.hideTooltip();
      });

    this.g
      .append('g')
      .attr('class', 'frequency-axis')
      .attr('transform', `translate(${this.xOffset},0)`)
      .call(
        d3
          .axisLeft(this.y)
          .tickSize(0)
          .tickPadding(10)
      )
      .selectAll('.tick text')
      .style('font-size', function() {
        if(maxXValue >= 100000 && maxXValue < 1000000) return 18;
        if(maxXValue >= 1000000) return 15;
        if(showBaseline) return 20;
        return 24;
      })
      .text(function(d) {
        var text;
        data.results.forEach(result => {
          if(result.engagementLevel === d) text = `${result.count.toLocaleString()}`;
        });
        return text;
      })
      .attr('dy', function() {
        if(!showBaseline) return '0.32em';
        else return '-0.2em';
      })
      .attr('x', -55);

    this.g
      .selectAll('.tick')
      .append('text')
      .text(function(d) {
        var text;
        data.results.forEach(result => {
          if(result.engagementLevel === d) text = `(${(result.count / data.totalCount * 100).toFixed(0)}%)`;
        });
        return text;
      })
      .style('font-size', 14)
      .attr('dy', function() {
        if(!showBaseline) return '0.32em';
        else return '-0.45em';
      })
      .attr('x', -8)
      .attr('fill', '#000')
      .attr('opacity', 0.6);

    this.g
      .selectAll('.tick')
      .append('text')
      .text(d => this.convertCamelCase(d))
      .style('font-size', 14)
      .attr('x', -135)
      .attr('dy', '0.32em')
      .attr('fill', '#000')
      .attr('cursor', 'pointer')
      .on('mouseover', d => {
        this.hideTooltip();
        this.showTooltip(d);
      })
      .on('mouseout', () => {
        this.hideTooltip();
      });

    if(showBaseline) {
      this.g
        .selectAll('.tick')
        .append('text')
        .text(function(d) {
          var text;
          data.results.forEach(result => {
            if(result.engagementLevel === d) text = result.baselineCount.toLocaleString();
          });
          return text;
        })
        .style('font-size', 12)
        .attr('x', -46)
        .attr('dy', '1.4em')
        .attr('fill', '#000')
        .attr('opacity', 0.7);

      this.g
        .selectAll('.tick')
        .append('text')
        .text(function(d) {
          var text;
          data.results.forEach(result => {
            if(result.engagementLevel === d) text = `(${(result.baselineCount / data.baselineTotalCount * 100).toFixed(0)}%)`;
          });
          return text;
        })
        .style('font-size', 11)
        .attr('dy', '1.4em')
        .attr('x', -8)
        .attr('fill', '#000')
        .attr('opacity', 0.5);
    }

    this.g.selectAll('.domain').attr('opacity', 0);
  } //end: render()

  showTooltip(data) {
    var descriptions = {
      noEngagement: 'These customers did not open or click through any emails during this time period.',
      aware: 'These customers opened at least one email, but both open and clickthrough rates were below the median.',
      considering: 'These customers had higher open rates, but lower clickthrough rates than the median.',
      engaged: 'These customers were the most engaged, with higher clickthrough rates than the median.'
    };

    this.div
      .transition()
      .duration(600)
      .style('opacity', 1);

    this.div
      .html(`<p class="result">${this.convertCamelCase(data)}</p>` + `<p class="result"><span class="faded vLabel">${descriptions[data]}</span></p>`)
      .style('left', '0px')
      .style('top', `${this.y(data) - 70}px`);
  }

  showBarTooltip(data) {
    var dateFormat = d3.timeFormat('%B %Y');
    //var totalCount = data.key === 'count' ? this.data.totalCount : this.data.baselineTotalCount;

    if(this.data.timePeriod || this.data.baselineTimePeriod) {
      var timeFrame = data.key === 'count' ? this.data.timePeriod : this.data.baselineTimePeriod;
      if(timeFrame instanceof Date) {
        timeFrame = dateFormat(timeFrame);
      }
    }

    this.div
      .transition()
      .duration(600)
      .style('opacity', 1);

    this.div
      .html(
        `<p class="result">${timeFrame ? `${timeFrame} - ` : ''}${this.convertCamelCase(data.value.engagementLevel)}</p>`
          + `<p class="result"><span class="faded vLabel">Count:</span>${data.value.count.toLocaleString()}</p>`
      )
      .style('left', '265px')
      .style('top', `${this.y(data.value.engagementLevel) - 55 + this.y1(data.key)}px`);
  }

  hideTooltip() {
    this.div
      .transition()
      .duration(200)
      .style('opacity', 0);
  }

  convertCamelCase(str) {
    var result = str.replace(/([A-Z])/g, ' $1');
    result = result.charAt(0).toUpperCase() + result.slice(1);
    return result;
  }
}
