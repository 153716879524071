import { NgModule, ApplicationRef } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from '../components/auth/auth.interceptor';

import { BrowserModule } from '@angular/platform-browser';
import { DatePipe, DecimalPipe } from '@angular/common';

import { removeNgStyles, createNewHosts, createInputTransfer } from '@angularclass/hmr';

import { RouterModule, Routes } from '@angular/router';

import { ModalModule } from 'ngx-bootstrap/modal';

import { AppComponent } from './app.component';
import { DirectivesModule } from '../components/directives.module';
import { JwtModule } from '@auth0/angular-jwt';

import { AccountModule } from './account/account.module';
import { ActiveModule } from './active/active.module';
import { AdminModule } from './admin/admin.module';
import { CampaignModule } from './campaign/campaign.module';
import { DarkPoolModule } from './dark-pool/dark-pool.module';
import { FrequencyModule } from './frequency/frequency.module';
import { GeneratorModule } from './generator/generator.module';
import { MainModule } from './main/main.module';
import { PerformanceModule } from './performance/performance.module';
import { TimeWindowsModule } from './time-windows/time-windows.module';
import { WhoModule } from './who/who.module';

import { AuthGuard } from '../components/auth/auth-guard.service';

export function tokenGetter() {
  return localStorage.getItem('token');
}

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
      }
    }),
    RouterModule.forRoot(appRoutes, {
      enableTracing: process.env.NODE_ENV === 'development',
      onSameUrlNavigation: 'reload'
    }),
    DirectivesModule,
    AccountModule,
    ActiveModule,
    AdminModule,
    CampaignModule,
    DarkPoolModule,
    FrequencyModule,
    GeneratorModule,
    MainModule,
    PerformanceModule,
    TimeWindowsModule,
    WhoModule,
    ModalModule.forRoot()
  ],
  providers: [
    AuthGuard,
    DatePipe,
    DecimalPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
  static parameters = [ApplicationRef];
  constructor(appRef: ApplicationRef) {
    this.appRef = appRef;
  }

  hmrOnInit(store) {
    if(!store || !store.state) return;
    console.log('HMR store', store);
    console.log('store.state.data:', store.state.data);
    // inject AppStore here and update it
    // this.AppStore.update(store.state)
    if('restoreInputValues' in store) {
      store.restoreInputValues();
    }
    // change detection
    this.appRef.tick();
    Reflect.deleteProperty(store, 'state');
    Reflect.deleteProperty(store, 'restoreInputValues');
  }

  hmrOnDestroy(store) {
    var cmpLocation = this.appRef.components.map(cmp => cmp.location.nativeElement);
    // recreate elements
    store.disposeOldHosts = createNewHosts(cmpLocation);
    // inject your AppStore and grab state then set it on store
    // var appState = this.AppStore.get()
    store.state = {
      data: 'Motiva'
    };
    // store.state = Object.assign({}, appState)
    // save input values
    store.restoreInputValues = createInputTransfer();
    // remove styles
    removeNgStyles();
  }

  hmrAfterDestroy(store) {
    // display new elements
    store.disposeOldHosts();
    Reflect.deleteProperty(store, 'disposeOldHosts');
    // anything you need done the component is removed
  }
}
