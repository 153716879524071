import { Component, Input, SimpleChanges } from '@angular/core';

import { Campaign } from '../campaign';
import { CampaignService } from '../campaign.service';
import { DarkPoolService } from '../../../dark-pool/shared/dark-pool.service';

import { AuthService } from '../../../../components/auth/auth.service';
import { ImpersonationService } from '../../../../components/auth/impersonation.service';

import { Settings } from '../../../shared/settings';

@Component({
  selector: 'campaign-details',
  template: require('./campaign-details.html')
})
export class CampaignDetailsComponent {
  @Input() campaign: Campaign;
  @Input() campaignLocal;
  @Input() results;
  @Input() alerts;
  @Input() folderName;
  @Input() activateTimeWindows;

  settings: Settings = new Settings();

  CONTACT_EMAIL_SENT_MSG = 'Contacts sent an email';
  CONTACT_ELOQUA_BLOCKED_MSG = 'Contacts excluded by Eloqua because of previous unsubscribes, bounces, etc';
  CONTACT_FM_BLOCKED_MSG = 'Contacts blocked by Frequency Management and returned to Eloqua with an error';
  CONTACT_UNHANDLED_MSG = 'Contacts that have not been sent an email.<br/>Motiva has not scheduled these contacts to send in Eloqua yet';

  activeTab = {
    messageTesting: false,
    timeWindows: false,
    whoReport: false,
    opsView: true,
    config: false
  };

  static parameters = [CampaignService, DarkPoolService, AuthService, ImpersonationService];
  constructor(campaignService: CampaignService, darkPoolService: DarkPoolService, authService: AuthService, impersonation: ImpersonationService) {
    this.campaignService = campaignService;
    this.darkPoolService = darkPoolService;
    this.authService = authService;
    this.impersonation = impersonation;
    this.insufficientContactsData = null;
  }

  ngOnInit() {
    console.log('---------> Init campaign-details:', this.campaign, this.campaignLocal, this.results, this.alerts); //debug

    this.authService.getCurrentOrg().then(org => {
      this.currentOrg = org;

      //Only run this check after getting the org.
      if(this.campaign.status === 'insufficientContacts') {
        this.getInsufficientContactsData();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'activateTimeWindows') {
        if(this.activateTimeWindows) {
          this.activeTab.messageTesting = false;
          this.activeTab.timeWindows = true;
          this.activeTab.opsView = false;
          this.activeTab.config = false;
        }
        else if(this.campaign.status === 'draft') {
          this.activeTab.messageTesting = false;
          this.activeTab.timeWindows = false;
          this.activeTab.opsView = false;
          this.activeTab.config = true;
        }
        else if(!this.results.show) {
          this.activeTab.messageTesting = false;
          this.activeTab.timeWindows = false;
          this.activeTab.opsView = true;
          this.activeTab.config = false;
        }
        // If STO campaign, open to Time Windows tab
        else if(this.campaign.optimizationCriterion === 'sendTime') {
          this.activeTab.messageTesting = false;
          this.activeTab.timeWindows = true;
          this.activeTab.opsView = false;
          this.activeTab.config = false;
        }
        else {
          this.activeTab.messageTesting = true;
          this.activeTab.timeWindows = false;
          this.activeTab.opsView = false;
          this.activeTab.config = false;
        }
      }
      if(propName === 'results' && this.activeTab) {
        if(this.campaign.campaignId && this.campaignLocal && !this.campaignLocal.gotContactCounts && this.results && !this.folderName) {
          this.campaignService.getContactCounts(this.campaign, this.campaignLocal, this.results);

          //Get possible Dark Pool performance for this campaign.
          /* TODO: fish: Add potential Dark Pool enhanced performance (aka Time Machine) to the campaign details.
          this.darkPoolService.getPerformanceByCampaign(0, this.campaign.eloquaCampaignId).then(results => {
            console.log('----- Got performance by campaign:', results); //debug
          });
          */
        }

        //Activate one of the tabs be default.
        if(this.campaign.status === 'draft') {
          this.activeTab.messageTesting = false;
          this.activeTab.timeWindows = false;
          this.activeTab.opsView = false;
          this.activeTab.config = true;
        }
        else if(!this.results.show) {
          this.activeTab.messageTesting = false;
          this.activeTab.timeWindows = false;
          this.activeTab.opsView = true;
          this.activeTab.config = false;
        }
        // If STO campaign, open to Time Windows tab
        else if(this.campaign.optimizationCriterion === 'sendTime') {
          this.activeTab.messageTesting = false;
          this.activeTab.timeWindows = true;
          this.activeTab.opsView = false;
          this.activeTab.config = false;
        }
        else {
          this.activeTab.messageTesting = true;
          this.activeTab.timeWindows = false;
          this.activeTab.opsView = false;
          this.activeTab.config = false;
        }
      }
    }
  }

  getInsufficientContactsData() {
    if(this.campaignLocal.numDaysWithSends) {
      var data = {
        contactCount: this.campaignLocal.totalNumberOfContacts,
        numTreatments: this.campaign.emails.length,
        numDays: this.campaignLocal.numDaysWithSends,
        firstBatch: this.campaign.recentActionServiceNotifyCount && this.campaign.recentActionServiceNotifyCount.length > 0 ? this.campaign.recentActionServiceNotifyCount[0].count : 0
      };
      data.totalContactsNeeded = data.numTreatments * data.numDays * this.settings.minimumContactsPerTreatmentPerBatch;
      this.insufficientContactsData = data;
    }
  }

  refresh() {
    window.location.reload();
  }
}
