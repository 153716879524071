import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../components/auth/auth-guard.service';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { ActiveComponent } from './active.component';

export const ROUTES: Routes = [
  {
    path: 'active',
    component: ActiveComponent,
    canActivate: [AuthGuard] //TODO: Only for Admins...
  }
];

@NgModule({
  imports: [BrowserModule, FormsModule, RouterModule.forChild(ROUTES), NgxDatatableModule, BsDropdownModule.forRoot()],
  declarations: [ActiveComponent],
  exports: [ActiveComponent]
})
export class ActiveModule {}
