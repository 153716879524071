import { Component, ElementRef, HostListener, Input, SimpleChanges, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import * as d3 from 'd3';

@Component({
  selector: 'viz-line-chart',
  template: '<div></div>'
})
export class LineChartComponent implements OnInit {
  @Input() data;
  @Input() height;
  @Input() extHighlightId; //highlight area externally

  @HostListener('window:resize', ['$event'])
  onResize() {
    //If not vislble, don't resize.
    if(!(this.htmlElement.offsetParent !== null)) return;
    var newWidth = this.htmlElement.parentElement.clientWidth;

    this.svg.attr('width', newWidth);
    this.width = +this.svg.attr('width') - this.margin.left - this.margin.right;

    if(this.data) {
      this.x = d3.scaleTime().range([50, this.width - 60]);
      this.render(this.data);
    }
  }

  margin = { top: 20, left: 60, bottom: 20, right: 40 };

  htmlElement: HTMLElement;
  svg;

  static parameters = [DecimalPipe, ElementRef];
  constructor(decimalPipe: DecimalPipe, element: ElementRef) {
    this.decimalPipe = decimalPipe;
    this.htmlElement = element.nativeElement;
    this.host = d3.select(element.nativeElement);
  }

  ngOnInit() {
    //Set width to 100% of parent container.
    this.width = this.htmlElement.parentElement.clientWidth;

    //append the svg object
    this.host.html('');
    this.svg = this.host
      .append('svg')
      .attr('id', 'rates_lineChart')
      .attr('height', this.height ? this.height : '250')
      .attr('width', this.width);

    //Add margin
    this.width = +this.svg.attr('width') - this.margin.left - this.margin.right;
    this.height = +this.svg.attr('height') - this.margin.top - this.margin.bottom;

    this.q = d3.quadtree();
    this.startEndTimes = [];
    this.sectionWidth;

    // parse the date / time
    this.parseTime = d3.utcParse('%Y-%m-%d');
    this.formatDate = d3.timeFormat('%b %d, %Y, %I:%M %p');

    this.g = this.svg.append('g').attr('transform', `translate(${this.margin.left},${this.margin.top})`);

    // Define the div for the tooltip
    this.tooltip = this.host
      .append('div')
      .attr('class', 'chart-tooltip line-chart-tooltip')
      .style('opacity', 0);

    this.x = d3.scaleLinear().range([20, this.width - 30]);
    this.x1 = d3.scaleLinear();
    this.y = d3.scaleLinear().range([this.height, 20]); //was: [height-50, 50]

    this.maxNumPerDay = 0;

    // define the line
    this.valueline = d3.line().y(d => this.y(d.value));

    // define the area
    this.area = d3
      .area()
      .curve(d3.curveMonotoneX)
      .y0(d => this.y(d.min))
      .y1(d => this.y(d.max));

    this.render(this.data);
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'data' && this.data && this.g) {
        this.render(this.data);
      }
      else if(propName === 'extHighlightId') {
        this.showUncertainty();
      }
    }
  }

  render(data) {
    this.g.selectAll('*').remove();

    if(!data || data.length === 0) return;
    this.sectionWidth = (this.width - 30) / data.days.length;

    // Get time range for each day to use for chart scale
    if(data.results[0] && data.results[0].series[0]) {
      this.startEndTimes = [[data.results[0].series[0].date]];
      data.results.forEach(result => {
        result.series.forEach(point => {
          data.days.forEach((day, i) => {
            var pointDate = point.date;
            if(day.getDate() === pointDate.getDate() && day.getMonth() === point.date.getMonth() && day.getYear() === point.date.getYear()) {
              if(!this.startEndTimes[i] || !this.startEndTimes[i][0] || pointDate.getTime() < this.startEndTimes[i][0].getTime()) {
                if(!this.startEndTimes[i]) this.startEndTimes[i] = [];
                this.startEndTimes[i][0] = pointDate;
              }
              if(!this.startEndTimes[i] || !this.startEndTimes[i][1] || pointDate.getTime() > this.startEndTimes[i][1].getTime()) {
                if(!this.startEndTimes[i]) this.startEndTimes[i] = [];
                this.startEndTimes[i][1] = pointDate;
              }
            }
          });
        });
      });
    }

    this.startEndTimes.forEach(day => {
      if(!day[1] || day[0].getTime() === day[1].getTime()) {
        if(day[0]) {
          var date = new Date(day[0]);
          day[0] = new Date(date.setHours(0, 0, 0, 0));
          day[1] = new Date(date.setHours(23, 59, 59, 59));
        }
      }
    });

    var yMax = 0;
    var dates = [];

    if(data.results[0] && data.results[0].series.length > 0) {
      this.maxNumPerDay = 0;
      var currentDayCount = 0;
      var currentDate = data.results[0].series[0].date;

      data.results[0].series.forEach(point => {
        var pointDate = point.date;
        if(pointDate.getDate() === currentDate.getDate()) currentDayCount++;
        else {
          if(currentDayCount > this.maxNumPerDay) {
            this.maxNumPerDay = currentDayCount;
          }
          currentDayCount = 0;
          currentDate = point.date;
        }
      });
    }

    // If the program duration is longer than result data, add empty days to chart
    var daysToAdd = 0;
    if(data.results[0] && data.results[0].series.length < data.programDuration.value) {
      daysToAdd = data.programDuration.value - data.results[0].series.length;
    }

    data.results.forEach((result, i) => {
      var series = result.series;

      if(series.length > 0) {
        series.forEach(e => {
          //Only update, if not null.
          if(e.value) {
            e.value = +e.value;
            e.min = +e.min;
            e.max = +e.max;
          }
          if(dates.length < series.length) dates.push(e.date);
        });

        // Determine the number of days left in the program with no results yet
        for(var j = 0; j < daysToAdd; j++) {
          var extraDate = data.results[i].series[data.results[i].series.length - 1].date;
          dates.push(extraDate);
        }

        // Show the max uncertainty of the latest day of results or max value
        series.forEach(day => {
          if(day.value > yMax) {
            yMax = day.value;
          }
        });

        this.y.domain([0, yMax]).nice();

        setTimeout(() => {
          // TODO: find a better way to fix the timing
          // go through again, get x,y and add to quadtree
          series.forEach(e => {
            data.days.forEach((day, index) => {
              if(day.getDate() === e.date.getDate() && day.getMonth() === e.date.getMonth() && day.getYear() === e.date.getYear()) {
                this.x1.range([index * this.sectionWidth, (index + 1) * this.sectionWidth - this.sectionWidth / 10]);
                this.x1.domain([this.startEndTimes[index][0], this.startEndTimes[index][1]]);
              }
            });
            e.x = Math.floor(this.x1(e.date));
            e.y = Math.floor(this.y(e.value));
            e.seriesId = result.id;

            // add this point to quadtree, so we can find it with UI
            this.q.add([e.x, e.y, e]);
          });

          var color = data.info[result.id].color;

          if(result.display) {
            this.drawSeries(this.g, series, color, result.id);
          }
        }, 1);
      }
    });

    // If more than 16 dates, reduce number of ticks shown (Only ever show a max of 16)
    var xAxisValues = [];
    if(data.days.length > 16 || this.width < 1000) {
      var maxNum = 16;
      if(this.width < 1000) {
        maxNum = maxNum / 2;
      }
      var num = data.days.length / maxNum;
      if(num % 1 > 0) {
        num = Math.trunc(data.days.length / maxNum) + 1;
      }
      data.days.forEach(function(date, i) {
        if(i % num === 0) {
          xAxisValues.push(date);
        }
      });
    }
    else {
      xAxisValues = data.days;
    }

    var xDomain = d3.extent(xAxisValues, d => d);

    var start = new Date(xDomain[0]);
    var end = new Date(xDomain[1]);
    if(data.programDuration.endDate && data.programDuration.endDate.getTime() > end.getTime()) {
      end = new Date(data.programDuration.endDate);
    }
    end.setHours(23, 59, 59);
    this.x.domain([start, end]);

    // add x axis
    this.g
      .append('g')
      .attr('class', 'xAxis')
      .attr('transform', `translate(0,${this.height})`)
      .call(
        d3
          .axisBottom(this.x)
          .tickValues(xAxisValues)
          .tickFormat(d3.timeFormat('%a, %b %d'))
          .tickSizeOuter(0)
      )
      .selectAll('.tick')
      .attr('transform', d => {
        var tickDate = new Date(d);
        var xVal = 0;
        data.days.forEach((day, i) => {
          var date = new Date(day);
          if(tickDate.getTime() === date.getTime()) {
            xVal = i * this.sectionWidth;
          }
        });
        return `translate(${xVal + this.sectionWidth / 2},0)`;
      });

    // Add the y Axis
    this.g
      .append('g')
      .attr('class', 'yAxis')
      .call(
        d3
          .axisLeft(this.y)
          .ticks(5)
          .tickFormat(d => `${d3.format('.1f')(d * 100)}%`)
      )
      .selectAll('.tick:not(:first-of-type) line')
      .attr('stroke', '#777')
      .attr('opacity', '0.2')
      .attr('x2', this.width);

    this.g
      .select('.yAxis')
      .selectAll('.tick:first-of-type line')
      .attr('stroke', '#000')
      .attr('opacity', '1')
      .attr('x2', this.width);
  } //end: render()

  //----------------------------------------------------------------------------
  drawSeries(targ, series, color, id) {
    this.valueline.x(d => {
      this.data.days.forEach((day, i) => {
        if(day.getDate() === d.date.getDate() && day.getMonth() === d.date.getMonth() && day.getYear() === d.date.getYear()) {
          this.x1.range([i * this.sectionWidth, (i + 1) * this.sectionWidth - this.sectionWidth / 10]);
          this.x1.domain([this.startEndTimes[i][0], this.startEndTimes[i][1]]);
        }
      });
      return this.x1(d.date);
    });

    this.area.x(d => {
      this.data.days.forEach((day, i) => {
        if(day.getDate() === d.date.getDate() && day.getMonth() === d.date.getMonth() && day.getYear() === d.date.getYear()) {
          this.x1.range([i * this.sectionWidth, (i + 1) * this.sectionWidth - this.sectionWidth / 10]);
          this.x1.domain([this.startEndTimes[i][0], this.startEndTimes[i][1]]);
        }
      });
      return this.x1(d.date);
    });

    //draw line
    targ
      .append('path')
      .data([series])
      .attr('class', 'line')
      .attr('stroke', color)
      .attr('d', this.valueline)
      .attr('id', () => `line_${id}`);

    //draw uncertainty
    targ
      .append('path')
      .datum(() => series)
      .attr('class', 'area')
      .attr('fill', color)
      .attr('d', this.area)
      .attr('id', () => `item_${id}`);

    // draw dots
    targ
      .selectAll('dot')
      .data(series)
      .enter()
      .append('circle')
      .attr('class', 'line-dot')
      .attr('r', () => {
        // If just one data point, always show dot
        if(series.length === 1) return 4;
        return 1;
      })
      .attr('cx', d => {
        this.data.days.forEach((day, i) => {
          if(day.getDate() === d.date.getDate() && day.getMonth() === d.date.getMonth() && day.getYear() === d.date.getYear()) {
            this.x1.range([i * this.sectionWidth, (i + 1) * this.sectionWidth - this.sectionWidth / 10]);
            this.x1.domain([this.startEndTimes[i][0], this.startEndTimes[i][1]]);
          }
        });
        return this.x1(d.date);
      })
      .attr('cy', d => this.y(d.value))
      .attr('fill', color)
      .attr('id', d => {
        this.data.days.forEach((day, i) => {
          if(day.getDate() === d.date.getDate() && day.getMonth() === d.date.getMonth() && day.getYear() === d.date.getYear()) {
            this.x1.range([i * this.sectionWidth, (i + 1) * this.sectionWidth - this.sectionWidth / 10]);
            this.x1.domain([this.startEndTimes[i][0], this.startEndTimes[i][1]]);
          }
        });
        return `dot_${Math.floor(this.x1(d.date))}_${Math.floor(this.y(d.value))}`;
      })
      .attr('lineId', `dot_${id}`)
      .attr('opacity', function() {
        // If just one data point, always show dot
        if(series.length === 1) return 0.9;
        return 0.1;
      });

    // interaction for highlighting areas and points on chart
    targ.on('mousemove', () => {
      // get mouse position
      var mouseXy = d3.mouse(d3.event.target);

      // find closest data point to mouse position
      var dot = this.q.find(mouseXy[0], mouseXy[1]);
      var seriesId = dot[2].seriesId;
      var date = dot[2].date;

      this.data.results.forEach(result => {
        if(result.id === seriesId && result.display) {
          this.highlightDot(seriesId, date, true);
          this.highlightArea(seriesId);
        }
      });
    });

    targ.on('mouseout', () => {
      this.hideTooltip();
    });
  } //end: drawSeries()

  highlightDot(seriesId, date, showTooltip) {
    if(this.data.results && date) {
      var series = [];
      var results = this.data.results;
      results.forEach(result => {
        if(result.id === seriesId) {
          series = result;
        }
      });

      var point = this.findPoint(series, date);

      if(point) {
        this.data.days.forEach((day, i) => {
          if(day.getDate() === point.date.getDate() && day.getMonth() === point.date.getMonth() && day.getYear() === point.date.getYear()) {
            this.x1.range([i * this.sectionWidth, (i + 1) * this.sectionWidth - this.sectionWidth / 10]);
            this.x1.domain([this.startEndTimes[i][0], this.startEndTimes[i][1]]);
          }
        });
        this.highlightPoint(Math.floor(this.x1(point.date)), Math.floor(this.y(point.value)));
        if(showTooltip) {
          this.showToolTip(point, seriesId);
        }
      }
    }
  }

  findPoint(series, date) {
    var point;
    var points = series.series;
    points.forEach(d => {
      var currentDate = d.date;
      if(currentDate.getTime() == date.getTime()) {
        point = {
          date: currentDate,
          value: d.value
        };
      }
    });
    return point;
  }

  highlightPoint(x, y) {
    if(this.data.results && this.data.results[0] && this.data.results[0].series.length > 1) {
      d3.selectAll('.line-dot')
        .attr('r', 1)
        .style('opacity', 0.1);

      d3.select(`[id='dot_${x}_${y}']`)
        .attr('r', 4)
        .style('opacity', 0.9);
    }
  }

  highlightArea(id) {
    this.data.results.forEach(result => {
      if(result.id !== id) {
        this.removeHighlight(result.id);
      }

      //If the area being highlighted only has 1 point, highlight that dot, yo!
      if(result && result.id === id && result.series.length === 1) {
        this.highlightDot(id, result.series[0].date, false);
      }
    });

    d3.select(`[id='item_${id}']`)
      .transition()
      .duration(200)
      .style('opacity', 0.3);
  }

  removeHighlight(id) {
    d3.select(`[id='item_${id}']`)
      .transition()
      .duration(200)
      .style('opacity', 0.05);
  }

  showToolTip(d, seriesId) {
    var rateLabel = '';

    if(this.data.attrToChart === 'click') {
      rateLabel = 'Clickthrough';
    }
    else if(this.data.attrToChart === 'open') {
      rateLabel = 'Open rate';
    }

    this.tooltip
      .transition()
      .duration(600)
      .style('opacity', 1);

    this.tooltip
      .html(
        `<p class="name">${this.data.info[seriesId].name}</p>`
          + `<p class="result smaller"><span class="faded vLabel">Subject:</span>${this.data.info[seriesId].subject}</p><hr/>`
          + `<p class="date"><strong>${this.formatDate(d.date)}</strong></p><div>`
          + `<p class="result"><span class="faded vLabel">${rateLabel}:</span><strong>`
          + `${this.decimalPipe.transform(d.value * 100, '1.1-2')}%</strong></p>`
          + '</div>'
      )
      .style('left', `${this.x1(d.date)}px`)
      .style('top', '-40px');
  }

  findDate(array, date) {
    for(var i = 0; i < array.length; i += 1) {
      var currentDate = array[i].date;
      if(currentDate.getTime() === date.getTime()) {
        return i;
      }
    }
    return -1;
  }

  hideTooltip(id) {
    if(this.data.results && this.data.results[0] && this.data.results[0].series.length > 1) {
      d3.selectAll('.line-dot')
        .attr('r', 1)
        .style('opacity', 0.1);
    }

    //Will we ever pass the id?
    if(id) {
      this.removeHighlight(id);
    }
    else if(!id) {
      this.data.results.forEach(result => {
        this.removeHighlight(result.id);
      });
    }

    this.tooltip
      .transition()
      .duration(100)
      .style('opacity', 0);
  } //end: hideTooltip()

  showUncertainty() {
    this.highlightArea(this.extHighlightId);
  }
}
