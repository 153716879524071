// @flow
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import moment from 'moment-timezone';
import * as svg from 'save-svg-as-png';

import { AuthService } from '../../../components/auth/auth.service';
import { UserService } from '../../../components/auth/user.service';
import { ImpersonationService } from '../../../components/auth/impersonation.service';

import { Campaign } from '../shared/campaign';
import { CampaignService } from '../shared/campaign.service';
import { CampaignEmailService } from '../shared/campaign-email.service';
import { DeleteCancelComponent } from './delete-cancel/delete-cancel.component';
import { LoggerService } from '../../shared/logger.service';
import { ErrorEmailComponent } from './error-email/error-email.component';
import { HeroEmailComponent } from './hero-email/hero-email.component';
import { CampaignFilesService } from '../shared/campaign-files.service';

import { isLoading } from '../../../components/util';

@Component({
  selector: 'campaign',
  template: require('./campaign.html')
})
export class CampaignComponent implements OnInit {
  selectedCampaignId;
  campaign: Campaign = new Campaign();

  bsModalRef: BsModalRef;

  static parameters = [
    ActivatedRoute,
    Router,
    BsModalService,
    AuthService,
    UserService,
    ImpersonationService,
    CampaignService,
    CampaignEmailService,
    LoggerService,
    CampaignFilesService
  ];
  constructor(
    route: ActivatedRoute,
    router: Router,
    modalService: BsModalService,
    authService: AuthService,
    userService: UserService,
    impersonation: ImpersonationService,
    campaignService: CampaignService,
    campaignEmailService: CampaignEmailService,
    loggerService: LoggerService,
    campaignFilesService: CampaignFilesService
  ) {
    this.route = route;
    this.router = router;
    this.modalService = modalService;
    this.authService = authService;
    this.userService = userService;
    this.impersonation = impersonation;
    this.campaignService = campaignService;
    this.campaignEmailService = campaignEmailService;
    this.loggerService = loggerService;
    this.campaignFilesService = campaignFilesService;

    this.loadingCampaign = false;
    this.isLoading = isLoading;
    this.isAdmin = false;
    this.activateTimeWindows = false;

    this.campaignLocal = {
      isSimple: false,
      currentDate: moment.tz()
    };
    this.alerts = [];
  }

  ngOnInit() {
    console.log('---------> Init campaign.'); //debug

    this.authService.getCurrentUser().then(user => {
      this.currentUser = user;
    });

    this.isDemo = this.impersonation.isDemoMode();

    //For Impersonation
    this.userSub = this.authService.currentUserChanged.subscribe(user => {
      this.currentUser = user;
    });

    this.authService.isAdmin().then(is => {
      this.isAdmin = is;

      // Get folder names for sample data
      if(this.isAdmin) {
        this.campaignFilesService.getSampleDataFolders().then(
          folders => {
            this.dataFolders = folders;
          },
          err => {
            console.log('Error getting sample data folders', err);
          }
        );
      }
    });

    this.results = {
      show: false,
      latest: { totals: {} }
    };

    //For loading debug data.
    this.dataFolders;
    this.selectedDataFolder;

    this.campaignIdSub = this.route.paramMap.subscribe(params => {
      this.selectedCampaignId = params.get('campaignId');
      this.loadSelectedCampaign();
    });

    //For Impersonation, handles the case when we go straight to a copied url.
    this.navSub = this.router.events.subscribe((e: any) => {
      if(e instanceof NavigationEnd) {
        if(this.impersonation.isImpersonating()) {
          if(this.selectedCampaignId) {
            this.loadSelectedCampaign();
          }
        }
      }
    });
  }

  ngOnDestroy() {
    if(this.userSub) this.userSub.unsubscribe();
    if(this.campaignIdSub) this.campaignIdSub.unsubscribe();
    if(this.navSub) this.navSub.unsubscribe();
  }

  loadSelectedCampaign() {
    if(this.loadingCampaign) return; //debounce
    this.alerts = [];
    this.folderName = null;
    this.loadingCampaign = true;
    this.activateTimeWindows = false;

    //Load "demo mode" campaigns.
    if(this.isDemo) {
      this.loadDataFiles(this.selectedCampaignId);
    }
    //If old school demo campaign was selected, load from files
    else if(this.selectedCampaignId === '11111111-1111-1111-1111-111111111111') {
      this.loadDataFiles('Demo');
    }
    else if(this.selectedCampaignId === 'a2666cc7-53b7-492e-98f3-8f90c3eda081') {
      this.loadDataFiles('Demo Subpopulations');
    }
    else {
      this.campaignService
        .get(this.selectedCampaignId)
        .toPromise()
        .then(
          campaign => {
            //This case is for Admins opening a campaign via a link that is outside their
            //own organization. In this case, the campaign is viewed via Impersonation.
            if(campaign.organizationId !== this.currentUser.organizationId) {
              if(this.isAdmin) {
                this.userService
                  .get({ id: campaign.uid })
                  .toPromise()
                  .then(user => {
                    this.impersonation.impersonateUser(user, this.selectedCampaignId);
                    this.loadingCampaign = false;
                  });
              }
              else {
                this.loggerService.logMessage('Non-Admin trying to open campaign outside their org.', 'info');
                this.router.navigate(['/home']);
                this.loadingCampaign = false;
              }
            }
            else {
              this.campaign = campaign;
              this.campaignService.eloquaCampaignId = campaign.eloquaCampaignId;
              this.updateUi();
              this.loadingCampaign = false;
            }
          },
          error => {
            this.loggerService.logMessage('Error retrieving campaign.', 'error', error);
            this.loadingCampaign = false;
          }
        )
        .catch(e => {
          this.loggerService.logMessage('Exception retrieving campaign.', 'error', e);
          this.loadingCampaign = false;
        });
    }
  }

  updateUi() {
    this.campaignLocal = this.campaignService.initCampaignLocal(this.campaign);
    this.campaignService.getResults(this.campaign, this.campaignLocal).then(results => {
      this.results = results;
    });
  }

  archiveCancelCampaign() {
    var id = this.campaign.campaignId;

    //Will do different things depending on the status.
    var action = 'archive';

    if(this.campaignService.isActive(this.campaign.status)) {
      action = 'cancel';
    }

    //Display Delete/Cancel Modal
    const initialState = {
      campaign: this.campaign
    };
    this.bsModalRef = this.modalService.show(DeleteCancelComponent, { initialState });
    var sub = this.bsModalRef.content.action.subscribe(doIt => {
      if(doIt) {
        this.campaignService
          .delete(id)
          .toPromise()
          .then(() => {
            if(action === 'archive') {
              console.log('Campaign successfully archived.');
              //TODO: Revove this campaign from the list somehow...
              //vm.$rootScope.$emit('deleteCampaignFromList', id);
            }
            else {
              console.log('Campaign successfully cancelled.');
              //TODO: Is there a better way to reload?
              window.location.reload();
            }
          })
          .catch(error => {
            if(action === 'archive') {
              action = 'archiving';
            }
            else {
              action = 'cancelling';
            }
            //TODO: Add an alert message somehow?
            //vm.addAlert('Error ' + action + ' campaign: ' + vm.campaign.name, 'danger', response);
            this.loggerService.logMessage(`Error ${action} campaign`, 'error', error);
          });
      }
      if(sub) sub.unsubscribe();
    });
  } //end: archiveCancelCampaign()

  shareReport() {
    this.alerts = [];
    var reportImages = {};
    var chartIds = ['rates_lineChart', 'sendTimes_heatmap'];

    chartIds.forEach(id => {
      if(document.getElementById(id)) {
        svg.svgAsPngUri(document.getElementById(id), {}, function(uri) {
          reportImages[id] = uri;
        });
      }
      else if(id == 'sendTimes_heatmap') {
        this.activateTimeWindows = true;
        setTimeout(() => {
          console.log('Loaded heatmap', document.getElementById(id));
          if(document.getElementById(id)) {
            svg.svgAsPngUri(document.getElementById(id), {}, function(uri) {
              reportImages[id] = uri;
            });
          }
          this.activateTimeWindows = false;
        }, 2000);
      }
    });

    var initialState = {
      campaign: this.campaign,
      campaignLocal: this.campaignLocal,
      results: this.results,
      reportImages
    };

    this.modalRef = this.modalService.show(HeroEmailComponent, {
      initialState,
      class: 'modal-lg'
    });

    var sub = this.modalService.onHidden.subscribe(() => {
      if(this.modalRef.content.okClicked) {
        // Send email
        var emails = '';
        if(this.modalRef.content.emails.length > 0) {
          emails = [this.modalRef.content.emails.slice(0, -1).join(', '), this.modalRef.content.emails.slice(-1)[0]].join(this.modalRef.content.emails.length < 2 ? '' : ', and ');
        }

        this.campaignEmailService.sendHeroEmail(this.modalRef.content.response.emails, this.modalRef.content.response.emailData, this.currentUser).then(
          () => {
            var message = `Email sent successfully to: ${emails}`;
            this.alerts.push({ msg: message, type: 'success' });
          },
          error => {
            console.log('Error sending email', error);

            //TODO: Add error alert.
            // if(error.data && error.data.errors && error.data.errors.length > 0) {
            //   vm.uiparams.closableAlerts.push({msg: `Error sending emails: ${error.data.errors[0].message}`, type: 'danger'});
            // }
          }
        );
      }
      if(sub) sub.unsubscribe();
    });
  } //end: emailReport()

  //For Motiva admins to manually send a campaign error email.
  sendCampaignError() {
    var initialState = { campaign: this.campaign };
    this.modalRef = this.modalService.show(ErrorEmailComponent, {
      initialState,
      class: 'modal-lg'
    });

    var sub = this.modalService.onHidden.subscribe(() => {
      if(this.modalRef.content.okClicked) {
        if(this.modalRef.content.emails.length > 0) {
          this.campaignEmailService.sendCampaignErrorEmail(this.campaign.campaignId, this.modalRef.content.emails, this.modalRef.content.errorType).then(
            () => {
              var emails = [this.modalRef.content.emails.slice(0, -1).join(', '), this.modalRef.content.emails.slice(-1)[0]].join(this.modalRef.content.emails.length < 2 ? '' : ', and ');
              var message = `Email sent successfully to: ${emails}`;
              this.alerts.push({ msg: message, type: 'success' });
            },
            error => {
              console.log('Error sending email', error);

              //TODO: Add error alert.
              // if(error.data && error.data.errors && error.data.errors.length > 0) {
              //   vm.uiparams.closableAlerts.push({msg: `Error sending emails: ${error.data.errors[0].message}`, type: 'danger'});
              // }
            }
          );
        }
      }
      if(sub) sub.unsubscribe();
    });
  }

  //For testing Winner Found email
  sendWinnerFound() {
    this.campaignEmailService.sendWinnerFoundEmail(this.campaign.campaignId, 115).then(
      () => {
        var message = 'Email sent successfully.';
        this.alerts.push({ msg: message, type: 'success' });
      },
      error => {
        console.log('Error sending email', error);
        this.alerts.push({ msg: error.error ? error.error : error.message, type: 'danger' });
      }
    );
  }

  //For testing Failed to Start email
  sendFailedToStart() {
    this.campaignEmailService.sendFailedToStartEmail(this.campaign.campaignId).then(
      () => {
        var message = 'Email sent successfully.';
        this.alerts.push({ msg: message, type: 'success' });
      },
      error => {
        console.log('Error sending email', error);
        this.alerts.push({ msg: error.message, type: 'danger' });
      }
    );
  }

  loadDataFiles(folder) {
    this.folderName = folder;
    this.campaignFilesService.loadDataFiles(folder).then(
      response => {
        this.campaign = response.campaign;
        this.campaignLocal = this.campaignService.initCampaignLocal(this.campaign);
        this.campaignFilesService.getResults(this.campaign, this.campaignLocal, folder).then(results => {
          this.results = results;
        });

        this.loadingCampaign = false;
      },
      error => {
        this.loadingCampaign = false;
        console.log('Error getting data from files', error);
      }
    );
  }

  downloadDataFiles() {
    this.campaignFilesService.downloadDataFiles(this.campaign);
  }
}
