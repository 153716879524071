import { Component, ElementRef, Input, SimpleChanges, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'viz-radar-chart',
  template: '<div></div>'
})
export class RadarChartComponent implements OnInit {
  @Input() data;
  @Input() width;
  @Input() height;
  @Input() chartType;

  htmlElement: HTMLElement;
  svg;

  static parameters = [ElementRef];
  constructor(element: ElementRef) {
    this.htmlElement = element.nativeElement;
    this.host = d3.select(element.nativeElement);
  }

  ngOnInit() {
    this.width = this.width ? this.width : 300;
    this.height = this.height ? this.height : 300;
    this.chartType = this.chartType ? this.chartType : 'thumbnail';

    this.radius = 2;
    this.maxValue = 100;
    this.factor = 1;
    this.factorLegend = 0.85;
    this.levels = 5;
    this.levelLabels = ['F', 'D', 'C', 'B', 'A'];
    this.radians = 2 * Math.PI;
    this.opacityArea = 0.5;
    this.toRight = -9;
    this.translateX = this.chartType === 'main' ? 70 : 0;
    this.translateY = this.chartType === 'main' ? 25 : 0;
    this.extraWidthX = 150;
    this.extraWidthY = 50;
    this.color = d3.scaleOrdinal().range(['#607D8B', '#7C4DFF']);

    this.render(this.data);
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'data' && this.data && this.data.length > 0 && typeof this.color !== 'undefined') {
        this.render(this.data);
      }
    }
  }

  render(data) {
    if(!data || data.length === 0) return;

    this.host.html('');

    var series = 0;
    var dataValues = [];

    var allAxis = data.map(i => i.name);
    var total = allAxis.length;
    var radius = this.factor * Math.min(this.width / 2, this.height / 2);

    var g = this.host
      .append('svg')
      .attr('width', this.width + this.extraWidthX)
      .attr('height', this.height + this.extraWidthY)
      .append('g')
      .attr('transform', `translate(${this.translateX},${this.translateY})`);

    //Circular segments
    for(var j = 0; j < this.levels; j++) {
      var levelFactor = this.factor * radius * ((j + 1) / this.levels);
      g.selectAll('.levels')
        .data(allAxis)
        .enter()
        .append('svg:line')
        .attr('x1', (d, i) => levelFactor * (1 - this.factor * Math.sin(i * this.radians / total)))
        .attr('y1', (d, i) => levelFactor * (1 - this.factor * Math.cos(i * this.radians / total)))
        .attr('x2', (d, i) => levelFactor * (1 - this.factor * Math.sin((i + 1) * this.radians / total)))
        .attr('y2', (d, i) => levelFactor * (1 - this.factor * Math.cos((i + 1) * this.radians / total)))
        .attr('class', 'line')
        .style('stroke', '#C2CFE0')
        .style('stroke-opacity', '1')
        .style('stroke-width', '1px')
        .attr('transform', `translate(${this.width / 2 - levelFactor}, ${this.height / 2 - levelFactor})`);
    }

    var axis = g
      .selectAll('.axis')
      .data(allAxis)
      .enter()
      .append('g')
      .attr('class', 'axis');

    var axisLevel = this.factor * radius * (1 / this.levels);

    axis
      .append('line')
      .attr('x1', (d, i) => axisLevel * (1 - this.factor * Math.sin(i * this.radians / total)) + this.width / 2 - axisLevel)
      .attr('y1', (d, i) => axisLevel * (1 - this.factor * Math.cos(i * this.radians / total)) + (this.height / 2 - axisLevel))
      .attr('x2', (d, i) => this.width / 2 * (1 - this.factor * Math.sin(i * this.radians / total)))
      .attr('y2', (d, i) => this.height / 2 * (1 - this.factor * Math.cos(i * this.radians / total)))
      .attr('class', 'line')
      .style('stroke', '#C2CFE0')
      .style('stroke-width', '1px');

    if(this.chartType === 'main') {
      // Axis labels
      axis
        .append('text')
        .attr('class', 'legend')
        .text(d => d)
        .style('font-family', 'sans-serif')
        .style('font-size', '13px')
        .attr('text-anchor', 'middle')
        .attr('dy', '1.5em')
        .attr('transform', d => {
          //fugly
          if(d === 'Orthographe' && this.width < 250) {
            return 'translate(4, -10)';
          }
          else {
            return 'translate(0, -10)';
          }
        })
        .attr('x', (d, i) => this.width / 2 * (1 - this.factorLegend * Math.sin(i * this.radians / total)) - 63 * Math.sin(i * this.radians / total))
        .attr('y', (d, i) => this.height / 2 * (1 - Math.cos(i * this.radians / total)) - 20 * Math.cos(i * this.radians / total) - 5);

      //Text indicating at what grade each level is
      for(var k = 0; k < this.levels; k++) {
        var levelFactorText = this.factor * radius * ((k + 1) / this.levels);
        g.selectAll('.levels')
          .data([1])
          .enter()
          .append('rect')
          .attr('x', () => levelFactorText * (1 - this.factor * Math.sin(0)))
          .attr('y', () => levelFactorText * (1 - this.factor * Math.cos(0)))
          .attr('width', '19')
          .attr('height', '15')
          .style('stroke-width', 0.1)
          .style('stroke', '#4C5862')
          .style('fill', '#FAFAFA')
          .attr('transform', `translate(${this.width / 2 - levelFactorText + this.toRight}, ${this.height / 2 - levelFactorText - 2})`);

        g.selectAll('.levels')
          .data([1])
          .enter()
          .append('svg:text')
          .attr('x', () => levelFactorText * (1 - this.factor * Math.sin(0)))
          .attr('y', () => levelFactorText * (1 - this.factor * Math.cos(0)))
          .attr('class', 'legend')
          .style('font-family', 'sans-serif')
          .style('font-size', '10px')
          .attr('transform', `translate(${this.width / 2 - levelFactorText - 3}, ${this.height / 2 - levelFactorText + 9})`)
          .attr('fill', '#4C5862')
          .text(this.levelLabels[k]);
      }
    }

    g.selectAll('.nodes').data(data, (l, m) => {
      dataValues.push([
        this.width / 2 * (1 - parseFloat(Math.max(l.value, 0)) / this.maxValue * this.factor * Math.sin(m * this.radians / total)),
        this.height / 2 * (1 - parseFloat(Math.max(l.value, 0)) / this.maxValue * this.factor * Math.cos(m * this.radians / total))
      ]);
    });
    dataValues.push(dataValues[0]);
    g.selectAll('.area')
      .data([dataValues])
      .enter()
      .append('polygon')
      .attr('class', `radar-chart-serie${series}`)
      .style('stroke-width', '1px')
      .style('stroke', '#00a569')
      .attr('points', d => {
        var str = '';
        for(var pti = 0; pti < d.length; pti++) {
          str = `${str + d[pti][0]},${d[pti][1]} `;
        }
        return str;
      })
      .style('fill', '#00a569')
      .style('fill-opacity', this.opacityArea);
    //.on('mouseover', function(d) {
    // var z = "polygon."+d3.select(this).attr("class");
    // g.selectAll("polygon")
    //  .transition(200)
    //  .style("fill-opacity", 0.1);
    // g.selectAll(z)
    //  .transition(200)
    //  .style("fill-opacity", .7);
    //})
    //.on('mouseout', function() {
    // g.selectAll("polygon")
    //  .transition(200)
    //  .style("fill-opacity", this.opacityArea);
    //});

    var tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'toolTip');

    // Add nodes
    if(this.chartType === 'main') {
      g.selectAll('.nodes')
        .data(data)
        .enter()
        .append('svg:circle')
        .attr('class', `radar-chart-serie${series}`)
        .attr('r', this.radius)
        .attr('alt', n => Math.max(n.value, 0))
        .attr('cx', (o, p) => {
          dataValues.push([
            this.width / 2 * (1 - parseFloat(Math.max(o.value, 0)) / this.maxValue * this.factor * Math.sin(p * this.radians / total)),
            this.height / 2 * (1 - parseFloat(Math.max(o.value, 0)) / this.maxValue * this.factor * Math.cos(p * this.radians / total))
          ]);
          return this.width / 2 * (1 - Math.max(o.value, 0) / this.maxValue * this.factor * Math.sin(p * this.radians / total));
        })
        .attr('cy', (q, r) => this.height / 2 * (1 - Math.max(q.value, 0) / this.maxValue * this.factor * Math.cos(r * this.radians / total)))
        .attr('data-id', s => s.name)
        .style('fill', this.color(series))
        .style('stroke-width', '2px')
        .style('stroke', this.color(series))
        .style('fill-opacity', 0.9)
        .on('mouseover', d => {
          tooltip
            .style('left', `${d3.event.pageX - 40}px`)
            .style('top', `${d3.event.pageY - 80}px`)
            .style('display', 'inline-block')
            .html(`${d.name}<br><span>${d.value}</span>`);
        })
        .on('mouseout', () => {
          tooltip.style('display', 'none');
        });
    }
  } //end: render()
}
