import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'dark-pool-domains-to-ignore',
  template: require('./dark-pool-domains-to-ignore.html')
})
export class DarkPoolDomainsToIgnoreComponent {
  static parameters = [BsModalRef];
  constructor(bsModalRef: BsModalRef) {
    this.bsModalRef = bsModalRef;
    this.okClicked = false;
    this.newDomain = '';
  }

  addDomain() {
    var domainsToAdd = this.newDomain.split(',');
    domainsToAdd.forEach(domain => {
      this.domainsToIgnore.push(domain.trim());
    });
    this.newDomain = '';
  }

  removeDomain(index) {
    this.domainsToIgnore.splice(index, 1);
  }

  ok() {
    this.okClicked = true;
    this.bsModalRef.hide();
  }

  cancel() {
    this.bsModalRef.hide();
  }
}
