import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { userRoles } from '../app.constants';
import { UserService } from '../../components/auth/user.service';
import { ImpersonationService } from '../../components/auth/impersonation.service';

@Component({
  selector: 'admin',
  template: require('./admin.html'),
  styles: [require('./admin.scss')]
})
export class AdminComponent implements OnInit {
  @ViewChild('userTmpl', { static: true }) userTmpl: TemplateRef<any>;
  @ViewChild('orgTmpl', { static: true }) orgTmpl: TemplateRef<any>;
  @ViewChild('receiveEmailsTmpl', { static: true }) receiveEmailsTmpl: TemplateRef<any>;
  @ViewChild('impersonateTmpl', { static: true }) impersonateTmpl: TemplateRef<any>;

  static parameters = [UserService, ImpersonationService];
  constructor(userService: UserService, impersonation: ImpersonationService) {
    this.userRoles = userRoles;
    this.userService = userService;
    this.impersonation = impersonation;

    this.rows = [];
    this.loading = false;

    //TODO: Add pagingation sizes in a custom footer dropdown???
    //paginationSizes: [10, 25, 50],

    //Setup grid
    this.page = {
      number: 0,
      size: 10,
      sort: {
        field: '_id',
        direction: 'asc'
      },
      total: 0,
      searchString: '',
      filterOrganization: null, //not in use
      filterRole: 'all'
    };
  }

  ngOnInit() {
    this.columns = [
      { prop: '_id', name: 'Id', width: 65 },
      { prop: 'name', cellTemplate: this.userTmpl },
      {
        cellTemplate: this.orgTmpl,
        prop: 'Organization.name',
        name: 'Organization',
        sortable: false
      },
      { prop: 'email' },
      {
        cellTemplate: this.receiveEmailsTmpl,
        prop: 'userOptions.receiveEmails',
        name: 'Receive Emails',
        width: 50
      },
      { prop: 'role', cellClass: 'capitalize', width: 50 },
      {
        cellTemplate: this.impersonateTmpl,
        name: 'Impersonate',
        sortable: false,
        width: 50
      }
    ];

    //Reload saved filter
    var filter = JSON.parse(localStorage.getItem('adminViewFilter'));
    if(filter) {
      this.page = filter;
      this.getPage({ offset: this.page.number });
    }
    else {
      this.getPage({ offset: 0 });
    }
  }

  ngOnDestroy() {
    //Save filter
    localStorage.setItem('adminViewFilter', JSON.stringify(this.page));
  }

  //Get a page of users
  getPage(pageInfo) {
    this.loading = true;
    this.page.number = pageInfo.offset;
    this.userService
      .getPage(this.page)
      .toPromise()
      .then(
        page => {
          this.page.total = page.totalNum;
          this.rows = page.users;
          this.loading = false;
        },
        //Error Handling...
        error => {
          console.log('Error getting users page', error);
          this.loading = false;
        }
      );
  }

  onSort(event) {
    this.page.sort.field = event.column.prop;
    this.page.sort.direction = event.newValue;
    this.getPage({ offset: this.page.number });
  }

  //Filter users based on search
  filterUsers() {
    this.getPage({ offset: 0 });
  }

  filterRoleChanged(role) {
    this.page.filterRole = role;
    this.getPage({ offset: 0 });
  }

  clearFilter() {
    this.page.filterRole = 'all';
    this.page.searchString = '';
    this.getPage({ offset: 0 });
  }

  //Activate impersonation for this user.
  impersonateUser(user) {
    this.impersonation.impersonateUser(user);
  }
}
