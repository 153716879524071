import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'dark-pool-bot-details',
  template: require('./dark-pool-bot-details.html')
})
export class DarkPoolBotDetailsComponent {
  static parameters = [BsModalRef];
  constructor(bsModalRef: BsModalRef) {
    this.bsModalRef = bsModalRef;
    this.filterOn = false;
  }

  ngAfterViewInit() {
    //Init select field.
    this.botSummary.forEach(item => {
      item.selected = false;
    });

    this.buildChartData();
  }

  buildChartData() {
    this.chartData = {
      name: 'Form Submits by Day',
      columns: [],
      values: []
    };

    //Build list of the forms to show.
    var formsToShow = {};
    this.selectedFormCount = 0;
    if(this.filterOn) {
      this.botSummary.forEach(item => {
        formsToShow[item.assetname] = item.selected;
        if(item.selected) this.selectedFormCount++;
      });
    }

    var dayCounts = this.botDetails.reduce((x, cur) => {
      if(!x[cur.activityday]) x[cur.activityday] = 0;

      //If the filter is on, check each against the list of forms to show.
      if(this.filterOn) {
        if(formsToShow[cur.assetname]) {
          x[cur.activityday] = x[cur.activityday] + 1;
        }
      }
      else {
        x[cur.activityday] = x[cur.activityday] + 1;
      }

      return x;
    }, {});

    for(const key in dayCounts) {
      const count = dayCounts[key];
      this.chartData.columns.push(key);
      this.chartData.values.push(count);
    }
  }

  filterByRow(row) {
    row.selected = !row.selected;
    this.filterOn = this.botSummary.some(item => item.selected);
    this.buildChartData();
  }

  done() {
    this.bsModalRef.hide();
  }
}
