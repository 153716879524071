// @flow
import { Injectable } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { LoggerService } from '../../../shared/logger.service';

import moment from 'moment-timezone';

import { getLocalEquivalentFromMoment } from '../../../../components/util';

@Injectable({
  providedIn: 'root'
})
export class CampaignScheduleService {
  static parameters = [HttpClient, DecimalPipe, LoggerService];
  constructor(http: HttpClient, decimalPipe: DecimalPipe, loggerService: LoggerService) {
    this.http = http;
    this.decimalPipe = decimalPipe;
    this.loggerService = loggerService;
  }

  //Retrieve the campaign's schedule info from the backend and process.
  getSchedule(campaign, schedule, totals) {
    totals.expectedSends = 0;
    totals.excludedFromCampaign = 0;
    totals.blockedCount = 0;

    const promise = new Promise((resolve, reject) => {
      this.http
        .get(`/core-api/api/campaign/${campaign.campaignId}/schedule`)
        .toPromise()
        .then(
          campaignSchedule => {
            this.processSchedule(campaign, schedule, campaignSchedule, totals);
            resolve();
          },
          //Error Handling...
          error => {
            this.loggerService.logMessage('Error getting Campaign schedule', 'error', error);
            console.error('There was an error!', error);
            reject(error);
          }
        );
    });

    return promise;
  } //end: getSchedule()

  processSchedule(campaign, schedule, campaignSchedule, totals) {
    var prevDay = '';
    var dayCount = 0;

    schedule.totalNumBatches = campaignSchedule.length;

    campaignSchedule.forEach(batch => {
      if(batch.actualSentSize > 0 || campaignSchedule.length < 500) {
        // For long campaigns, hide rows with a batch size of 0
        var dateTz = moment.tz(batch.scheduledSendDateTime, campaign.timezone); //Localize timezone

        if(prevDay !== dateTz.date()) {
          prevDay = dateTz.date();
          dayCount++;

          schedule.push({
            date: getLocalEquivalentFromMoment(dateTz),
            daySchedule: [],
            isOpen: false,
            dayTotals: {
              plannedBatchSize: null,
              actualSentSize: null,
              blockedCount: null
            },
            dayCount
          });
        }

        var obj = {
          batchId: batch.batchId,
          actualSentSize: batch.actualSentSize,
          plannedBatchSize: batch.plannedBatchSize,
          blockedCount: batch.blockedCount,
          scheduledSendDateTime: null,
          processingEnded: null,
          dayCount,
          status: batch.status
        };

        //Convert displayed dates to local timezone equivalents.
        obj.scheduledSendDateTime = getLocalEquivalentFromMoment(dateTz);

        if(batch.processingEnded) {
          dateTz = moment.tz(batch.processingEnded, campaign.timezone);
          obj.processingEnded = getLocalEquivalentFromMoment(dateTz);
          totals.expectedSends += batch.plannedBatchSize ? batch.plannedBatchSize : batch.actualSentSize;
          totals.blockedCount = typeof batch.blockedCount !== 'undefined' ? batch.blockedCount : totals.blockedCount; //take last value
        }

        schedule[dayCount - 1].daySchedule.push(obj);

        // Add to day totals
        if(obj.plannedBatchSize !== null) schedule[dayCount - 1].dayTotals.plannedBatchSize += obj.plannedBatchSize;
        if(obj.actualSentSize !== null) schedule[dayCount - 1].dayTotals.actualSentSize += obj.actualSentSize;
        if(obj.blockedCount !== null) schedule[dayCount - 1].dayTotals.blockedCount += obj.blockedCount;
      }
    });

    totals.excludedFromCampaign = totals.sends - totals.deliveries;
    totals.percentSent = totals.deliveries / totals.sends * 100;
    totals.percentExcluded = totals.excludedFromCampaign / totals.sends * 100;

    if(totals.percentSent < 100) {
      totals.percentSent = this.decimalPipe.transform(totals.percentSent, '1.1-1');
    }
    else {
      totals.percentSent = this.decimalPipe.transform(100, '1.0-0');
    }
  }

  // Build the data for the schedule charts
  getScheduleChartData(schedule) {
    var scheduleChartData = {
      results: [],
      colors: {
        scheduledSends: 'rgba(0, 147, 208, 0.5)',
        sent: '#0193cf'
      }
    };

    schedule.forEach(day => {
      var obj = {
        date: day.date,
        scheduledSends: day.dayTotals.plannedBatchSize,
        sent: day.dayTotals.actualSentSize
      };
      scheduleChartData.results.push(obj);
    });

    return scheduleChartData;
  } // end: getScheduleChartData()
}
