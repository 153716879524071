import { Component, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { Clipboard } from '@angular/cdk/clipboard';
import { GeneratorService } from '../../generator/shared/generator.service';
import { AuthService } from '../../../components/auth/auth.service';

@Component({
  selector: 'email-details',
  template: require('./email-details.html')
})
export class EmailDetailsComponent {
  static parameters = [BsModalRef, HttpClient, Clipboard, GeneratorService, AuthService];
  constructor(bsModalRef: BsModalRef, http: HttpClient, clipboard: Clipboard, generatorService: GeneratorService, authService: AuthService) {
    this.bsModalRef = bsModalRef;
    this.http = http;
    this.clipboard = clipboard;
    this.generatorService = generatorService;
    this.authService = authService;

    this.loading = false;
    this.htmlText = null;
    this.plainText = null;
    this.emailDetails = null;
    this.copySubjectLineMsg = '';
    this.copyPlainTextMsg = '';
    this.copyStrippedPlainTextMsg = '';
    this.copyHtmlBodyMsg = '';
    this.copyStrippedHtmlBodyMsg = '';
  }

  ngOnInit() {
    console.log(`---> Get email details for emailId: ${this.emailId}, orgId: ${this.orgId}`); //debug
    this.loading = true;
    this.authService.getCurrentUser().then(user => {
      this.currentUser = user;
    });

    this.http
      .get(`/eloqua-api/api/assets/email/${this.emailId}?depth=complete&orgId=${this.orgId}`)
      .toPromise()
      .then(
        email => {
          this.loading = false;
          console.log('------------------------------------------------------'); //debug
          console.log(`---> Email.htmlContent.type: ${email.htmlContent.type}`); //debug
          console.log(`---> Email.htmlContent.contentSource: ${email.htmlContent.contentSource}`); //debug
          console.log('---> Email details:', email); //debug

          //type:
          // -- StructuredHtmlContent -- content in htmlBody?
          // -- ResponsiveHtmlContent -- content in htmlBody?
          // -- RawHtmlContent -- content in html?

          //contentSource:
          // -- editor -- content in htmlBody?
          // -- responsive -- content in htmlBody?
          // -- upload -- content in html?

          //contentSections:
          //

          //plainText:
          //

          this.emailDetails = email;
          this.initializeEmailDetails(this.emailDetails);
        },
        //Error Handling...
        error => {
          //this.loading = false;
          console.log('Error getting email', error);

          // Try refreshing eloqua token and trying again
          this.authService
            .verifyAuth(this.currentUser._id)
            .toPromise()
            .then(
              () => {
                // Token refreshed successfully, so try getting email asset again
                this.http
                  .get(`/eloqua-api/api/assets/email/${this.emailId}?depth=complete&orgId=${this.orgId}`)
                  .toPromise()
                  .then(
                    email => {
                      this.loading = false;
                      this.emailDetails = email;
                      this.initializeEmailDetails(this.emailDetails);
                    },
                    error => {
                      this.loading = false;
                      console.log('Error getting email', error);
                  })
              },
              //Error Handling...
              error => {
                console.log('Error verifying auth', error);
                this.loading = false;
              }
            );
          });
  }

  initializeEmailDetails(emailDetails) {
    //If we have plainText
    if(typeof this.emailDetails.plainText !== 'undefined') {
      this.plainText = this.emailDetails.plainText;

      this.generatorService.strip(this.emailDetails.plainText).then(result => {
        this.strippedPlainText = result.strippedText;
      });
    }

    //If we have htmlContent.html
    if(typeof this.emailDetails.htmlContent.html !== 'undefined') {
      this.htmlText = this.emailDetails.htmlContent.html;
    }

    //If we have htmlContent.htmlBody
    if(typeof this.emailDetails.htmlContent.htmlBody !== 'undefined') {
      this.htmlText = this.emailDetails.htmlContent.htmlBody;
    }

    //Strip the htmlText, if we have it.
    if(this.htmlText) {
      this.generatorService.strip(this.htmlText).then(result => {
        this.strippedHtmlText = result.strippedText;
      });
    }
  }

  copySubjectLine() {
    this.clipboard.copy(this.emailDetails.subject);
    this.copySubjectLineMsg = 'Subject Line copied to the clipboard.';
    setTimeout(() => {
      this.copySubjectLineMsg = '';
    }, 1800);
  }

  copyPlainText() {
    this.clipboard.copy(this.emailDetails.plainText);
    this.copyPlainTextMsg = 'Plain Text Body copied to the clipboard.';
    setTimeout(() => {
      this.copyPlainTextMsg = '';
    }, 1800);
  }

  copyStrippedPlainText() {
    this.clipboard.copy(this.strippedPlainText);
    this.copyStrippedPlainTextMsg = 'Plain Text Body (Stripped) copied to the clipboard.';
    setTimeout(() => {
      this.copyStrippedPlainTextMsg = '';
    }, 1800);
  }

  copyHtmlBody() {
    this.clipboard.copy(this.emailDetails.htmlContent.root);
    this.copyHtmlBodyMsg = 'HTML Body copied to the clipboard.';
    setTimeout(() => {
      this.copyHtmlBodyMsg = '';
    }, 1800);
  }

  copyStrippedHtmlBody() {
    this.clipboard.copy(this.strippedHtmlText);
    this.copyStrippedHtmlBodyMsg = 'HTML Body (Stripped) copied to the clipboard.';
    setTimeout(() => {
      this.copyStrippedHtmlBodyMsg = '';
    }, 1800);
  }

  done() {
    this.bsModalRef.hide();
  }
}
