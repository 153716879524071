import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImpersonationService {
  @Output() impersonationChanged = new EventEmitter(true);

  constructor() {
    this._impersonating = false;
    this._impersonatingUser = null;

    //Add a demo mode too
    this._demoMode = false;
  }

  isImpersonating() {
    return this._impersonating;
  }

  get impersonatingUser() {
    return this._impersonatingUser;
  }

  set impersonatingUser(user) {
    this._impersonatingUser = user;
  }

  /**
   * Impersonate user
   *
   */
  impersonateUser(user, openCampaign) {
    this.stopDemoMode();

    this._impersonating = true;
    this._impersonatingUser = user;

    var impersonation = { user, openCampaign };
    this.impersonationChanged.emit(impersonation);
  }

  /**
   * Stop impersonating
   *
   */
  stopImpersonating() {
    this._impersonating = false;
    this._impersonatingUser = null;
    this.impersonationChanged.emit();
  }

  // Handle the demo mode.
  isDemoMode() {
    return this._demoMode;
  }

  startDemoMode() {
    this._demoMode = true;
  }

  stopDemoMode() {
    this._demoMode = false;
  }
}
