import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../components/auth/auth-guard.service';

import { AlertModule } from 'ngx-bootstrap/alert';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { DirectivesModule } from '../../components/directives.module';
import { DarkPoolModule } from '../dark-pool/dark-pool.module';

import { MainComponent } from './main.component';
import { DarkPoolHomeComponent } from '../dark-pool/shared/dark-pool-home/dark-pool-home.component';
import { GlobalFunnelComponent } from './global-funnel/global-funnel.component';

import { NestedCirclesComponent } from '../../components/data-viz/nested-circles-chart/nested-circles-chart.component';

export const ROUTES: Routes = [
  {
    path: 'home',
    component: MainComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forChild(ROUTES),
    AlertModule.forRoot(), PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    DirectivesModule,
    DarkPoolModule
  ],
  declarations: [
    NestedCirclesComponent,
    MainComponent,
    DarkPoolHomeComponent,
    GlobalFunnelComponent
  ],
  exports: [
    MainComponent,
    DarkPoolHomeComponent,
    GlobalFunnelComponent
  ]
})
export class MainModule {}
