// @flow
import { Component, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { AuthService } from '../../../components/auth/auth.service';
import { OrgService } from '../../../components/auth/org.service';
import { GeneratorService } from '../shared/generator.service';

import { AddBrandVoiceComponent } from '../add-brand-voice/add-brand-voice.component';

@Component({
  selector: 'brand-voices',
  template: require('./brand-voices.html')
})
export class BrandVoicesComponent implements OnInit {
  organization = {
    enableBrandVoices: false,
    generatorBrandVoices: []
  };

  static parameters = [BsModalService, AuthService, OrgService, GeneratorService];
  constructor(modalService: BsModalService, authService: AuthService, orgService: OrgService, generatorService: GeneratorService) {
    this.modalService = modalService;
    this.authService = authService;
    this.orgService = orgService;
    this.generatorService = generatorService;
  }

  ngOnInit() {
    this.alerts = [];
    this.creatingBrandVoice = false;
    this.updatingBrandVoice = false;
    this.languages = this.generatorService.getLanguages();
    this.authService.getCurrentOrg().then(org => {
      if(org) {
        this.organization = org;
        this.initBrandVoice();
      }
    });

    //If the page is loaded directly.
    this.orgSub = this.authService.currentOrgChanged.subscribe(org => {
      if(this.updatingBrandVoice) return;
      this.organization = org;
      this.initBrandVoice();
    });
  }

  initBrandVoice() {
    //Generator Brand Voices init.
    this.brandVoiceCollapsed = [];
    this.inputsCollapsed = [];
    if(!this.organization.generatorBrandVoices) {
      this.organization.generatorBrandVoices = [];
    }
    else {
      this.organization.generatorBrandVoices.forEach((brandVoice, i) => {
        //init collapse
        this.brandVoiceCollapsed[i] = true;
        this.inputsCollapsed[i] = true;
      });
    }
  }

  ngOnDestroy() {
    if(this.orgSub) this.orgSub.unsubscribe();
  }

  allowEditing() {
    return this.authService.hasRole('user');
  }

  updateBrandVoices() {
    this.alerts = [];
    this.updatingBrandVoice = true;
    this.orgService
      .update(this.organization)
      .toPromise()
      .then(
        () => {
          this.alerts.push({ type: 'success', msg: 'Organization successfully updated.' });
          this.authService.currentOrg = this.organization;
          setTimeout(() => {
            this.updatingBrandVoice = false;
          });
        },
        //Error Handling...
        error => {
          this.alerts.push({ type: 'warning', msg: 'Error updating organization.' });
          console.log('Error updating organization', error);
        }
      );
  }

  addBrandVoice() {
    this.alerts = [];
    const initialState = {};
    this.bsModalRef = this.modalService.show(AddBrandVoiceComponent, { initialState, class: 'modal-lg' });
    var sub = this.modalService.onHidden.subscribe(() => {
      if(this.bsModalRef.content.okClicked) {
        this.creatingBrandVoice = true;
        this.newBrandVoice = this.bsModalRef.content.newBrandVoice;

        //Create the brand voice
        this.generatorService.createBrandVoice(this.newBrandVoice).then(response => {
          var brandVoice = `<strong>Brand Voice & Tone Guidelines for ${this.newBrandVoice.name}</strong><br><br>`;
          brandVoice += response[0].message.content.replace(/(?:\r\n|\r|\n)/g, '<br>'); //convert newlines (\n) to <br>
          this.newBrandVoice.description = brandVoice; 

          //console.log('------> Save the newBrandVoice:', this.newBrandVoice); //debug

          this.organization.generatorBrandVoices.push(this.newBrandVoice);
          this.brandVoiceCollapsed[this.organization.generatorBrandVoices.length - 1] = false;
          this.inputsCollapsed[this.organization.generatorBrandVoices.length - 1] = false;
          this.updateBrandVoices(); //save changes

          this.creatingBrandVoice = false;
        });
      }
      if(sub) sub.unsubscribe();
    });
  }

  removeBrandVoice(index) {
    this.alerts = [];
    if(confirm('Are you sure you want to remove this Brand Voice?')) {
      this.organization.generatorBrandVoices.splice(index, 1);
      this.updateBrandVoices(); //save changes
    }
  }
}
