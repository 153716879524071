import { Component, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'fm-priority-mappings',
  template: require('./fm-priority-mappings.html')
})
export class FmPriorityMappingsComponent {
  static parameters = [BsModalRef];
  constructor(bsModalRef: BsModalRef) {
    this.bsModalRef = bsModalRef;
    this.okClicked = false;
  }

  ngOnInit() {
    //console.log('---------> Init fm-priority-mappings:', this.fmConfig, this.priority); //debug
    this.workingPriority = cloneDeep(this.priority);
    if(typeof this.workingPriority.fieldMappings === 'undefined') {
      this.workingPriority.fieldMappings = [];
    }
  }

  addMapping() {
    this.workingPriority.fieldMappings.push({
      product: '',
      region: '',
      campaignType: ''
    });
  }

  removeMapping(i) {
    this.workingPriority.fieldMappings.splice(i, 1);
  }

  ok() {
    this.okClicked = true;

    //Update with the working copy
    var idx = null;
    this.fmConfig.priorities.forEach((priority, i) => {
      if(priority.value === this.workingPriority.value) {
        idx = i;
      }
    });

    if(idx !== null) this.fmConfig.priorities[idx].fieldMappings = this.workingPriority.fieldMappings;

    this.bsModalRef.hide();
  }

  cancel() {
    this.bsModalRef.hide(); 
  }
}
