// @flow
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

type UserType = {
  // TODO: use Mongoose model
  id?: string,
  _id?: string,
  name?: string,
  email?: string
};

@Injectable({
  providedIn: 'root'
})
export class UserService {
  baseUrl = '/api/users';

  static parameters = [HttpClient];
  constructor(http: HttpClient) {
    this.http = http;
  }

  query(): Observable<UserType[]> {
    return this.http.get(`${this.baseUrl}/`);
  }

  get(user = { id: 'me' }): Observable<UserType> {
    return this.http.get(`${this.baseUrl}/${user.id || user._id}`);
  }

  updateMe(user): Observable<Object> {
    return this.http.put(`${this.baseUrl}/updateMe`, user);
  }

  update(user): Observable<Object> {
    return this.http.put(`${this.baseUrl}/${user.id || user._id}/update`, user);
  }

  /**
   * Gets page of users
   *
   * @param  {Object}
   * @return {Promise}
   */
  getPage(page): Observable<UserType> {
    var params = new HttpParams();
    params = params.append('sortField', page.sort.field);
    params = params.append('sortDirection', page.sort.direction);
    params = params.append('searchString', page.searchString);
    params = params.append('filterOrganization', page.filterOrganization);
    params = params.append('filterRole', page.filterRole !== 'all' ? page.filterRole : '');

    return this.http.get(`${this.baseUrl}/getPage/${page.number}/${page.size}`, { params });
  }
}
