import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { QuillModule } from 'ngx-quill';

import { DirectivesModule } from '../../components/directives.module';

import { AddBrandVoiceComponent } from './add-brand-voice/add-brand-voice.component';
import { GeneratorComponent } from './generator.component';
import { BrandVoicesComponent } from './brand-voices/brand-voices.component';
import { MessageGeneratorComponent } from './message-generator/message-generator.component';
import { SubjectLineAnalyzerComponent } from './subject-line-analyzer/subject-line-analyzer.component';
import { SubjectLineGeneratorComponent } from './subject-line-generator/subject-line-generator.component';

const generatorRoutes: Routes = [
  {
    path: 'generator',
    component: GeneratorComponent
  }
];

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    RouterModule.forChild(generatorRoutes),
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    CollapseModule.forRoot(),
    PopoverModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline'/*, 'strike'*/],    // toggled buttons
          //['blockquote', 'code-block'],
          //[{ 'header': 1 }, { 'header': 2 }],             // custom button values
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          //[{ 'script': 'sub'}, { 'script': 'super' }],    // superscript/subscript
          [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
          //[{ 'direction': 'rtl' }],                       // text direction
          [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          //[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          //[{ 'color': [] }, { 'background': [] }],        // dropdown with defaults from theme
          [{ 'font': [] }],
          [{ 'align': [] }],
          ['clean'],                                        // remove formatting button
          ['link'/*, 'image', 'video'*/]                    // link and image, video
        ]
      }
    }),
    DirectivesModule,
    BrowserAnimationsModule
  ],
  declarations: [
    AddBrandVoiceComponent,
    GeneratorComponent,
    BrandVoicesComponent,
    MessageGeneratorComponent,
    SubjectLineAnalyzerComponent,
    SubjectLineGeneratorComponent
  ],
  entryComponents: [
    AddBrandVoiceComponent
  ]
})
export class GeneratorModule {}
