//@flow
import { Component, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { Campaign } from '../campaign';
import { PersonaService } from '../../../who/shared/persona.service';

import { PersonaSelectorComponent } from './persona-selector/persona-selector.component';
import { cloneDeep } from 'lodash';
import { ngxCsv } from 'ngx-csv/ngx-csv';

import * as d3 from 'd3';

@Component({
  selector: 'campaign-personas',
  template: require('./campaign-personas.html')
})
export class CampaignPersonasComponent {
  @Input() campaign: Campaign;
  @Input() campaignLocal;
  @Input() results;
  @Input() visible;
  @Input() folderName;

  static parameters = [PersonaService, BsModalService];
  constructor(personaService: PersonaService, bsModalService: BsModalService, bsModalRef: BsModalRef) {
    this.personaService = personaService;
    this.bsModalRef = bsModalRef;
    this.bsModalService = bsModalService;
  }

  ngOnInit() {
    this.loadingPersonas = true;
    this.rateToggle = 'openRate';

    this.init();
  }

  init() {
    this.colorScale = d3
      .scaleLinear()
      .range(['#eee', '#81c6e3', '#0193cf']);

    this.personas = [];

    if(this.folderName) {
      this.buildCampaignPersonasFromFile();
    }
    else {
      this.personaService.getCampaignPersonas(this.campaign.campaignId, this.campaign.organizationId).then(personas => {
        this.personasLocal = personas;
        this.sortPersonas();

        // Get min and max values
        var minValue = 1;
        var maxValue = 0;

        personas.forEach(persona => {
          if(persona.personaStats.openRate < minValue) minValue = persona.personaStats.openRate;
          if(persona.personaStats.openRate > maxValue) maxValue = persona.personaStats.openRate;

          if(persona.personaStats.clickthroughRate < minValue) minValue = persona.personaStats.clickthroughRate;
          if(persona.personaStats.clickthroughRate > maxValue) maxValue = persona.personaStats.clickthroughRate;
        });

        this.colorScale.domain([minValue, (minValue + maxValue) / 2, maxValue]);

        var overallStats = {
          openRate: this.results.latest.totals.openRate,
          clickthroughRate: this.results.latest.totals.clickthroughRate,
          clickToOpenRate: this.results.latest.totals.clickthroughs / this.results.latest.totals.opens
        };

        this.personaService.setRateComparisonArrows(overallStats, this.personasLocal);
        this.getSelectedPersonas();
      });
    }
  }

  sortPersonas() {
    this.personasLocal.sort((a, b) => {
      if(a.contactCount > b.contactCount) return -1;
      else if(a.contactCount < b.contactCount) return 1;
      return 0;
    });
  }

  selectPersonas() {
    var initialState = {
      personas: cloneDeep(this.personasLocal),
      campaignId: this.campaign.campaignId
    };

    this.bsModalRef = this.bsModalService.show(PersonaSelectorComponent, { initialState });

    var sub = this.bsModalService.onHidden.subscribe(() => {
      if(this.bsModalRef.content.okClicked) {
        this.loadingPersonas = true;
        this.personasLocal = this.bsModalRef.content.personas;
        this.loadingPersonas = false;
      }

      if(sub) sub.unsubscribe();
    });
  }

  getSelectedPersonas() {
    this.personaService.getSelectedPersonasForCampaign(this.campaign.campaignId).then(selectedPersonas => {
      if(selectedPersonas && selectedPersonas.length > 0) {
        this.personasLocal.forEach(persona => {
          persona.isSelected = false;
          selectedPersonas.forEach(selectedPersona => {
            if(selectedPersona.personaId == persona.personaId) {
              persona.isSelected = true;
            }
          });
        });
      }
      // If no saved selection, default to show the top 8
      else {
        this.personasLocal.forEach((persona, i) => {
          if(i < 8) persona.isSelected = true;
          else persona.isSelected = false;
        });
      }

      this.loadingPersonas = false;
    },
    err => {
      console.log('Error getting selected personas', err);
      // Show top 8 by population
      this.personasLocal.forEach((persona, i) => {
        if(i < 8) persona.isSelected = true;
        else persona.isSelected = false;
      });

      this.loadingPersonas = false;
    });
  }

  exportPersonaData() {
    var filename = `Persona_data_for_${this.campaign.name}`;
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      noDownload: false,
      headers: [
        'Persona Name',
        'Contact Count',
        '% of Campaign Population',
        'Total Distinct Sends',
        'Total Distinct Opens',
        'Total Distinct Clickthroughs',
        'Open Rate',
        'Clickthrough Rate',
        'Click to Open Rate'
      ]
    };

    this.personaService.getExportData(this.personasLocal, this.campaign.uniqueContactCount).then(exportData => {
      new ngxCsv(exportData, filename, options);
    });
  }

  buildCampaignPersonasFromFile() {
    this.personaService.getCampaignPersonasFromFile(this.folderName).then(personas => {
      this.personasLocal = personas;
      this.sortPersonas();

      // Get min and max values
      var minValue = 1;
      var maxValue = 0;

      personas.forEach(persona => {
        if(persona.personaStats.openRate < minValue) minValue = persona.personaStats.openRate;
        if(persona.personaStats.openRate > maxValue) maxValue = persona.personaStats.openRate;

        if(persona.personaStats.clickthroughRate < minValue) minValue = persona.personaStats.clickthroughRate;
        if(persona.personaStats.clickthroughRate > maxValue) maxValue = persona.personaStats.clickthroughRate;
      });

      this.colorScale.domain([minValue, (minValue + maxValue) / 2, maxValue]);
      this.loadingPersonas = false;
    });
  }
}
