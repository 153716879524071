// @flow
import { Component, Input, SimpleChanges } from '@angular/core';

import { ImpersonationService } from '../../../../components/auth/impersonation.service';

import { Campaign } from '../campaign';
import { CampaignService } from '../campaign.service';
import { CampaignScheduleService } from './campaign-schedule.service';
import { CampaignFilesService } from '../campaign-files.service';

import moment from 'moment-timezone';

@Component({
  selector: 'campaign-schedule',
  template: require('./campaign-schedule.html')
})
export class CampaignScheduleComponent {
  @Input() campaign: Campaign;
  @Input() results;
  @Input() visible;
  @Input() folderName;

  static parameters = [CampaignService, CampaignScheduleService, CampaignFilesService, ImpersonationService];
  constructor(campaignService: CampaignService, campaignScheduleService: CampaignScheduleService, campaignFilesService: CampaignFilesService, impersonationService: ImpersonationService) {
    this.campaignService = campaignService;
    this.campaignScheduleService = campaignScheduleService;
    this.campaignFilesService = campaignFilesService;
    this.impersonation = impersonationService;
    this.inited = false;
  }

  ngOnInit() {
    this.schedule = [];
    this.scheduleChartData;

    this.isDemo = this.impersonation.isDemoMode();

    if(this.folderName) {
      this.campaignFilesService.getSchedule(this.campaign, this.schedule, this.results.latest.totals, this.folderName).then(schedule => {
        this.schedule = schedule;
        this.scheduleChartData = this.campaignScheduleService.getScheduleChartData(schedule);
      });
    }
    else if(this.results.latest && !this.isDemo) {
      this.campaignScheduleService.getSchedule(this.campaign, this.schedule, this.results.latest.totals).then(() => {
        this.scheduleChartData = this.campaignScheduleService.getScheduleChartData(this.schedule);
      });
    }

    this.inited = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes && this.inited) {
      this.schedule = [];
      this.scheduleChartData;
      if(this.folderName) {
        this.campaignFilesService.getSchedule(this.campaign, this.schedule, this.results.latest.totals, this.folderName).then(schedule => {
          this.schedule = schedule;
          this.scheduleChartData = this.campaignScheduleService.getScheduleChartData(schedule);
        });
      }
      else if(this.results.latest && !this.isDemo) {
        this.campaignScheduleService.getSchedule(this.campaign, this.schedule, this.results.latest.totals).then(() => {
          this.scheduleChartData = this.campaignScheduleService.getScheduleChartData(this.schedule);
        });
      }
    }
  }

  // Check if date is the current day
  isToday(date, timezone) {
    var today = moment.tz(new Date(), timezone);
    var dateToCheck = moment(date);

    if(today.month() === dateToCheck.month() && today.date() === dateToCheck.date() && today.year() === dateToCheck.year()) {
      return true;
    }
    return false;
  }
}
