// @flow
import { Component, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../components/auth/auth.service';
import { GeneratorService } from '../shared/generator.service';

@Component({
  selector: 'message-generator',
  template: require('./message-generator.html')
})
export class MessageGeneratorComponent implements OnInit {
  static parameters = [Clipboard, AuthService, GeneratorService, HttpClient];
  constructor(clipboard: Clipboard, authService: AuthService, generatorService: GeneratorService, http: HttpClient) {
    this.clipboard = clipboard;
    this.http = http;
    this.authService = authService;
    this.generatorService = generatorService;

    this.isOpenSubjectLine = true;
    this.isOpenMessageBody = true;
  }

  ngOnInit() {
    this.sampleMessage = null;

    this.loadingSubjectLines = false;
    this.lockedSubjectLines = [];
    this.generatedSubjectLines = [];

    this.loadingMessageBody = false;
    this.lockedMessageBodies = [];
    this.generatedMessageBody = [];

    this.options = {
      n: 3,
      language: 'english',
      tone: 'Select or Type Your Own',
      voice: 'Select or Type Your Own',
      audience: 'Select or Type Your Own',
      customBrandVoice: false
    };
    this.languages = this.generatorService.getLanguages();
    this.toneSelector = null;
    this.voiceSelector = null;
    this.personaVoiceSelector = null;

    this.authService.getCurrentOrg().then(org => {
      this.currentOrg = org;
      this.checkForBrandVoice();
      this.checkForPersonaVoice();
    });

    //If the page is loaded directly.
    this.orgSub = this.authService.currentOrgChanged.subscribe(org => {
      this.currentOrg = org;
      //force refresh or brand voices
      this.initVoices();
      this.customBrandVoicesAdded = false;
      this.checkForBrandVoice();

      this.personaVoicesAdded = false;
      this.checkForPersonaVoice();
    });

    //TODO: Get these from a service.
    this.tones = [
      'Academic',
      'Conversational',
      'Creative',
      'Empathic',
      'Humorous',
      'Professional',
      'Simple'
    ];

    this.initVoices();
    this.brandVoices = {};
    this.customBrandVoicesAdded = false;
    this.CUSTOM_BRAND_VOICE_PREFIX = 'CUSTOM BRAND VOICE: ';

    this.personaVoices = {};
    this.personaVoiceNames = [];
    this.personaVoicesAdded = false;
    this.PERSONA_VOICE_PREFIX = 'PERSONA VOICE: ';
  }

  initVoices() {
    //TODO: Get these from a service.
    this.voices = [
      'Caveman',
      'Cormac McCarthy',
      'Douglas Adams',
      'Fairy Princess',
      'Gabriel Garcia Marquez',
      'George Orwell',
      'Haruki Murakami',
      'Hunter S. Thompson',
      'Jane Austen',
      'Mark Twain',
      'Maya Angelou',
      'William Shakespeare'
    ];

    this.personaVoiceNames = [];
  }

  ngOnDestroy() {
    if(this.orgSub) this.orgSub.unsubscribe();
  }

  checkForBrandVoice() {
    if(this.currentOrg && this.currentOrg.enableBrandVoices && !this.customBrandVoicesAdded) {
      this.currentOrg.generatorBrandVoices.forEach(brandVoice => {
        if(brandVoice.show) {
          this.customBrandVoicesAdded = true;
          this.voices.unshift(this.CUSTOM_BRAND_VOICE_PREFIX + brandVoice.name);
          this.brandVoices[brandVoice.name] = brandVoice.description;
        }
      });
    }
  }

  checkForPersonaVoice() {
    if(this.currentOrg && !this.personaVoicesAdded) {
      this.http.get('/api/persona-voices')
        .toPromise()
        .then(personaVoices => {
          console.log("Got Persona Voices: ", personaVoices);
          this.personaVoicesAdded = true;
          personaVoices.forEach(personaVoice => {
            this.personaVoiceNames.unshift(this.PERSONA_VOICE_PREFIX + personaVoice.personaName);
            this.personaVoices[personaVoice.personaName] = personaVoice ;
          });
        });
    }
  }

  generate() {
    if(this.isOpenSubjectLine) {
      //console.log('-----> generate() Subject Lines with options: ', this.options); //debug
      this.loadingSubjectLines = true;
      this.generatedSubjectLines = [];
      this.generatorService.generateSubjectLineFromBody(this.sampleMessage, this.options).then(response => {
        this.loadingSubjectLines = false;
        //console.log('-----> generateSubjectLineFromBody response = ', response); //debug
        this.generatedSubjectLines = response;

        //Loop over and set each to !locked
        this.generatedSubjectLines.forEach(obj => {
          obj.locked = false;
          obj.copyMsg = '';
        });
      });
    }

    if(this.isOpenMessageBody) {
      //console.log('-----> generate() Message Body with options: ', this.options); //debug
      this.loadingMessageBody = true;
      this.generatedMessageBody = [];
      this.generatorService.generateMessageBodyFromBody(this.sampleMessage, this.options).then(response => {
        this.loadingMessageBody = false;
        //console.log('-----> generateMessageBodyFromBody response = ', response); //debug
        this.generatedMessageBody = response;

        //Loop over and set each to !locked and collapsed
        this.generatedMessageBody.forEach(obj => {
          obj.locked = false;
          obj.collapsed = true;
          obj.copyMsg = '';
          obj.displayMessageBody = obj.message.content.match(/<email>(.*?)<\/email>/s)[1]; // only display the email body from response
        });
      });
    }
  }

  toggleSubjectLineLock(subjectLine) {
    subjectLine.locked = !subjectLine.locked;

    if(subjectLine.locked) {
      this.lockedSubjectLines.push(subjectLine);
    }
    else {
      this.lockedSubjectLines = this.lockedSubjectLines.filter(c => {
        if(c.locked) return c;
      });
    }
  }

  copySubjectLine(content) {
    //Copy the subject line to the clipboard
    this.clipboard.copy(content.message.content);
    content.copyMsg = true;

    //Hide msg after a bit
    setTimeout(() => {
      content.copyMsg = false;
    }, 1800);
  }

  toggleMessageBodyLock(messageBody) {
    messageBody.locked = !messageBody.locked;

    if(messageBody.locked) {
      this.lockedMessageBodies.push(messageBody);
    }
    else {
      this.lockedMessageBodies = this.lockedMessageBodies.filter(c => {
        if(c.locked) return c;
      });
    }
  }

  copyMessageBody(content) {
    //Copy the message body to the clipboard
    this.clipboard.copy(content.displayMessageBody);
    content.copyMsg = 'Message body copied to the clipboard.';

    //Hide msg after a bit
    setTimeout(() => {
      content.copyMsg = '';
    }, 1800);
  }

  toneSelected() {
    this.options.tone = this.toneSelector;
  }

  voiceSelected() {
    this.options.voice = this.voiceSelector;
  }

  brandVoiceSelected() {
    if(this.customBrandVoicesAdded && this.voiceSelector.startsWith(this.CUSTOM_BRAND_VOICE_PREFIX) && this.brandVoices.hasOwnProperty(this.voiceSelector.split(this.CUSTOM_BRAND_VOICE_PREFIX).pop())) {
      this.options.customBrandVoice = true;
      this.options.tone = '';
    }
    else {
      this.options.customBrandVoice = false;
    }
  }

  personaVoiceSelected() {
    this.options.audience = this.personaVoiceSelector;
    if(this.personaVoicesAdded && this.personaVoiceSelector.startsWith(this.PERSONA_VOICE_PREFIX) && this.personaVoices.hasOwnProperty(this.personaVoiceSelector.split(this.PERSONA_VOICE_PREFIX).pop())) {
      this.options.personaVoiceSelected = true;
      var selectedPersonaVoice = this.personaVoices[this.personaVoiceSelector.split(this.PERSONA_VOICE_PREFIX).pop()];
      this.options.personaVoiceId = selectedPersonaVoice.personaId;
      this.options.tone = '';
    }
    else {
      this.options.personaVoiceSelected = false;
    }
  }
}
