import { Component, ElementRef, HostListener, Input, SimpleChanges, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'viz-heatmap',
  template: '<div></div>'
})
export class HeatmapComponent implements OnInit {
  @Input() data;
  @Input() verticalLabels;
  @Input() horizontalLabels;
  @Input() showSendTimeRestrictions;
  @Input() showLegend;
  @Input() reportType;

  @HostListener('window:resize', ['$event'])
  onResize() {
    //If not vislble, don't resize.
    if(!(this.htmlElement.offsetParent !== null)) return;

    //TODO: This needs some work...
    this.width = this.htmlElement.parentElement.clientWidth > 1150 ? 1150 : this.htmlElement.parentElement.clientWidth;

    //Add margin - for width only.
    var marginWidth = +this.width - this.margin.left - this.margin.right;

    //Height based on data
    this.boxSize = Math.floor(marginWidth / (this.numCols + 1));
    this.height = this.boxSize * this.numRows + 180;

    this.r = d3.scaleSqrt().range([0, this.boxSize / 2 - 1]);

    this.svg.attr('width', this.width);
    this.svg.attr('height', this.height);
    this.chart.attr('width', this.width);
    this.chart.attr('height', this.height);

    if(this.data) {
      this.render(this.data);
    }
  }

  margin = { top: 25, left: 70, bottom: 30, right: 100 };

  htmlElement: HTMLElement;
  svg;

  numCols = 24; //24 hours
  numRows = 7; //7 days

  static parameters = [ElementRef];
  constructor(element: ElementRef) {
    this.htmlElement = element.nativeElement;
    this.host = d3.select(element.nativeElement);
  }

  ngOnInit() {
    //Set width to 100% of parent container or max 1500
    this.width = this.htmlElement.parentElement.clientWidth > 1150 ? 1150 : this.htmlElement.parentElement.clientWidth;

    //Add margin - for width only.
    var marginWidth = +this.width - this.margin.left - this.margin.right;

    //Height based on data
    this.boxSize = Math.floor(marginWidth / (this.numCols + 1));
    this.height = this.boxSize * this.numRows + 180;

    //append the svg object
    this.host.html('');
    this.svg = this.host
      .append('svg')
      .attr('id', 'sendTimes_heatmap')
      .attr('width', this.width)
      .attr('height', this.height);

    this.chart = this.svg
      .append('g')
      .attr('width', this.width)
      .attr('height', this.height);

    this.tooltip = this.host
      .append('div')
      .attr('class', 'heatmap-tooltip')
      .style('opacity', 0);

    //Circles
    this.r = d3.scaleSqrt().range([0, this.boxSize / 2 - 1]); //Sqrt scale to size by circle area, not radius

    this.render(this.data);
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'data' && this.data && this.chart) {
        this.render(this.data);
      }
    }
  }

  render(data) {
    this.chart.selectAll('*').remove();
    if(!this.data || this.data.length === 0) return;

    var max = d3.max(this.data.reportData, d => +d.volume);

    this.r.domain([0, max]);

    if(data.colorScheme) {
      this.colorScale = d3.scaleLinear()
        .range(data.colorScheme) //selected color scheme
        .domain([0, d3.max(data.reportData, d => d.value) / 2, d3.max(data.reportData, d => d.value)]);
    }
    else {
      this.colorScale = d3.scaleLinear()
        .range(['#ffffdd', '#3e9583', '#1f2d86']) //selected color scheme
        .domain([0, d3.max(data.reportData, d => d.value) / 2, d3.max(data.reportData, d => d.value)]);
    }

    var sendTimeRestrictionsLines;
    if(this.showSendTimeRestrictions && data.sendTimeRestrictions) {
      sendTimeRestrictionsLines = this.buildSendTimeRestrictionsLines(this.data);
    }

    this.chart
      .selectAll('.day')
      .data(this.verticalLabels)
      .enter()
      .append('text')
      .attr('class', 'day')
      .attr('x', 30)
      .attr('y', (d, i) => (i + 1) * (this.boxSize + 2) + this.margin.top)
      .text(d => d);

    this.chart
      .selectAll('.hour')
      .data(this.horizontalLabels)
      .enter()
      .append('text')
      .attr('class', 'hour')
      .attr('x', (d, i) => i * (this.boxSize + 2) + this.boxSize / 3 + this.margin.left)
      .attr('y', this.margin.top)
      .attr('width', this.boxSize)
      .text(d => d);

    //opens / clicks
    var squares = this.chart
      .selectAll('.heatmap-dot')
      .data(this.data.reportData)
      .enter();

    squares
      .append('rect')
      .attr('class', d => {
        // add heatmap-box-current to highlight current day and hour when campaign is running
        if(this.data.showNow && !this.data.isForEmailReport && d.x === this.data.current.hour && d.y === this.data.current.day) {
          return 'heatmap-box heatmapEntry heatmap-box-current';
        }
        return 'heatmap-box heatmapEntry';
      })
      .attr('x', d => d.x * (this.boxSize + 2) + this.margin.left)
      .attr('y', d => d.y * (this.boxSize + 2))
      .attr('width', this.boxSize)
      .attr('height', this.boxSize)
      .style('fill', d => {
        if(this.data.color == 'squares' && d.volume > 0) {
          return this.colorScale(d.value);
        }
        else if(d.y === 6 || d.y === 7) {
          return '#afb5bb';
        }
        else {
          return '#d8dbde';
        }
      })
      .on('mouseover', d => {
        this.mouseOver(d);
        // Bold the daily and hourly averages
        d3.select(`#day_average_${d.y - 1}`)
          .style('font-weight', 'bold');

        d3.select(`#hour_average_${d.x}`)
          .style('font-weight', 'bold');
      })
      .on('mouseout', d => {
        this.mouseOut();
        d3.select(`#day_average_${d.y - 1}`)
          .style('font-weight', '300');

        d3.select(`#hour_average_${d.x}`)
          .style('font-weight', '300');
      });

    //Add cirlces/bubbles for volume
    squares
      .append('circle')
      .attr('class', 'heatmap-dot heatmapEntry')
      .attr('r', d => this.r(d.volume))
      .attr('cx', d => d.x * (this.boxSize + 2) + this.boxSize / 2 + this.margin.left)
      .attr('cy', d => d.y * (this.boxSize + 2) + this.boxSize / 2)
      .style('fill', d => {
        if(this.data.color == 'squares') {
          return '#afb5bb';
        }
        else {
          return this.colorScale(d.value);
        }
      })
      .style('opacity', 0.8)
      .on('mouseover', d => {
        this.mouseOver(d);

        // Bold the daily and hourly averages
        d3.select(`#day_average_${d.y - 1}`)
          .style('font-weight', 'bold');

        d3.select(`#hour_average_${d.x}`)
          .style('font-weight', 'bold');
      })
      .on('mouseout', (d) => {
        this.mouseOut();

        d3.select(`#day_average_${d.y - 1}`)
          .style('font-weight', '300');

        d3.select(`#hour_average_${d.x}`)
          .style('font-weight', '300');
      });

    var lineFunction = d3
      .line()
      .x(function(d) {
        return d.x;
      })
      .y(function(d) {
        return d.y;
      });

    if(sendTimeRestrictionsLines && this.showSendTimeRestrictions) {
      sendTimeRestrictionsLines.forEach(line => {
        this.chart
          .append('path')
          .attr('d', lineFunction(line))
          .attr('stroke', () => {
            if(this.reportType == 'global') return '#EA5048';
            return '#2b7fba';
          })
          .attr('stroke-width', '2px')
          .attr('fill', 'none');
      });
    }

    // Daily averages
    if(data.dailyAverages && data.dailyAverages.length > 0) {
      this.chart.selectAll('.dailyAverage')
        .data(data.dailyAverages)
        .enter()
        .append('text')
        .attr('class', 'day')
        .attr('id', (d, i) => `day_average_${i}`)
        .attr('x', 24 * (this.boxSize + 2) + this.margin.left + this.boxSize / 2)
        .attr('y', (d, i) => (i + 1) * (this.boxSize + 2) + this.margin.top - 8)
        .text(d => {
          if(d >= 0) {
            return `${(d * 100).toFixed(1)}%`;
          }
          else {
            return '--';
          }
        });

      this.chart.append('text')
        .attr('font-size', '12px')
        .attr('font-weight', 'bold')
        .attr('x', 24 * (this.boxSize + 2) + this.margin.left + this.boxSize / 2)
        .attr('y', this.margin.top - 14)
        .text('Average');

      this.chart.append('text')
        .attr('font-size', '12px')
        .attr('font-weight', 'bold')
        .attr('x', 24 * (this.boxSize + 2) + this.margin.left + this.boxSize / 3)
        .attr('y', this.margin.top)
        .text(() => {
          if(data.reportData[0].units == 'Clickthrough Rate') {
            return 'Click Rate';
          }
          return 'Open Rate';
        });

      var dailyAveragesLine = [
        {x: 24 * (this.boxSize + 2) + this.margin.left + 4, y: this.boxSize + 2},
        {x: 24 * (this.boxSize + 2) + this.margin.left + 4, y: 8 * (this.boxSize + 2) - 2}
      ];
      squares.append('path')
        .attr('d', lineFunction(dailyAveragesLine))
        .attr('stroke', '#0193cf')
        .attr('stroke-width', '3px')
        .attr('fill', 'none');
    }

    // Hourly averages
    if(data.hourlyAverages && data.hourlyAverages.length > 0) {
      this.chart.selectAll('.hourlyAverage')
        .data(data.hourlyAverages)
        .enter()
        .append('text')
        .attr('class', 'hour')
        .attr('id', (d, i) => `hour_average_${i}`)
        .style('font-size', '14px')
        .attr('x', (d, i) => {
          if(d >= 0.095) {
            return i * (this.boxSize + 2) + this.boxSize / 8 + this.margin.left;
          }
          else {
            return i * (this.boxSize + 2) + this.boxSize / 4 + this.margin.left;
          }
        })
        .attr('y', this.margin.top + (this.boxSize + 2) * 8)
        .text(d => {
          if(d >= 0) {
            return `${(d * 100).toFixed(0)}%`;
          }
          else {
            return '--';
          }
        });

      this.chart.append('text')
        .attr('font-size', '12px')
        .attr('font-weight', 'bold')
        .attr('x', 8)
        .attr('y', 8 * (this.boxSize + 2) + 15)
        .text('Average');

      this.chart.append('text')
        .attr('font-size', '12px')
        .attr('font-weight', 'bold')
        .attr('x', 0)
        .attr('y', 8 * (this.boxSize + 2) + 30)
        .text(() => {
          if(data.reportData[0].units == 'Clickthrough Rate') {
            return 'Click Rate';
          }
          return 'Open Rate';
        });

      var houlyAveragesLine = [
        {x: this.margin.left, y: 8 * (this.boxSize + 2) + 4},
        {x: 24 * (this.boxSize + 2) + this.margin.left - 2, y: 8 * (this.boxSize + 2) + 4}
      ];
      squares.append('path')
        .attr('d', lineFunction(houlyAveragesLine))
        .attr('stroke', '#0193cf')
        .attr('stroke-width', '3px')
        .attr('fill', 'none');
    }

    if(this.showLegend) {
      this.renderLegend(this.svg, this.data.reportData, this.colorScale);
    }
  } //end: render()

  //----------------------------------------------------------------------------
  //Helper to draw the legend.
  renderLegend(svg, reportData, colorScale) {
    //Extra scale since the color scale is interpolated
    var countScale = d3
      .scaleLinear()
      .domain([0, d3.max(reportData, d => d.value)])
      .range([0, this.width]);

    //Calculate the variables for the gradient
    var numStops = 10;
    var countPoint = [];
    var countRange = countScale.domain();
    countRange[2] = countRange[1] - countRange[0];

    for(var i = 0; i < numStops; i++) {
      countPoint.push(i * countRange[2] / (numStops - 1) + countRange[0]);
    }

    //Create the gradient
    this.chart
      .append('defs')
      .append('linearGradient')
      .attr('id', 'legend-heatmap')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '100%')
      .attr('y2', '0%')
      .selectAll('stop')
      .data(d3.range(numStops))
      .enter()
      .append('stop')
      .attr('offset', (d, j) => countScale(countPoint[j]) / this.width)
      .attr('stop-color', (d, k) => colorScale(countPoint[k]));

    //Draw that Legend...
    var legendWidth = Math.min(this.width * 0.8, 400);
    var legendsvg = this.chart.append('g').attr('transform', `translate(${this.width / 2},${this.boxSize * this.numRows + 30})`);

    if(this.data.hourlyAverages) {
      legendsvg.attr('transform', `translate(${this.width / 2},${this.boxSize * this.numRows + 70})`);
    }

    //Draw the Rectangle
    legendsvg
      .append('rect')
      .attr('x', -legendWidth / 2)
      .attr('y', 60)
      .attr('width', legendWidth)
      .attr('height', 10)
      .style('fill', 'url(#legend-heatmap)');

    var legendUnits = reportData.length > 0 ? reportData[0].units : 'Legend';

    //Append title
    legendsvg
      .append('text')
      .attr('class', 'heatmapLegendTitle')
      .attr('x', 0)
      .attr('y', 50)
      .style('text-anchor', 'middle')
      .text(legendUnits);

    //Set scale for x-axis
    var xScale = d3
      .scaleLinear()
      .range([-legendWidth / 2, legendWidth / 2])
      .domain([0, d3.max(reportData, d => d.value)]);

    //Define x-axis
    var xAxis = d3.axisBottom(xScale);

    //Set tick format
    var xAxisTicks = xAxis.scale().ticks(xAxis.ticks()[0]);
    var precision = 0;

    xAxisTicks.forEach(tick => {
      if(parseInt((tick * 100).toString()[2]) > 0 && precision < 1) precision = 1;
      if(parseInt((tick * 100).toString()[3]) > 0) precision = 2;
    });

    xAxis = d3
      .axisBottom(xScale)
      .ticks(5)
      .tickFormat(d3.format(`,.${precision}%`));

    //Set up X axis
    legendsvg
      .append('g')
      .attr('class', 'heatmapLegendAxis')
      .attr('transform', `translate(0,${70})`)
      .call(xAxis);
  } //end: renderLegend()

  //Very specific to Motiva...
  buildSendTimeRestrictionsLines(data) {
    var sendTimeRestrictions = data.sendTimeRestrictions;
    var sendTimeRestrictionsLines = [];
    var sendTimeRestrictionsLine = [];

    var lineStart = 0;

    sendTimeRestrictions.forEach((day, i) => {
      if((sendTimeRestrictionsLine.length < 1 || !sendTimeRestrictions[i - 1]) && day) {
        sendTimeRestrictionsLine.push({
          x: day[0] * (this.boxSize + 2) + this.margin.left + 1,
          y: (i + 1) * (this.boxSize + 2) + 1
        });

        sendTimeRestrictionsLine.push({
          x: (day[1] + 1) * (this.boxSize + 2) + this.margin.left - 1,
          y: (i + 1) * (this.boxSize + 2) + 1
        });

        sendTimeRestrictionsLine.push({
          x: (day[1] + 1) * (this.boxSize + 2) + this.margin.left - 1,
          y: (i + 2) * (this.boxSize + 2)
        });
      }
      else if(day) {
        if(sendTimeRestrictions[i - 1]) {
          sendTimeRestrictionsLine.push({
            x: (day[1] + 1) * (this.boxSize + 2) + this.margin.left - 1,
            y: (i + 1) * (this.boxSize + 2) - 1
          });

          sendTimeRestrictionsLine.push({
            x: (day[1] + 1) * (this.boxSize + 2) + this.margin.left - 1,
            y: (i + 2) * (this.boxSize + 2) - 1
          });
        }
      }

      if(!sendTimeRestrictions[i + 1]) {
        for(var j = i; j >= lineStart; j--) {
          if(sendTimeRestrictions[j]) {
            sendTimeRestrictionsLine.push({
              x: sendTimeRestrictions[j][0] * (this.boxSize + 2) + this.margin.left + 1,
              y: (j + 2) * (this.boxSize + 2) - 1
            });

            sendTimeRestrictionsLine.push({
              x: sendTimeRestrictions[j][0] * (this.boxSize + 2) + this.margin.left + 1,
              y: (j + 1) * (this.boxSize + 2)
            });
          }
        }

        sendTimeRestrictionsLines.push(sendTimeRestrictionsLine);
        sendTimeRestrictionsLine = [];
        lineStart = i + 1;
      }
    });

    return sendTimeRestrictionsLines;
  } //end: buildSendTimeRestrictionsLines()

  mouseOver(d) {
    var precision = this.getPrecision(this.data.reportData);
    this.tooltip
      .transition()
      .duration(1500)
      .style('opacity', 0.9);

    var html = '';
    html += `<b>${this.verticalLabels[d.y - 1]} ${this.horizontalLabels[d.x]}`;
    if(this.data.showNow && d.x === this.data.current.hour && d.y === this.data.current.day) {
      html += ' - Current Time';
    }

    html += `</b><br/>Sends: ${d.volume.toLocaleString()}<br/>${d.units}: ${(d.value * 100).toFixed(precision)}%`;

    if(this.horizontalLabels[d.x] === '8p' || this.horizontalLabels[d.x] === '9p' || this.horizontalLabels[d.x] === '10p' || this.horizontalLabels[d.x] === '11p') {
      if(d.units === 'Open Rate') {
        this.tooltip
          .html(html)
          .style('left', `${d3.event.layerX - 120}px`)
          .style('top', `${d3.event.layerY - 28}px`);
      }
      else {
        this.tooltip
          .html(html)
          .style('left', `${d3.event.layerX - 150}px`)
          .style('top', `${d3.event.layerY - 28}px`);
      }
    }
    else {
      this.tooltip
        .html(html)
        .style('left', `${d3.event.layerX + 20}px`)
        .style('top', `${d3.event.layerY - 28}px`);
    }
  } //end: mouseOver

  mouseOut() {
    this.tooltip
      .transition()
      .duration(10)
      .style('opacity', 0);
  }

  getPrecision(data) {
    var precision = 1;
    data.forEach(d => {
      if(parseInt((d.value * 100).toString()[4]) > 0) {
        precision = 2;
      }
    });
    return precision;
  }
}
