import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { LoggerService } from '../logger.service';
import { AuthService } from '../../../components/auth/auth.service';

@Component({
  selector: 'segment-selector',
  template: require('./segment-selector.html')
})
export class SegmentSelectorComponent implements OnInit {
  alerts = [];
  loading = true;
  error = false;

  availableSegments = [];
  filteredSegments = [];
  selectedSegments = [];
  multiSelect = false;

  currentPage = 0;
  pageSize = 100;

  search = {
    text: '',
    orderBy: 'updatedAt',
    isDescending: true,
    busy: false,
    stop: false
  };

  _timeout = null;

  static parameters = [HttpClient, BsModalRef, LoggerService, AuthService];
  constructor(http: HttpClient, bsModalRef: BsModalRef, loggerService: LoggerService, authService: AuthService) {
    this.http = http;
    this.bsModalRef = bsModalRef;
    this.loggerService = loggerService;
    this.authService = authService;
    this.okClicked = false;
  }

  ngOnInit() {
    this.getNextPage();

    this.authService.getCurrentUser().then(user => {
      this.currentUser = user;
    });
  }

  changeFilter() {
    if(!this.search.busy) {
      if(this._timeout) {
        clearTimeout(this._timeout);
      }

      //Wait a bit before running search
      this._timeout = setTimeout(() => {
        this.runNewSearch();
      }, 1.2 * 1000);
    }
  }

  runNewSearch() {
    this.filteredSegments = [];
    this.search.stop = false;
    this.currentPage = 1;
    this.getNextPage(1);
  }

  getNextPage(page) {
    if(!page) {
      this.currentPage++;
    }
    else {
      this.currentPage = page;
    }

    if(this.currentPage === 1) {
      this.availableSegments = [];
    }

    if(this.search.busy) return;
    this.search.busy = true;

    var search = 'name!=motiva::*';
    if(this.search.text && this.search.text != '') {
      search = `'*${this.search.text}*'`;
    }

    var params = new HttpParams();
    params = params.append('limit', this.pageSize);
    params = params.append('page', this.currentPage);
    params = params.append('search', search);
    params = params.append('orderBy', this.search.orderBy);
    params = params.append('isDescending', this.search.isDescending);

    this.http
      .get('/eloqua-api/api/segments', { params })
      .toPromise()
      .then(
        segments => {
          //console.log('Got segments!', segments); //debug
          //Once we don't get anything back, stop scrolling.
          if(segments.length === 0) {
            this.loading = false;
            this.search.busy = false;
            this.search.stop = true;
            return;
          }

          this.processSegmentsPage(segments);
        },
        //Error Handling...
        error => {
          // Try refreshing eloqua token and trying again
          this.authService
            .verifyAuth(this.currentUser._id)
            .toPromise()
            .then(
              () => {
                // Token was refreshed successfully, so try getting segments again
                this.http
                .get('/eloqua-api/api/segments', { params })
                .toPromise()
                .then(
                  segments => {
                    //console.log('Got segments!', segments); //debug
                    //Once we don't get anything back, stop scrolling.
                    if(segments.length === 0) {
                      this.loading = false;
                      this.search.busy = false;
                      this.search.stop = true;
                      return;
                    }
          
                    this.processSegmentsPage(segments);
                  },
                  error => {
                    console.log('Error getting segments', error);
                    this.loading = false;
                    this.error = true;
                    this.loggerService.logMessage('Error getting Segments', 'error', error);
                  });
              },
              error => {
                console.log('Error verifying auth', error);
                this.loading = false;
                this.error = true;
                this.loggerService.logMessage('Error getting Segments', 'error', error);
              });
        }
      );
  } //end: getNextPage()

  processSegmentsPage(segments) {
    segments.forEach(segment => {
      segment.createdAt = new Date(segment.createdAt * 1000);

      //Strip time from update date
      var tmpUpdateDate = new Date(segment.updatedAt * 1000);
      segment.updatedAt = new Date(tmpUpdateDate.getFullYear(), tmpUpdateDate.getMonth(), tmpUpdateDate.getDate());

      //If multiSelect, then pre-select selectedSegments.
      if(this.multiSelect && this.selectedSegments.length > 0) {
        var s = this.selectedSegments.find(x => x.id === segment.id);
        if(s) segment.selected = true;
      }

      // Remove duplicates and motiva:: segments
      var found = false;
      for(var i = 0; i < this.availableSegments.length; i++) {
        if(this.availableSegments[i].id === segment.id) {
          found = true;
        }
      }
      if(!found && segment.name.substring(0, 8) !== 'motiva::') {
        this.availableSegments.push(segment);
      }
    }); //endfor

    // If there arent enough segments after filtering out "motiva::"
    // and there are more segments to get, get next page
    if(this.availableSegments.length < 10 && segments.length >= this.pageSize) {
      this.search.busy = false;
      this.getNextPage();
    }
    else {
      //If multiSelect and selectedSegments, make sure all selected segments are there.
      if(this.multiSelect && this.selectedSegments.length > 0) {
        this.selectedSegments.forEach(item => {
          var seg = this.availableSegments.find(x => x.id === item.id);
          if(!seg) {
            this.availableSegments.push(item);
          }
        });
      }

      this.filteredSegments = this.availableSegments;
      this.loading = false;
      this.search.busy = false;
    }
  }

  toggleCheckbox(segment) {
    this.alerts = [];

    //For single select, un-select everything else first.
    if(!this.multiSelect) {
      this.filteredSegments.forEach(s => {
        s.selected = false;
      });
    }

    segment.selected = !segment.selected;
  }

  ok() {
    this.okClicked = true;

    //Set the selectedSegment or selectedSegments depending on settings.
    if(this.multiSelect) {
      this.selectedSegments = [];
      this.availableSegments.forEach(segment => {
        if(segment.selected) this.selectedSegments.push(segment);
      });
    }
    else {
      this.availableSegments.forEach(segment => {
        if(segment.selected) this.selectedSegment = segment;
      });
    }

    this.bsModalRef.hide();
  }

  cancel() {
    this.bsModalRef.hide();
  }
}
