// @flow
import { Component, OnInit, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ngxCsv } from 'ngx-csv/ngx-csv';

import { FunnelService } from '../../funnel/shared/funnel.service';
import { AuthService } from '../../../components/auth/auth.service';
import { ImpersonationService } from '../../../components/auth/impersonation.service';
import { CampaignFilesService } from '../../campaign/shared/campaign-files.service';

import { FilterSelectorComponent } from '../../../components/filter-selector/filter-selector.component';

import { getCacheValue, setCacheValue, removeCacheValue } from '../../../components/util.js';

@Component({
  selector: 'global-funnel',
  template: require('./global-funnel.html')
})
export class GlobalFunnelComponent implements OnInit {
  modalRef: BsModalRef;

  @Input() currentOrg;
  @Input() funnelLocal;

  static parameters = [FunnelService, BsModalService, AuthService, ImpersonationService, CampaignFilesService];
  constructor(funnelService: FunnelService, modalService: BsModalService, authService: AuthService, impersonation: ImpersonationService, campaignFilesService: CampaignFilesService) {
    this.funnelService = funnelService;
    this.modalService = modalService;
    this.authService = authService;
    this.impersonation = impersonation;
    this.campaignFilesService = campaignFilesService;

    this.loading = true;
    this.loadingFilterOptions = true;
    this.exportingFunnelData = false;

    this.getCacheValue = getCacheValue;
    this.setCacheValue = setCacheValue;
    this.removeCacheValue = removeCacheValue;

    this.filters = {
      eloquaCampaigns: [],
      treatments: [],
      currentFilterSettings: {
        filterBy: 'all',
        ids: []
      }
    };

    this.isAdmin = false;
    this.authService.isAdmin().then(is => {
      this.isAdmin = is;
    });

    this.isDemo = false;
  }

  ngOnInit() {
    this.init();
    this.isDemo = this.impersonation.isDemoMode();

    if(this.isDemo) {
      this.loadingFilterOptions = false;
      this.loadDemo();
      return;
    }

    //Check cache
    var funnelLocal = this.getCacheValue('globalFunnel');
    var funnelFilter = this.getCacheValue('globalFunnelFilter');

    if(funnelLocal && funnelFilter && funnelLocal.data.results.length > 0) {
      //Found data in the cache
      this.funnelLocal = funnelLocal;
      this.loading = false;
      this.filters = funnelFilter;
      this.loadingFilterOptions = false;
    }
    else {
      // Get filter options
      this.funnelService.getFilterOptions(this.currentOrg._id).then(
        filterOptions => {
          this.filters.eloquaCampaigns = filterOptions.eloquaCampaigns;
          this.filters.treatments = filterOptions.treatments;
          this.loadingFilterOptions = false;
        },
        error => {
          this.loadingFilterOptions = false;
          console.log('Error: retrieving filter options.', error);
        }
      );

      // Build funnel chart
      this.buildFunnelChart();
    }
  }

  init() {
    this.funnelLocal = {
      data: {
        results: [],
        colors: {
          noEngagement: '#CECECE',
          aware: '#84C2E7',
          considering: '#41A0DA',
          engaged: '#1F8ACE'
        },
        baselineColors: {
          noEngagement: '#aeaeae',
          aware: '#699cbb',
          considering: '#388bbd',
          engaged: '#1971a9'
        }
      },
      campaignType: 'all Motiva and Non-Motiva',
      timePeriodOptions: []
    };
  }

  ngOnDestroy() {
    //Cache report data
    if(this.funnelLocal && !this.loading && this.filters && !this.loadingFilterOptions && !this.isDemo) {
      this.setCacheValue('globalFunnel', this.funnelLocal);
      this.setCacheValue('globalFunnelFilter', this.filters);
    }
  }

  funnelTimePeriodChanged(timePeriod, isBaseline) {
    this.loading = true;
    if(isBaseline) {
      this.funnelLocal.baselineTimePeriod = timePeriod;
    }
    else {
      this.funnelLocal.timePeriod = timePeriod;
    }

    // Get funnel data with new time period
    this.funnelService.getFunnelData(this.currentOrg._id, this.filters.currentFilterSettings).then(funnelData => {
      this.funnelDataAllMonths = funnelData.data;
      this.funnelDataUpdatedAt = funnelData.updatedAt ? new Date(funnelData.updatedAt) : null;

      this.funnelService.processFunnelData(funnelData.data, this.funnelLocal).then(() => {
        this.loading = false;
      });
    });
  }

  openFilterSelector() {
    const initialState = {
      eloquaCampaigns: this.filters.eloquaCampaigns,
      treatments: this.filters.treatments,
      currentFilter: this.filters.currentFilterSettings,
      report: 'globalFunnel'
    };

    this.modalRef = this.modalService.show(FilterSelectorComponent, { initialState });

    var sub = this.modalService.onHidden.subscribe(() => {
      if(this.modalRef.content.okClicked) {
        this.filters.currentFilterSettings = this.modalRef.content.response;
        this.loading = true;

        if(this.isDemo) return this.loadDemoFiltered();

        // Build funnel chart
        this.buildFunnelChart();
      }
      if(sub) sub.unsubscribe();
    });
  }

  clearFilter() {
    this.loading = true;
    this.filters.currentFilterSettings = {
      filterBy: 'all',
      ids: []
    };

    this.filters.eloquaCampaigns.forEach(campaign => {
      campaign.selected = false;
    });

    this.filters.treatments.forEach(treatment => {
      treatment.selected = false;
    });

    if(this.isDemo) {
      this.loadDemo();
    }
    else {
      this.buildFunnelChart();
    }
  }

  buildFunnelChart() {
    // Build funnel chart
    this.funnelService.getFunnelData(this.currentOrg._id, this.filters.currentFilterSettings).then(
      funnelData => {
        if(funnelData && funnelData.data && funnelData.data.length > 0) {
          this.funnelDataAllMonths = funnelData.data;
          this.funnelDataUpdatedAt = funnelData.updatedAt ? new Date(funnelData.updatedAt) : null;

          this.funnelService.getTimePeriodOptions(this.funnelDataAllMonths).then(response => {
            this.funnelLocal.timePeriodOptions = response.timePeriodOptions;
            this.funnelLocal.timePeriod = response.selectedTimePeriods.timePeriod;
            this.funnelLocal.baselineTimePeriod = response.selectedTimePeriods.baselineTimePeriod;

            this.funnelService.processFunnelData(funnelData.data, this.funnelLocal).then(() => {
              this.loading = false;
            });
          });
        }
        else {
          this.loading = false;
        }
      },
      error => {
        console.log('Error: getting funnel data.', error);
        this.loading = false;
      }
    );
  } //end: buildFunnelChart()

  exportFunnelData() {
    this.exportingFunnelData = true;
    // Get funnel data with new time period
    this.funnelService.getFunnelData(this.currentOrg._id, this.filters.currentFilterSettings).then(funnelData => {
      var headers = ['Month', 'No Engagement', 'Aware', 'Considering', 'Engaged'];
      var options = {
        fieldSeparator: ',',
        showLabels: true,
        noDownload: false,
        headers
      };

      var filename = 'Funnel data';

      if(this.filters.currentFilterSettings.filterBy !== 'all') {
        filename += ` for ${this.filters.currentFilterSettings.numberSelected}`;

        if(this.filters.currentFilterSettings.filterBy === 'treatments') {
          filename += ' emails';
        }
        else if(this.filters.currentFilterSettings.filterBy === 'eloquaCampaigns') {
          filename += ' campaigns';
        }
      }

      new ngxCsv(funnelData.data, filename, options);
      this.exportingFunnelData = false;
    });
  }

  loadDemo() {
    this.init();
    this.filters = this.campaignFilesService.getDemoGlobalFilter();
    this.funnelService.getDemoFunnelData().then(demoFunnelData => {
      if(demoFunnelData && demoFunnelData.data && demoFunnelData.data.length > 0) {
        this.funnelDataAllMonths = demoFunnelData.data;
        this.funnelDataUpdatedAt = demoFunnelData.updatedAt ? new Date(demoFunnelData.updatedAt) : null;
        this.funnelService.getTimePeriodOptions(this.funnelDataAllMonths).then(response => {
          this.funnelLocal.timePeriodOptions = response.timePeriodOptions;
          this.funnelLocal.timePeriod = response.selectedTimePeriods.timePeriod;
          this.funnelLocal.baselineTimePeriod = response.selectedTimePeriods.baselineTimePeriod;
          this.funnelLocal.campaignType = 'all Motiva and Non-Motiva';
          this.funnelService.processFunnelData(demoFunnelData.data, this.funnelLocal).then(() => {
            this.loading = false;
          });
        });
      }
      else {
        this.loading = false;
      }
    },
    error => {
      console.log('Error getting demo funnel Data', error);
    });
  }

  loadDemoFiltered() {
    //TODO: This isn't working in the UI. The selected campaign isn't selected.
    if(this.filters.currentFilterSettings.filterBy === 'eloquaCampaigns') {
      this.filters.currentFilterSettings.ids.forEach(id => {
        this.filters.eloquaCampaigns.forEach(campaign => {
          if(campaign.campaign_id === id) {
            campaign.selected = true;
          }
        });
      });
    }

    this.funnelService.getDemoFilteredFunnelData().then(demoFunnelData => {
      if(demoFunnelData && demoFunnelData.data && demoFunnelData.data.length > 0) {
        this.funnelDataAllMonths = demoFunnelData.data;
        this.funnelDataUpdatedAt = demoFunnelData.updatedAt ? new Date(demoFunnelData.updatedAt) : null;
        this.funnelService.getTimePeriodOptions(this.funnelDataAllMonths).then(response => {
          this.funnelLocal.timePeriodOptions = response.timePeriodOptions;
          this.funnelLocal.timePeriod = response.selectedTimePeriods.timePeriod;
          this.funnelLocal.baselineTimePeriod = response.selectedTimePeriods.baselineTimePeriod;
          this.funnelLocal.campaignType = 'selected';
          this.funnelService.processFunnelData(demoFunnelData.data, this.funnelLocal).then(() => {
            this.loading = false;
          });
        });
      }
      else {
        this.loading = false;
      }
    },
    error => {
      console.log('Error getting demo funnel Data', error);
    });
  }
}
