import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { UserService } from '../../../../components/auth/user.service';

@Component({
  selector: 'error-email',
  template: require('./error-email.html')
})
export class ErrorEmailComponent implements OnInit {
  campaign: {};

  static parameters = [BsModalRef, UserService];
  constructor(
    bsModalRef: BsModalRef,
    userService: UserService
  ) {
    this.bsModalRef = bsModalRef;
    this.userService = userService;

    this.emails = [];
    this.emailString = '';

    this.errors = {};
    this.submitted = false;

    this.errorType = 'general';
    this.campaignOwner = null;
  }

  ngOnInit() {
    if(this.campaign) {
      this.userService.get({ id: this.campaign.uid }).toPromise()
        .then(user => {
          this.campaignOwner = user;
          if(this.campaignOwner) {
            this.emailString = this.campaignOwner.email;
          }
        });
    }
  }

  // Check if email is valid
  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  ok() {
    this.errors = {};
    this.submitted = true;
    this.okClicked = true;

    this.emails = this.emailString.split(',');
    this.emails = this.emails.map(email => email.trim());

    // Make sure emails are valid
    this.emails.forEach(email => {
      if(!this.validateEmail(email)) {
        this.errors.emails = 'Please enter valid email addresses separated by commas.';
        this.errors.submit = this.errors.emails;
      }
    });

    if(!this.errors.emails) {
      this.bsModalRef.hide();
    }
  }

  cancel() {
    this.okClicked = false;
    this.bsModalRef.hide();
  }
}
