// @flow
import { Injectable } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import moment from 'moment-timezone';

import { Campaign } from './campaign';
import { campaignKpis } from './campaign-kpis';
import { COLOR_PATTERN } from './campaign-colors';
import { CampaignDatesService } from './campaign-dates.service';
import { OrganizationService } from '../../organization/shared/organization.service';

import { LoggerService } from '../../shared/logger.service';

import { UserService } from '../../../components/auth/user.service';
import { OrgService } from '../../../components/auth/org.service';

import { daysBetweenDates, getLocalEquivalentFromMoment, startLoading, stopLoading } from '../../../components/util';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  MIN_CONTACTS_DIFF = 5; //use a few less contacts to decide when to show results (in case Eloqua skips a few).

  //TODO: Use Settings.minimumContactsPerTreatmentPerBatch.
  MIN_CONTACTS_PER_TREATMENT_PER_BATCH = 150;

  baseUrl = '/core-api/api/campaign';
  baseUrlLocal = '/api/campaigns';
  baseUrlCoreDecision = '/core-api/api/decision-service';
  baseUrlDecisions = '/api/decision-services';

  static parameters = [HttpClient, DecimalPipe, CampaignDatesService, LoggerService, UserService, OrgService, OrganizationService];
  constructor(
    http: HttpClient,
    decimalPipe: DecimalPipe,
    campaignDatesService: CampaignDatesService,
    loggerService: LoggerService,
    userService: UserService,
    orgService: OrgService,
    organizationService: OrganizationService
  ) {
    this.http = http;
    this.decimalPipe = decimalPipe;
    this.loggerService = loggerService;
    this.userService = userService;
    this.orgService = orgService;
    this.startLoading = startLoading;
    this.stopLoading = stopLoading;
    this.campaignDatesService = campaignDatesService;
    this.organizationService = organizationService;

    this.userNames = {};
    this.organizationNames = {};

    //For all all possible campaign statuses, see:
    // https://github.com/Motiva-AI/pipeline-schemas/blob/master/src/pipeline_schemas/core.clj
    this.statuses = [
      'draft',
      'settingUp',
      'failedStart',
      'insufficientContacts',
      'running',
      'failedRun',
      'scheduled',
      'completed',
      'cancelled',
      'deleted',
      'paused',
      'continue',
      'finishedExperimentNowCollecting'
    ];

    this.activeStatuses = ['scheduled', 'running', 'failedRun', 'paused', 'continue', 'finishedExperimentNowCollecting'];
    this.archiveStatuses = ['failedStart', 'insufficientContacts', 'completed', 'cancelled', 'paused'];
    this.errorStatuses = ['failedStart', 'insufficientContacts', 'failedRun'];
    this.possiblyDoneStatuses = ['paused', 'cancelled', 'finishedExperimentNowCollecting', 'completed'];

    this._confidenceThresholds = [
      { value: 0.99, display: '99%' },
      { value: 0.95, display: '95%' },
      { value: 0.9, display: '90%' }
    ];

    this._eloquaCampaignId;

    //For caching Eloqua campaign names
    this._eloquaCampaignNames = {};
  }

  //Eloqua Campaign ID of selected Motiva step, used for showing "eye" icon.
  get eloquaCampaignId() {
    return this._eloquaCampaignId;
  }

  set eloquaCampaignId(id) {
    this._eloquaCampaignId = id;
  }

  home(): Observable<Campaign[]> {
    var params = new HttpParams();
    params = params.append('viewOrg', true);
    return this.http.get(`${this.baseUrl}/home`, { params });
  }

  active(lookBack, viewOrg): Observable<Campaign[]> {
    var params = new HttpParams();
    params = params.append('lookBack', lookBack);
    params = params.append('viewOrg', viewOrg);
    params = params.append('viewAll', !viewOrg);
    return this.http.get(`${this.baseUrl}/active`, { params });
  }

  query(): Observable<Campaign[]> {
    var params = new HttpParams();
    params = params.append('viewOrg', true);
    return this.http.get(`${this.baseUrl}`, { params });
  }

  queryByEloquaCampaign(eloquaCampaignId): Observable<Campaign[]> {
    return this.http.get(`${this.baseUrl}/decision-service/${eloquaCampaignId}`);
  }

  get(id): Observable<Campaign> {
    return this.http.get(`${this.baseUrl}/${id}`);
  }

  create(id, campaign): Observable<Object> {
    return this.http.post(`${this.baseUrl}/${id}`, campaign);
  }

  update(id, campaign): Observable<Object> {
    return this.http.put(`${this.baseUrl}/${id}/from-canvas`, campaign);
  }

  //Cancel an active campaign / archive an inactive campaign
  delete(id): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`, { responseType: 'text' });
  }

  getEloquaCampaignName(orgId, eloquaCampaignId): Observable<any> {
    var promise = new Promise((resolve, reject) => {
      //First try to get campaign name from cache.
      var key = `eloqua::${orgId}::${eloquaCampaignId}`;
      var cachedValue = this._eloquaCampaignNames.hasOwnProperty(key) ? this._eloquaCampaignNames[key] : null;
      if(cachedValue) {
        if(cachedValue === 'Not Found') {
          cachedValue = null;
        }
        resolve(cachedValue);
      }
      else {
        //Then, try to get campaign name from backend.
        this.http.get(`${this.baseUrlLocal}/campaignName/${eloquaCampaignId}`)
          .toPromise()
          .then(campaignName => {
            this._eloquaCampaignNames[key] = campaignName.name; //add to cache
            resolve(campaignName.name === 'Not Found' ? null : campaignName.name);
          },
          error => {
            /* This calls eloqua directly with depth=partial instead of depth=minimal.
            this.http
              .get(`${this.baseUrlLocal}/eloquaCampaign/${eloquaCampaignId}`)
              .toPromise()
              */

            //If we didn't find campaign name, get it from Eloqua, save to backend, and return.
            this.http
              .get(`/eloqua-api/api/campaign/${eloquaCampaignId}`)
              .toPromise()
              .then(
                campaignDetails => {
                  this._eloquaCampaignNames[key] = campaignDetails.name; //add to cache

                  //Save Eloqua campaign details to backend.
                  this.http.post(`${this.baseUrlLocal}`, campaignDetails)
                    .toPromise()
                    .then(() => {
                      resolve(campaignDetails.name);
                    });
                },
                err => {
                  //Campaign not in Eloqua anymore.
                  if(err.status === 404) {
                    this._eloquaCampaignNames[key] = 'Not Found'; //add to cache
                    //Save missing Eloqua campaign details to backend.
                    var missingCampaign = { id: eloquaCampaignId, name: 'Not Found' };
                    this.http.post(`${this.baseUrlLocal}`, missingCampaign)
                      .toPromise()
                      .then(() => {
                        reject(err);
                      });
                  }
                  else {
                    console.log('Error getting campaign details from eloqua:', err);
                    reject(err);
                  }
                }
              )
              .catch(ex => {
                console.log('Exception getting details from eloqua:', ex);
                reject(ex);
              });
          })
          .catch(e => {
            console.log('Exception getting campaign name from backend:', e);
            reject(e);
          });
      }
    });
    return promise;
  }

  //**********************************************
  // Decision Services managed by Big Enos (Core)
  //**********************************************

  getCoreDecisionService(id): Observable<any> {
    return this.http.get(`${this.baseUrlCoreDecision}/${id}`);
  }

  createCoreDecisionService(id, decision, type): Observable<Object> {
    var params = new HttpParams();
    params = params.append('activity', type);
    return this.http.post(`${this.baseUrlCoreDecision}/${id}`, decision, { params });
  }

  updateCoreDecisionService(id, decision): Observable<Object> {
    return this.http.put(`${this.baseUrlCoreDecision}/${id}`, decision);
  }

  //**********************************************
  // Decision Services managed by Frontend
  //**********************************************

  getDecisionService(id): Observable<Object> {
    return this.http.get(`${this.baseUrlDecisions}/${id}`);
  }

  createDecisionService(id, decision): Observable<Object> {
    return this.http.post(`${this.baseUrlDecisions}/${id}`, decision);
  }

  updateDecisionService(id, decision): Observable<Object> {
    return this.http.put(`${this.baseUrlDecisions}/${id}`, decision);
  }

  //***********************************
  // Helpers
  //***********************************

  getModelForSave(campaign, campaignLocal, currentUser) {
    var promise = new Promise((resolve, reject) => {
      //For emails, insert/update with only id's.
      var emailAssetIds = [];

      campaign.emails.forEach(email => {
        emailAssetIds.push(email.id);
      });

      var model = {
        name: campaign.name,
        emailAssetIds,
        eloquaCampaignId: campaign.eloquaCampaignId,
        isMotionControlled: campaign.isMotionControlled,
        optimizationCriterion: campaign.optimizationCriterion,
        overrideOrgContactFields: campaign.overrideOrgContactFields,
        allowEmailResendToPastRecipients: campaign.allowEmailResendToPastRecipients,
        includeListUnsubscribeHeader: campaign.includeListUnsubscribeHeader,
        allowSendToMasterExclude: campaign.allowSendToMasterExclude,
        allowSendToUnsubscribed: campaign.allowSendToUnsubscribed,
        timezone: campaign.timezone,
        confidenceThreshold: campaign.confidenceThreshold,
        overrideSendFrequencyCheck: campaign.overrideSendFrequencyCheck,
        letMotivaDecideSendTimeWindow: campaign.letMotivaDecideSendTimeWindow
      };

      if(campaign.isMotionControlled) {
        this.campaignDatesService.alignDates(campaign, campaignLocal);

        model.scheduledStart = campaign.scheduledStart;
        model.scheduledEnd = campaign.scheduledEnd;

        //TODO: This doesn't exist for STO....
        model.sendWinnerToAllRemainingImmediately = campaign.sendWinnerToAllRemainingImmediately; //"All in" is only for Bulk
      }
      else {
        model.fmRetryWindow = campaign.fmRetryWindow;
      }

      //Always set "All in" for Simple.
      if(campaignLocal.isSimple) {
        model.sendWinnerToAllRemainingImmediately = true;
      }

      //Only add signatureRuleId, if populated.
      if(campaign.signatureRuleId) {
        model.signatureRuleId = campaign.signatureRuleId;
        model.signatureRuleName = campaign.signatureRuleName;
      }

      //Add check for updating FM rule.
      if(model.overrideSendFrequencyCheck) {
        //If overriding FM, set rule to null.
        model.selectedFrequencyManagementRuleId = null;
      }
      else {
        //If not overriding FM, set rule UUID.
        model.selectedFrequencyManagementRuleId = campaign.selectedFrequencyManagementRuleId;

        //Set prioirity rule, if defined.
        if(typeof campaign.fmPriority !== 'undefined') {
          model.fmPriority = campaign.fmPriority;
        }
      }

      //Get Motiva send time window recommendation
      if(campaign.letMotivaDecideSendTimeWindow) {
        //Make sure we have an orgId.
        var orgId = campaign.organizationId ? campaign.organizationId : currentUser.organizationId;
        this.organizationService.getRecommendedSendTimeRestrictions(orgId, campaignLocal.restrictSendTimes, campaign.timezone).then(
          () => {
            model.sendTimeConstraints = this.campaignDatesService.convertLocalToSendTimesMatrix(campaignLocal);
            resolve(model);
          },
          error => {
            reject(error);
          }
        );
      }
      else {
        model.sendTimeConstraints = this.campaignDatesService.convertLocalToSendTimesMatrix(campaignLocal);
        resolve(model);
      }
    });

    return promise;
  }

  //The campaignLocal data struct contains campaign values that are calcaulted
  //locally on the client.
  initCampaignLocal(campaign) {
    var type = campaign.optimizationCriterion === 'sendTime' ? 'sto' : 'mt';
    var campaignLocal = {
      isSimple: this.isSimple(campaign),
      totalNumberOfContacts: null,
      notEnoughContactsPerTreatment: false,
      //date info
      currentDate: moment.tz(campaign.timezone),
      currentDay: null,
      startDateTimezone: null, //moment.js object using the correct timezone
      scheduledStartLocal: null, //js date showing the "local equivalent" of the date in the campaign's timezone
      endDateTimezone: null, //moment.js object using the correct timezone
      scheduledEndLocal: null, //js date showing the "local equivalent" of the date in the campaign's timezone
      lengthDays: null,
      numDaysWithSends: null,
      restrictSendTimes: {
        active: type === 'mt',
        startHour: type === 'mt' ? 9 : 0, //24-hour
        endHour: type === 'mt' ? 17 : 23, //24-hour
        daysOfTheWeek: {
          sunday: true,
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: true
        }
      },
      //contact counts
      unhandledCount: 0,
      frequencyManagedCount: 0,
      blockedByEloquaCount: 0,
      gotContactCounts: false
    };

    this.addTotalNumberOfContacts(campaign, campaignLocal);
    this.campaignDatesService.convertSendTimesMatrixToLocal(campaign, campaignLocal);
    return campaignLocal;
  }

  addTotalNumberOfContacts(campaign, campaignLocal) {
    if(typeof campaign.recentActionServiceNotifyCount !== 'undefined') {
      campaignLocal.totalNumberOfContacts = 0;
      if(campaign.recentActionServiceNotifyCount && campaign.recentActionServiceNotifyCount.length > 0) {
        campaign.recentActionServiceNotifyCount.forEach(counter => {
          campaignLocal.totalNumberOfContacts += counter.count;
        });
      }
    }
  }

  allStatuses() {
    return this.statuses;
  }

  isUpdateable(status) {
    if(typeof status === 'undefined' || status === '' || status === 'draft') {
      return true;
    }
    return false;
  }

  isActive(status) {
    if(this.activeStatuses.indexOf(status) !== -1) {
      return true;
    }
    return false;
  }

  isPossiblyDone(status) {
    if(this.possiblyDoneStatuses.indexOf(status) !== -1) {
      return true;
    }
    return false;
  }

  isSimple(campaign) {
    var result = false;

    //Both Simple Bulk and Drip have sendWinnerToAllRemainingImmediately set to true.
    if(
      campaign.optimizationCriterion !== 'sendTime'
      && campaign.emails.length === 1
      && typeof campaign.sendWinnerToAllRemainingImmediately !== 'undefined'
      && campaign.sendWinnerToAllRemainingImmediately
    ) {
      result = true;
    }

    return result;
  }

  isError(campaign) {
    if(campaign && this.errorStatuses.indexOf(campaign.status) !== -1) {
      return true;
    }
    return false;
  }

  canArchive(campaign) {
    if(
      campaign
      && this.archiveStatuses.indexOf(campaign.status) !== -1
      && campaign.campaignId !== '11111111-1111-1111-1111-111111111111'
      && campaign.campaignId !== 'a2666cc7-53b7-492e-98f3-8f90c3eda081'
    ) {
      return true;
    }
    return false;
  }

  decodeStatus(status) {
    var decode = status.replace(/^./, status[0].toUpperCase());
    switch (status) {
    case 'settingUp':
      decode = 'Initializing';
      break;

    case 'insufficientContacts':
      decode = 'Insufficient Contacts';
      break;

    case 'failedStart':
      decode = 'Failed to Start';
      break;

    case 'failedRun':
      decode = 'Error';
      break;

    case 'finishedExperimentNowCollecting':
      decode = 'Collecting Results';
      break;

    case null:
    case undefined:
      decode = 'Draft';
      break;
    }

    return decode;
  }

  confidenceThresholds() {
    return this._confidenceThresholds;
  }

  //Add user information to the campaign list.
  addUserInfo(campaigns) {
    var promises = [];

    //First, find all users.
    var users = {};
    campaigns.forEach(campaign => {
      var id = `${campaign.organizationId}::${campaign.uid}`;
      if(!users.hasOwnProperty(id)) {
        users[id] = {
          uid: campaign.uid,
          organizationId: campaign.organizationId,
          campaigns: [campaign]
        };
      }
      else {
        users[id].campaigns.push(campaign);
      }
    });

    //Then update them in the campaign.
    for(var key in users) {
      var user = users[key];
      promises.push(this.updateUserInfo(user.uid, user.organizationId, user.campaigns));
    }

    return Promise.all(promises);
  }

  addOrganizationInfo(campaigns) {
    var promises = [];

    //First, find all organizations.
    var organizations = {};
    campaigns.forEach(campaign => {
      var id = campaign.organizationId;
      if(!organizations.hasOwnProperty(id)) {
        organizations[id] = {
          organizationId: campaign.organizationId,
          campaigns: [campaign]
        };
      }
      else {
        organizations[id].campaigns.push(campaign);
      }
    });

    //Then update them in the campaign.
    for(var key in organizations) {
      var organization = organizations[key];
      promises.push(this.updateOrganizationInfo(organization.organizationId, organization.campaigns));
    }

    return Promise.all(promises);
  }

  //***********************************
  // Campaign Results Handling
  //***********************************

  getResults(campaign, campaignLocal) {
    this.startLoading();

    var isSimple = this.isSimple(campaign);
    var show = false;

    const promise = new Promise((resolve, reject) => {
      this.http
        .get(`/core-api/api/campaign/${campaign.campaignId}/report`)
        .toPromise()
        .then(
          report => {
            this.stopLoading();
            //Make sure we have report data...
            if(!report || typeof report.multiArmedBandit === 'undefined' || report.multiArmedBandit === null || report.multiArmedBandit.length === 0) {
              resolve({ show: false });
              return;
            }
            //First entry is just the initialization.
            else if(report.multiArmedBandit.length > 1) {
              show = true;
              this.campaignDatesService.setResultsDateParams(report.multiArmedBandit, campaign, campaignLocal);
            }
            else {
              show = false;
            }

            // If there are more than 30 days of results, reduce to 1 entry per day
            var tmpReport = [];
            if(report.multiArmedBandit.length > 700) {
              var count = 0;
              var currentDate = new Date(report.multiArmedBandit[0].resultDateTime);
              report.multiArmedBandit.forEach(result => {
                var date = new Date(result.resultDateTime);
                if(date.getDate() === currentDate.getDate()) {
                  tmpReport[count] = result;
                }
                else {
                  count++;
                  currentDate = date;
                  tmpReport[count] = result;
                }
              });
            }
            else {
              tmpReport = report.multiArmedBandit;
            }

            var results = this.transformResults(
              tmpReport,
              report.emailInfo,
              campaignLocal.currentDate,
              campaignLocal.scheduledStartLocal,
              campaignLocal.scheduledEndLocal,
              campaign.timezone,
              campaignLocal.lengthDays,
              campaign.optimizationCriterion,
              campaign.confidenceThreshold,
              isSimple
            );

            //For MT only, if any treatment has less than MIN_CONTACTS_PER_TREATMENT_PER_BATCH, hide results.
            if(!isSimple && campaign.optimizationCriterion !== 'sendTime') {
              results.treatmentDetails.forEach(treatment => {
                if(treatment.hideMyRates) {
                  show = false;
                  if(treatment.sends < this.MIN_CONTACTS_PER_TREATMENT_PER_BATCH) campaignLocal.notEnoughContactsPerTreatment = true;
                }
              });
            }

            results.show = show;
            resolve(results);
          },
          //Error Handling...
          error => {
            this.stopLoading();
            this.loggerService.logMessage('Error getting Campaign results', 'error', error);
            console.error('There was an error!', error);
            reject(error);
          }
        );
    });

    return promise;
  } //end: getResults()

  /**
   * Transform the results from the /report endpoint.
   *
   *  -- Convert date/times into their local tz equivalent, so they can be displayed in the campaign's tz -- not the user's tz.
   *  -- Calculate incremental sends.
   *  -- Aggreate the numberical results for totals.
   *  -- Calculate a few kpi's for the totals.
   *
   */
  transformResults(report, emailInfo, currentDate, startDate, endDate, timezone, lengthDays, optimizationCriterion, confidenceThreshold, isSimple) {
    this.sortResults(report);

    var resultsData = this.initResultsData(report, emailInfo, startDate, endDate, lengthDays);
    var kpiToCheck = optimizationCriterion === 'opens' ? 'treatmentProbabilityOpen' : 'treatmentProbabilityClickthrough';

    //Loop over the report data
    for(var i = 0; i < report.length; i++) {
      var result = report[i];

      //Convert all date/times to local tz equivalents.
      var resultDateTz = moment.tz(result.resultDateTime, timezone);
      var resultDate = getLocalEquivalentFromMoment(resultDateTz);

      var obj = {
        resultDateTime: resultDate,
        totals: result.totals
      };

      //Loop over treatments.
      for(var treatment in resultsData.treatments) {
        if(!obj.hasOwnProperty(treatment)) obj[treatment] = {};

        //Add data for each KPI
        for(var kpi in campaignKpis) {
          var value = 0;

          //Check for missing values
          if(typeof result[treatment] !== 'undefined' && typeof result[treatment][kpi] !== 'undefined') {
            value = result[treatment][kpi];
          }
          else {
            value = null;
          }

          obj[treatment][kpi] = value;

          //Mark the result entry where we found the winner and went all-in.
          if(kpi === kpiToCheck && value > confidenceThreshold && !resultsData.winnerFound && report[report.length - 1][treatment][kpi] > confidenceThreshold) {
            obj.winnerFound = true;
            resultsData.winnerFound = true;
          }
        }
      }

      resultsData.trends.push(obj);
    }

    if(resultsData.trends.length > 0) {
      resultsData.latest = resultsData.trends[resultsData.trends.length - 1];
    }

    this.removePriorInfluence(resultsData);
    this.buildDetails(resultsData, optimizationCriterion, timezone, isSimple);
    this.findTopPerformers(optimizationCriterion, confidenceThreshold, resultsData);
    this.setColors(resultsData);

    return resultsData;
  } //end: transformResults()

  //***************************************
  // Local / Private methods
  //
  // There is currently no way to make
  // private methods in js.
  //***************************************

  sortResults(report) {
    report.sort(function(a, b) {
      var aDate = new Date(a.resultDateTime);
      var bDate = new Date(b.resultDateTime);
      return aDate - bDate;
    });
  }

  getFirstLastDaysOfCampaign(report) {
    var firstLastDays = [];

    if(report.length > 0) {
      //resultDate doesn't have time.
      var firstDay = new Date(report[0].resultDateTime.substr(0, 4), report[0].resultDateTime.substr(5, 2) - 1, report[0].resultDateTime.substr(8, 2));
      firstDay.setHours(0, 0, 0, 0);

      var lastDay = new Date(report[report.length - 1].resultDateTime.substr(0, 4), report[report.length - 1].resultDateTime.substr(5, 2) - 1, report[report.length - 1].resultDateTime.substr(8, 2));
      lastDay.setHours(0, 0, 0, 0);
    }

    firstLastDays.push(firstDay);
    firstLastDays.push(lastDay);

    return firstLastDays;
  } //end: getFirstLastDaysOfCampaign()

  initResultsData(report, emailInfo, startDate, endDate, lengthDays) {
    var reportStartDate;
    var reportEndDate;
    var reportDays = lengthDays;
    var postCampaignDays = 0;

    //Get number of days of data
    var firstLastDays = this.getFirstLastDaysOfCampaign(report);
    var tmpStartDate = firstLastDays[0];
    var tmpEndDate = firstLastDays[1];
    var daysOfData = daysBetweenDates(tmpStartDate, tmpEndDate);

    if(startDate) {
      reportStartDate = new Date(startDate.getTime());
    }
    else {
      //If startDate is not set, use first day of data.
      reportStartDate = tmpStartDate;
      reportDays = daysBetweenDates(reportStartDate, tmpEndDate);
    }

    if(endDate) {
      reportEndDate = new Date(endDate.getTime());
    }
    else {
      reportEndDate = null;
    }

    //Check if more days data than startDate/endDate difference.
    if(daysOfData > reportDays) {
      postCampaignDays = daysOfData - reportDays;
    }

    return {
      params: {
        startDate: reportStartDate,
        endDate: reportEndDate,
        lengthDays: reportDays,
        postCampaignDays
      },
      kpis: {},
      treatments: emailInfo,
      trends: [],
      latest: { totals: {} },
      topPerformers: false,
      winnerFound: false
    };
  }

  buildDetails(resultsData, optimizationCriterion, timezone, isSimple) {
    var treatmentMap = {};

    //Build a list for the details table...
    resultsData.treatmentDetails = [];

    var hideConfidence = false;
    var onlyPriorOpens = false;
    var onlyPriorClicks = false;

    var numberOfTreatments = Object.keys(resultsData.treatments).length;

    for(var key in resultsData.treatments) {
      if(resultsData.treatments.hasOwnProperty(key)) {
        var email = resultsData.treatments[key];
        var latest = resultsData.latest[key];
        var lastUsedTz = moment.tz(email.lastUsed, timezone);
        var latestObj = {
          id: key,
          name: email.name,
          showTrend: true,
          topPerformer: false,
          subject: email.subject,
          sends: latest.sends,
          deliveries: latest.deliveries,
          bouncebacks: latest.bouncebacks,
          unsubscribes: latest.unsubscribes,
          opens: latest.opens,
          openRate: latest.openRate,
          expectedValueOpenRate: latest.expectedValueOpenRate,
          treatmentProbabilityOpen: latest.treatmentProbabilityOpen,
          clickthroughs: latest.clickthroughs,
          clickthroughRate: latest.clickthroughRate,
          expectedValueCtRate: latest.expectedValueCtRate,
          treatmentProbabilityClickthrough: latest.treatmentProbabilityClickthrough,
          optimizationCriterion,
          hideMyRates: false,
          active: numberOfTreatments == 1 ? true : latest.active, // If only one treatment, it is always active
          lastUsed: getLocalEquivalentFromMoment(lastUsedTz)
        };

        //If any MT treatment is below the minimum sends (or deliveries?), hide rates and confidence everywhere.
        if(!isSimple && latestObj.sends < this.MIN_CONTACTS_PER_TREATMENT_PER_BATCH - this.MIN_CONTACTS_DIFF) {
          //Check if all rates are exactly equal to the priors.
          if(this.decimalPipe.transform(latest.expectedValueOpenRate * 100, '1.1-1') === '20.0') onlyPriorOpens = true;
          if(this.decimalPipe.transform(latest.expectedValueCtRate * 100, '1.1-1') === '5.0') onlyPriorClicks = true;

          latestObj.hideMyRates = true;
          hideConfidence = true;
        }

        resultsData.treatmentDetails.push(latestObj);
        treatmentMap[key] = latestObj;
      }
    }

    //Add hideConfidence to all detail records.
    for(var keyInMap in treatmentMap) {
      if(treatmentMap.hasOwnProperty(keyInMap)) {
        treatmentMap[keyInMap].hideConfidence = hideConfidence;

        //hide rates for MT emails if everything is still set to the priors.
        if(!isSimple && (onlyPriorOpens || onlyPriorClicks)) treatmentMap[keyInMap].hideMyRates = true;
      }
    }
  } //end: buildDetails()

  /**
   * Remove the influence of the priors, by only showing rates if there are
   * more than MIN_CONTACTS_PER_TREATMENT_PER_BATCH per treatment.
   */
  removePriorInfluence(resultsData) {
    resultsData.trends.forEach(result => {
      for(var treatment in resultsData.treatments) {
        //If less than the minimum total sends for any record, don't show the rates.
        if(result[treatment].sends < this.MIN_CONTACTS_PER_TREATMENT_PER_BATCH - this.MIN_CONTACTS_DIFF || result[treatment].sends === null) {
          result[treatment].expectedValueOpenRate = null;
          result[treatment].expectedValueCtRate = null;

          //also hide credible intervals
          result[treatment].credibleIntervalMinOpen = null;
          result[treatment].credibleIntervalMaxOpen = null;
          result[treatment].credibleIntervalMinCt = null;
          result[treatment].credibleIntervalMaxCt = null;
        }
      }
    });
  } //end: removePriorInfluence()

  /**
   * Mark the top X treatments in treatmentDetails as top performers.
   *
   */
  findTopPerformers(optimizationCriterion, confidenceThreshold, resultsData) {
    //If we don't have enough volume on each treatment, don't show topPerformers.
    if(resultsData.treatmentDetails[0].hideConfidence) return;

    var optimizationAttribute = optimizationCriterion === 'opens' ? 'treatmentProbabilityOpen' : 'treatmentProbabilityClickthrough';

    //First sort by optimization criterion.
    resultsData.treatmentDetails.sort(function(a, b) {
      if(a[optimizationAttribute] < b[optimizationAttribute]) return 1;
      if(a[optimizationAttribute] > b[optimizationAttribute]) return -1;
      return 0;
    });

    resultsData.topTreatments = [];
    var totalConfidence = 0;

    //Loop over all
    for(var i = 0; i < resultsData.treatmentDetails.length; i++) {
      if(resultsData.treatmentDetails[i]) {
        resultsData.treatmentDetails[i].topPerformer = true;
        resultsData.topTreatments.push(resultsData.treatmentDetails[i]);

        totalConfidence += resultsData.treatmentDetails[i][optimizationAttribute];
      }

      //List of topTreatmetns includes the smallest number of treatments,
      // whose sum of their confidence is greater than the confidence threshold.
      if(totalConfidence > confidenceThreshold) {
        break;
      }
    }

    //Indicate "topPerformers", if the # of treatments to hit the confidenceThreshold is
    // less than or equal to the total number of treatments.
    if(resultsData.topTreatments.length < resultsData.treatmentDetails.length || resultsData.topTreatments[0][optimizationAttribute] > confidenceThreshold) {
      resultsData.topPerformers = true;
    }
  } //end: findTopPerformers()

  setColors(resultsData) {
    var colorIdx = 0;

    //Set colors based on Name, so they don't change based on results.
    resultsData.treatmentDetails.sort(function(a, b) {
      if(a.name < b.name) return -1;
      if(a.name > b.name) return 1;
      return 0;
    });

    resultsData.colors = {};

    //Set the colors
    resultsData.treatmentDetails.forEach(function(treatment) {
      if(typeof treatment.color === 'undefined') {
        treatment.color = COLOR_PATTERN[colorIdx++];
      }

      resultsData.colors[treatment.id] = treatment.color;
    });
  } //end: setColors()

  /**
   * Add the contact counts from a seperate API call to the campaignLocal object.
   *
   */
  getContactCounts(campaign, campaignLocal, results) {
    this.http
      .get(`/core-api/api/campaign/${campaign.campaignId}/contact-counts`)
      .toPromise()
      .then(
        contactCounts => {
          campaignLocal.frequencyManagedCount = contactCounts.numberOfContactsBlockedByFrequencyManager ? contactCounts.numberOfContactsBlockedByFrequencyManager : 0;
          campaignLocal.blockedByEloquaCount = contactCounts.numberOfContactsBlockedByEloqua ? contactCounts.numberOfContactsBlockedByEloqua : 0;

          //calc for contact bar
          if(campaign.status !== 'draft') {
            campaignLocal.unhandledCount = campaign.uniqueContactCount - campaignLocal.frequencyManagedCount - campaignLocal.blockedByEloquaCount;

            if(typeof results !== 'undefined' && typeof results.latest !== 'undefined') {
              campaignLocal.unhandledCount -= results.latest.totals.sends;
            }
          }

          campaignLocal.gotContactCounts = true;
        },
        //Error Handling...
        error => {
          this.stopLoading();
          this.loggerService.logMessage('Error getting Campaign results', 'error', error);
          console.error('There was an error!', error);
        }
      );
  }

  /**
   * Add user details to the campaign list.
   *
   */
  updateUserInfo(uid, organizationId, campaigns) {
    const promise = new Promise((resolve, reject) => {
      if(this.userNames[`${organizationId}::${uid}`]) {
        campaigns.forEach(campaign => {
          campaign.createdBy = this.userNames[`${organizationId}::${uid}`];
        });
        resolve();
      }
      else {
        this.userService
          .get({ id: uid })
          .toPromise()
          .then(user => {
            this.userNames[`${organizationId}::${uid}`] = user.name;
            campaigns.forEach(campaign => {
              campaign.createdBy = user.name;
            });
            resolve();
          })
          .catch(err => {
            if(err.status === 404) {
              console.log(`User: ${uid} not found.`);
              resolve();
            }
            else {
              console.log(err);
              reject(err);
            }
          });
      }
    });

    return promise;
  } //end: updateUserInfo()

  getUserNames() {
    return this.userNames;
  }

  /**
   * Add org details to the active campaign list.
   *
   */
  updateOrganizationInfo(organizationId, campaigns) {
    const promise = new Promise((resolve, reject) => {
      if(this.organizationNames[organizationId]) {
        campaigns.forEach(campaign => {
          campaign.organizationName = this.organizationNames[organizationId];
        });
        resolve();
      }
      else {
        this.orgService
          .get(organizationId)
          .toPromise()
          .then(organization => {
            this.organizationNames[organizationId] = organization.name;

            campaigns.forEach(campaign => {
              campaign.organizationName = organization.name;
            });

            resolve();
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      }
    });

    return promise;
  } //end: updateOrganizationInfo();

  /**
   * Export the Ops View.
   *
   */
  getExportData(treatmentDetails) {
    var locale = null;
    var exportData = [];

    treatmentDetails.forEach(treatment => {
      var obj = {
        id: treatment.id,
        name: treatment.name,
        subject: treatment.subject,
        sends: this.decimalPipe.transform(treatment.sends, '1.0-0'),
        deliveries: this.decimalPipe.transform(treatment.deliveries, '1.0-0'),
        bouncebacks: this.decimalPipe.transform(treatment.bouncebacks, '1.0-0'),
        unsubscribes: this.decimalPipe.transform(treatment.unsubscribes, '1.0-0'),
        opens: this.decimalPipe.transform(treatment.opens, '1.0-0'),
        openRate: this.decimalPipe.transform(treatment.expectedValueOpenRate * 100, '1.1-2'),
        treatmentProbabilityOpen: this.decimalPipe.transform(treatment.treatmentProbabilityOpen * 100, '1.1-1'),
        clickthroughs: this.decimalPipe.transform(treatment.clickthroughs, '1.0-0'),
        clickthroughRate: this.decimalPipe.transform(treatment.expectedValueCtRate * 100, '1.1-2'),
        treatmentProbabilityClickthrough: this.decimalPipe.transform(treatment.treatmentProbabilityClickthrough * 100, '1.1-1')
      };

      exportData.push(obj);
    });

    return exportData;
  }

  getExportHeaders() {
    return [
      'Email ID', 'Name', 'Subject',
      'Sends', 'Delivered', 'Bouncebacks', 'Unsubscribes',
      'Opens', 'Open Rate', 'Confidence wrt Opens',
      'Clicks', 'Clickthrough Rate', 'Confidence wrt Clicks'
    ];
  }
}
