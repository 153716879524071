import { Component, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { sortBy } from 'lodash';

@Component({
  selector: 'cdo-details',
  template: require('./cdo-details.html')
})
export class CdoDetailsComponent {
  static parameters = [BsModalRef, HttpClient];
  constructor(bsModalRef: BsModalRef, http: HttpClient) {
    this.bsModalRef = bsModalRef;
    this.http = http;

    this.cdoDetails = null;
  }

  ngOnInit() {
    this.http
      .get(`/eloqua-api/api/export/custom-object/${this.cdoId}?orgId=${this.orgId}`)
      .toPromise()
      .then(
        result => {
          if(result) {
            this.cdoDetails = result;
            this.cdoDetails.fields = sortBy(this.cdoDetails.fields, 'field-id');
            this.updatedAt = new Date(this.cdoDetails['source-updated-at']);
          }
        },
        //Error Handling...
        error => {
          console.log('Error getting Custom Object details', error);
        }
      );
  }

  done() {
    this.bsModalRef.hide();
  }
}
