class ContactFieldCoverageCount {
  key: string;
  coun: number;
}

export class Population {
  contactFieldCoverageCounts: ContactFieldCoverageCount[] = [];
  eloquaInstancePopulationSize: number;
  populationSize: number;
  updatedAt: string;
}
