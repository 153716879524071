import { Component, Input, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ngxCsv } from 'ngx-csv/ngx-csv';

import { AuthService } from '../../../../components/auth/auth.service';
import { ImpersonationService } from '../../../../components/auth/impersonation.service';

import { DarkPoolService } from '../dark-pool.service';
import { DarkPoolBotDetailsComponent } from '../dark-pool-bot-details/dark-pool-bot-details.component';

import { cloneDeep, orderBy } from 'lodash';

@Component({
  selector: 'dark-pool-bot-detection',
  template: require('./dark-pool-bot-detection.html')
})
export class DarkPoolBotDetection implements OnInit {
  @ViewChild('detailsTmpl', { static: true }) detailsTmpl: TemplateRef<any>;
  @ViewChild('domainTmpl', { static: true }) domainTmpl: TemplateRef<any>;
  @ViewChild('integerTmpl', { static: true }) integerTmpl: TemplateRef<any>;
  @ViewChild('isScannerHeaderTmpl', { static: true }) isScannerHeaderTmpl: TemplateRef<any>;
  @ViewChild('openRateHeaderTmpl', { static: true }) openRateHeaderTmpl: TemplateRef<any>;
  @ViewChild('openRateNoCampaignHeaderTmpl', { static: true }) openRateNoCampaignHeaderTmpl: TemplateRef<any>;
  @ViewChild('percentTmpl', { static: true }) percentTmpl: TemplateRef<any>;

  @Input() currentOrg;
  @Input() visible;

  static parameters = [BsModalService, AuthService, DarkPoolService, ImpersonationService];
  constructor(modalService: BsModalService, authService: AuthService, darkPoolService: DarkPoolService, impersonation: ImpersonationService) {
    this.modalService = modalService;
    this.authService = authService;
    this.darkPoolService = darkPoolService;
    this.impersonation = impersonation;

    this.isDemo = false;

    this.rows = [];
    this.loading = true;
    this.loadingDetails = false;
    this.searchString = '';
    this.page = {
      number: 0,
      numberPlusOne: 1,
      size: 15,
      sizes: [10, 15, 25, 50, 100, 250],
      totalPages: 1
    };

    this.darkPoolTimeRange = {};
  }

  ngOnInit() {
    this.isDemo = this.impersonation.isDemoMode();
    this.authService.getCurrentUser().then(user => {
      this.currentUser = user;
    });

    //Column defs for the bots grid
    this.columns = [
      { prop: 'contactId', name: 'Contact ID', sortable: false },
      { prop: 'domain', name: 'Domain', cellTemplate: this.domainTmpl },
      { prop: 'formSubmits', name: 'Form Submits', cellClass: 'rightAlign', headerClass: 'darkPoolHeaderAttention rightAlign', cellTemplate: this.integerTmpl },
      { prop: 'uniqueForms', name: 'Unique Forms', cellClass: 'rightAlign', headerClass: 'darkPoolHeaderAttention rightAlign', cellTemplate: this.integerTmpl },
      { prop: 'uniqueOpenRateInCampaigns', name: 'Unique Open Rate', cellClass: 'rightAlign', headerClass: 'darkPoolHeaderAttention rightAlign', cellTemplate: this.percentTmpl },
      { prop: 'openRateInCampaigns', name: 'Open Rate', cellClass: 'rightAlign', headerClass: 'darkPoolHeaderAttention rightAlign', cellTemplate: this.percentTmpl, headerTemplate: this.openRateHeaderTmpl },
      //{ prop: 'openRateNoCampaign', name: 'Open Rate (No Campaign)', cellClass: 'rightAlign', headerClass: 'darkPoolHeaderAttention rightAlign', cellTemplate: this.percentTmpl, headerTemplate: this.openRateNoCampaignHeaderTmpl },
      { prop: 'isScanner', name: 'Scanner Present', headerClass: 'darkPoolHeaderAttention centerAlign', cellClass: 'centerAlign', headerTemplate: this.isScannerHeaderTmpl },
      { prop: 'contactId', name: 'Contact Details', headerClass: 'darkPoolHeaderAttention centerAlign', cellClass: 'centerAlign', cellTemplate: this.detailsTmpl, sortable: false }
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'visible') {
        //Lazy load.
        if(this.visible) {
          if(this.isDemo) {
            this.loadDemoData();
            return;
          }

          // Get DP time range
          this.darkPoolService.getDarkPoolTimeRange(this.currentOrg._id).then(timeRange => {
            this.darkPoolTimeRange.startDate = timeRange.start_date;
            this.darkPoolTimeRange.endDate = timeRange.end_date;

            this.loadData();
          });
        }
      }
    }
  }

  onPageChange(event) {
    this.page.number = event.offset;
    this.page.numberPlusOne = this.page.number + 1;
    this.onPageSizeChange();
  }

  onPageSizeChange() {
    this.page.totalPages = Math.ceil(this.rows.length / this.page.size);
  }

  onPageNumberChange() {
    this.page.number = this.page.numberPlusOne - 1;
  }

  loadData() {
    this.rows = [];
    this.loading = true;
    this.page.number = 0;
    this.page.numberPlusOne = 1;

    //TODO: Where to get the periodIndex?
    this.darkPoolService.getBots(this.darkPoolTimeRange.startDate, this.darkPoolTimeRange.endDate).then(bots => {
      this.rows = bots;
      this.rowsCopy = cloneDeep(bots);
      this.onPageSizeChange();
      this.loading = false;
    })
      .catch(e => {
        console.log('Exception retrieving bots.', e);
        this.loading = false;
      });
  }

  filterChanged() {
    this.rows = [];

    // Filter by search string
    var searchString = this.searchString.toLowerCase();
    if(searchString !== '') {
      function searchFilter(row) {
        return row.domain && row.domain.toLowerCase().indexOf(searchString) > -1;
      }
      this.rows = this.rowsCopy.filter(searchFilter);
    }
    else {
      this.rows = cloneDeep(this.rowsCopy);
    }
    this.onPageSizeChange();
  } //end: filterChanged()

  viewDetails(row) {
    if(this.isDemo) {
      this.loadDemoDetailsData(row);
      return;
    }

    //TODO: Where to get the periodIndex?
    this.loadingDetails = true;
    this.darkPoolService.getBotDetails(this.darkPoolTimeRange.startDate, this.darkPoolTimeRange.endDate, row.contactId).then(botDetails => {
      var formSubmitCounts = botDetails.reduce((x, cur) => {
        if(!x[cur.assetname]) x[cur.assetname] = 0;
        x[cur.assetname] = x[cur.assetname] + 1;
        return x;
      }, {});

      var botSummary = [];
      for(const key in formSubmitCounts) {
        const count = formSubmitCounts[key];
        botSummary.push({
          assetname: key,
          count
        });
      }
      botSummary = orderBy(botSummary, 'count', 'desc');

      const initialState = { botSummary, botDetails, contactId: row.contactId, domain: row.domain };
      this.modalService.show(DarkPoolBotDetailsComponent, { initialState, class: 'modal-lg' });
      this.loadingDetails = false;
    })
      .catch(e => {
        console.log('Exception retrieving bot details.', e);
        this.loadingDetails = false;
      });
  }

  exportAllContacts() {
    var headers = [
      'Eloqua Contact ID',
      'Domain',
      'Form Submits',
      'Unique Forms',
      'Unique Open Rate',
      'Open Rate',
      'Scanner Present'
    ];
    var options = {
      fieldSeparator: ',',
      showLabels: true,
      noDownload: false,
      headers
    };

    var filename = 'All Bots contacts';

    if(this.currentOrg.darkPoolSetting === 'freemium') {
      filename += '-FREEMIUM';
    }

    var results = [];

    this.rowsCopy.forEach(item => {
      results.push({
        eloquaContactId: item.eloquaContactId ? item.eloquaContactId : item.contactId,
        domain: item.domain,
        formSubmits: item.formSubmits,
        uniqueForms: item.uniqueForms,
        uniqueOpenRateInCampaigns: item.uniqueOpenRateInCampaigns ? item.uniqueOpenRateInCampaigns : '',
        openRateInCampaigns: item.openRateInCampaigns ? item.openRateInCampaigns : '',
        isScanner: !!item.isScanner
      });
    });

    new ngxCsv(results, filename, options);
  }

  loadDemoData() {
    this.rows = [];
    this.loading = true;
    this.darkPoolService.getDemoData('bots').then(bots => {
      this.rows = bots;
      this.rowsCopy = cloneDeep(bots);
      this.onPageSizeChange();
      this.loading = false;
    });
  }

  loadDemoDetailsData(row) {
    this.loadingDetails = true;
    this.darkPoolService.getDemoData('bot-details').then(botDetails => {
      var formSubmitCounts = botDetails.reduce((x, cur) => {
        if(!x[cur.assetname]) x[cur.assetname] = 0;
        x[cur.assetname] = x[cur.assetname] + 1;
        return x;
      }, {});

      var botSummary = [];
      for(const key in formSubmitCounts) {
        const count = formSubmitCounts[key];
        botSummary.push({
          assetname: key,
          count
        });
      }
      botSummary = orderBy(botSummary, 'count', 'desc');

      const initialState = { botSummary, botDetails, contactId: row.contactId, domain: row.domain };
      this.modalService.show(DarkPoolBotDetailsComponent, { initialState, class: 'modal-lg' });
      this.loadingDetails = false;
    })
      .catch(e => {
        console.log('Exception retrieving bot details.', e);
        this.loadingDetails = false;
      });
  }
}
