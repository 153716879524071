// @flow
import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { AuthService } from '../../../../components/auth/auth.service';
import { ImpersonationService } from '../../../../components/auth/impersonation.service';

import { PersonaService } from '../persona.service';
import { PersonaDefinitionComponent } from '../persona-definition/persona-definition.component';

import { cloneDeep } from 'lodash';
import { ngxCsv } from 'ngx-csv/ngx-csv';

@Component({
  selector: 'persona-report',
  template: require('./persona-report.html')
})
export class PersonaReportComponent implements OnInit {
  @Input() orgContactFieldsLocal;
  @Input() population;

  bsModalRef: BsModalRef;

  static parameters = [PersonaService, BsModalService, AuthService, ImpersonationService];
  constructor(personaService: PersonaService, modalService: BsModalService, authService: AuthService, impersonation: ImpersonationService) {
    this.personaService = personaService;
    this.modalService = modalService;
    this.authService = authService;
    this.impersonation = impersonation;
    this.isDemo = false;
  }

  ngOnInit() {
    this.init();
    this.isDemo = this.impersonation.isDemoMode();

    this.authService.getCurrentOrg().then(org => {
      this.currentOrg = org;
      if(!this.isDemo) {
        this.loadPersonas();
      }
      else {
        this.loadDemo();
      }
    });

    this.isAdmin = false;
    this.authService.isAdmin().then(is => {
      this.isAdmin = is;
    });
  }

  init() {
    this.personas = [];
    this.currentPersonaLocal = {};

    this.loading = true;
    this.loadingTopEmails = true;

    this.rateToggle = 'openRate';

    this.exportTypes = [{
      display: 'Persona Data',
      value: 'allData'
    }, {
      display: 'Contacts',
      value: 'contacts'
    }];

    this.contactCountThreshold = 0.5;
  }

  loadPersonas() {
    this.personaService.getPersonas(this.currentOrg._id).then(personas => {
      this.personas = personas;
      this.sortPersonas();

      if(this.personas && this.personas.length > 0) {
        this.currentPersonaLocal = personas[0];
        this.showReport(this.currentPersonaLocal);
      }

      this.personaService.getOverallOrgStats(this.currentOrg._id).then(orgStats => {
        this.overallOrgStats = orgStats;
        this.personaService.setRateComparisonArrows(this.overallOrgStats, this.personas);
        this.personasFiltered = cloneDeep(this.personas);
        this.loading = false;
      });
    });
  }

  sortPersonas() {
    this.personas.sort((a, b) => {
      if(a.personaStats[this.rateToggle] > b.personaStats[this.rateToggle]) return -1;
      else if(a.personaStats[this.rateToggle] < b.personaStats[this.rateToggle]) return 1;
      return 0;
    });

    this.personasFiltered = cloneDeep(this.personas);
    this.loading = false;
  }

  rateToggleChanged() {
    this.loading = true;
    this.searchString = '';
    this.sortPersonas();
    this.showReport(this.currentPersonaLocal);
  }

  filterPersonas() {
    var searchString = this.searchString.toLowerCase();
    function searchFilter(persona) {
      return persona.name && persona.name.toLowerCase().indexOf(searchString) > -1;
    }
    this.personasFiltered = cloneDeep(this.personas.filter(searchFilter));
  }

  changeContactCountThreshold(threshold) {
    this.contactCountThreshold = threshold;
    this.showReport(this.currentPersonaLocal);
  }

  definePersona() {
    var initialState = {
      visibleContactFields: this.orgContactFieldsLocal,
      population: this.population,
      persona: null,
      isDemo: this.isDemo
    };

    this.bsModalRef = this.modalService.show(PersonaDefinitionComponent, {
      initialState,
      class: 'modal-lg',
      id: 1
    });

    var sub = this.modalService.onHidden.subscribe(() => {
      if(this.bsModalRef.content.okClicked && !this.isDemo) {
        var newPersona = this.bsModalRef.content.personaLocal;
        newPersona.isUpdating = true;
        newPersona.isNewPersona = true;

        // save current persona ids
        var currentPersonaIds = [];
        this.personas.forEach(persona => {
          currentPersonaIds.push(persona.personaId);
        });

        this.personas.push(newPersona);
        this.personasFiltered = cloneDeep(this.personas);

        var model = this.personaService.getPersonaModelForSave(this.bsModalRef.content.personaLocal);
        this.personaService.savePersona(this.currentOrg._id, model).then(() => {
          console.log('Persona saved successfully');
          if(this.bsModalRef.content.personaLocal.definitionType == 'attributes') {
            this.reloadPersonas();
          }
          else {
            // For segment persona, get new Id and wait for report update
            var newPersonaId = '';
            this.personaService.getPersonas(this.currentOrg._id).then(personas => {
              personas.forEach(persona => {
                if(currentPersonaIds.indexOf(persona.personaId) < 0) {
                  newPersonaId = persona.personaId;
                  this.waitForPersonaUpdate(newPersonaId);
                }
              });
            });
          }
        },
        err => {
          console.log('Error saving persona', err);
        });

        if(sub) sub.unsubscribe();
      }
    });
  }

  showReport(persona) {
    this.loadingTopEmails = true;
    this.currentPersonaLocal = persona;

    if(!this.currentPersonaLocal.isUpdating && !this.isDemo) {
      this.personaService.getTopEmailsForPersona(
        persona.personaId,
        this.currentOrg._id,
        this.rateToggle,
        this.contactCountThreshold,
        20
      ).then(response => {
        this.currentPersonaLocal.topEmails = response;

        // Add email asset urls
        var url = this.authService.getEloquaInstanceUrl();
        if(url) {
          this.currentPersonaLocal.topEmails.forEach(email => {
            email.eloquaAssetUrl = `${url}/Main.aspx#emails&id=${email.treatment_id}`;
          });
        }

        this.loadingTopEmails = false;
      },
      err => {
        console.log('Error getting top emails for persona', err);
      });
    }
    else if(this.isDemo) {
      this.personaService.getDemoTopEmails(this.rateToggle, this.currentPersonaLocal.personaId).then(response => {
        this.currentPersonaLocal.topEmails = response;

        var url = this.authService.getEloquaInstanceUrl();
        if(url) {
          this.currentPersonaLocal.topEmails.forEach(email => {
            email.eloquaAssetUrl = `${url}/Main.aspx#emails&id=${email.treatment_id}`;
          });
        }

        this.loadingTopEmails = false;
      });
    }
    else {
      this.loadingTopEmails = false;
    }
  }

  updatePersona(persona) {
    var initialState = {
      visibleContactFields: this.orgContactFieldsLocal,
      population: this.population,
      persona,
      isDemo: this.isDemo
    };

    this.bsModalRef = this.modalService.show(PersonaDefinitionComponent, {
      initialState,
      class: 'modal-lg',
      id: 1
    });

    var sub = this.modalService.onHidden.subscribe((reason) => {
      // Make sure that onHidden was called by PersonaDefinition modal
      if(reason.id == 1 && !this.isDemo) {
        if(this.bsModalRef.content.okClicked) {
          // Update persona
          var model = this.personaService.getPersonaModelForSave(this.bsModalRef.content.personaLocal);
          this.personaService.updatePersona(this.currentOrg._id, this.bsModalRef.content.personaLocal.personaId, model).then(() => {
            console.log('Persona updated successfully');
            if(this.bsModalRef.content.personaLocal.definitionType == 'segment'
               && (persona.definitionType != this.bsModalRef.content.personaLocal.definitionType
                   || persona.segmentId != this.bsModalRef.content.personaLocal.segment.id)) {
              this.reloadPersonas(this.bsModalRef.content.personaLocal.personaId);
              this.waitForPersonaUpdate(this.bsModalRef.content.personaLocal.personaId);
            }
            else {
              this.reloadPersonas();
            }
          },
          err => {
            console.log('Error updating persona', err);
          });
        }
        if(this.bsModalRef.content.okClicked || this.bsModalRef.content.deletedPersona) {
          this.loading = true;
          this.reloadPersonas(this.bsModalRef.content.personaLocal.personaId);
        }

        if(sub) sub.unsubscribe();
      }
    });
  }

  reloadPersonas(personaUpdatingId) {
    this.personaService.getPersonas(this.currentOrg._id).then(personas => {
      this.personas = personas;

      if(personaUpdatingId) {
        this.personas.forEach(p => {
          if(p.personaId == personaUpdatingId) {
            p.isUpdating = true;
          }
        });
      }

      this.sortPersonas();

      if(this.personas && this.personas.length > 0) {
        this.currentPersonaLocal = personas[0];
        this.showReport(this.currentPersonaLocal);
      }

      this.personaService.setRateComparisonArrows(this.overallOrgStats, this.personas);
      this.personasFiltered = cloneDeep(this.personas);
      this.loading = false;
    });
  }

  waitForPersonaUpdate(personaId) {
    var startTime = new Date();
    var intervalCount = 0;
    var personaUpdateInterval = setInterval(() => {
      if(intervalCount > 50) {
        clearInterval(personaUpdateInterval);
      }
      else {
        this.personaService.getPersonas(this.currentOrg._id).then(personas => {
          personas.forEach(persona => {
            if(persona.personaId == personaId && persona.personaStats && persona.personaStats.updatedAt) {
              var updatedAt = new Date(persona.personaStats.updatedAt);
              if(updatedAt.getTime() > startTime.getTime()) {
                clearInterval(personaUpdateInterval);
                this.reloadPersonas();
              }
            }
          });
        });
      }
      intervalCount++;
    }, 10000);
  }

  exportPersonaData() {
    var filename = 'All Personas';
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      noDownload: false,
      headers: [
        'Persona Name',
        'Contact Count',
        '% of Population',
        'Total Distinct Sends',
        'Total Distinct Opens',
        'Total Distinct Clickthroughs',
        'Open Rate',
        'Clickthrough Rate',
        'Click to Open Rate'
      ]
    };

    this.personaService.getExportData(this.personas, this.population.populationSize).then(exportData => {
      var download = new ngxCsv(exportData, filename, options);
      console.log('Exported persona data', download._options.filename);
    });
  }

  exportTopEmails() {
    this.loadingTopEmailsExportData = true;
    var filename = `Top_Emails_for_Persona_${this.currentPersonaLocal.name}`;
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      noDownload: false,
      headers: [
        'Email Id',
        'Email Name',
        'Subject Line',
        'Contact Count',
        'Total Distinct Sends',
        'Total Distinct Opens',
        'Total Distinct Clickthroughs',
        'Open Rate',
        'Clickthrough Rate',
        'Click to Open Rate'
      ]
    };

    var exportData = [];
    //Get all emails ordered by rate
    this.personaService.getTopEmailsForPersona(
      this.currentPersonaLocal.personaId,
      this.currentOrg._id,
      this.rateToggle,
      0.0,
      'all')
      .then(emails => {
        emails.forEach(email => {
          exportData.push({
            id: email.treatment_id,
            name: email.name,
            subject: email.subject,
            contactCount: email.contact_count,
            sends: email.sends,
            opens: email.opens,
            clickthroughs: email.clicks,
            openRate: email.open_rate,
            clickthroughRate: email.click_rate,
            clickToOpenRate: email.clicks / email.opens
          });
        });

        var download = new ngxCsv(exportData, filename, options);
        console.log('Exported persona top emails', download._options.filename);

        this.loadingTopEmailsExportData = false;
      });
  }

  exportPersonaContactIds() {
    var filename = `Contacts in Persona-${this.currentPersonaLocal.name}`;
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      showLabels: true,
      noDownload: false,
      headers: ['Contact Id']
    };

    var exportData = [];
    this.personaService.getPersonaContactIds(this.currentPersonaLocal.personaId).then(contactIds => {
      console.log('contactIds', contactIds);
      contactIds.forEach(contactId => {
        exportData.push(contactId);
      });

      var download = new ngxCsv(exportData, filename, options);
      console.log('Exported persona contact ids', download._options.filename);
    });
  }

  exportData(exportType) {
    if(exportType == 'allData') {
      this.exportPersonaData();
    }
    else {
      this.exportPersonaContactIds();
    }
  }

  loadDemo() {
    // Load demo
    this.personaService.getDemoPersonas().then(demoPersonas => {
      this.personas = demoPersonas;
      this.sortPersonas();

      if(this.personas && this.personas.length > 0) {
        this.currentPersonaLocal = demoPersonas[0];

        this.personaService.getDemoTopEmails(this.rateToggle, this.currentPersonaLocal.personaId).then(response => {
          this.currentPersonaLocal.topEmails = response;

          var url = this.authService.getEloquaInstanceUrl();
          if(url) {
            this.currentPersonaLocal.topEmails.forEach(email => {
              email.eloquaAssetUrl = `${url}/Main.aspx#emails&id=${email.treatment_id}`;
            });
          }

          this.loadingTopEmails = false;
        });
      }

      this.overallOrgStats = {
        openRate: 0.10,
        clickthroughRate: 0.03,
        clickToOpenRate: 0.20
      };

      this.personasFiltered = cloneDeep(this.personas);
    });
  } //end: loadDemo()
}
