import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { LoggerService } from '../../../../shared/logger.service';

@Component({
  selector: 'emails-selector',
  template: require('./emails-selector.html')
})
export class EmailsSelectorComponent implements OnInit {
  @Output() action = new EventEmitter(true);

  alerts = [];
  loading = true;
  error = false;

  availableEmails = [];
  filteredEmails = [];

  currentPage = 0;
  pageSize = 25;

  search = {
    text: '',
    orderBy: 'updatedAt',
    isDescending: true,
    busy: false,
    stop: false
  };

  _timeout = null;

  results = {};

  static parameters = [HttpClient, BsModalRef, LoggerService];
  constructor(http: HttpClient, bsModalRef: BsModalRef, loggerService: LoggerService) {
    this.http = http;
    this.bsModalRef = bsModalRef;
    this.loggerService = loggerService;
  }

  ngOnInit() {
    this.getNextPage();
  }

  trackEmail(index, email) {
    return email ? email.id : undefined;
  }

  changeFilter() {
    if(!this.search.busy) {
      if(this._timeout) {
        clearTimeout(this._timeout);
      }

      //Wait a bit before running search
      this._timeout = setTimeout(() => {
        this.runNewSearch();
      }, 1.2 * 1000);
    }
  }

  runNewSearch() {
    this.filteredEmails = [];
    this.search.stop = false;
    this.getNextPage(1);
  }

  getNextPage(page) {
    if(!page) {
      this.currentPage++;
    }
    else {
      this.currentPage = page;
    }

    if(this.currentPage === 1) {
      this.availableEmails = [];
    }

    if(this.search.busy) return;
    this.search.busy = true;

    var params = new HttpParams();
    params = params.append('limit', this.pageSize);
    params = params.append('page', this.currentPage);
    params = params.append('search', this.search.text);
    params = params.append('orderBy', this.search.orderBy);
    params = params.append('isDescending', this.search.isDescending);

    this.http
      .get('/eloqua-api/api/assets/emails', { params })
      .toPromise()
      .then(
        emails => {
          //Once we don't get anything back, stop scrolling.
          if(emails.length === 0) {
            this.loading = false;
            this.search.busy = false;
            this.search.stop = true;
            return;
          }

          var campaignEmails = [];

          this.campaign.emails.forEach(e => {
            campaignEmails.push(e.id);

            //Only add to the results, if not already there.
            if(!this.results.hasOwnProperty(e.id)) {
              this.results[e.id] = {
                selected: true,
                email: e
              };
            }
          });

          emails.forEach(email => {
            email.createdAt = new Date(email.createdAt * 1000);

            //Strip time from update date
            var tmpUpdateDate = new Date(email.updatedAt * 1000);
            email.updatedAt = new Date(tmpUpdateDate.getFullYear(), tmpUpdateDate.getMonth(), tmpUpdateDate.getDate());

            if(campaignEmails.indexOf(email.id) > -1) {
              email.selected = true;
            }
            else {
              email.selected = false;
            }

            //TEMP CHECK TO PREVENT DUPLICATES
            var found = false;
            for(var i = 0; i < this.availableEmails.length; i++) {
              if(this.availableEmails[i].id === email.id) {
                found = true;
              }
            }
            if(!found) {
              this.availableEmails.push(email);
            }
          });

          this.filteredEmails = this.availableEmails;

          this.loading = false;
          this.search.busy = false;
        },
        //Error Handling...
        error => {
          this.loading = false;
          this.error = true;
          this.loggerService.logMessage('Error getting Emails', 'error', error);
        }
      );
  } //end: getNextPage()

  toggleCheckbox(email) {
    this.alerts = [];
    email.selected = !email.selected;
    this.results[email.id] = {
      selected: email.selected,
      email
    };
  }

  ok() {
    var selectedEmails = [];
    this.alerts = [];

    for(var key in this.results) {
      if(this.results[key].selected) {
        selectedEmails.push(this.results[key].email);
      }
    }

    //Make sure at least 2 emails are selected for MT.
    if(selectedEmails.length < 2) {
      this.alerts.push({
        msg: 'At least two emails must be selected for Message Testing.',
        type: 'danger'
      });
    }
    else {
      this.action.emit(selectedEmails);
      this.bsModalRef.hide();
    }
  }

  cancel() {
    this.bsModalRef.hide();
  }
}
