/* eslint-disable */
var COLOR_BLUE         = '#0984e3'; //was: '#0086d6';
var COLOR_LIGHT_BLUE   = '#74b9ff'; //was: '#8ed7de';

var COLOR_GOLD         = '#fdcb6e'; //was: '#fdca4f';
var COLOR_LIGHT_GOLD   = '#ffeaa7'; //was: '#fcec4d';

var COLOR_GREEN        = '#00b894'; //was: '#00965e';
var COLOR_LIGHT_GREEN  = '#55efc4'; //was: '#80c67b';

var COLOR_RED          = '#d63031'; //was: '#ea5044';
var COLOR_LIGHT_RED    = '#ff7675'; //was: '#fa74b2';

var COLOR_PURPLE       = '#6c5ce7'; //was: '#84367c';
var COLOR_LIGHT_PURPLE = '#a29bfe'; //was: '#c73e74';

var COLOR_AQUA         = '#00cec9'; //was: '#00cccc';
var COLOR_LIGHT_AQUA   = '#81ecec'; //was: '#ccc947';

var COLOR_PINK         = '#e84393';
var COLOR_LIGHT_PINK   = '#fd79a8';

//var COLOR_BROWN        = '#ad856e';
//var COLOR_LIGHT_BROWN  = '#f6c6a8';

var COLOR_ORANGE       = '#e17055'; //was: '#ff7f0e';
var COLOR_LIGHT_ORANGE = '#fab1a0'; //was: '#ffbb78';

//var COLOR_GREY         = '#2d3436'; //was: '#708393';
//var COLOR_LIGHT_GREY   = '#636e72'; //was: '#eff3f5';

export var COLOR_PATTERN = [
  COLOR_PURPLE,
  COLOR_ORANGE,
  COLOR_AQUA,
  COLOR_PINK,

  COLOR_BLUE,
  COLOR_GOLD,
  COLOR_GREEN,
  COLOR_RED,

  //2nd set
  COLOR_LIGHT_PURPLE,
  COLOR_LIGHT_ORANGE,
  COLOR_LIGHT_AQUA,
  COLOR_LIGHT_PINK,

  COLOR_LIGHT_BLUE,
  COLOR_LIGHT_GOLD,
  COLOR_LIGHT_GREEN,
  COLOR_LIGHT_RED
];
