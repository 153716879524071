// @flow
import { Component, ElementRef, Input, SimpleChanges } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { AuthService } from '../../../../components/auth/auth.service';

import { LoggerService } from '../../../shared/logger.service';

import { Campaign } from '../campaign';
import { CampaignService } from '../campaign.service';
import { CampaignDatesService } from '../campaign-dates.service';
import { GeneratorService } from '../../../generator/shared/generator.service';

import { EmailsSelectorComponent } from './emails-selector/emails-selector.component';
import { SubjectLineAnalysisModalComponent } from '../../../generator/subject-line-analysis-modal/subject-line-analysis-modal.component';
import { UpdateFrequencyComponent } from '../../../organization/shared/update-frequency/update-frequency.component';

import { Settings } from '../../../shared/settings';

import { getUtcISOFromMoment, getLocalEquivalentFromMoment, startLoading, stopLoading } from '../../../../components/util';

import moment from 'moment-timezone';
import { cloneDeep, isEqual } from 'lodash';

@Component({
  selector: 'campaign-config',
  template: require('./campaign-config.html')
})
export class CampaignConfigComponent {
  @Input() campaign: Campaign;
  @Input() campaignLocal;

  settings: Settings = new Settings();

  bsModalRef: BsModalRef;

  currentOrg = {
    fmConfig: {
      rules: [],
      orgFmRule: {
        emailsPerTimePeriod: 1,
        timePeriodDays: 0
      }
    }
  };

  alerts = [];
  bestNumberOfContactsMin;
  totalNumberOfContactsMin;

  static parameters = [ElementRef, HttpClient, DecimalPipe, BsModalService, AuthService, LoggerService, CampaignService, CampaignDatesService, GeneratorService];
  constructor(
    element: ElementRef,
    http: HttpClient,
    decimalPipe: DecimalPipe,
    modalService: BsModalService,
    authService: AuthService,
    loggerService: LoggerService,
    campaignService: CampaignService,
    campaignDatesService: CampaignDatesService,
    generatorService: GeneratorService
  ) {
    this.element = element;
    this.http = http;
    this.decimalPipe = decimalPipe;
    this.modalService = modalService;
    this.authService = authService;
    this.loggerService = loggerService;
    this.campaignService = campaignService;
    this.campaignDatesService = campaignDatesService;
    this.generatorService = generatorService;
    this.isInEloqua = false;
    this.scroller = null;
    this.fmV2Config = true; //always FM V2 now.
  }

  ngOnInit() {
    console.log('---------> Init campaign-conifg:', this.campaign, this.campaignLocal); //debug

    this.authService.getCurrentUser().then(user => {
      this.currentUser = user;
    });

    this.authService.isInEloqua().then(is => {
      this.isInEloqua = is;

      //Only call these after finding out if we are in Eloqua or not.
      this.getEmails();
      this.getSignatureRules();
    });

    this.alerts = [];
    this.saving = false;
    this.configComplete = false; //indicates Eloqua config is complete.

    this.language = 'english';
    this.languages = this.generatorService.getLanguages();

    //If campaignLocal wasn't initialized outside this component, do it here.
    if(typeof this.campaignLocal.restrictSendTimes === 'undefined') {
      this.campaignLocal = this.campaignService.initCampaignLocal(this.campaign);
      this.campaignDatesService.setDateOptions(this.campaign.timezone, this.campaignLocal);
    }

    this.configSteps = {
      currentStep: 0,
      steps: [
        {
          isOpen: true,
          completed: false
        },
        {
          isOpen: false,
          completed: false
        },
        {
          isOpen: false,
          completed: false
        },
        {
          isOpen: false,
          completed: false
        },
        {
          isOpen: false,
          completed: false
        }
      ],
      priorityIsOpen: false,
      summaryCheck1: false,
      summaryCheck2: false,
      summaryCheck3: false
    };

    //Single select email
    this.selectedEmail = null;
    this.emailSearchFilter = [];
    this.emails = [];

    //Motion Controlled (Bulk) vs. Eloqua Controlled (Drip)
    this.isMotionCollapsed = true;
    this.isEloquaCollapsed = true;

    //Date Handling
    this.timezones = this.campaignDatesService.timezones();
    this.restrictSendTimesMsg = '';

    //Frequency Management
    this.fmRetryWindow = 2;

    this.authService.getCurrentOrg().then(org => {
      if(typeof org !== 'undefined') {
        this.currentOrg = org;
        this.authService.checkFmRules(this.currentOrg);
        this.checkFmV2();
      }
    });

    this.orgSub = this.authService.currentOrgChanged.subscribe(org => {
      if(typeof org !== 'undefined') {
        this.currentOrg = org;
        this.authService.checkFmRules(this.currentOrg);
        this.checkFmV2();
      }
    });

    this.signatureRules = [];
    this.filteredSignatureRules = [];
    this.signatureRuleSearchFilter = '';

    //For an existing campaign.
    if(this.campaign.campaignId) {
      this.updateUi(true);
      this.checkEloquaCampaign();
    }

    // Default value for fmPriority
    this.priorities = [{
      value: 1,
      display: 'No FM Priority'
    }];
    this.fmPriorityAutoSelected = false;
  } //end: ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'campaignLocal' && typeof this.campaignLocal.restrictSendTimes !== 'undefined') {
        //For a new campaign.
        //In this case, campaignLocal gets updated after the component is created.
        this.updateUi(true);
        this.getEmails();
      }
    }
  }

  ngAfterViewInit() {
    //Setup AutoScroll
    const accordionHeadings = this.element.nativeElement.querySelectorAll('.accordion .panel-heading');

    accordionHeadings.forEach(accordionHeading => {
      //Click on accordion header.
      accordionHeading.addEventListener('click', () => {
        const panel = accordionHeading.closest('accordion-group');
        this.autoScroll(panel, accordionHeading);
      });

      accordionHeading.addEventListener('magicScroll', () => {
        const panel = accordionHeading.closest('accordion-group');
        this.autoScroll(panel, accordionHeading);
      });
    });
  }

  checkFmV2() {
    //Let org's overwrite the default priorities.
    if(this.currentOrg.fmConfig && typeof this.currentOrg.fmConfig.priorities !== 'undefined' && this.currentOrg.fmConfig.priorities && this.currentOrg.fmConfig.priorities.length > 0) {
      this.priorities = this.currentOrg.fmConfig.priorities;
      this.priorities.sort((a, b) => a.display > b.display ? 1 : b.display > a.display ? -1 : 0);
    }
  }

  ngOnDestroy() {
    if(this.orgSub) this.orgSub.unsubscribe();
  }

  autoScroll(panel, accordionHeading) {
    if(!this.scroller) {
      //In Eloqua. scroll the whole html element.
      if(this.isInEloqua) {
        this.scroller = document.querySelector('html');
      }
      //Outside Eloqua. scroll the main element.
      else {
        this.scroller = document.querySelector('#main');
      }
    }

    if(this.scrollTimer) {
      clearTimeout(this.scrollTimer);
    }

    //wait (using timeout) for angular to complete rendering
    this.scrollTimer = setTimeout(() => {
      //Only scroll when opening
      if(panel.classList.contains('panel-open')) {
        const y = accordionHeading.offsetTop + this.scroller.offsetTop + 57;
        this.scroller.scrollTo({ top: y, behavior: 'smooth' });
      }
    }, 350);
  } //end: autoScoll()

  updateUi(init = false) {
    if(typeof this.configSteps === 'undefined') return;

    //If we cannot update, set the config steps to done.
    if(!this.campaignService.isUpdateable(this.campaign.status)) {
      this.configSteps.currentStep = this.configSteps.steps.length;
      this.configSteps.steps[0].isOpen = false;
      this.configSteps.steps.forEach(step => {
        step.completed = true;
      });
    }

    if(init && this.campaign.emails) {
      //Analyze subject lines
      this.campaign.emails.forEach(email => {
        this.generatorService.analyzeEmailSubjectLine(email, this.language);
      });
    }

    //Date Handling
    this.updateDates(init);

    //Contact Count Handling
    this.checkContactCounts();

    //Frequency Managment
    this.fmRetryWindow = this.campaign.fmRetryWindow / 24; //Convert to days from campaign (which is in hours).

    //Copy the campaign (for autoSave)
    this.campaignCopy = cloneDeep(this.campaign);
  } //end: updateUi()

  updateDates(init) {
    this.campaignDatesService.setDateOptions(this.campaign.timezone, this.campaignLocal);
    this.campaignDatesService.setDates(this.campaign, this.campaignLocal);
    this.setSendTimes(init);
  }

  //Set the dates used by the UI to set the start and end times.
  setSendTimes(init) {
    if(init) {
      this.campaignLocal.restrictSendTimes.startTime = new Date();
      this.campaignLocal.restrictSendTimes.startTime.setHours(this.campaignLocal.restrictSendTimes.startHour);
      this.campaignLocal.restrictSendTimes.startTime.setMinutes(0);

      this.campaignLocal.restrictSendTimes.endTime = new Date();
      this.campaignLocal.restrictSendTimes.endTime.setHours(this.campaignLocal.restrictSendTimes.endHour);
      this.campaignLocal.restrictSendTimes.endTime.setMinutes(0);
    }

    this.restrictSendTimesMsg = this.campaignDatesService.restrictSendTimesMsg(this.campaignLocal.restrictSendTimes, this.campaign.timezone);
    this.restrictSendTimesCopy = cloneDeep(this.campaignLocal.restrictSendTimes); //Copy restrictSendTimes
  }

  checkContactCounts() {
    //Motiva-controlled and draft status
    if(this.campaign.isMotionControlled && this.campaignService.isUpdateable(this.campaign.status)) {
      if(typeof this.campaignLocal.numDaysWithSends !== 'undefined' && this.campaign.emails.length > 0) {
        var batchesPerDay = 0;
        var sendTimeConstraints = this.campaign.sendTimeConstraints ? this.campaign.sendTimeConstraints : this.campaignDatesService.convertLocalToSendTimesMatrix(this.campaignLocal);

        if(sendTimeConstraints && sendTimeConstraints[0] && sendTimeConstraints[0].length > 0) {
          sendTimeConstraints[0].forEach(hour => {
            if(hour) batchesPerDay++;
          });

          if(batchesPerDay <= 0) {
            sendTimeConstraints[1].forEach(hour => {
              if(hour) batchesPerDay++;
            });
          }
        }

        if(this.campaignLocal.numDaysWithSends > 0 && !this.campaignLocal.isSimple && this.campaign.optimizationCriterion !== 'sendTime') {
          this.totalNumberOfContactsMin = this.campaignLocal.numDaysWithSends * this.campaign.emails.length * this.settings.minimumContactsPerTreatmentPerBatch;
          this.addAlert(
            `With the current configuration, the segment feeding into this optimizer must include at least <strong>${this.decimalPipe.transform(
              this.totalNumberOfContactsMin,
              '1.0-0'
            )} contacts</strong>.`,
            'info'
          );

          if(batchesPerDay !== 0) {
            this.bestNumberOfContactsMin = batchesPerDay * this.totalNumberOfContactsMin;
            this.addAlert(`For best results, make sure this optimizer receives at least <strong>${this.decimalPipe.transform(this.bestNumberOfContactsMin, '1.0-0')} contacts</strong>.`, 'info');
          }
        }
      }
    }
  }

  //Move along the config steps.
  saveConfigStep() {
    this.configSteps.steps[this.configSteps.currentStep].completed = true;
    this.configSteps.steps[this.configSteps.currentStep].isOpen = false;

    //If Simple or STO, skip step 2 (optimization criterion)
    if((this.campaignLocal.isSimple || this.campaign.optimizationCriterion === 'sendTime') && this.configSteps.currentStep === 0) {
      this.configSteps.currentStep = 2;
    }
    else {
      this.configSteps.currentStep++;
    }

    //Open next step
    if(this.configSteps.currentStep < this.configSteps.steps.length) {
      //Trigger auto-scroll to next step
      const step = this.element.nativeElement.querySelector(`#step${this.configSteps.currentStep}`);
      const panelHeading = step.closest('.accordion .panel-heading');

      //If the target panel is already open, use 'magicScroll', otherwise 'click'.
      if(this.configSteps.steps[this.configSteps.currentStep].isOpen) {
        const event = new Event('magicScroll');
        panelHeading.dispatchEvent(event);
      }
      else {
        panelHeading.click();
      }
    }
  } //end: saveConfigStep()

  trackEmail(index, email) {
    return email ? email.id : undefined;
  }

  //**************************************
  // Update Helpers
  //**************************************

  //Multi-select emails
  openEmailsSelector() {
    const initialState = {
      campaign: this.campaign
    };
    this.bsModalRef = this.modalService.show(EmailsSelectorComponent, { initialState });
    var sub = this.bsModalRef.content.action.subscribe(selectedEmails => {
      this.campaign.emails = selectedEmails;

      // Analyze subject lines
      if(this.campaign.emails && this.campaign.emails.length > 0) {
        this.campaign.emails.forEach(email => {
          this.generatorService.analyzeEmailSubjectLine(email, this.language);
        });
      }

      this.saveCampaign();
      if(sub) sub.unsubscribe();
    });
  }

  //Run the Subject Line Analyzer (SLA)
  openSubjectLineAnalyzer(email) {
    const initialState = {
      email,
      isInEloqua: this.isInEloqua
    };
    this.bsModalRef = this.modalService.show(SubjectLineAnalysisModalComponent, { initialState, class: 'modal-lg' });
  }

  //Single Select emails
  getEmails() {
    //Only access Eloqua when inside Eloqua.
    if(this.isInEloqua && (this.campaign.optimizationCriterion === 'sendTime' || this.campaignLocal.isSimple)) {
      this.emails = [];

      var params = new HttpParams();
      params = params.append('limit', 1000);
      params = params.append('page', 1);
      params = params.append('search', this.emailSearchFilter);
      params = params.append('orderBy', 'name');
      params = params.append('isDescending', true);

      this.http
        .get('/eloqua-api/api/assets/emails', { params })
        .toPromise()
        .then(
          emails => {
            this.emails = emails;
          },
          //Error Handling...
          error => {
            console.log('Error getting emails', error);
            this.loggerService.logMessage('Error getting Emails', 'error', error);
          }
        );
    }
  }

  selectEmail(email) {
    this.selectedEmail = email;

    this.campaign.emails[0] = email;
    this.campaign.emails[0].updatedAt = new Date(email.updatedAt * 1000);
    this.saveCampaign();

    this.generatorService.analyzeEmailSubjectLine(this.campaign.emails[0], this.language);
  }

  changeLanguage() {
    //Re-analyze subject lines with the new language.
    this.campaign.emails.forEach(email => {
      email.subjectLineAnalysis = null;
      this.generatorService.analyzeEmailSubjectLine(email, this.language);
    });
  }

  changeOptimizationCriterion() {
    if(this.campaign.optimizationCriterion === 'clickthroughs') {
      this.campaign.optimizationCriterion = 'opens';
    }
    else {
      this.campaign.optimizationCriterion = 'clickthroughs';
    }
    this.changeField();
  }

  changeConfidenceThreshold(confidence) {
    this.campaign.confidenceThreshold = confidence;
    this.changeField();
  }

  changeMotionControlled() {
    this.campaign.isMotionControlled = !this.campaign.isMotionControlled;
    this.motionControlledUpdated();
    this.changeField();
  }

  motionControlledUpdated() {
    if(this.campaign.campaignId) {
      if(this.campaign.isMotionControlled) {
        if(!this.campaign.scheduledStart || !this.campaign.scheduledEnd || !this.campaignLocal.scheduledStartLocal || !this.campaignLocal.scheduledEndLocal) {
          this.campaignDatesService.initDateOptions(this.campaign, this.campaignLocal);
          this.campaignDatesService.setDates(this.campaign, this.campaignLocal);
        }
      }
      else {
        this.campaignLocal.numDaysWithSends = 0;
        if(this.campaignLocal.restrictSendTimes.daysOfTheWeek.sunday) this.campaignLocal.numDaysWithSends++;
        if(this.campaignLocal.restrictSendTimes.daysOfTheWeek.monday) this.campaignLocal.numDaysWithSends++;
        if(this.campaignLocal.restrictSendTimes.daysOfTheWeek.tuesday) this.campaignLocal.numDaysWithSends++;
        if(this.campaignLocal.restrictSendTimes.daysOfTheWeek.wednesday) this.campaignLocal.numDaysWithSends++;
        if(this.campaignLocal.restrictSendTimes.daysOfTheWeek.thursday) this.campaignLocal.numDaysWithSends++;
        if(this.campaignLocal.restrictSendTimes.daysOfTheWeek.friday) this.campaignLocal.numDaysWithSends++;
        if(this.campaignLocal.restrictSendTimes.daysOfTheWeek.saturday) this.campaignLocal.numDaysWithSends++;
      }
    }
  }

  changeEndDate() {
    if(!this.campaignLocal.scheduledEndLocal || !this.campaignLocal.startDateTimezone) return;

    //The datepicker-options are not working in the Action Service config, so...
    if(this.campaignLocal.currentDate.isAfter(this.campaignLocal.scheduledEndLocal)) {
      var tomorrow = this.campaignLocal.currentDate.add(1, 'days');
      this.campaign.scheduledEnd = getUtcISOFromMoment(tomorrow);
      this.campaignService.setDates(this.campaign, this.campaignLocal);
    }

    //Also check if the new scheduledEnd is before scheduledStart.
    if(this.campaignLocal.startDateTimezone.isAfter(this.campaignLocal.scheduledEndLocal)) {
      var startDate = this.campaignLocal.endDateTimezone.clone().subtract(this.campaignDatesService.DEFAULT_CAMPAIGN_LENGTH_DAYS(), 'days');
      if(this.campaignLocal.currentDate.isAfter(startDate)) {
        startDate = this.campaignLocal.currentDate.clone();
      }

      this.campaign.scheduledStart = getUtcISOFromMoment(startDate);
      this.campaignService.setDates(this.campaign, this.campaignLocal);
    }

    this.campaignDatesService.updateEndDate(this.campaign, this.campaignLocal);

    //If new scheduledEnd is same or before scheduledStart, update scheduledEnd to one day after.
    if(this.campaignLocal.endDateTimezone.isSameOrBefore(this.campaignLocal.startDateTimezone)) {
      this.campaignLocal.endDateTimezone = moment.tz(this.campaign.scheduledStart, this.campaign.timezone);
      this.campaignLocal.endDateTimezone.add(1, 'days');

      this.campaign.scheduledEnd = getUtcISOFromMoment(this.campaignLocal.endDateTimezone);
      this.campaignLocal.scheduledEndLocal = getLocalEquivalentFromMoment(this.campaignLocal.endDateTimezone);

      this.campaignDatesService.updateCampaignLength(this.campaign, this.campaignLocal);
    }

    this.saveCampaign();
  }

  toggleSendTimeRestrictions() {
    this.campaignLocal.restrictSendTimes.active = !this.campaignLocal.restrictSendTimes.active;

    // If not restricting send times set letMotivaDecideSendTimeWindow to false
    if(!this.campaignLocal.restrictSendTimes.active) {
      this.campaign.letMotivaDecideSendTimeWindow = false;
    }

    if(this.campaignLocal.restrictSendTimes.active && !this.campaign.letMotivaDecideSendTimeWindow) {
      this.campaignLocal.restrictSendTimes.show = true;
    }
    else {
      this.campaignLocal.restrictSendTimes.show = false;
    }

    this.initializeSendTimes();
    this.restrictSendTimesMsg = this.campaignDatesService.restrictSendTimesMsg(this.campaignLocal.restrictSendTimes, this.campaign.timezone);
  }

  initializeSendTimes() {
    if(!this.campaignLocal.restrictSendTimes.active) {
      this.campaignLocal.restrictSendTimes.startTime = new Date();
      this.campaignLocal.restrictSendTimes.startTime.setHours(0);
      this.campaignLocal.restrictSendTimes.startTime.setMinutes(0);

      this.campaignLocal.restrictSendTimes.endTime = new Date();
      this.campaignLocal.restrictSendTimes.endTime.setHours(23);
      this.campaignLocal.restrictSendTimes.endTime.setMinutes(0);

      this.campaignLocal.restrictSendTimes.daysOfTheWeek = {
        sunday: true,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true
      };

      this.campaignLocal.restrictSendTimes.setBy = 'manuallySelect';
    }
    else {
      this.campaignLocal.restrictSendTimes.startTime = new Date();
      this.campaignLocal.restrictSendTimes.startTime.setHours(this.campaign.optimizationCriterion === 'sendTime' ? 7 : 9);
      this.campaignLocal.restrictSendTimes.startTime.setMinutes(0);

      this.campaignLocal.restrictSendTimes.endTime = new Date();
      this.campaignLocal.restrictSendTimes.endTime.setHours(this.campaign.optimizationCriterion === 'sendTime' ? 19 : 17);
      this.campaignLocal.restrictSendTimes.endTime.setMinutes(0);

      this.campaignLocal.restrictSendTimes.setBy = 'manuallySelect';
    }
  }

  changeSendTimeRestrictions() {
    if(this.campaignLocal.restrictSendTimes.active && !this.campaign.letMotivaDecideSendTimeWindow) {
      this.campaignLocal.restrictSendTimes.show = true;
    }
    else {
      this.campaignLocal.restrictSendTimes.show = false;
    }

    if(this.campaignLocal.restrictSendTimes) {
      this.restrictSendTimesMsg = this.campaignDatesService.restrictSendTimesMsg(this.campaignLocal.restrictSendTimes, this.campaign.timezone);
    }
    this.changeField();
  }

  changeTimezone(timezone) {
    this.campaign.timezone = timezone;
    this.updateDates();
    this.autoSave();
  }

  //Open dialog to update Org's FM settings
  updateFrequency() {
    const initialState = { organization: this.currentOrg };
    this.modalService.show(UpdateFrequencyComponent, { initialState, class: 'modal-lg' });
  }

  fmRetryWindowChanged() {
    //change fm retry window to hours before saving to campaign
    this.campaign.fmRetryWindow = this.fmRetryWindow * 24;
    this.changeField();
  }

  getSignatureRules() {
    //Only access Eloqua when inside Eloqua.
    if(this.isInEloqua) {
      this.signatureRules = [];
      this.http
        .get('/eloqua-api/api/assets/signature-rules')
        .toPromise()
        .then(
          signatureRules => {
            this.signatureRules = signatureRules;
            this.filteredSignatureRules = cloneDeep(this.signatureRules);
          },
          //Error Handling...
          error => {
            console.log('Error getting Signature Rules', error);
            this.loggerService.logMessage('Error getting Signature Rules', 'error', error);
          }
        );
    }
  }

  changeSignatureRule(rule) {
    if(rule) {
      this.campaign.signatureRuleId = rule.id;
      this.campaign.signatureRuleName = rule.name;
    }
    else {
      this.campaign.signatureRuleId = null;
      this.campaign.signatureRuleName = null;
    }

    this.changeField();
  }

  filterSignatureRules() {
    var searchString = this.signatureRuleSearchFilter.toLowerCase();

    function searchFilter(signatureRule) {
      return signatureRule.name && signatureRule.name.toLowerCase().indexOf(searchString) > -1;
    }

    this.filteredSignatureRules = this.signatureRules.filter(searchFilter);
  }

  //**************************************
  // Form Handling + Auto Save
  //**************************************

  addAlert(msg, type, response) {
    var message = msg;
    var details = response;

    if(typeof response !== 'undefined' && typeof response !== 'string') {
      message = this.loggerService.userMessage(msg, response);
      details = this.loggerService.parseResponse(response);

      if(msg !== message) {
        message = `${msg}: ${message}`;
      }
    }

    this.alerts.push({ type, msg: message, details });
  }

  autoSave() {
    if(this.saving || !this.isChanged()) return; //debounce
    if(this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.saveCampaign();
    }, 1.2 * 1000); //wait X seconds before save
  }

  isChanged() {
    if(this.campaign && this.campaignLocal.restrictSendTimes && this.restrictSendTimesCopy) {
      if(!isEqual(this.campaignLocal.restrictSendTimes, this.restrictSendTimesCopy)) {
        this.campaign.sendTimeConstraints = this.campaignDatesService.convertLocalToSendTimesMatrix(this.campaignLocal);
        return true;
      }
    }

    //If FM is turned on and a rule isn't selected, automatically select the first rule.
    if(!this.campaign.overrideSendFrequencyCheck) {
      if(typeof this.campaign.selectedFrequencyManagementRuleId === 'undefined' || !this.campaign.selectedFrequencyManagementRuleId) {
        if(this.currentOrg.fmConfig.rules.length > 0) {
          this.campaign.selectedFrequencyManagementRuleId = this.currentOrg.fmConfig.rules[0].id;
        }
      }
    }

    //If FM is turned on and a priority level isn't selected, automatically select the middle priority level.
    if(!this.campaign.overrideSendFrequencyCheck) {
      if(typeof this.campaign.fmPriority === 'undefined' || !this.campaign.fmPriority) {
        if(typeof this.currentOrg.fmConfig.priorities !== 'undefined' && this.currentOrg.fmConfig.priorities && this.currentOrg.fmConfig.priorities.length > 0) {
          console.log(`setting campaign fmPriority to ${Math.round(this.currentOrg.fmConfig.priorities.length / 2)}`);
          this.campaign.fmPriority = Math.round(this.currentOrg.fmConfig.priorities.length / 2);
        }
      }
    }

    //debug: compareObjects(this.campaign, this.campaignCopy); //import from util.js, if needed
    return !isEqual(this.campaign, this.campaignCopy);
  }

  blurField() {
    //Only allow updates inside Eloqua.
    if(!this.isInEloqua) {
      return;
    }

    if(typeof this.campaign !== 'undefined') {
      this.autoSave();
    }
  }

  changeField() {
    //Only allow updates inside Eloqua.
    if(!this.isInEloqua) {
      return;
    }

    if(typeof this.campaign !== 'undefined') {
      this.autoSave();
    }
  }

  //Never allow updates outside of Eloqua.
  disableField() {
    //Only allow updates inside Eloqua.
    if(!this.isInEloqua) {
      return true;
    }

    //Must have at least user role to update.
    if(!this.authService.hasRole('user')) return true;

    // If organization is inactive, disable
    if(this.currentOrg && !this.currentOrg.active) return true;

    if(typeof this.campaign !== 'undefined') {
      if(!this.campaignService.isUpdateable(this.campaign.status) || this.saving) {
        return true;
      }
    }

    return false;
  }

  //**************************************
  // The Big Bad Save function...
  //**************************************

  saveCampaign() {
    if(this.saving || !this.isChanged()) return; //debounce
    if(this.campaign.name === '' && this.campaign.emails.length === 0 && !this.configComplete) return; //nothing to save yet
    if(!this.campaignService.isUpdateable(this.campaign.status)) return; //campaign is not updateable.

    this.alerts = [];
    this.saving = true;

    this.campaignService.getModelForSave(this.campaign, this.campaignLocal, this.currentUser).then(
      model => {
        startLoading();

        if(!this.campaign.hasOwnProperty('campaignId') || this.campaign.campaignId === null) {
          this.campaignService
            .create(this.campaign.instanceId, model)
            .toPromise()
            .then(
              result => {
                this.campaign.campaignId = result.campaignId;
                this.checkEloquaCampaign();
                this.postSave();
              },
              //Error Handling...
              error => {
                stopLoading();
                this.saving = false;
                this.addAlert('Error creating campaign', 'danger', error);

                //FOR NOW: Log all errors. (sc-15459)
                //if(error.status.toString()[0] !== '4') {
                this.loggerService.logMessage('Error creating campaign', 'error', error);
                //}
                //else {
                //  console.log('Error creating campaign', error);
                //}
              }
            );
        }
        else {
          this.campaignService
            .update(this.campaign.campaignId, model)
            .toPromise()
            .then(
              () => {
                this.postSave();
              },
              //Error Handling...
              response => {
                stopLoading();
                this.saving = false;
                this.addAlert('Error updating campaign', 'danger', response);

                var errorLevel = 'error';
                if(response.error && response.error.error && response.error.error.indexOf !== 'undefined' && response.error.error.indexOf('do not have Email Group associated') !== -1) {
                  errorLevel = 'warning';
                }

                //FOR NOW: Log all errors. (sc-15459)
                //if(response.status.toString()[0] !== '4') {
                this.loggerService.logMessage('Error updating campaign', errorLevel, response);
                //}
                //else {
                //  console.log('Error updating campaign', response);
                //}

                this.campaign = cloneDeep(this.campaignCopy); //Revert...
              }
            );
        }
      },
      error => {
        console.log('Error updating campaign', error);
      }
    );
  } //end: saveCampaign()

  postSave() {
    stopLoading();
    this.saving = false;
    this.updateUi();

    if(this.isInEloqua && this.campaignService.isUpdateable(this.campaign.status) && !this.configComplete) {
      this.configurationComplete(); //This can be called multiple times.
    }
  }

  //Set config-complete in Eloqua
  configurationComplete() {
    this.loggerService.logMessage('Action Service: Check if configuration complete.', 'info'); //debug

    //Only call config complete, if the campaign has been saved to the database first.
    if(this.campaign.hasOwnProperty('campaignId') && this.campaign.campaignId !== null) {
      this.campaignService
        .get(this.campaign.campaignId)
        .toPromise()
        .then(checkCampaign => {
          //If mandatory fields are populated...
          if(typeof checkCampaign !== 'undefined' && checkCampaign.name && checkCampaign.emails.length > 0 && !this.configComplete) {
            //Extra check for MT: must have at least 2 emails.
            if(!this.campaignLocal.isSimple && checkCampaign.optimizationCriterion !== 'sendTime' && checkCampaign.emails.length < 2) {
              return;
            }

            //If FM v2, must select a priority.
            if(!checkCampaign.overrideSendFrequencyCheck && this.fmV2Config && (typeof checkCampaign.fmPriority === 'undefined' || !checkCampaign.fmPriority)) {
              return;
            }

            console.log(`Action Service: About to call Config Complete, campaign.emails.length = ${checkCampaign.emails.length}`); //debug
            this.loggerService.logMessage('Action Service: Calling Config Complete.', 'info'); //debug

            var url = `/auth/eloqua/action-service/config-complete?instance=${checkCampaign.campaignId}`;
            var data = {};

            this.http
              .put(url, data, { responseType: 'text' })
              .toPromise()
              .then(() => {
                this.configComplete = true; //only prevents re-calls within a session.
              },
              //Error Handling...
              error => {
                this.loggerService.logMessage('Error configuring the Motiva AI Optimizer in Eloqua', 'error', error);
              });
          }
        });
    }
  } //end: configurationComplete()

  checkEloquaCampaign() {
    this.http
      .get(`/eloqua-api/api/campaign/${this.campaign.eloquaCampaignId}?is-detailed=true`)
      .toPromise()
      .then(
        campaignDetails => {
          console.log('---- Eloqua campaign details:', campaignDetails); //debug

          //campaignDetails.currentStatus: draft, active...
          var eloquaCampaignStatus = campaignDetails.currentStatus;

          //Also check if we need to automatically set the FM Priority rule.
          if(eloquaCampaignStatus === 'draft'
             && typeof this.currentOrg.fmConfig.priorities !== 'undefined'
             && this.currentOrg.fmConfig.priorities
             && (typeof this.campaign.fmPriority === 'undefined' || !this.campaign.fmPriority)) {
            var found = false;
            for(var i = 0; i < this.currentOrg.fmConfig.priorities.length; i++) {
              const priority = this.currentOrg.fmConfig.priorities[i];

              if(typeof priority.fieldMappings !== 'undefined' && !found) {
                for(var j = 0; j < priority.fieldMappings.length; j++) {
                  const fieldMapping = priority.fieldMappings[j];
                  console.log(' --> Checking fieldMapping:', fieldMapping); //debug

                  if((fieldMapping.product === '' || campaignDetails.defaultFieldValues.product === fieldMapping.product)
                     && (fieldMapping.region === '' || campaignDetails.defaultFieldValues.region === fieldMapping.region)
                     && (fieldMapping.campaignType === '' || campaignDetails.defaultFieldValues.campaignType === fieldMapping.campaignType)) {
                    console.log('  >>> FOUND!!!'); //debug
                    found = true;
                    this.campaign.fmPriority = priority.value;
                    this.campaign.autoSelectedFmPriority = priority.value; //<< this value is currently not saved to the campgin.
                    this.autoSave();
                    break;
                  }
                }
              }
            }
          }

          //Display a msg indicating FM Priority was automatically selected -- only shows the first time, because autoSelectedFmPriority is not saved.
          if(this.campaign.autoSelectedFmPriority && this.campaign.autoSelectedFmPriority === this.campaign.fmPriority) {
            this.fmPriorityAutoSelected = true;
          }
        },
        //Error Handling...
        error => {
          console.log('Error getting Eloqua campaign details.', error);
          this.loggerService.logMessage('Error getting Eloqua campaign details', 'error', error);
        }
      );
  } //end: checkEloquaCampaign()
}
