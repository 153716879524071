import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { cloneDeep } from 'lodash';

@Component({
  selector: 'email-priority-selector',
  template: require('./email-priority-selector.html')
})
export class EmailPrioritySelectorComponent implements OnInit {
  @Output() action = new EventEmitter(true);

  static parameters = [BsModalRef];
  constructor(bsModalRef: BsModalRef) {
    this.bsModalRef = bsModalRef;
    this.selectedEmail = {};
    this.searchString;
  }

  ngOnInit() {
    this.emailsFiltered = cloneDeep(this.emailOptions);
  }

  setSelected(email) {
    this.selectedEmail = email;
  }

  filterEmails() {
    var searchStr = this.searchString.toLowerCase();

    if(searchStr) {
      function searchFilter(email) {
        return email.name && email.name.toLowerCase().indexOf(searchStr) > -1 || email.status && email.status.toLowerCase().indexOf(searchStr) > -1;
      }

      this.emailsFiltered = this.emailOptions.filter(searchFilter);
    }
    else {
      this.emailsFiltered = cloneDeep(this.emailOptions);
    }
  }

  ok() {
    // Add email to priorities list
    this.selectedEmail.rank = this.emailPriorities.length;
    this.emailPriorities.push(this.selectedEmail);

    this.action.emit(true);
    this.bsModalRef.hide();
  }

  cancel() {
    this.bsModalRef.hide();
  }
}
