import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { CampaignService } from '../../shared/campaign.service';

@Component({
  selector: 'delete-cancel',
  template: require('./delete-cancel.html')
})
export class DeleteCancelComponent {
  @Output() action = new EventEmitter(true);

  static parameters = [BsModalRef, CampaignService];
  constructor(bsModalRef: BsModalRef, campaignService: CampaignService) {
    this.bsModalRef = bsModalRef;
    this.campaignService = campaignService;
  }

  ok() {
    this.action.emit(true);
    this.bsModalRef.hide();
  }

  cancel() {
    this.bsModalRef.hide();
  }
}
