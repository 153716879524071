// @flow
import { Component, Input, SimpleChanges } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { Campaign } from '../campaign';
import { WhoService } from '../../../who/shared/who.service';
import { AuthService } from '../../../../components/auth/auth.service';
import { CampaignFilesService } from '../campaign-files.service';
import { ImpersonationService } from '../../../../components/auth/impersonation.service';

import { ContactAttributeSelectorComponent } from '../../../who/shared/contact-attribute-selector/contact-attribute-selector.component';

import { cloneDeep } from 'lodash';

@Component({
  selector: 'campaign-who',
  template: require('./campaign-who.html')
})
export class CampaignWhoComponent {
  @Input() campaign: Campaign;
  @Input() campaignLocal;
  @Input() results;
  @Input() visible;
  @Input() folderName;

  @Input() whoReport;
  @Input() loadingTopAttributes;
  @Input() loadingPerformanceDetails;

  modalRef: BsModalRef;

  static parameters = [WhoService, AuthService, BsModalService, CampaignFilesService, ImpersonationService];
  constructor(whoService: WhoService, authService: AuthService, modalService: BsModalService, campaignFilesService: CampaignFilesService, impersonationService: ImpersonationService) {
    this.whoService = whoService;
    this.authService = authService;
    this.modalService = modalService;
    this.campaignFilesService = campaignFilesService;
    this.impersonation = impersonationService;
  }

  ngOnInit() {
    this.init();
    this.isDemo = this.impersonation.isDemoMode();

    if(this.folderName) {
      this.buildWhoReportFromFile();
    }
    else {
      this.buildWhoReport();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'visible') {
        if(this.visible) {
          this.init();

          if(this.folderName) {
            this.buildWhoReportFromFile();
          }
          else {
            this.buildWhoReport();
          }
        }
      }
    }
  }

  init() {
    this.loadingTopAttributes = true;
    this.loadingWhoReport = true;
    this.loadingPerformanceDetails = true;

    if(!this.whoReport) {
      this.whoReport = {
        config: [],
        featureSets: {
          headers: [],
          data: [],
          relevantFeatures: [],
          relevantFeaturesMap: {},
          totalCountMap: {},
          featureCounts: {},
          highestCount: 0
        },
        keys: [],
        topFeaturesToggle: 'opens'
      };
    }

    this.orgSettings = {};

    this.filterSettings = {
      filterBy: 'motivaCampaign',
      ids: this.campaign.campaignId
    };

    this.buildTreatmentDetails();
  }

  buildWhoReport() {
    this.loadingWhoReport = true;
    this.loadingPerformanceDetails = true;
    this.loadingTopAttributes = true;

    this.whoService.getContactAttributes(this.whoReport).then(
      response => {
        this.orgContactFieldsLocal = cloneDeep(response.orgContactFields);
        this.orgSettings = response.orgSettings;

        if(!this.orgContactFieldsLocal || this.orgContactFieldsLocal.length == 0) {
          this.orgContactFieldsLocal = this.campaign.contactFields;
        }

        // Get top performing features
        this.whoService.getTopPerformingFeatures(this.campaign.organizationId, this.whoReport, this.orgContactFieldsLocal, this.filterSettings).then(
          () => {
            this.loadingWhoReport = false;
            this.loadingTopAttributes = false;
          },
          err => {
            console.log('Error getting top attributes', err);
            this.loadingWhoReport = false;
            this.loadingTopAttributes = false;
          }
        );

        this.whoService.buildWhoReport(this.campaign.organizationId, this.whoReport, this.orgContactFieldsLocal, this.filterSettings, this.treatmentDetails).then(
          whoReport => {
            this.whoReports = whoReport.reports;
            this.loadingWhoReport = false;
            this.loadingPerformanceDetails = false;
          },
          err => {
            this.loadingWhoReport = false;
            this.loadingPerformanceDetails = false;
            console.log('Error building who report', err);
          }
        );
      },
      error => {
        console.log('Error getting contact attributes for campaign who', error);
        this.loadingWhoReport = false;
      }
    );
  }

  buildTreatmentDetails() {
    this.treatmentDetails = {};
    this.campaign.emails.forEach(email => {
      this.treatmentDetails[email.id] = {
        name: email.name,
        subject: email.subject,
        updatedAt: email.updatedAt
      };
    });
  }

  topFeaturesChanged() {
    this.loadingTopAttributes = true;
    // Get top performing features
    if(!this.folderName) {
      this.whoService.getTopPerformingFeatures(this.campaign.organizationId, this.whoReport, this.orgContactFieldsLocal, this.filterSettings).then(
        () => {
          this.loadingTopAttributes = false;
        },
        err => {
          console.log('Error getting top attributes', err);
          this.loadingTopAttributes = false;
        }
      );
    }
    else {
      this.campaignFilesService.getTopPerformingFeatures(this.whoReport, this.folderName).then(
        () => {
          this.loadingTopAttributes = false;
        },
        error => {
          console.log('Error getting top attributes from file', error);
        }
      );
    }
  }

  selectContactFields() {
    var initialState = {
      campaign: this.campaign,
      totalCountMap: this.whoReport.featureSets.totalCountMap,
      totalNum: null
    };

    this.modalRef = this.modalService.show(ContactAttributeSelectorComponent, { initialState });

    var sub = this.modalService.onHidden.subscribe(() => {
      if(this.modalRef.content.okClicked) {
        this.loadingPerformanceDetails = true;
        this.whoReport.config = [];

        this.getOrgSettings();

        //Refresh who report with selected fields
        this.selectedContactFieldsLocal = this.modalRef.content.selectedContactFields;

        if(this.folderName) {
          this.campaignFilesService.buildWhoReport(this.whoReport, this.selectedContactFieldsLocal, this.treatmentDetails, this.folderName).then(
            whoReport => {
              this.whoReports = whoReport.reports;
              this.loadingPerformanceDetails = false;
            },
            err => {
              console.log('Error building who report', err);
            }
          );
        }
        else {
          this.whoService.buildWhoReport(this.campaign.organizationId, this.whoReport, this.selectedContactFieldsLocal, this.filterSettings, this.treatmentDetails).then(
            response => {
              this.whoReports = response.reports;
              this.loadingPerformanceDetails = false;
            },
            err => {
              console.log('Error building who report', err);
            }
          );
        }
      }
      if(sub) sub.unsubscribe();
    });
  }

  getOrgSettings() {
    //Query the Org settings each time, in case they are changed by someone else.
    this.whoService.getOrgSettings().then(
      orgSettings => {
        this.orgSettings = orgSettings;
      },
      error => {
        this.loggerService.logMessage('Error getting Organization Settings', 'error', error);
      }
    );
  }

  buildWhoReportFromFile() {
    this.whoService.getContactAttributes(this.whoReport).then(
      response => {
        this.orgContactFieldsLocal = cloneDeep(response.orgContactFields);
        this.orgSettings = response.orgSettings;

        this.loadingWhoReport = false;

        //TODO: get this data from file
        this.campaignFilesService.getTopPerformingFeatures(this.whoReport, this.folderName).then(
          () => {
            this.loadingTopAttributes = false;

            this.campaignFilesService.buildWhoReport(this.whoReport, this.orgContactFieldsLocal, this.treatmentDetails, this.folderName).then(
              whoReport => {
                this.whoReports = whoReport.reports;
                this.loadingPerformanceDetails = false;
              },
              err => {
                console.log('Error building who report', err);
              }
            );
          },
          err => {
            console.log('Error getting top attributes', err);
            this.loadingTopAttributes = false;
          }
        );
      },
      error => {
        console.log('Error getting contact attributes for campaign who', error);
        this.loadingWhoReport = false;
      }
    );
  }
}
