import { Injectable } from '@angular/core';
import { HttpRequest, HttpParams, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { ImpersonationService } from './impersonation.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  static parameters = [Router, ImpersonationService];
  constructor(router: Router, impersonation: ImpersonationService) {
    this.router = router;
    this.impersonation = impersonation;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(this.impersonation.isImpersonating()) {
      //Add impersonate param for non-html url's.
      if(request.url.indexOf('.html') === -1) {
        request = request.clone({
          params: (request.params ? request.params : new HttpParams()).set('impersonate', this.impersonation.impersonatingUser._id)
        });
      }
    }

    return next.handle(request).pipe(
      tap(
        () => {},
        (err: any) => {
          if(err instanceof HttpErrorResponse) {
            //If not a 401 or failed call to Eloqua API, don't logout.
            if(err.status !== 401 || request.url.indexOf('eloqua-api') >= 0) {
              return;
            }
            this.router.navigate(['/login']);
          }
        }
      )
    );
  }
}
