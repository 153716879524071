import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { AuthGuard } from '../../components/auth/auth-guard.service';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { DirectivesModule } from '../../components/directives.module';
import { OrganizationModule } from '../organization/organization.module';

import { FrequencyComponent } from './frequency.component';
import { FmReportComponent } from './shared/fm-report/fm-report.component';

import { LineAndBarChartComponent } from '../../components/data-viz/line-and-bar-chart/line-and-bar-chart.component';
import { HorizontalBarChartComponent } from '../../components/data-viz/horizontal-bar-chart/horizontal-bar-chart.component';

const frequencyRoutes: Routes = [
  {
    path: 'frequency',
    component: FrequencyComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forChild(frequencyRoutes),
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ButtonsModule.forRoot(),
    CollapseModule.forRoot(),
    PopoverModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgxDatatableModule,
    DirectivesModule,
    OrganizationModule,
    FormsModule
  ],
  declarations: [
    FrequencyComponent,
    FmReportComponent,
    LineAndBarChartComponent,
    HorizontalBarChartComponent
  ]
})
export class FrequencyModule {}
