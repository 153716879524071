import { Component } from '@angular/core';
import { AuthService } from '../../../components/auth/auth.service';
import { UserService } from '../../../components/auth/user.service';

// @flow
@Component({
  selector: 'settings',
  template: require('./settings.html')
})
export class SettingsComponent {
  currentUser;
  currentOrg;

  static parameters = [AuthService, UserService];
  constructor(authService: AuthService, userService: UserService) {
    this.authService = authService;
    this.userService = userService;

    this.currentUser = {};
    this.currentOrg = { subscriptionLevel: '' };
    this.receiveEmailsChanged = false;

    this.authService.getCurrentUser().then(user => {
      this.currentUser = user;

      // If user has no userOptions, add and save.
      if(!this.currentUser.userOptions) {
        this.currentUser.userOptions = {
          receiveEmails: true
        };
        this.update();
      }
    });

    this.authService.getCurrentOrg().then(org => {
      if(typeof org !== 'undefined') {
        this.currentOrg = org;
      }
    });

    this.orgSub = this.authService.currentOrgChanged.subscribe(org => {
      if(typeof org !== 'undefined') {
        this.currentOrg = org;
      }
    });
  }

  ngOnDestroy() {
    if(this.orgSub) this.orgSub.unsubscribe();
  }

  update() {
    this.alerts = [];
    this.userService
      .updateMe(this.currentUser)
      .toPromise()
      .then(
        () => {
          this.alerts.push({ type: 'success', msg: 'User successfully updated.' });
          this.receiveEmailsChanged = false;
        },
        //Error Handling...
        error => {
          this.alerts.push({ type: 'warning', msg: 'Error updating user.' });
          console.log('Error updating user', error);
        }
      );
  }

  changeReceiveEmails() {
    this.receiveEmailsChanged = true;
  }
}
