import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { LoggerService } from '../../app/shared/logger.service';

import { UserService } from './user.service';
import { OrgService } from './org.service';
import { ImpersonationService } from './impersonation.service';

import { safeCb, clearCache } from '../util';
import { userRoles } from '../../app/app.constants';

// @flow
class User {
  _id = '';
  name = '';
  email = '';
  role = '';
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  @Output() currentUserChanged = new EventEmitter(true);
  @Output() currentOrgChanged = new EventEmitter(true);
  _currentUser: User = new User();
  _currentOrg;
  userRoles = userRoles || [];
  userService;
  orgService;

  static parameters = [HttpClient, Router, LoggerService, UserService, OrgService, ImpersonationService];
  constructor(http: HttpClient, router: Router, loggerService: LoggerService, userService: UserService, orgService: OrgService, impersonation: ImpersonationService) {
    this.http = http;
    this.router = router;
    this.loggerService = loggerService;
    this.userService = userService;
    this.orgService = orgService;
    this.impersonation = impersonation;

    if(this.getToken()) {
      this.getUserAndOrg();
    }

    this.impersonation.impersonationChanged.subscribe(impersonating => {
      if(typeof impersonating !== 'undefined') {
        this.impersonateUser(impersonating.user, impersonating.openCampaign);
      }
      else {
        this.stopImpersonating();
      }
    });
  }

  /**
   * Check if userRole is >= role
   * @param {String} role - role to check against
   */
  hasRole(role) {
    return userRoles.indexOf(this._currentUser.role) >= userRoles.indexOf(role);
  }

  get currentUser() {
    return this._currentUser;
  }

  set currentUser(user) {
    this._currentUser = user;
    this.currentUserChanged.emit(user);
  }

  /**
   * Gets all available info on a user
   *
   * @param  {Function} [callback] - function(user)
   * @return {Promise}
   */
  getCurrentUser(callback) {
    safeCb(callback)(this.currentUser);
    return Promise.resolve(this.currentUser);
  }

  /**
   * Gets base Eloqua URL from the current user.
   *
   * @return {String}
   */
  getEloquaInstanceUrl() {
    var url = '';
    if(this._currentUser && typeof this._currentUser.eloqua !== 'undefined') {
      url = this._currentUser.eloqua._json.urls.base;
    }
    return url;
  }

  /**
   * Get/set the _currentOrg variable.
   * Emit an event when the _currentorg changes.
   *
   */
  get currentOrg() {
    return this._currentOrg;
  }

  set currentOrg(org) {
    this._currentOrg = org;
    this.currentOrgChanged.emit(org);
  }

  /**
   * Gets all available info on an organization
   *
   * @param  {Function} [callback] - function(organization)
   * @return {Promise}
   */
  getCurrentOrg(callback) {
    safeCb(callback)(this.currentOrg);
    return Promise.resolve(this.currentOrg);
  }

  getCurrentOrgSync() {
    return this.currentOrg;
  }

  /**
   * Update the org in the db and the currentOrg var.
   *
   * @param org
   *
   */
  updateCurrentOrg(org) {
    this.orgService
      .update(org)
      .toPromise()
      .then(
        () => {
          this.currentOrg = org;
        },
        //Error Handling...
        error => {
          this.loggerService.logMessage('Error updating organization', 'error', error);
        }
      );
  }

  /**
   * Get User and Organization from the backend.
   *
   * @return {Promise}
   */
  getUserAndOrg() {
    return this.userService
      .get()
      .toPromise()
      .then((user: User) => {
        this.currentUser = user;

        //Set User Context for Intercom, Sentry, Heap, etc.
        this.loggerService.setUserContext(this.currentUser);

        //Get and set currentOrg -- also check subscription level.
        this.orgService
          .get(this.currentUser.organizationId)
          .toPromise()
          .then(org => {
            this.currentOrg = org;
          });

        return !!this.currentUser._id;
      })
      .catch(err => {
        console.log(err);

        //Clear User Context for Intercom, Sentry, Heap, etc.
        this.loggerService.setUserContext();
        localStorage.removeItem('token');
        return false;
      });
  }

  /**
   * Delete access token and user info
   * @return {Promise}
   */
  logout() {
    localStorage.removeItem('token');
    this.currentUser = new User();
    this.currentOrg = {};

    //Clear User Context for Intercom, Sentry, Heap, etc.
    this.loggerService.setUserContext();

    return Promise.resolve();
  }

  /**
   * Checks if user is logged in
   * @param {function} [callback]
   * @returns {Promise}
   */
  isLoggedIn(callback?) {
    let is = !!this.currentUser._id;
    if(is || !this.getToken()) {
      //Got the user locally, or there is no token stored
      safeCb(callback)(is);
      return Promise.resolve(is);
    }
    else {
      //User not yet loaded locally, but token is present
      return this.getUserAndOrg();
    }
  }

  /**
   * Checks if user is logged in
   * @returns {Boolean}
   */
  isLoggedInSync() {
    return !!this.currentUser._id;
  }

  /**
   * Check if a user is an admin
   *
   * @param  {Function|*} [callback] - optional, function(is)
   * @return {Promise}
   */
  isAdmin(callback) {
    return this.getCurrentUser().then(user => {
      var is = user.role === 'admin';
      safeCb(callback)(is);
      return is;
    });
  }

  isAdminSync() {
    return this.currentUser.role === 'admin';
  }

  /**
   * Get auth token
   *
   * @return {String} - a token string used for authenticating
   */
  getToken() {
    var token = null;
    try {
      token = localStorage.getItem('token');
    }
    catch(e) {
      console.log('Unable to get token from localStorage.');
    }
    return token;
  }

  /**
   * Is running inside of Eloqua?
   *
   */
  isInEloqua() {
    var inEloqua = this.router.url.startsWith('/action-service') || this.router.url.startsWith('/decision-service');
    return Promise.resolve(inEloqua);
  }

  /**
   * For Admins only: Impersonate a user outside your org.
   *
   */
  impersonateUser(user, openCampaign) {
    clearCache(); //Clear global cache

    this.currentUser = user;
    this.currentOrg = user.Organization;

    if(!openCampaign) {
      this.router.navigate(['/home']);
    }
    else {
      this.router.navigate(['/campaigns', openCampaign]);
    }
  }

  /**
   * For Admins only: Stop impersonating a user outside your org.
   *
   */
  stopImpersonating() {
    this.getUserAndOrg().then(() => {
      this.router.navigate(['/active']);
      setTimeout(() => {
        clearCache();
      }); //Clear global cache
    });
  }

  /**
   * Verify (and possibly refresh) Eloqua oAuth token.
   *
   * Call this if you receive a 401 from an Eloqua API.
   *
   */
  verifyAuth(userId) {
    var url = '/auth/eloqua/verify';

    if(this.currentUser._id !== userId) {
      url += `?impersonate=${userId}`;
    }

    return this.http.get(url, { responseType: 'text' });
  }

  checkFmRules(org) {
    if(typeof org !== 'undefined' && org) {
      // Initalize fm rules
      if(typeof org.fmConfig === 'undefined' || !org.fmConfig) {
        org.fmConfig = {
          rules: [],
          orgFmRule: {
            emailsPerTimePeriod: 1,
            timePeriodDays: 0
          }
        };
      }

      if(typeof org.fmConfig.orgFmRule === 'undefined' || !org.fmConfig.orgFmRule) {
        org.fmConfig.orgFmRule = {
          emailsPerTimePeriod: 1,
          timePeriodDays: 0
        };
      }

      org.fmConfig.rules.sort((a, b) => a.name > b.name ? 1 : b.name > a.name ? -1 : 0);
    }
  }
}
