import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

import { AuthService } from '../../../../components/auth/auth.service';
import { ImpersonationService } from '../../../../components/auth/impersonation.service';

import { CampaignService } from '../../../campaign/shared/campaign.service';
import { DarkPoolService } from '../dark-pool.service';

import { cloneDeep } from 'lodash';

@Component({
  selector: 'dark-pool-time-machine',
  template: require('./dark-pool-time-machine.html')
})
export class DarkPoolTimeMachine implements OnInit {
  @Input() currentOrg;
  @Input() visible;

  static parameters = [AuthService, CampaignService, DarkPoolService, ImpersonationService];
  constructor(authService: AuthService, campaignService: CampaignService, darkPoolService: DarkPoolService, impersonation: ImpersonationService) {
    this.authService = authService;
    this.campaignService = campaignService;
    this.darkPoolService = darkPoolService;
    this.impersonation = impersonation;

    this.isDemo = false;

    this.rows = [];
    this.loading = true;
    this.firstTime = true;
    this.filterActive = 'exclude';
    this.timeMachineTab = 'topCampaigns';
    this.loadingCampaigns = false;
    this.eloquaCampaigns = [];
    this.eloquaCampaignsCopy = [];
    this.selectedCampaign = null;
    this.campaignSearchFilter = [];
    this.selectedCampaignResults = [];
    this.selectCampaignFirstTime = true;
    this.contactBreakdownLabels = this.darkPoolService.getContactBreakdownLabels();
    this.contactBreakdownDescriptions = this.darkPoolService.getContactBreakdownDescriptions();
    this.darkPoolTimeRange = {};
  }

  ngOnInit() {
    this.isDemo = this.impersonation.isDemoMode();
    this.authService.getCurrentUser().then(user => {
      this.currentUser = user;
    });

    this.contactBreakdownSelection = [];
    this.contactBreakdownSelection.push({ checked: false, score: -1, label: this.contactBreakdownLabels.scoreUndetermined, description: this.contactBreakdownDescriptions.scoreUndetermined });
    this.contactBreakdownSelection.push({ checked: false, score: 0, label: this.contactBreakdownLabels.score0, description: this.contactBreakdownDescriptions.score0 });
    this.contactBreakdownSelection.push({ checked: false, score: 1, label: this.contactBreakdownLabels.score1, description: this.contactBreakdownDescriptions.score1 });
    this.contactBreakdownSelection.push({ checked: false, score: 2, label: this.contactBreakdownLabels.score2, description: this.contactBreakdownDescriptions.score2 });
    this.contactBreakdownSelection.push({ checked: true, score: 3, label: this.contactBreakdownLabels.score3, description: this.contactBreakdownDescriptions.score3 });
    this.contactBreakdownSelection.push({ checked: true, score: 4, label: this.contactBreakdownLabels.score4, description: this.contactBreakdownDescriptions.score4 });
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'visible') {
        //Lazy load.
        if(this.visible) {
          if(this.isDemo) {
            this.loadDemoData();
            return;
          }

          // Get DP time range
          this.darkPoolService.getDarkPoolTimeRange(this.currentOrg._id).then(timeRange => {
            this.darkPoolTimeRange.startDate = timeRange.start_date;
            this.darkPoolTimeRange.endDate = timeRange.end_date;

            this.loadData();
          });
        }
      }
    }
  }

  loadData() {
    this.loading = true;
    this.darkPoolService.getCampaignPerformance(this.darkPoolTimeRange.startDate, this.darkPoolTimeRange.endDate, this.filterActive).then(campaignPerformance => {
      //If we didn't find anything with 'exclude', try again with 'all' contacts.
      if(campaignPerformance.length === 0 && this.filterActive === 'exclude' && this.firstTime) {
        this.firstTime = false;
        this.filterActive = 'all';
        this.loadData();
        return;
      }

      campaignPerformance.forEach(campaign => {
        campaign.link = this.currentUser.eloqua ? `${this.currentUser.eloqua._json.urls.base}/Main.aspx#campaigns&id=${campaign.eloquaCampaignId}` : '';
      });

      this.rows = campaignPerformance;

      //Get and add Eloqua campaign names.
      this.rows.forEach(campaign => {
        this.campaignService.getEloquaCampaignName(this.currentOrg._id, campaign.eloquaCampaignId)
          .then(campaignName => {
            if(campaignName) {
              campaign.name = campaignName;
            }
            else {
              campaign.name = 'Eloqua Campaign';
            }
          },
          //Error Handling...
          error => {
            console.log('Error getting Eloqua campaign details.', error);
            campaign.name = 'Eloqua Campaign';
          });
      });

      this.loading = false;
    })
      .catch(e => {
        console.log('Exception retrieving campaign performance.', e);
        this.loading = false;
      });
  }

  tabChanged() {
    if(this.loadingCampaigns) return;
    if(this.timeMachineTab === 'selectCampaign' && this.eloquaCampaigns.length === 0 && !this.loading) {
      this.loadingCampaigns = true;
      this.darkPoolService.getPerformanceCampaigns(this.darkPoolTimeRange.startDate, this.darkPoolTimeRange.endDate).then(campaigns => {
        this.eloquaCampaigns = campaigns;
        this.eloquaCampaignsCopy = cloneDeep(campaigns);
        this.loadingCampaigns = false;
      });
    }
  }

  filterCampaigns() {
    this.eloquaCampaigns = [];

    // Filter by search string
    var searchString = this.campaignSearchFilter.toLowerCase();
    if(searchString !== '') {
      function searchFilter(campaign) {
        return campaign.name && campaign.name.toLowerCase().indexOf(searchString) > -1 || campaign.eloquaCampaignId && campaign.eloquaCampaignId.toString().indexOf(searchString) > -1;
      }
      this.eloquaCampaigns = this.eloquaCampaignsCopy.filter(searchFilter);
    }
    else {
      this.eloquaCampaigns = cloneDeep(this.eloquaCampaignsCopy);
    }
  }

  selectCampaign(campaign) {
    this.selectedCampaign = campaign;

    //Get possible Dark Pool performance for this campaign.
    this.loading = true;
    this.darkPoolService.getPerformanceByCampaign(this.darkPoolTimeRange.startDate, this.darkPoolTimeRange.endDate, this.selectedCampaign.eloquaCampaignId).then(results => {
      this.selectCampaignFirstTime = false;

      //add data for the chart.
      var obj = {
        scoreUndetermined: 0,
        score0: 0,
        score1: 0,
        score2: 0,
        score3: 0,
        score4: 0
      };
      var chart = [];

      results.forEach(result => {
        if(result.contactScore === -1) {
          obj.scoreUndetermined = result.uniqueSends;
        }
        else if(result.contactScore === 0) {
          obj.score0 = result.uniqueSends;
        }
        else if(result.contactScore === 1) {
          obj.score1 = result.uniqueSends;
        }
        else if(result.contactScore === 2) {
          obj.score2 = result.uniqueSends;
        }
        else if(result.contactScore === 3) {
          obj.score3 = result.uniqueSends;
        }
        else if(result.contactScore === 4) {
          obj.score4 = result.uniqueSends;
        }
      });
      chart.push(obj);

      var item = {
        eloquaCampaignId: this.selectedCampaign.eloquaCampaignId,
        name: this.selectedCampaign.name,
        link: this.currentUser.eloqua ? `${this.currentUser.eloqua._json.urls.base}/Main.aspx#campaigns&id=${this.selectedCampaign.eloquaCampaignId}` : '',
        highlight: true,
        results,
        chart
      };

      this.selectedCampaignResults.unshift(item);

      setTimeout(() => {
        item.highlight = false;
      }, 2000);

      this.recomputeAdjustedRates();
      this.loading = false;
    });
  }

  recomputeAdjustedRates() {
    this.selectedCampaignResults.forEach(campaign => {
      var totalSends = 0;
      var totalOpens = 0;
      var totalClicks = 0;

      var adjustedSends = 0;
      var adjustedOpens = 0;
      var adjustedClicks = 0;

      campaign.results.forEach(result => {
        totalSends += parseInt(result.uniqueSends);
        totalOpens += parseInt(result.uniqueOpens);
        totalClicks += parseInt(result.uniqueClicks);

        this.contactBreakdownSelection.forEach(selection => {
          if(!selection.checked) {
            if(result.contactScore === selection.score) {
              adjustedSends += parseInt(result.uniqueSends);
              adjustedOpens += parseInt(result.uniqueOpens);
              adjustedClicks += parseInt(result.uniqueClicks);
            }
          }
        });
      });

      campaign.sends = totalSends;
      campaign.sendsAdjusted = adjustedSends;

      campaign.openRate = totalOpens / totalSends;
      campaign.clickRate = totalClicks / totalSends;

      campaign.openRateAdjusted = adjustedOpens / adjustedSends;
      campaign.clickRateAdjusted = adjustedClicks / adjustedSends;

      //Improvement = (filtered_rate - original_rate) / original_rate
      campaign.openImprovement = (campaign.openRateAdjusted - campaign.openRate) / campaign.openRate;
      campaign.clickImprovement = (campaign.clickRateAdjusted - campaign.clickRate) / campaign.clickRate;
    });
  }

  loadDemoData() {
    this.loading = true;
    this.darkPoolService.getDemoData('campaign-performance').then(campaignPerformance => {
      campaignPerformance.forEach(campaign => {
        campaign.link = this.currentUser.eloqua ? `${this.currentUser.eloqua._json.urls.base}/Main.aspx#campaigns&id=${campaign.eloquaCampaignId}` : '';
      });

      this.rows = campaignPerformance;
      this.loading = false;
    });
  }
}
