import {
  Component,
  ElementRef,
  HostListener,
  Input,
  SimpleChanges,
  OnInit
} from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'viz-histogram-color',
  template: '<div></div>'
})
export class HistogramColorComponent implements OnInit {
  @Input() data;
  @Input() height;
  @Input() property;
  @Input() xLabel;
  @Input() yLabel;

  @HostListener('window:resize', ['$event'])
  onResize() {
    //If not vislble, don't resize.
    if(!(this.htmlElement.offsetParent !== null)) return;
    var newWidth = this.htmlElement.parentElement.clientWidth;

    this.svg.attr('width', newWidth);
    this.width = +this.svg.attr('width') - this.margin.left - this.margin.right;

    if(this.data) {
      this.chart.attr('width', this.width);
      this.render(this.data);
    }
  }

  margin = {
    top: 20,
    left: 40,
    bottom: 40,
    right: 40
  };

  //For Brush
  marginBrush = { top: 210, left: 40, bottom: 30, right: 40 };
  heightBrush = 30;

  htmlElement: HTMLElement;
  svg;

  static parameters = [ElementRef];
  constructor(element: ElementRef) {
    this.htmlElement = element.nativeElement;
    this.host = d3.select(element.nativeElement);
    this.legendHeight = 80;
  }

  ngOnInit() {
    if(!this.format) this.format = ',d';

    //Set width to 100% of parent container.
    this.width = this.htmlElement.parentElement.clientWidth;
    this.height = this.height ? this.height : 350;

    //append the svg object
    this.host.html('');
    this.svg = this.host
      .append('svg')
      .attr('height', this.height + this.legendHeight)
      .attr('width', this.width);

    //Use margin
    this.width = +this.svg.attr('width') - this.margin.left - this.margin.right;
    this.height = +this.svg.attr('height') - this.margin.top - this.margin.bottom - this.legendHeight;

    this.tooltip = this.host
      .append('div')
      .attr('class', 'chart-tooltip')
      .style('opacity', 0)
      .style('top', `${this.height + 5}px`);

    this.chart = this.svg
      .append('g')
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`)
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('overflow', 'hidden');

    //Add a group for the brush, if needed.
    this.context = this.svg
      .append('g')
      .attr('class', 'context')
      .attr('transform', `translate(${this.marginBrush.left + 42}, ${this.marginBrush.top})`);

    this.render(this.data);
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'data' && this.data && this.chart) {
        if(this.width <= 0) {
          this.ngOnInit();
        }
        else {
          this.render(this.data);
        }
      }
      if(propName === 'property' && this.property && this.chart) {
        this.render(this.data);
      }
    }
  }

  render(data) {
    this.chart.selectAll('*').remove();
    this.context.selectAll('*').remove();

    if(!data || data.length === 0) return;

    //Make room for the x-axis range selector.
    if(data.length > 100) {
      this.height = +this.svg.attr('height') - this.margin.top - this.margin.bottom - this.legendHeight - 80;
      this.chart.attr('height', this.height);

      this.brush = d3
        .brushX()
        .extent([
          [0, 0],
          [this.width - this.marginBrush.left - this.marginBrush.right, this.heightBrush]
        ])
        .on('brush end', () => {
          this.brushed();
        });
    }

    var maxAvgSend = d3.max(data.map(d => d.averageSends)) + 1;
    var xAxisBandValues = d3.range(1, maxAvgSend, 1);

    //X axis: scale and draw.
    this.x = d3.scaleBand()
      .domain(xAxisBandValues)
      .range([this.margin.left, this.width - this.margin.right])
      .paddingInner(0.04)
      .paddingOuter(0.04);

    var xAxisValues = this.getxAxisValues(data);
    this.xAxisBottom = d3.axisBottom(this.x).tickValues(xAxisValues)
      .tickSizeOuter(0);
    this.xAxis = g => g
      .attr('transform', `translate(0,${this.height - this.margin.bottom})`)
      .call(this.xAxisBottom);

    this.chart.append('g')
      .attr('class', 'xaxis')
      .attr('transform', `translate(0,${this.height})`)
      .call(this.xAxis);

    //x-axis label
    if(this.xLabel) {
      this.chart.append('text')
        .attr('transform',
          `translate(${this.width / 2}, ${this.height})`)
        .style('text-anchor', 'middle')
        .style('font-weight', '200')
        .text(this.xLabel);
    }

    //Y axis: scale and draw
    this.y = d3.scaleLinear()
      .domain([0, d3.max(data, d => d.domainCount)])
      .nice()
      .range([this.height - this.margin.bottom, this.margin.top]);

    this.yAxis = g => g
      .attr('transform', `translate(${this.margin.left},0)`)
      .call(d3.axisLeft(this.y).tickFormat(d3.format(',')));

    this.chart.append('g')
      .attr('class', 'yaxis')
      .attr('transform', `translate(0,${this.height})`)
      .call(this.yAxis);

    //y-axis label
    if(this.yLabel) {
      this.chart.append('text')
        .attr('transform', 'rotate(-90)')
        .attr('y', 0 - this.margin.left + 10)
        .attr('x', 0 - this.height / 2)
        .attr('dy', '1em')
        .style('text-anchor', 'middle')
        .style('font-weight', '200')
        .text(this.yLabel);
    }

    var colorScale = d3.scaleLinear()
      .range([d3.interpolateRdBu(1), d3.interpolateRdBu(0.5), d3.interpolateRdBu(0)])
      .domain([0, d3.max(data, d => d[this.property]) / 2, d3.max(data, d => d[this.property])]);

    //append the bars
    this.barArea = this.chart
      .append('g')
      .attr('class', 'barArea');

    this.barArea.selectAll('rect')
      .data(data)
      .join('rect')
      .attr('class', 'histogram-color-bar')
      .attr('fill', d => d3.interpolateRdBu(1 - d[this.property])) //"1 - " reverses the color scale.
      .attr('x', d => this.x(d.averageSends))
      .attr('y', d => this.y(d.domainCount))
      .attr('height', d => this.y(0) - this.y(d.domainCount))
      .attr('width', this.x.bandwidth())
      .on('mouseover', d => {
        this.showTooltip(d);
      })
      .on('mouseout', () => {
        this.hideTooltip();
      });

    //For Brush
    if(data.length > 100) {
      this.brushWidth = this.width - this.marginBrush.left - this.marginBrush.right;

      this.xBrush = d3.scaleBand()
        .domain(xAxisBandValues)
        .range([0, this.brushWidth])
        .paddingInner(0.04)
        .paddingOuter(0.04);

      this.yBrush = d3.scaleLinear()
        .domain([0, d3.max(data, d => d.domainCount)])
        .nice()
        .range([this.heightBrush, 0]);

      this.addRangeSelector(this.data);
    }

    this.renderLegend(this.svg, this.data, colorScale);
  } //end: render()

  addRangeSelector(data) {
    this.context.selectAll('rect')
      .data(data)
      .join('rect')
      .attr('class', 'histogram-range-selector-bar')
      .attr('fill', '#333333')
      .attr('opacity', 0.6)
      .attr('x', d => this.xBrush(d.averageSends))
      .attr('y', d => this.yBrush(d.domainCount))
      .attr('height', d => this.yBrush(0) - this.yBrush(d.domainCount))
      .attr('width', this.xBrush.bandwidth());

    this.context
      .append('g')
      .attr('class', 'brush')
      .call(this.brush)
      .call(this.brush.move, [0, this.brushWidth]);
  } //end: addRangeSelector()

  brushed() {
    if(d3.event.sourceEvent && d3.event.sourceEvent.type === 'zoom') return; // ignore brush-by-zoom

    var selectedRange = d3.event.selection || this.xBrush.range();
    var selectedDomain = selectedRange.map(this.scaleBandInvert(this.xBrush), this.xBrush);
    var xAxisBandValues = d3.range(selectedDomain[0], selectedDomain[1], 1);

    //In the case where we clicked in the selector but outside the selection bar,
    //the selection is null, so we set the selectedRange to show all the data.
    //We also need to update the selection bar in this case to the max width.
    if(d3.event.selection === null && this.context) {
      this.context.select('.brush').call(this.brush.move, [0, this.brushWidth]);
    }

    //Set the x scale to only include the selected values.
    this.x.domain(xAxisBandValues);

    //Set the y scale to only include the selected x values.
    var maxY = d3.max(this.data, d => {
      if(xAxisBandValues.includes(d.averageSends)) {
        return d.domainCount;
      }
    });

    this.y.domain([0, maxY]).nice();

    if(this.data) this.update(this.data);
  } //end: brushed()

  scaleBandInvert(scale) {
    var domain = scale.domain();
    var paddingOuter = scale(domain[0]);
    var eachBand = scale.step();
    return function(value) {
      var index = Math.floor((value - paddingOuter) / eachBand);
      return domain[Math.max(0, Math.min(index, domain.length - 1))];
    };
  }

  update(data) {
    if(!data || data.length === 0) return;

    //Only show data from selected x-axis range.
    var filteredData = [];
    data.forEach(d => {
      if(this.x(d.averageSends)) {
        filteredData.push(d);
      }
    });

    this.barArea.selectAll('rect')
      .data(filteredData)
      .join('rect')
      .attr('fill', d => d3.interpolateRdBu(1 - d[this.property]))
      .attr('x', d => this.x(d.averageSends))
      .attr('y', d => this.y(d.domainCount))
      .attr('height', d => this.y(0) - this.y(d.domainCount))
      .attr('width', this.x.bandwidth());

    var xAxisValues = this.getxAxisValues(filteredData);
    this.xAxisBottom.tickValues(xAxisValues);

    d3.select('.xaxis').call(this.xAxis); //Set x-axis labels
    d3.select('.yaxis').call(this.yAxis); //Set y-axis labels
  } //end: update()

  //Helper to draw the legend.
  renderLegend(svg, data, colorScale) {
    //Extra scale since the color scale is interpolated
    var countScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, d => d[this.property])])
      .range([0, this.width]);

    //Calculate the variables for the gradient
    var numStops = 10;
    var countPoint = [];
    var countRange = countScale.domain();
    countRange[2] = countRange[1] - countRange[0];

    for(var i = 0; i < numStops; i++) {
      countPoint.push(i * countRange[2] / (numStops - 1) + countRange[0]);
    }

    //Create the gradient
    this.chart
      .append('defs')
      .append('linearGradient')
      .attr('id', 'legend-histogram-color')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '100%')
      .attr('y2', '0%')
      .selectAll('stop')
      .data(d3.range(numStops))
      .enter()
      .append('stop')
      .attr('offset', (d, j) => countScale(countPoint[j]) / this.width)
      .attr('stop-color', (d, k) => colorScale(countPoint[k]));

    //Place that Legend...
    var legendWidth = Math.min(this.width * 0.8, 400);
    var legendsvg = this.chart.append('g').attr('transform', `translate(${this.width / 2},${this.height - 10})`);

    //Draw the Rectangle
    legendsvg
      .append('rect')
      .attr('class', 'legend-bar')
      .attr('x', -legendWidth / 2)
      .attr('y', 60)
      .attr('width', legendWidth)
      .attr('height', 10)
      .style('fill', 'url(#legend-histogram-color)');

    var legendTitleText = data.length > 0 ? this.property : 'Legend';
    //Changing upperCamelCase to Title Case
    const tempTitle = legendTitleText.replace(/([A-Z])/g, ' $1');
    const finalTitle = tempTitle.charAt(0).toUpperCase() + tempTitle.slice(1);

    //Append title
    legendsvg
      .append('text')
      .attr('class', 'histogram-color-legend-title')
      .attr('x', 0)
      .attr('y', 50)
      .style('text-anchor', 'middle')
      .text(finalTitle);

    //Set scale for x-axis
    var xScale = d3
      .scaleLinear()
      .range([-legendWidth / 2, legendWidth / 2])
      .domain([0, d3.max(data, d => d[this.property])]);

    //Define x-axis
    var xAxis = d3.axisBottom(xScale);

    //Set tick format
    var xAxisTicks = xAxis.scale().ticks(xAxis.ticks()[0]);
    var precision = 0;

    xAxisTicks.forEach(tick => {
      if(parseInt((tick * 100).toString()[2]) > 0 && precision < 1) precision = 1;
      if(parseInt((tick * 100).toString()[3]) > 0) precision = 2;
    });

    xAxis = d3
      .axisBottom(xScale)
      .ticks(5)
      .tickFormat(d3.format(`,.${precision}%`));

    //Set up X axis
    legendsvg
      .append('g')
      .attr('class', 'histogram-color-legend-axis')
      .attr('transform', `translate(0,${70})`)
      .call(xAxis);
  } //end: renderLegend()

  getxAxisValues(data) {
    var xAxisValues = data.map(d => d.averageSends);
    var minAvgSend = d3.min(xAxisValues);
    var maxAvgSend = d3.max(xAxisValues) + 1;

    //If more than 50, reduce number of ticks shown
    if(data.length > 50 || this.width < 1000) {
      var firstTick = Math.ceil(minAvgSend / 5) * 5;
      xAxisValues = d3.range(firstTick, maxAvgSend, 5);

      if(data.length > 50 && this.width < 1000) {
        firstTick = Math.ceil(minAvgSend / 10) * 10;
        xAxisValues = d3.range(firstTick, maxAvgSend, 10);
      }
    }
    else {
      xAxisValues = d3.range(minAvgSend, maxAvgSend, 1);
    }

    return xAxisValues;
  } //end: getxAxisValues()

  showTooltip(value) {
    this.hideTooltip();

    if(value) {
      this.tooltip
        .transition()
        .duration(500)
        .style('opacity', 1);

      const tempPropertyTitleCase = this.property.replace(/([A-Z])/g, ' $1');
      const propertyTitleCase = tempPropertyTitleCase.charAt(0).toUpperCase() + tempPropertyTitleCase.slice(1);

      this.tooltip
        .html(
          `${`<p class="result"><span class="faded vLabel">${this.xLabel}:</span><strong>${value.averageSends}</strong></p>`
            + `<p class="result"><span class="faded vLabel">${this.yLabel}:</span><strong>${d3.format(',')(value.domainCount)}</strong></p>`
            + `<p class="result"><span class="faded vLabel">${propertyTitleCase}:</span><strong>${d3.format('.1%')(value[this.property])}</strong></p>`
          }`
        )
        .style('left', `${this.x(value.averageSends) + this.x.bandwidth() / 2}px`)
        .style('top', `${this.height + 5}px`);
    }
  } //end: showTooltip()

  hideTooltip() {
    this.tooltip
      .transition()
      .duration(200)
      .style('opacity', 0);
  }
}
