import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { QuillModule } from 'ngx-quill';

import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { DirectivesModule } from '../../components/directives.module';

import { AuthGuard } from '../../components/auth/auth-guard.service';
import { AdminComponent } from './admin.component';
import { UpdateUserComponent } from './update-user/update-user.component';
import { UpdateOrgComponent } from './update-org/update-org.component';
import { CdoDetailsComponent } from './cdo-details/cdo-details.component';
import { FmPriorityMappingsComponent } from './fm-priority-mappings/fm-priority-mappings.component';

//TODO: These routes need more than basic AuthGuard.
//      Only admins can activate these routes.
const adminRoutes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'update-user/:userId',
    component: UpdateUserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'update-org/:orgId',
    component: UpdateOrgComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    RouterModule.forChild(adminRoutes),
    AlertModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CollapseModule.forRoot(),
    TabsModule.forRoot(),
    NgxDatatableModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline'/*, 'strike'*/],    // toggled buttons
          //['blockquote', 'code-block'],
          //[{ 'header': 1 }, { 'header': 2 }],             // custom button values
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          //[{ 'script': 'sub'}, { 'script': 'super' }],    // superscript/subscript
          [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
          //[{ 'direction': 'rtl' }],                       // text direction
          [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          //[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          //[{ 'color': [] }, { 'background': [] }],        // dropdown with defaults from theme
          [{ 'font': [] }],
          [{ 'align': [] }],
          ['clean'],                                        // remove formatting button
          ['link'/*, 'image', 'video'*/]                    // link and image, video
        ]
      }
    }),
    DirectivesModule
  ],
  declarations: [AdminComponent, UpdateUserComponent, UpdateOrgComponent, CdoDetailsComponent, FmPriorityMappingsComponent],
  entryComponents: [CdoDetailsComponent, FmPriorityMappingsComponent],
  exports: [AdminComponent, UpdateUserComponent, UpdateOrgComponent]
})
export class AdminModule {}
