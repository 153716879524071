export var campaignKpis = {
  active: {
    type: 'boolean',
    name: 'Active'
  },
  sends: {
    type: 'basic',
    name: 'Sends',
    axisLabel: 'Sends',
    units: '',
    format: ','
  },
  incrementSends: {
    type: 'basic',
    name: 'Daily Sends',
    axisLabel: 'Daily Sends',
    units: '',
    format: ','
  },
  deliveries: {
    type: 'basic',
    name: 'Delivered',
    axisLabel: 'Delivered',
    units: '',
    format: ','
  },
  bouncebacks: {
    type: 'basic',
    name: 'Bouncebacks',
    axisLabel: 'Bouncebacks',
    units: '',
    format: ','
  },
  unsubscribes: {
    type: 'basic',
    name: 'Unsubscribes',
    axisLabel: 'Unsubscribes',
    units: '',
    format: ','
  },

  //KPI's for Opens --------------
  opens: {
    type: 'basic',
    name: 'Opens',
    axisLabel: 'Opens',
    units: '',
    format: ','
  },
  openRate: {
    type: 'basic',
    name: 'Open Rate',
    axisLabel: 'Open Rate',
    units: '%',
    format: '.1%'
  },
  expectedValueOpenRate: {
    type: 'basic',
    name: 'Open Rate',
    axisLabel: 'Open Rate',
    units: '%',
    format: '.1%'
  },
  //Confidence
  treatmentProbabilityOpen: {
    type: 'basic',
    name: 'Treatment Probability',
    axisLabel: 'Treatment Probability - Opens',
    units: '%',
    format: '.1%'
  },
  credibleIntervalMinOpen: {
    type: 'basic',
    name: 'Confidence Interval - Open - Min',
    axisLabel: 'Confidence Interval - Min',
    units: '%',
    format: '.1%'
  },
  credibleIntervalMaxOpen: {
    type: 'basic',
    name: 'Confidence Interval - Open - Max',
    axisLabel: 'Confidence Interval - Max',
    units: '%',
    format: '.1%'
  },

  //KPI's for Clicks --------------
  clickthroughs: {
    type: 'basic',
    name: 'Clicks',
    axisLabel: 'Clicks',
    units: '',
    format: ','
  },
  clickthroughRate: {
    type: 'basic',
    name: 'Clickthrough Rate',
    axisLabel: 'Clickthrough Rate',
    units: '%',
    format: '.1%'
  },
  expectedValueCtRate: {
    type: 'basic',
    name: 'Clickthrough Rate',
    axisLabel: 'Clickthrough Rate',
    units: '%',
    format: '.1%'
  },
  //Confidence
  treatmentProbabilityClickthrough: {
    type: 'basic',
    name: 'Treatment Probability',
    axisLabel: 'Treatment Probability - Clickthroughs',
    units: '%',
    format: '.1%'
  },
  credibleIntervalMinCt: {
    type: 'basic',
    name: 'Confidence Interval - Click - Min',
    axisLabel: 'Confidence Interval - Min',
    units: '%',
    format: '.1%'
  },
  credibleIntervalMaxCt: {
    type: 'basic',
    name: 'Confidence Interval - Click - Max',
    axisLabel: 'Confidence Interval - Max',
    units: '%',
    format: '.1%'
  }
};
