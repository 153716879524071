// @flow
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { cloneDeep, isEqual } from 'lodash';

import { AuthService } from '../../../../components/auth/auth.service';
import { CampaignService } from '../../shared/campaign.service';
import { DarkPoolService } from '../../../dark-pool/shared/dark-pool.service';
import { LoggerService } from '../../../shared/logger.service';

import { startLoading, stopLoading } from '../../../../components/util';

@Component({
  selector: 'decision-service-smart-suppress',
  template: require('./decision-service-smart-suppress.html')
})
export class EloquaDecisionServiceSmartSuppressComponent implements OnInit {
  static parameters = [ActivatedRoute, HttpClient, AuthService, CampaignService, DarkPoolService, LoggerService];
  constructor(route: ActivatedRoute, http: HttpClient, authService: AuthService, campaignService: CampaignService, darkPoolService: DarkPoolService, loggerService: LoggerService) {
    this.http = http;
    this.route = route;
    this.authService = authService;
    this.loggerService = loggerService;
    this.campaignService = campaignService;
    this.darkPoolService = darkPoolService;

    this.saving = false;
    this.currentOrg = null;
    this.missingEloquaCampaignId = false;
  }

  ngOnInit() {
    this.authService.getCurrentOrg().then(org => {
      this.currentOrg = org;
    });

    //In case the currentOrg isn't set when the UI loads.
    this.orgSub = this.authService.currentOrgChanged.subscribe(org => {
      if(typeof org !== 'undefined') {
        this.currentOrg = org;
      }
    });

    //Get the query parameters.
    this.paramSub = this.route.queryParams.subscribe(params => {
      if(typeof params.id === 'undefined' || params.id === '' || typeof params.type === 'undefined') {
        this.missingEloquaCampaignId = true;
        return;
      }

      //Get params
      this.instanceId = params.instance;

      //Init a decision entity.
      this.decision = {
        status: 'draft',
        dsType: 'smartSuppress',
        config: {},
        eloquaCampaignId: parseInt(params.id)
      };

      this.initCheckboxes();

      //Get decision service from the db, if it exists.
      this.campaignService
        .getDecisionService(this.instanceId)
        .toPromise()
        .then(latestDecision => {
          //If an existing Decision exists...
          if(latestDecision) {
            this.decision = {
              instanceId: this.instanceId,
              status: latestDecision.status,
              dsType: latestDecision.dsType,
              config: latestDecision.config,
              eloquaCampaignId: latestDecision.eloquaCampaignId,
              organizationId: latestDecision.organizationId
            };

            this.decisionCopy = cloneDeep(this.decision); //Copy the decision.
          }
        })
        .catch(error => {
          if(error.status.toString() === '404') {
            if(this.currentOrg && this.currentOrg.enableSmartSuppress) {
              //If we didn't find an instance, create one automatically with the defaults.
              this.saveDecision();
            }
          }
          else if(error.status.toString()[0] !== '4') {
            this.loggerService.logMessage('Error getting decision service', 'error', error);
          }
        });
    });
  } //end: ngOnInit()

  ngOnDestroy() {
    if(this.orgSub) this.orgSub.unsubscribe();
  }

  initCheckboxes() {
    this.contactBreakdownLabels = this.darkPoolService.getContactBreakdownLabels();
    this.contactBreakdownDescriptions = this.darkPoolService.getContactBreakdownDescriptions();

    //Setup array.
    this.decision.config.contactBreakdownSelection = [];
    this.decision.config.contactBreakdownSelection.push({ checked: false, score: -1, label: this.contactBreakdownLabels.scoreUndetermined, description: this.contactBreakdownDescriptions.scoreUndetermined });
    this.decision.config.contactBreakdownSelection.push({ checked: false, score: 0, label: this.contactBreakdownLabels.score0, description: this.contactBreakdownDescriptions.score0 });
    this.decision.config.contactBreakdownSelection.push({ checked: false, score: 1, label: this.contactBreakdownLabels.score1, description: this.contactBreakdownDescriptions.score1 });
    this.decision.config.contactBreakdownSelection.push({ checked: false, score: 2, label: this.contactBreakdownLabels.score2, description: this.contactBreakdownDescriptions.score2 });
    this.decision.config.contactBreakdownSelection.push({ checked: true, score: 3, label: this.contactBreakdownLabels.score3, description: this.contactBreakdownDescriptions.score3 });
    this.decision.config.contactBreakdownSelection.push({ checked: true, score: 4, label: this.contactBreakdownLabels.score4, description: this.contactBreakdownDescriptions.score4 });
  }

  postSave() {
    stopLoading();
    this.saving = false;

    if(this.decision.status === 'draft') {
      this.configurationComplete();
    }

    this.decisionCopy = cloneDeep(this.decision); //Copy the decision.
  }

  saveDecision() {
    startLoading();
    this.saving = true;

    if(this.isChanged()) {
      if(!this.decision.hasOwnProperty('instanceId')) {
        this.campaignService
          .createDecisionService(this.instanceId, this.decision)
          .toPromise()
          .then(() => {
            this.decision.instanceId = this.instanceId;
            this.postSave();
          })
          .catch(error => {
            stopLoading();
            this.saving = false;
            this.loggerService.logMessage('Error creating decision service', 'error', error);
          });
      }
      else {
        this.campaignService
          .updateDecisionService(this.instanceId, this.decision)
          .toPromise()
          .then(() => {
            this.postSave();
          })
          .catch(error => {
            stopLoading();
            this.saving = false;
            this.loggerService.logMessage('Error updating decision service', 'error', error);
          });
      }
    }
    else {
      stopLoading();
      this.saving = false;
    }
  } //end: saveDecision()

  autoSave() {
    if(this.saving || !this.isChanged()) return; //debounce
    if(this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.saveDecision();
    }, 1.2 * 1000); //wait X seconds before save
  }

  isChanged() {
    return !isEqual(this.decision, this.decisionCopy);
  }

  //Set config-complete in Eloqua
  configurationComplete() {
    var url = `/auth/eloqua/decision-service/config-complete?instance=${this.instanceId}`;
    var data = {};

    this.saving = true;
    this.http
      .put(url, data, { responseType: 'text' })
      .toPromise()
      .then(
        () => {
          this.saving = false;
          //After configuration is complete, update status to 'active'.
          this.decision.status = 'active';
          this.saveDecision();
        },
        //Error Handling...
        error => {
          this.saving = false;
          this.loggerService.logMessage('Error configuring a Decision Service instance in Eloqua', 'error', error);
        }
      );
  } //end: configurationComplete()
}
