import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { LoggerService } from '../../../shared/logger.service';
import { OrgService } from '../../../../components/auth/org.service';

import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'update-frequency',
  template: require('./update-frequency.html')
})
export class UpdateFrequencyComponent implements OnInit {
  static parameters = [BsModalRef, LoggerService, OrgService];
  constructor(bsModalRef: BsModalRef, loggerService: LoggerService, orgService: OrgService) {
    this.bsModalRef = bsModalRef;
    this.loggerService = loggerService;
    this.orgService = orgService;
    this.organization = { fmConfig: { rules: [] }};

    this.alerts = [];
    this.isChanged = false;

    this.newFmRule = {
      id: null,
      name: '',
      emailsPerTimePeriod: null,
      timePeriodDays: null
    };
    this.allowNewFmRule = false;
  }

  ngOnInit() {
    //To handle cancel without save.
    this.workingOrgRule = cloneDeep(this.organization.fmConfig.orgFmRule);
    this.workingRules = cloneDeep(this.organization.fmConfig.rules);
  }

  changeFmRule() {
    this.alerts = [];
    this.isChanged = true;
  }

  addFmRuleChange() {
    this.alerts = [];
    this.allowNewFmRule = this.newFmRule.name && this.newFmRule.emailsPerTimePeriod && this.newFmRule.timePeriodDays;
  }

  addFmRule() {
    this.newFmRule.id = uuidv4();
    this.workingRules = [...this.workingRules, this.newFmRule];

    this.newFmRule = {
      id: null,
      name: '',
      emailsPerTimePeriod: null,
      timePeriodDays: null
    };

    this.allowNewFmRule = false;
    this.isChanged = true;
    this.alerts = [];
  }

  removeFmRule(index) {
    //TODO: Before allowing delete, add a check to make sure the current rule
    //      isn't being used by any active campaigns.
    this.alerts = [];
    this.isChanged = true;
    this.workingRules.splice(index, 1);
    this.workingRules = [...this.workingRules];
  }

  save() {
    //Save the Organization
    this.alerts = [];
    this.organization.fmConfig.orgFmRule = this.workingOrgRule;
    this.organization.fmConfig.rules = this.workingRules;
    this.orgService
      .update(this.organization)
      .toPromise()
      .then(
        () => {
          this.isChanged = false;
          this.workingOrgRule = cloneDeep(this.organization.fmConfig.orgFmRule);
          this.workingRules = cloneDeep(this.organization.fmConfig.rules);
          this.alerts.push({ type: 'success', msg: 'FM rules successfully updated.' });
        },
        //Error Handling...
        error => {
          this.alerts.push({ type: 'warning', msg: 'Error updating FM rules.' });
          this.loggerService.logMessage('Error updating FM Rules', 'error', error);
        }
      );
  }

  cancel() {
    this.bsModalRef.hide();
  }
}
