// @flow
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AuthService } from '../../../../components/auth/auth.service';
import { FunnelService } from '../../../funnel/shared/funnel.service';
import { CampaignService } from '../campaign.service';
import { PersonaService } from '../../../who/shared/persona.service';

import { ngxCsv } from 'ngx-csv/ngx-csv';

@Component({
  selector: 'eloqua',
  template: require('./eloqua-campaign.html')
})
export class EloquaCampaignComponent implements OnInit {
  @ViewChild('integerTmpl', { static: true }) integerTmpl: TemplateRef<any>;
  @ViewChild('percentTmpl', { static: true }) percentTmpl: TemplateRef<any>;

  @Input() funnelLocal;
  @Input() eloquaCampaignId;

  static parameters = [ActivatedRoute, AuthService, FunnelService, CampaignService, PersonaService];
  constructor(route: ActivatedRoute, authService: AuthService, funnelService: FunnelService, campaignService: CampaignService, personaService: PersonaService) {
    this.route = route;
    this.authService = authService;
    this.funnelService = funnelService;
    this.campaignService = campaignService;
    this.personaService = personaService;

    this.loadingPersonas = false;
    this.loadingFunnel = false;
    this.exporting = false;

    this.filters = {
      currentFilterSettings: {
        filterBy: 'eloquaCampaigns',
        ids: []
      }
    };

    this.rows = [];
    this.page = {
      number: 0,
      numberPlusOne: 1,
      size: 15,
      sizes: [10, 15, 25, 50, 100, 250]
    };

    this.currentOrg = null;
    this.currentUser = null;

    this.loading = false; //debounce
  }

  ngOnInit() {
    console.log('---------> Init eloqua-campaign:', this.funnelLocal, this.eloquaCampaignId); //debug
    this.columns = [
      { prop: 'personaName', name: 'Persona', sortable: false },
      { prop: 'emailName', name: 'Email Name', sortable: false },
      { prop: 'sends', name: 'Total Sent', sortable: false, cellClass: 'rightAlign', headerClass: 'rightAlign', cellTemplate: this.integerTmpl },
      { prop: 'opens', name: 'Opens', sortable: false, cellClass: 'rightAlign', headerClass: 'rightAlign', cellTemplate: this.integerTmpl },
      { prop: 'openRate', name: 'Open Rate', sortable: false, cellClass: 'rightAlign', headerClass: 'rightAlign', cellTemplate: this.percentTmpl },
      { prop: 'clickthroughs', name: 'Clickthroughs', sortable: false, cellClass: 'rightAlign', headerClass: 'rightAlign', cellTemplate: this.integerTmpl },
      { prop: 'clickthroughRate', name: 'Click Rate', sortable: false, cellClass: 'rightAlign', headerClass: 'rightAlign', cellTemplate: this.percentTmpl },
      { prop: 'clickToOpenRate', name: 'Click-to-Open Rate', sortable: false, cellClass: 'rightAlign', headerClass: 'rightAlign', cellTemplate: this.percentTmpl }
    ];

    this.authService.getCurrentUser().then(user => {
      this.currentUser = user;
      this.init();
    });

    this.authService.getCurrentOrg().then(org => {
      if(typeof org !== 'undefined') {
        this.currentOrg = org;
        this.init();
      }
    });

    //To handle going to this page's url directly.
    this.authService.currentOrgChanged.subscribe(org => {
      if(typeof org !== 'undefined') {
        this.currentOrg = org;
        this.init();
      }
    });

    this.route.paramMap.subscribe(params => {
      this.eloquaCampaignId = params.get('eloquaCampaignId');
      this.init();
    });
  }

  init() {
    this.funnelLocal = {
      data: {
        results: [],
        colors: {
          noEngagement: '#CECECE',
          aware: '#84C2E7',
          considering: '#41A0DA',
          engaged: '#1F8ACE'
        },
        baselineColors: {
          noEngagement: '#aeaeae',
          aware: '#699cbb',
          considering: '#388bbd',
          engaged: '#1971a9'
        }
      },
      campaignType: 'all Motiva and Non-Motiva'
    };
    this.timePeriodOptions = [];

    this.rows = [];
    this.page.number = 0;
    this.page.numberPlusOne = 1;

    //Only when we have all params, should we query the data.
    if(this.currentOrg && this.currentUser && this.eloquaCampaignId && !this.loading) {
      this.loading = true;
      this.getEloquaCampaignName();
      this.getCampaignPersonaReportData();

      this.funnelService.getEloquaCampaignTransientIds(this.currentOrg._id, this.eloquaCampaignId).then(ids => {
        this.filters.currentFilterSettings.ids = ids;
        this.buildFunnelChart();
        this.loading = false;
      });
    }
  }

  getEloquaCampaignName() {
    if(this.currentUser && this.eloquaCampaignId) {
      this.campaignService.getEloquaCampaignName(this.currentUser.organizationId, this.eloquaCampaignId)
        .then(campaignName => {
          this.campaignName = campaignName;
        },
        error => {
          console.log('Error getting Eloqua campaign details.', error);
          this.campaignName = null;
        });
    }
  }

  funnelTimePeriodChanged(timePeriod, isBaseline) {
    this.loadingFunnel = true;
    if(isBaseline) {
      this.funnelLocal.baselineTimePeriod = timePeriod;
    }
    else {
      this.funnelLocal.timePeriod = timePeriod;
    }

    // Get funnel data with new time period
    this.funnelService.getFunnelData(this.currentOrg._id, this.filters.currentFilterSettings).then(funnelData => {
      this.funnelDataAllMonths = funnelData.data;
      this.funnelDataUpdatedAt = new Date(funnelData.updatedAt);

      this.funnelService.processFunnelData(funnelData.data, this.funnelLocal).then(() => {
        this.loadingFunnel = false;
      });
    });
  }

  buildFunnelChart() {
    this.loadingFunnel = true;
    this.funnelService.getFunnelData(this.currentOrg._id, this.filters.currentFilterSettings).then(funnelData => {
      if(funnelData && funnelData.data && funnelData.data.length > 0) {
        this.funnelDataAllMonths = funnelData.data;
        this.funnelDataUpdatedAt = new Date(funnelData.updatedAt);

        this.funnelService.getTimePeriodOptions(this.funnelDataAllMonths).then(response => {
          this.timePeriodOptions = response.timePeriodOptions;
          this.funnelLocal.timePeriod = response.selectedTimePeriods.timePeriod;
          this.funnelLocal.baselineTimePeriod = response.selectedTimePeriods.baselineTimePeriod;

          this.funnelService.processFunnelData(funnelData.data, this.funnelLocal).then(() => {
            this.loadingFunnel = false;
          });
        });
      }
      else {
        this.loadingFunnel = false;
      }
    });
  }

  openEloquaCampaign() {
    var link = this.currentUser.eloqua ? `${this.currentUser.eloqua._json.urls.base}/Main.aspx#campaigns&id=${this.eloquaCampaignId}` : null;
    window.open(link, 'eloqua');
  }

  getCampaignPersonaReportData() {
    this.loadingPersonas = true;
    var promises = [];
    promises.push(this.getAllOrgPersonas());
    promises.push(this.getEloquaCampaignPersonaResults());
    Promise.all(promises).then(() => {
      var tempRows = [];
      this.allOrgPersonas.forEach(orgPersona => {
        this.eloquaCampaignPersonaResults.forEach(eloquaCampaignPersonaResult => {
          if(eloquaCampaignPersonaResult.persona_id === orgPersona.personaId) {
            tempRows.push({
              personaId: orgPersona.personaId,
              personaName: orgPersona.name,
              emailId: eloquaCampaignPersonaResult.treatment_id,
              emailName: eloquaCampaignPersonaResult.name,
              sends: parseInt(eloquaCampaignPersonaResult.sends),
              opens: parseInt(eloquaCampaignPersonaResult.opens),
              clickthroughs: parseInt(eloquaCampaignPersonaResult.clickthroughs),
              openRate: parseInt(eloquaCampaignPersonaResult.opens) / parseInt(eloquaCampaignPersonaResult.sends),
              clickthroughRate: parseInt(eloquaCampaignPersonaResult.clickthroughs) / parseInt(eloquaCampaignPersonaResult.sends),
              clickToOpenRate: parseInt(eloquaCampaignPersonaResult.clickthroughs) > 0 ? parseInt(eloquaCampaignPersonaResult.opens) / parseInt(eloquaCampaignPersonaResult.clickthroughs) : 0
            });
          }
        });
      });

      tempRows.sort((a, b) => {
        if(a.personaName === b.personaName) {
          return a.emailName < b.emailName ? -1 : 1;
        }
        else {
          return a.personaName < b.personaName ? -1 : 1;
        }
      });

      //Add group number for each persona name, so we can group the data visually.
      var prevItem;
      var groupIndex = 1;
      tempRows.forEach(item => {
        if(prevItem && prevItem.personaName !== item.personaName) {
          groupIndex++;
        }
        item.group = groupIndex;
        prevItem = item;
      });

      this.rows = tempRows;
      this.loadingPersonas = false;
      this.onPageSizeChange();
    });
  }

  getRowClass(row) {
    if(row.group % 2 == 0) {
      return 'datatable-row-even-steven';
    }
    else {
      return 'datatable-row-odd-bird';
    }
  }

  getAllOrgPersonas() {
    return this.personaService.getPersonas(this.currentOrg._id).then(personas => {
      this.allOrgPersonas = personas;
    });
  }

  getEloquaCampaignPersonaResults() {
    return this.personaService.getEloquaCampaignPersonaResults(this.currentOrg._id, this.eloquaCampaignId).then(results => {
      this.eloquaCampaignPersonaResults = results;
    });
  }

  onPageChange(event) {
    this.page.number = event.offset;
    this.page.numberPlusOne = this.page.number + 1;
    this.onPageSizeChange();
  }

  onPageSizeChange() {
    this.page.totalPages = Math.ceil(this.rows.length / this.page.size);
  }

  onPageNumberChange() {
    this.page.number = this.page.numberPlusOne - 1;
  }

  export() {
    this.exporting = true;
    var headers = ['Persona Name', 'Email Name', 'Email ID', 'Sends', 'Opens', 'Open Rate', 'Clickthroughs', 'Clickthrough Rate', 'Click-to-Open Rate'];
    var options = {
      fieldSeparator: ',',
      showLabels: true,
      noDownload: false,
      headers
    };

    var exportRows = this.rows.map(o => ({
      personaName: o.personaName,
      emailName: o.emailName,
      emailId: o.emailId,
      sends: o.sends,
      opens: o.opens,
      openRate: o.openRate,
      clickthroughs: o.clickthroughs,
      clickthroughRate: o.clickthroughRate,
      clickToOpenRate: o.clickToOpenRate
    }));

    var filename = `${this.campaignName} Performance`;
    new ngxCsv(exportRows, filename, options);
    this.exporting = false;
  }
}
