import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'campaign-selector',
  template: require('./campaign-selector.html')
})
export class CampaignSelectorComponent implements OnInit {
  @Output() action = new EventEmitter(true);

  static parameters = [BsModalRef];
  constructor(bsModalRef: BsModalRef) {
    this.bsModalRef = bsModalRef;
  }

  ngOnInit() {
    this.showEmailCount = true;
    this.emailOptions.forEach(email => {
      email.isSelected = false;

      if(typeof email.emails === 'undefined') {
        this.showEmailCount = false;
      }
    });
  }

  setSelected(selectedCampaign) {
    this.emailOptions.forEach(email => {
      email.isSelected = false;
    });

    selectedCampaign.isSelected = true;
  }

  ok() {
    var emailToOpen;
    this.emailOptions.forEach(email => {
      if(email.isSelected) {
        emailToOpen = email;
      }
    });

    this.action.emit(emailToOpen);
    this.bsModalRef.hide();
  }

  cancel() {
    this.bsModalRef.hide();
  }
}
