import { Component, ElementRef, HostListener, Input, SimpleChanges, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'viz-horizontal-stacked-bar',
  template: '<div></div>'
})
export class HorizontalStackedBarComponent implements OnInit {
  @Input() data;
  @Input() labels;
  @Input() descriptions;
  @Input() boundX;
  @Input() height;

  @HostListener('window:resize', ['$event'])
  onResize() {
    //If not vislble, don't resize.
    if(!(this.htmlElement.offsetParent !== null)) return;

    //Width comes from parent.
    this.width = this.htmlElement.parentElement.clientWidth - 10;
    this.chartWidth = +this.width - this.margin.left - this.margin.right;

    this.svg.attr('width', this.width);
    this.x = d3.scaleLinear().range([this.margin.left, this.chartWidth - this.margin.right]);

    this.render();
  }

  htmlElement: HTMLElement;
  svg;

  static parameters = [ElementRef];
  constructor(element: ElementRef) {
    this.htmlElement = element.nativeElement;
    this.host = d3.select(element.nativeElement);
    this.tooltipWidth = 270; //copied from css .horizontal-stacked-bar-tooltip width.

    this.COLOR_AQUA = '#81ecec';
    this.COLOR_GREEN = '#00b894'; // #00a569
    this.COLOR_YELLOW = '#fdcb6e'; // #fdc94f
    this.COLOR_ORANGE = '#e17055'; // #f96247
    this.COLOR_RED = '#d63031'; // #db2f51
  }

  ngOnInit() {
    this.margin = { top: 2, left: 2, bottom: 2, right: 2 };

    this.width = this.htmlElement.parentElement.clientWidth - 10; //Width comes from parent.
    if(this.width < 0) this.width = 135; //Because of table rendering issue.

    this.height = this.height ? this.height : '20';

    //Create the svg using width 100% to fill the container.
    this.svg = this.host
      .append('svg')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
      .attr('height', this.height)
      .attr('width', this.width);

    this.g = this.svg.append('g').attr('transform', `translate(${this.margin.left},${this.margin.top})`);

    // Tooltip div
    this.div = this.host
      .append('div')
      .attr('class', 'horizontal-stacked-bar-tooltip')
      .style('opacity', 0);

    // Set up chart
    this.chartWidth = +this.width - this.margin.left - this.margin.right;
    this.chartHeight = +this.height - this.margin.top - this.margin.bottom;

    this.formatPercent = d3.format('.1%');
    this.formatValue = (x) => isNaN(x) ? 'N/A' : d3.format(',')(x);

    this.boundX = this.boundX ? this.boundX : false;

    this.render();
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'data' && this.data && this.g) {
        this.render();
      }
    }
  }

  render() {
    if(!this.data) return;
    this.g.selectAll('*').remove();

    var keys = Object.keys(this.data[0]);
    var colors = [this.COLOR_AQUA, this.COLOR_GREEN, this.COLOR_YELLOW, this.COLOR_ORANGE, this.COLOR_RED, '#000000']; //TODO: externalize

    const stackGen = d3.stack()
      .keys(keys)
      .offset(d3.stackOffsetExpand);

    const series = stackGen(this.data)
      .map(d => (d.forEach(v => {
        v.key = d.key;
        v.label = this.labels[d.key];
        if(this.descriptions && this.descriptions.hasOwnProperty(d.key)) {
          v.description = this.descriptions[d.key];
        }
        else {
          v.description = '';
        }
      }), d));

    const color = d3.scaleOrdinal()
      .domain(series.map(d => d.key))
      .range(colors)
      .unknown('#fcc');

    this.x = d3.scaleLinear().range([this.margin.left, this.chartWidth - this.margin.right]);

    var bars = this.g.selectAll('.horizontal-stacked-bar')
      .data(series)
      .enter()
      .append('g')
      .attr('fill', d => color(d.key))
      .selectAll('rect')
      .data(d => d)
      .join('rect')
      .attr('class', 'horizontal-stacked-bar')
      .attr('x', d => this.x(d[0]))
      .attr('y', this.margin.top)
      .attr('width', d => this.x(d[1]) - this.x(d[0]))
      .attr('height', this.chartHeight);

    bars
      .on('mouseover', d => {
        this.hideTooltip();
        this.showTooltip(d);

        this.hideHighlight();
        this.highlightBar(d);
      })
      .on('mouseout', () => {
        this.hideTooltip();
        this.hideHighlight();
      });
  } //end: render

  showTooltip(d) {
    this.div
      .transition()
      .duration(800)
      .style('opacity', 1);

    var topEdge = 25;
    var midPoint = this.x(d[0]) + (this.x(d[1]) - this.x(d[0])) / 2;
    var leftEdge = this.margin.left + midPoint - this.tooltipWidth / 2; //minus half the width of the diaglog.

    /* start: DEBUG
    console.log('=======> Tooltip for:', d);
    console.log(`---- this.width = ${this.width}`);
    console.log('---------------------------');
    console.log(`---- this.x(d[0]) = ${this.x(d[0])}`);
    console.log(`---- this.x(d[1]) = ${this.x(d[1])}`);
    console.log('---------------------------');
    console.log(`---- midPoint = ${midPoint}`);
    console.log(`---- leftEdge = ${leftEdge}`);
    /* end: DEBUG */

    if(this.boundX) {
      if(leftEdge < 0) {
        leftEdge = this.margin.left;
        //console.log(`---- leftEdge (1) = ${leftEdge}`); //DEBUG
      }
      else if(leftEdge + this.tooltipWidth > this.width) {
        leftEdge = this.width - this.tooltipWidth + 10;
        //console.log(`---- leftEdge (2) = ${leftEdge}`); //DEBUG
      }
    }

    this.div
      .html(
        `<p class="resultLabel">${d.label}</p>`
          + `<p class="resultValue">${this.formatPercent(d[1] - d[0])} (${this.formatValue(d.data[d.key])})</p>`
          + '<hr style="margin-top:8px; margin-bottom:8px"\\>'
          + `<p class="resultDesc">${d.description}<p>`
      )
      .style('top', `${topEdge}px`)
      .style('left', `${leftEdge}px`);
  }

  hideTooltip() {
    this.div
      .transition()
      .duration(200)
      .style('opacity', 0);
  }

  highlightBar(d) {
    this.g
      .selectAll('.horizontal-stacked-bar')
      .classed('selected-horizontal-stacked-bar', dd => {
        if(d.key === dd.key) return true;
        return false;
      });
  }

  hideHighlight() {
    this.g
      .selectAll('.horizontal-stacked-bar')
      .classed('selected-horizontal-stacked-bar', false);
  }
}
