// @flow
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BsModalService } from 'ngx-bootstrap/modal';

import { cloneDeep, isEqual } from 'lodash';

import { UserService } from '../../../../components/auth/user.service';
import { CampaignService } from '../../shared/campaign.service';
import { LoggerService } from '../../../shared/logger.service';

import { CampaignSelectorComponent } from '../../shared/campaign-selector/campaign-selector.component';

import { isLoading } from '../../../../components/util';

@Component({
  selector: 'decision-service',
  template: require('./decision-service.html')
})
export class EloquaDecisionServiceComponent implements OnInit {
  STATE = {
    INIT: 'init',
    NO_MOTIVA_CAMPAIGNS: 'noCampaigns',
    ONLY_ONE_MOTIVA_CAMPAIGN: 'onlyOne',
    MORE_THAN_ONE_MOTIVA_CAMPAIGN: 'moreThanOne'
  };

  static parameters = [ActivatedRoute, HttpClient, BsModalService, UserService, CampaignService, LoggerService];
  constructor(route: ActivatedRoute, http: HttpClient, modalService: BsModalService, userService: UserService, campaignService: CampaignService, loggerService: LoggerService) {
    this.http = http;
    this.route = route;
    this.modalService = modalService;
    this.userService = userService;
    this.loggerService = loggerService;
    this.campaignService = campaignService;
    this.isLoading = isLoading;

    this.emails = [];
    this.selectedEmail = { emails: [] };
    this.motivaEmailSelected = false;
    this.state = this.STATE.INIT;
    this.saving = false;

    //Init a decision entity.
    this.decision = {
      motivaCampaignId: null,
      status: 'draft'
    };

    this.missingEloquaCampaignId = false;
  }

  ngOnInit() {
    //Get the query parameters.
    this.paramSub = this.route.queryParams.subscribe(params => {
      if(typeof params.id === 'undefined' || params.id === '' || typeof params.type === 'undefined' || params.type !== 'Campaign') {
        this.missingEloquaCampaignId = true;
        return;
      }

      this.instanceId = params.instance;
      this.eloquaCampaignId = parseInt(params.id);
      this.activity = params.activity; //send, open, click

      //Init a decision entity.
      this.decision = {
        activity: this.activity,
        motivaCampaignId: null,
        status: 'draft'
      };

      //Get decision service from the db, if it exists.
      this.campaignService
        .getCoreDecisionService(this.instanceId)
        .toPromise()
        .then(latestDecision => {
          //If an existing Decision does exist...
          if(latestDecision) {
            this.decision = {
              activity: latestDecision.activity,
              motivaCampaignId: latestDecision.campaignId,
              instanceId: latestDecision.instance_id,
              status: latestDecision.status
            };

            this.decisionCopy = cloneDeep(this.decision); //Copy the decision.
            this.motivaEmailSelected = true;
          }

          //Get any Motiva emails associated with this Eloqua campaign.
          this.getCampaigns();
        })
        .catch(error => {
          if(error.status.toString()[0] !== '4') {
            this.loggerService.logMessage('Error getting decision service', 'error', error);
          }
          else {
            //If there is not an existing decision service...
            this.getCampaigns();
          }
        });
    });
  } //end: ngOnInit()

  /**
   * Use the Eloqua Campaign ID to query any Motiva Campaigns.
   *
   */
  getCampaigns() {
    this.campaignService
      .queryByEloquaCampaign(this.eloquaCampaignId)
      .toPromise()
      .then(emails => {
        if(emails.length >= 1) {
          this.emails = emails;

          if(this.motivaEmailSelected) {
            this.checkSelectedMotivaCampaign();
          }

          if(emails.length === 1) {
            this.state = this.STATE.ONLY_ONE_MOTIVA_CAMPAIGN;

            //If there is only one and nothing is selected, create a Decision entity for this: activity & motivaCampaignId.
            if(!this.motivaEmailSelected) {
              this.decision.motivaCampaignId = emails[0].campaignId;
              this.selectedEmail = emails[0];
              this.motivaEmailSelected = true;
              this.saveDecision();
            }
          }
          else if(emails.length > 1) {
            this.state = this.STATE.MORE_THAN_ONE_MOTIVA_CAMPAIGN;

            //If nothing is selected, ask the user to choose.
            if(!this.motivaEmailSelected) {
              this.openEmailSelector();
            }
          }
        }
        else {
          //If there are none, tell the user this control only works with Motiva AI Optimizers.
          this.state = this.STATE.NO_MOTIVA_CAMPAIGNS;
        }
      })
      .catch(error => {
        this.state = this.STATE.NO_MOTIVA_CAMPAIGNS;

        if(error.status !== 404) {
          this.loggerService.logMessage('Error getting campaigns', 'error', error);
        }
      });
  } //end: getCampaigns()

  checkSelectedMotivaCampaign() {
    var found = false;

    for(var i = 0; i < this.emails.length; i++) {
      if(this.emails[i].campaignId === this.decision.motivaCampaignId) {
        this.selectedEmail = this.emails[i];
        found = true;
        break;
      }
    }

    //If we didn't find the selected Motiva AI Optimizer in the existing Decision Service on the canvas, clear the selection.
    if(!found) {
      this.motivaEmailSelected = false;
      this.selectedEmail = null;

      //TODO: Should we also set the status back to 'draft', if it is 'active'?
    }
  } //end: checkSelectedMotivaCampaign()

  postSave() {
    this.saving = false;

    if(this.decision.status === 'draft') {
      this.configurationComplete();
    }

    this.checkSelectedMotivaCampaign();
    this.decisionCopy = cloneDeep(this.decision); //Copy the decision.
  }

  saveDecision() {
    //Only save if a Motiva AI Email Optimzer (Campaign) has been selected.
    if(!this.decision.motivaCampaignId) {
      return;
    }

    this.saving = true;

    var model = {
      motivaCampaignId: this.decision.motivaCampaignId,
      status: this.decision.status
    };

    if(this.isChanged()) {
      if(!this.decision.hasOwnProperty('instanceId')) {
        this.campaignService
          .createCoreDecisionService(this.instanceId, model, this.decision.activity)
          .toPromise()
          .then(() => {
            this.decision.instanceId = this.instanceId;
            this.postSave();
          })
          .catch(error => {
            this.saving = false;
            this.loggerService.logMessage('Error creating decision service', 'error', error);
          });
      }
      else {
        this.campaignService
          .updateCoreDecisionService(this.instanceId, model)
          .toPromise()
          .then(() => {
            this.postSave();
          })
          .catch(error => {
            this.saving = false;
            this.loggerService.logMessage('Error updating decision service', 'error', error);
          });
      }
    }
    else {
      this.saving = false;
    }
  } //end: saveDecision()

  isChanged() {
    return !isEqual(this.decision, this.decisionCopy);
  }

  openEmailSelector() {
    var userNames = this.campaignService.getUserNames();

    this.emails.forEach(email => {
      if(userNames[`${email.organizationId}::${email.uid}`]) {
        email.createdBy = userNames[`${email.organizationId}::${email.uid}`];
      }
      else {
        this.userService
          .get({ id: email.uid })
          .toPromise()
          .then(user => {
            userNames[`${email.organizationId}::${email.uid}`] = user.name;
            email.createdBy = user.name;
          });
      }
    });

    const initialState = {
      emailOptions: this.emails,
      title: 'Select Motiva AI Optimizer'
    };
    this.bsModalRef = this.modalService.show(CampaignSelectorComponent, { initialState });
    var sub = this.bsModalRef.content.action.subscribe(selectedCampaign => {
      if(selectedCampaign) {
        this.decision.motivaCampaignId = selectedCampaign.campaignId;
        this.selectedEmail = selectedCampaign;
        this.motivaEmailSelected = true;
        this.saveDecision();
      }
      if(sub) sub.unsubscribe();
    });
  } //end: openEmailSelector()

  //Set config-complete in Eloqua
  configurationComplete() {
    var url = `/auth/eloqua/decision-service/config-complete?instance=${this.instanceId}`;
    var data = {};

    this.saving = true;
    this.http
      .put(url, data, { responseType: 'text' })
      .toPromise()
      .then(
        () => {
          this.saving = false;
          //After configuration is complete, update status to 'active'.
          this.decision.status = 'active';
          this.saveDecision();
        },
        //Error Handling...
        error => {
          this.saving = false;
          this.loggerService.logMessage('Error configuring a Decision Service instance in Eloqua', 'error', error);
        }
      );
  } //end: configurationComplete()
}
