import { Component, Input, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ngxCsv } from 'ngx-csv/ngx-csv';

import { AuthService } from '../../../../components/auth/auth.service';
import { ImpersonationService } from '../../../../components/auth/impersonation.service';

import { DarkPoolHelpComponent } from '../dark-pool-help/dark-pool-help.component';
import { DarkPoolService } from '../dark-pool.service';

import { cloneDeep, filter } from 'lodash';
import * as d3 from 'd3';

@Component({
  selector: 'dark-pool-bouncebacks',
  template: require('./dark-pool-bouncebacks.html')
})
export class DarkPoolBouncebacks implements OnInit {
  @Input() visible;
  @Input() currentOrg;

  @ViewChild('domainTmpl', { static: true }) domainTmpl: TemplateRef<any>;
  @ViewChild('integerTmpl', { static: true }) integerTmpl: TemplateRef<any>;
  @ViewChild('percentTmpl', { static: true }) percentTmpl: TemplateRef<any>;
  @ViewChild('exportTmpl', { static: true }) exportTmpl: TemplateRef<any>;
  @ViewChild('exportDetailsTmpl', { static: true }) exportDetailsTmpl: TemplateRef<any>;
  @ViewChild('helpTmpl', { static: true }) helpTmpl: TemplateRef<any>;
  @ViewChild('byDomainTmpl', { static: true }) byDomainTmpl: TemplateRef<any>;
  @ViewChild('misclassifiedHeaderTmpl', { static: true }) misclassifiedHeaderTmpl: TemplateRef<any>;
  @ViewChild('viewBreakdownTmpl', { static: true }) viewBreakdownTmpl: TemplateRef<any>;

  static parameters = [BsModalService, AuthService, DarkPoolService, ImpersonationService];
  constructor(modalService: BsModalService, authService: AuthService, darkPoolService: DarkPoolService, impersonation: ImpersonationService) {
    this.modalService = modalService;
    this.authService = authService;
    this.darkPoolService = darkPoolService;
    this.impersonation = impersonation;

    this.isDemo = false;
    this.settings = {};
    this.loading = true;
    this.exporting = false;

    this.darkPoolTimeRange = {};

    this.page = {
      number: 0,
      numberPlusOne: 1,
      size: 15,
      sizes: [10, 15, 25, 50, 100, 250],
      totalPages: 1
    };

    //Only for byMotivaTag
    this.domain = null;
    this.rowsByMotivaTag = [];
    this.rowsByMotivaTagCopy = [];
    this.columnsByMotivaTag = [];
    this.selectedRowsByMotivaTag = [];

    //Only for byDomain
    this.motivaTag = null;
    this.rowsByDomain = [];
    this.rowsByDomainCopy = [];
    this.columnsByDomain = [];
    this.filterType = 'both';
    this.searchString = '';

    this.bouncebackTab = 'byMotivaTag';
  }

  ngOnInit() {
    this.isDemo = this.impersonation.isDemoMode();
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'visible') {
        //Lazy load.
        if(this.visible) {
          // Get DP time range
          this.darkPoolService.getDarkPoolTimeRange(this.currentOrg._id).then(timeRange => {
            this.darkPoolTimeRange.startDate = timeRange.start_date;
            this.darkPoolTimeRange.endDate = timeRange.end_date;

            this.init();
            this.loadByMotivaTag();
          });
        }
      }
    }
  }

  onPageChange(event) {
    this.page.number = event.offset;
    this.page.numberPlusOne = this.page.number + 1;
    this.onPageSizeChange();
  }

  onPageSizeChange() {
    if(this.bouncebackTab === 'byMotivaTag') {
      this.page.totalPages = Math.ceil(this.rowsByMotivaTag.length / this.page.size);
    }
    else if(this.bouncebackTab === 'byDomain') {
      this.page.totalPages = Math.ceil(this.rowsByDomain.length / this.page.size);
    }
  }

  onPageNumberChange() {
    this.page.number = this.page.numberPlusOne - 1;
  }

  init() {
    this.settings = this.darkPoolService.getLatestDarkPoolSettings(this.currentOrg);
    this.setColumnsByMotivaTag();
    this.setColumnsByDomain();
  }

  loadData() {
    if(this.bouncebackTab === 'byMotivaTag') {
      this.loadByMotivaTag();
    }
    else if(this.bouncebackTab === 'byDomain') {
      this.loadByDomain();
    }
  }

  exportContactIDs(motivaTag) {
    if(this.bouncebackTab === 'byMotivaTag') {
      this.exportByMotivaTagContactIDs(motivaTag);
    }
    //Currently, there is no byDomain contact export.
  }

  countBounces() {
    this.bounceCount = 0;
    if(this.bouncebackTab === 'byMotivaTag') {
      this.rowsByMotivaTag.forEach(item => {
        this.bounceCount += item.numberOfBouncebacks;
      });
    }
    else if(this.bouncebackTab === 'byDomain') {
      this.rowsByDomain.forEach(item => {
        this.bounceCount += item.numberOfBouncebacks;
      });
    }
    this.onPageSizeChange();
  }

  tabChanged() {
    if(this.loading || this.exporting) return;
    if(this.bouncebackTab === 'byMotivaTag') {
      this.countBounces();
    }
    if(this.bouncebackTab === 'byDomain') {
      if(this.rowsByDomainCopy.length === 0) {
        this.loadByDomain();
      }
      else {
        this.countBounces();
      }
    }
  }

  removeFilter() {
    if(this.loading || this.exporting) return;
    this.domain = null;
    this.motivaTag = null;

    if(this.bouncebackTab === 'byMotivaTag') {
      this.loadByMotivaTag();
    }
    if(this.bouncebackTab === 'byDomain') {
      this.loadByDomain();
    }
  }

  /**
   * By Motiva Tag specific code
   *
   */
  setColumnsByMotivaTag() {
    this.columnsByMotivaTag = [
      {
        prop: 'selected',
        name: '',
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizable: false,
        headerCheckboxable: true,
        checkboxable: true,
        width: 40
      },
      { prop: 'motivaTag', name: 'Motiva Tag' },
      { prop: 'isHard', name: 'Motiva-Flagged<br/>Hard Bounce', headerClass: 'centerAlign', cellClass: 'centerAlign', width: 85 },
      { prop: 'motivaTag', name: 'Help | Action', width: 70, cellTemplate: this.helpTmpl, sortable: false }
    ];

    if(!this.domain) {
      this.columnsByMotivaTag.push({ prop: 'motivaTag', name: 'Breakdown', width: 75, cellTemplate: this.byDomainTmpl, sortable: false });
    }

    if(this.domain) {
      this.columnsByMotivaTag.push({ prop: 'motivaTag', name: 'Details', headerClass: 'darkPoolHeaderAttention', cellTemplate: this.exportDetailsTmpl });
    }

    this.columnsByMotivaTag.push({ prop: 'numberOfContactsBounced', name: 'Contacts Bounced', cellClass: 'rightAlign', headerClass: ' darkPoolHeaderAttention rightAlign', cellTemplate: this.integerTmpl });
    this.columnsByMotivaTag.push({ prop: 'numberOfBouncebacks', name: 'Total Bounces', cellClass: 'rightAlign', headerClass: ' darkPoolHeaderAttention rightAlign', cellTemplate: this.integerTmpl });
    this.columnsByMotivaTag.push({ prop: 'percentContactsMisclassified', name: 'Misclassified Percent', cellClass: 'rightAlign', headerClass: ' darkPoolHeaderAttention rightAlign', cellTemplate: this.percentTmpl, headerTemplate: this.misclassifiedHeaderTmpl });
    this.columnsByMotivaTag.push({ prop: 'motivaTag', name: 'Export Contact ID\'s', headerClass: 'darkPoolHeaderAttention', cellTemplate: this.exportTmpl, sortable: false });
  }

  loadByMotivaTag() {
    this.page.number = 0;
    this.page.numberPlusOne = 1;

    if(this.isDemo) {
      this.loadByMotivaTagDemo();
      return;
    }
    this.loading = true;
    this.bounceCount = null;
    this.rowsByMotivaTag = [];

    //For loading sample data when the bounceback report hasn't been loaded.
    var limit = !this.settings.isBouncebackLoaded && this.darkPoolService.sampleStats.bounceLimit ? this.darkPoolService.sampleStats.bounceLimit : null;

    this.darkPoolService.getBreakdownByMotivaTag(this.darkPoolTimeRange.startDate, this.darkPoolTimeRange.endDate, this.domain, limit).then(data => {
      this.bounceCount = 0;
      data.forEach(item => {
        this.bounceCount += item.numberOfBouncebacks;
        item.class = this.darkPoolService.getMotivaTagClass(item.motivaTag); //Add Hard/Soft indicator
        item.isHard = item.class === 'hard' ? 'X' : null;
        item.percentContactsMisclassified = item.class === 'hard' ? item.percentContactsBouncedValid : null;

        //Save sampleStats for each tag.
        this.darkPoolService.sampleStats[item.motivaTag] = item.numberOfBouncebacks;
      });

      this.rowsByMotivaTag = data;
      this.rowsByMotivaTagCopy = cloneDeep(data);
      this.onPageSizeChange();
      this.loading = false;
    });
  }

  exportByMotivaTagContactIDs(motivaTag) {
    if(!this.settings.isBouncebackLoaded) {
      window.alert('Exporting is not supported with sample data.');
      return false;
    }

    this.exporting = true;
    this.darkPoolService.exportBouncebackContactIDs(this.darkPoolTimeRange.startDate, this.darkPoolTimeRange.endDate, motivaTag, this.domain).then(contactIDs => {
      var headers = ['Eloqua Contact ID', 'Is Valid'];
      var options = {
        fieldSeparator: ',',
        showLabels: true,
        noDownload: false,
        headers
      };

      var filename = `Bounceback contacts-${motivaTag}`;
      if(this.domain) filename += `-${this.domain}`;

      if(this.currentOrg.darkPoolSetting === 'freemium') {
        filename += '-FREEMIUM';
      }

      new ngxCsv(contactIDs, filename, options);
      this.exporting = false;
    });

    return false;
  }

  onSelectByMotivaTag({ selected }) {
    this.selectedRowsByMotivaTag.splice(0, this.selectedRowsByMotivaTag.length);
    this.selectedRowsByMotivaTag.push(...selected);
  }

  exportSelectedByMotivaTagContactIDs() {
    if(!this.settings.isBouncebackLoaded) {
      window.alert('Exporting is not supported with sample data.');
      return false;
    }

    this.exporting = true;
    var motivaTags = this.selectedRowsByMotivaTag.map(x => x.motivaTag);

    this.darkPoolService.exportTagsBouncebackContactIDs(this.darkPoolTimeRange.startDate, this.darkPoolTimeRange.endDate, motivaTags, this.domain).then(contactIDs => {
      var headers = ['Eloqua Contact ID', 'Is Valid'];
      var options = {
        fieldSeparator: ',',
        showLabels: true,
        noDownload: false,
        headers
      };

      var filename = 'Bounceback contacts';

      //Add the number of motivaTags to the filename.
      filename += `-for ${d3.format(',')(motivaTags.length)} motivaTags`;

      if(this.domain) filename += `-${this.domain}`;

      if(this.currentOrg.darkPoolSetting === 'freemium') {
        filename += '-FREEMIUM';
      }

      new ngxCsv(contactIDs, filename, options);
      this.exporting = false;
    });
  }

  exportAllByMotivaTagContactIDs() {
    if(!this.settings.isBouncebackLoaded) {
      window.alert('Exporting is not supported with sample data.');
      return false;
    }

    this.exporting = true;
    this.darkPoolService.exportAllBouncebackContactIDs(this.darkPoolTimeRange.startDate, this.darkPoolTimeRange.endDate, this.domain).then(contactIDs => {
      var headers = ['Eloqua Contact ID', 'Is Valid'];
      var options = {
        fieldSeparator: ',',
        showLabels: true,
        noDownload: false,
        headers
      };

      var filename = 'All Bounceback contacts';
      if(this.domain) filename += `-${this.domain}`;

      if(this.currentOrg.darkPoolSetting === 'freemium') {
        filename += '-FREEMIUM';
      }

      new ngxCsv(contactIDs, filename, options);
      this.exporting = false;
    });
  }

  exportBouncebackReport(motivaTag) {
    if(!this.settings.isBouncebackLoaded) {
      window.alert('Exporting is not supported with sample data.');
      return false;
    }

    this.exporting = true;
    this.darkPoolService.exportBouncebackReport(this.darkPoolTimeRange.startDate, this.darkPoolTimeRange.endDate, this.domain, motivaTag).then(contactIDs => {
      var headers = [
        'Eloqua Contact ID',
        'Timestamp',
        'SMTP Error Code',
        'SMTP Reply Code',
        'Motiva Tag',
        'Bounceback From Address',
        'Bounceback Message',
        'Email Name',
        'Email Subject',
        'Email Group'
      ];
      var options = {
        fieldSeparator: ',',
        showLabels: true,
        noDownload: false,
        headers
      };

      var filename = `Bounceback report-${this.domain}-${motivaTag}`;

      if(this.currentOrg.darkPoolSetting === 'freemium') {
        filename += '-FREEMIUM';
      }

      new ngxCsv(contactIDs, filename, options);
      this.exporting = false;
    });

    return false;
  }

  openHelp(motivaTag) {
    const initialState = { motivaTag };
    this.modalService.show(DarkPoolHelpComponent, { initialState });
    return false;
  }

  viewByDomain(motivaTag) {
    this.bouncebackTab = 'byDomain';
    this.domain = null;
    this.motivaTag = motivaTag;
    this.loadByDomain();
    return false;
  }

  loadByMotivaTagDemo() {
    this.loading = true;
    this.bounceCount = null;
    this.rowsByMotivaTag = [];
    this.darkPoolService.getDemoData('bounceback-breakdown').then(data => {
      this.bounceCount = 0;
      data.forEach(item => {
        this.bounceCount += item.numberOfBouncebacks;
        item.class = this.darkPoolService.getMotivaTagClass(item.motivaTag); //Add Hard/Soft indicator
        item.isHard = item.class === 'hard' ? 'X' : null;
        item.percentContactsMisclassified = item.class === 'hard' ? item.percentContactsBouncedValid : null;

        //Save sampleStats for each tag.
        this.darkPoolService.sampleStats[item.motivaTag] = item.numberOfBouncebacks;
      });

      this.rowsByMotivaTag = data;
      this.rowsByMotivaTagCopy = cloneDeep(data);
      this.onPageSizeChange();
      this.loading = false;
    });
  }

  /**
   * By Domain specific code
   *
   */
  setColumnsByDomain() {
    //Column defs for the grid -- different for sample data
    if(this.settings.isBouncebackLoaded) {
      this.columnsByDomain = [
        { prop: 'domain', name: 'Domain', cellTemplate: this.domainTmpl },
        { prop: 'totalContacts', name: 'Total Contacts', cellClass: 'rightAlign', headerClass: ' rightAlign', cellTemplate: this.integerTmpl },
        { prop: 'numberOfContactsBounced', name: 'Contacts Bounced (#)', cellClass: 'rightAlign', headerClass: ' darkPoolHeaderAttention rightAlign', cellTemplate: this.integerTmpl },
        { prop: 'bouncedContactsPercent', name: 'Contacts Bounced (%)', cellClass: 'rightAlign', headerClass: 'darkPoolHeaderAttention rightAlign', cellTemplate: this.percentTmpl },
        { prop: 'totalSends', name: 'Total Sends', cellClass: 'rightAlign', headerClass: ' rightAlign', cellTemplate: this.integerTmpl },
        { prop: 'numberOfBouncebacks', name: 'Total Bounces', cellClass: 'rightAlign', headerClass: ' darkPoolHeaderAttention rightAlign', cellTemplate: this.integerTmpl },
        //{ prop: 'bouncePercent', name: 'Bounce Percent', cellClass: 'rightAlign', headerClass: 'darkPoolHeaderAttention rightAlign', cellTemplate: this.percentTmpl },
        { prop: 'domain', name: 'Bounceback Details', headerClass: 'darkPoolHeaderAttention', cellTemplate: this.viewBreakdownTmpl, sortable: false }
      ];
    }
    else {
      this.columnsByDomain = [
        { prop: 'domain', name: 'Domain', cellTemplate: this.domainTmpl },
        { prop: 'numberOfContactsBounced', name: 'Contacts Bounced (#)', cellClass: 'rightAlign', headerClass: ' darkPoolHeaderAttention rightAlign', cellTemplate: this.integerTmpl },
        { prop: 'numberOfBouncebacks', name: 'Total Bounces', cellClass: 'rightAlign', headerClass: ' darkPoolHeaderAttention rightAlign', cellTemplate: this.integerTmpl },
        { prop: 'domain', name: 'Bounceback Details', headerClass: 'darkPoolHeaderAttention', cellTemplate: this.viewBreakdownTmpl, sortable: false }
      ];
    }
  }

  loadByDomain() {
    this.page.number = 0;
    this.page.numberPlusOne = 1;

    if(this.isDemo) {
      this.loadByDomainDemo();
      return;
    }

    this.loading = true;
    this.bounceCount = null;
    this.rowsByDomain = [];

    //For loading sample data when the bounceback report hasn't been loaded.
    var limit = !this.settings.isBouncebackLoaded && this.darkPoolService.sampleStats.bounceLimit ? this.darkPoolService.sampleStats.bounceLimit : null;
    if(this.motivaTag) {
      limit = this.darkPoolService.sampleStats[this.motivaTag];
    }

    this.darkPoolService.getBouncesByDomain(this.darkPoolTimeRange.startDate, this.darkPoolTimeRange.endDate, this.motivaTag, limit).then(data => {
      this.rowsByDomain = data;
      this.rowsByDomainCopy = cloneDeep(data);
      this.countBounces();
      this.onPageSizeChange();
      this.loading = false;
    });
  }

  filterBreakdown() {
    if(this.filterType === 'both') {
      this.rowsByDomain = cloneDeep(this.rowsByDomainCopy);
    }
    else {
      this.rowsByDomain = filter(this.rowsByDomainCopy, o => o.type === this.filterType);
    }
    this.countBounces();
  }

  filterChanged() {
    this.rowsByDomain = [];

    // Filter by search string
    var searchString = this.searchString.toLowerCase();
    if(searchString !== '') {
      function searchFilter(row) {
        return row.domain && row.domain.toLowerCase().indexOf(searchString) > -1;
      }
      this.rowsByDomain = this.rowsByDomainCopy.filter(searchFilter);
    }
    else {
      this.rowsByDomain = cloneDeep(this.rowsByDomainCopy);
    }
    this.countBounces();
  }

  viewBreakdown(domain) {
    this.bouncebackTab = 'byMotivaTag';
    this.domain = domain;
    this.motivaTag = null;
    this.loadByMotivaTag();
    return false;
  }

  exportDomainStats() {
    var filename = `${this.motivaTag} Bouncebacks by Domain`;
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      noDownload: false,
      headers: [
        'Domain',
        'Total Contacts',
        'Contacts Bounced (#)',
        'Contacts Bounced (%)',
        'Total Sends',
        'Total Bounces'
      ]
    };

    var exportData = [];
    this.rowsByDomain.forEach(row => {
      exportData.push({
        domain: row.domain,
        totalContacts: row.totalContacts,
        numberOfContactsBounced: row.numberOfContactsBounced,
        bouncedContactsPercent: row.bouncedContactsPercent,
        totalSends: row.totalSends,
        numberOfBouncebacks: row.numberOfBouncebacks
      });
    });

    new ngxCsv(exportData, filename, options);
  }

  loadByDomainDemo() {
    this.loading = true;
    this.bounceCount = null;
    this.darkPoolService.getDemoData('bounces-by-domain').then(data => {
      this.rowsByDomain = data;
      this.rowsByDomainCopy = cloneDeep(data);
      this.countBounces();
      this.loading = false;
    });
  }
}
