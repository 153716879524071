// @flow
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { combineLatest, defer, from } from 'rxjs';
import { mergeAll } from 'rxjs/operators';

import { BsModalService } from 'ngx-bootstrap/modal';

import { AuthService } from '../../../components/auth/auth.service';
import { ImpersonationService } from '../../../components/auth/impersonation.service';

import { CampaignService } from '../shared/campaign.service';
import { CampaignFilesService } from '../shared/campaign-files.service';
import { CampaignSelectorComponent } from '../shared/campaign-selector/campaign-selector.component';

import { isEquivalentObject } from '../../../components/util';
import { cloneDeep, uniqBy } from 'lodash';

@Component({
  selector: 'campaign-list',
  template: require('./campaign-list.html')
})
export class CampaignListComponent implements OnInit {
  @ViewChild('searchField') searchField: ElementRef;

  searchString = '';
  campaigns = [];
  campaignsFiltered = [];
  eloquaCampaigns = [];

  selectedCampaignId; //Selected Motiva step id from url
  selectedEloquaCampaignId; //Eloqua Campaign ID selected via url.

  campaignsExpanded = {};
  allExpanded = false;

  currentUser;

  static parameters = [ActivatedRoute, Router, BsModalService, AuthService, ImpersonationService, CampaignService, CampaignFilesService];
  constructor(route: ActivatedRoute, router: Router, modalService: BsModalService, authService: AuthService, impersonation: ImpersonationService, campaignService: CampaignService, campaignFilesService: CampaignFilesService) {
    this.route = route;
    this.router = router;
    this.authService = authService;
    this.modalService = modalService;
    this.impersonation = impersonation;
    this.campaignService = campaignService;
    this.campaignFilesService = campaignFilesService;
    this.campaignLevel = false;
  }

  ngOnInit() {
    console.log('---------> Init campaign-list.'); //debug
    this.resetFilter();

    this.authService.getCurrentUser().then(user => {
      this.currentUser = user;
    });

    this.isDemo = this.impersonation.isDemoMode();

    //For Impersonation
    this.userSub = this.authService.currentUserChanged.subscribe(user => {
      this.currentUser = user;
      this.getCampaigns();
    });

    if(this.route.firstChild) {
      //Get route and query params together
      var paramSub = combineLatest(this.route.firstChild.paramMap, this.route.firstChild.queryParamMap, (paramMap, queryParamMap) => ({ paramMap, queryParamMap }));

      paramSub.subscribe(ap => {
        this.selectedCampaignId = ap.paramMap.get('campaignId');

        //We got an eloqua campaign id
        if(ap.queryParamMap.get('type') === 'eloqua') {
          this.selectedEloquaCampaignId = ap.paramMap.get('campaignId');
          this.selectedCampaignId = null;
        }

        if(ap.paramMap.get('eloquaCampaignId')) {
          this.campaignLevel = true;
          this.selectedEloquaCampaignId = ap.paramMap.get('eloquaCampaignId');
        }
      });

      this.getCampaigns();
    }
    else {
      //No child, so we navigated directly to url: /campaigns
      this.getCampaigns();
    }
  }

  ngOnDestroy() {
    if(this.userSub) this.userSub.unsubscribe();
    if(this.paramSub) this.paramSub.unsubscribe();

    //Save filter and expanded campaigns, but not when impersonating.
    if(!this.impersonation.isImpersonating() && !this.isDemo) {
      localStorage.setItem('emailListFilter', JSON.stringify(this.emailListFilter));
      localStorage.setItem('campaignsExpanded', JSON.stringify(this.campaignsExpanded));
    }
  }

  resetFilter() {
    this.filterOn = false;
    this.emailListFilter = {
      sortBy: 'updatedAt',
      type: {
        messageTest: true,
        sendTime: true,
        simple: true
      },
      status: {
        draft: true,
        settingUp: true,
        scheduled: true,
        running: true,
        collectingResults: true,
        completed: true,
        failed: true,
        cancelled: true
      }
    };

    this.applyFilter();
  }

  filterChanged(emailListFilterLocal) {
    if(isEquivalentObject(this.emailListFilter, emailListFilterLocal)) {
      return false;
    }
    return true;
  }

  updateFilter() {
    this.searchString = '';
    this.filterOn = true;
    this.applyFilter();

    //Save filter to localStorage when no impersonating and not demo.
    if(!this.impersonation.isImpersonating() && !this.isDemo) {
      localStorage.setItem('emailListFilter', JSON.stringify(this.emailListFilter));
    }
  }

  applyFilter() {
    let filterByType = (campaign) => {
      var found = false;
      if(this.emailListFilter.type.messageTest && campaign.campaignType === 'message-testing') {
        found = true;
      }
      else if(this.emailListFilter.type.sendTime && campaign.campaignType === 'send-time-optimized') {
        found = true;
      }
      else if(this.emailListFilter.type.simple && campaign.campaignType === 'simple-blast') {
        found = true;
      }
      return found;
    };

    let filterByStatus = (campaign) => {
      var found = false;
      if(this.emailListFilter.status.draft && this.campaignService.isUpdateable(campaign.status)) {
        found = true;
      }
      else if(this.emailListFilter.status.settingUp && campaign.status === 'settingUp') {
        found = true;
      }
      else if(this.emailListFilter.status.scheduled && campaign.status === 'scheduled') {
        found = true;
      }
      else if(this.emailListFilter.status.running && campaign.status === 'running') {
        found = true;
      }
      else if(this.emailListFilter.status.collectingResults && campaign.status === 'finishedExperimentNowCollecting') {
        found = true;
      }
      else if(this.emailListFilter.status.completed && campaign.status === 'completed') {
        found = true;
      }
      else if(this.emailListFilter.status.failed && this.campaignService.isError(campaign)) {
        found = true;
      }
      else if(this.emailListFilter.status.cancelled && campaign.status === 'cancelled') {
        found = true;
      }
      return found;
    };

    this.campaignsFiltered = this.campaigns.filter(filterByType);
    this.campaignsFiltered = this.campaignsFiltered.filter(filterByStatus);
    this.campaignsFilteredSaved = cloneDeep(this.campaignsFiltered); //save to use for text search.
    this.sortCampaignsByEloquaId();
  } //end: applyFilter()

  getCampaigns() {
    if(!this.isDemo) {
      //Get the list of campaigns for this user.
      this.campaignService.query().subscribe(campaigns => {
        //Remove duplicates...
        this.campaigns = uniqBy(campaigns, 'campaignId');

        //TMP: Strip out anything that hasn't been updated for 12 months.
        var testDate = new Date();
        testDate.setMonth(testDate.getMonth() - 12);
        this.campaigns = this.campaigns.filter(campaign => {
          const campaignDate = new Date(campaign.statusDate);
          return campaignDate > testDate;
        });

        //Build master list of Eloqua Campaign details
        this.allEloquaCampaigns = {};

        //Display list once before getting names and users, incase it takes a long time.
        this.showCampaignList();

        const observables = this.campaigns.map(campaign => defer(() => this.addEloquaCampaignNameRx(campaign)));
        from(observables)
          .pipe(mergeAll(10)) //limit concurrency
          .subscribe({
            complete: () => {
              this.campaigns.forEach(campaign => {
                if(this.allEloquaCampaigns[campaign.eloquaCampaignId]) {
                  campaign.eloquaCampaignName = this.allEloquaCampaigns[campaign.eloquaCampaignId];
                }
              });

              //Add user info to the campaigns in the list.
              this.campaignService.addUserInfo(this.campaigns).then(() => {
                this.showCampaignList();
              });

              this.searchField.nativeElement.focus();
            }
          });
      });
    }
    else {
      setTimeout(() => {
        //Load Demo campaigns.
        this.campaigns = this.campaignFilesService.getDemoCampaignList();
        this.campaignsFiltered = cloneDeep(this.campaigns);
        this.allEloquaCampaigns = {};
        this.sortCampaignsByEloquaId();
        this.campaignsExpanded['126614'] = true;
        this.campaignsExpanded['127709'] = true;
        this.campaignsExpanded['127710'] = true;
        this.openSelectedCampaign();
        this.searchField.nativeElement.focus();
      }, 800);
    }
  }

  showCampaignList() {
    //Create a copy of the Campaign list to be filtered
    this.campaignsFiltered = cloneDeep(this.campaigns);

    //Save filter to localStorage when not impersonating.
    if(!this.impersonation.isImpersonating()) {
      var emailListFilterLocal = JSON.parse(localStorage.getItem('emailListFilter'));
      if(emailListFilterLocal) {
        if(this.filterChanged(emailListFilterLocal)) {
          this.filterOn = true;
          this.emailListFilter = emailListFilterLocal;
        }
      }
      this.applyFilter();
    }
    else {
      this.sortCampaignsByEloquaId();
    }

    this.reopenExpandedCampaigns();
    this.openSelectedCampaign();
  }

  addEloquaCampaignName(campaign) {
    const promise = new Promise(resolve => {
      if(campaign.eloquaCampaignId && !this.allEloquaCampaigns.hasOwnProperty(campaign.eloquaCampaignId)) {
        this.allEloquaCampaigns[campaign.eloquaCampaignId] = 'Eloqua Campaign:'; //debounce
        this.campaignService.getEloquaCampaignName(campaign.organizationId, campaign.eloquaCampaignId)
          .then(campaignName => {
            this.allEloquaCampaigns[campaign.eloquaCampaignId] = campaignName;
            resolve();
          },
          error => {
            this.allEloquaCampaigns[campaign.eloquaCampaignId] = null; //not found
            console.log(`Error getting Eloqua campaign details for id = ${campaign.eloquaCampaignId}.`, error); //debug
            resolve();
          });
      }
      else {
        resolve();
      }
    });
    return promise;
  }

  async addEloquaCampaignNameRx(campaign) {
    await this.addEloquaCampaignName(campaign);
  }

  openCampaign(campaignId) {
    this.selectedCampaignId = campaignId;
    this.router.navigate(['/campaigns', campaignId]);
  }

  openSelectedCampaign() {
    //If we are opening campaign-level details, don't open a campaign.
    if(this.campaignLevel) return;

    var eloquaCampaignId;
    if(this.selectedCampaignId) {
      eloquaCampaignId = this.eloquaCampaignByMotivaID[this.selectedCampaignId];
      this.campaignsExpanded[eloquaCampaignId] = true;
    }
    else if(this.selectedEloquaCampaignId) {
      this.campaignsExpanded[this.selectedEloquaCampaignId] = true;
      var selectedCampaigns = this.campaignsByEloquaID[this.selectedEloquaCampaignId];

      if(selectedCampaigns.length <= 0) {
        //If none, go to the Eloqua campaign page.
        this.router.navigate(['/campaigns/eloqua', this.selectedEloquaCampaignId]);
      }
      else if(selectedCampaigns.length === 1) {
        //If only one, open it.
        this.router.navigate(['/campaigns', selectedCampaigns[0].campaignId]);
      }
      else {
        //If more than one, open a dialog to select which one.
        this.openCampaignSelector(selectedCampaigns);
      }
    }
    else if(this.eloquaCampaigns.length > 0) {
      //If no campaign was selected, open the first one in the list
      this.selectedCampaignId = this.eloquaCampaigns[0].campaigns[0].campaignId;
      eloquaCampaignId = this.eloquaCampaignByMotivaID[this.selectedCampaignId];
      this.campaignsExpanded[eloquaCampaignId] = true;
      this.router.navigate(['/campaigns', this.selectedCampaignId]);
    }
  } //end: openSelectedCampaign()

  openCampaignSelector(selectedCampaigns) {
    this.campaignService.getUserNames(); //<< do we need this?

    const initialState = {
      emailOptions: selectedCampaigns,
      title: 'Select Motiva AI Optimizer'
    };
    this.bsModalRef = this.modalService.show(CampaignSelectorComponent, { initialState });
    var sub = this.bsModalRef.content.action.subscribe(selectedCampaign => {
      if(selectedCampaign) {
        this.router.navigate(['/campaigns', selectedCampaign.campaignId]);
      }
      if(sub) sub.unsubscribe();
    });
  } //end: openCampaignSelector()

  //Filter campaigns based on search
  filterCampaigns() {
    var searchString = this.searchString.toLowerCase();

    function searchFilter(campaign) {
      return (
        campaign.name && campaign.name.toLowerCase().indexOf(searchString) > -1
        || campaign.createdBy
          && campaign.createdBy
            .toString()
            .toLowerCase()
            .indexOf(searchString) > -1
        || campaign.eloquaCampaignId && campaign.eloquaCampaignId.toString().indexOf(searchString) > -1
        || campaign.eloquaCampaignName && campaign.eloquaCampaignName.toLowerCase().indexOf(searchString) > -1
      );
    }

    //When the filter is on, search on that list instead of the entire list of campaigns.
    if(this.filterOn) {
      this.campaignsFiltered = this.campaignsFilteredSaved.filter(searchFilter);
    }
    else {
      this.campaignsFiltered = this.campaigns.filter(searchFilter);
    }

    this.sortCampaignsByEloquaId();
  }

  sortCampaignsByEloquaId() {
    var eloquaCampaignIds = this.sortEloquaCampaigns();
    this.eloquaCampaigns = [];

    //Now build the Eloqua campaign list and decide which campaign to open.
    eloquaCampaignIds.forEach(id => {
      this.eloquaCampaigns.push({
        id,
        name: this.allEloquaCampaigns[id] ? this.allEloquaCampaigns[id] : null,
        link: this.currentUser.eloqua ? `${this.currentUser.eloqua._json.urls.base}/Main.aspx#campaigns&id=${id}` : '',
        campaigns: this.campaignsByEloquaID[id]
      });
    });
  } //end: sortCampaignsByEloquaId()

  sortEloquaCampaigns() {
    // Sort campaigns by eloqua campaign id
    this.campaignsByEloquaID = {};
    this.eloquaCampaignByMotivaID = {};

    this.campaignsFiltered.forEach(campaign => {
      if(this.campaignsByEloquaID[campaign.eloquaCampaignId]) {
        this.campaignsByEloquaID[campaign.eloquaCampaignId].push(campaign);
      }
      else {
        this.campaignsByEloquaID[campaign.eloquaCampaignId] = [campaign];
      }

      if(!this.eloquaCampaignByMotivaID.hasOwnProperty(campaign.campaignId)) {
        this.eloquaCampaignByMotivaID[campaign.campaignId] = campaign.eloquaCampaignId;
      }
    });

    var eloquaCampaignIds = Object.keys(this.campaignsByEloquaID);

    // Sort emails within eloqua campaign alphabetically by campaign name
    eloquaCampaignIds.forEach(eloquaCampaignId => {
      this.campaignsByEloquaID[eloquaCampaignId].sort((a, b) => {
        if(a.name < b.name) {
          return -1;
        }
        if(a.name > b.name) {
          return 1;
        }
        return 0;
      });
    });

    //sort eloqua campaign ids
    eloquaCampaignIds.sort((a, b) => {
      var aCampaigns = this.campaignsByEloquaID[a];
      var bCampaigns = this.campaignsByEloquaID[b];
      var maxDateA;
      var maxDateB;

      if(this.emailListFilter.sortBy === 'updatedAt') {
        maxDateA = aCampaigns[0].statusDate ? new Date(aCampaigns[0].statusDate) : new Date(aCampaigns[0].updatedAt);
        maxDateB = bCampaigns[0].statusDate ? new Date(bCampaigns[0].statusDate) : new Date(bCampaigns[0].updatedAt);
      }
      else {
        maxDateA = new Date(aCampaigns[0].createdAt);
        maxDateB = new Date(bCampaigns[0].createdAt);
      }

      aCampaigns.forEach(campaign => {
        var campaignDate;
        if(this.emailListFilter.sortBy === 'updatedAt') {
          campaignDate = campaign.statusDate ? new Date(campaign.statusDate) : new Date(campaign.updatedAt);
        }
        else {
          campaignDate = new Date(campaign.createdAt);
        }

        if(campaignDate > maxDateA) {
          maxDateA = campaignDate;
        }
      });

      bCampaigns.forEach(campaign => {
        var campaignDate;
        if(this.emailListFilter.sortBy === 'updatedAt') {
          campaignDate = campaign.statusDate ? new Date(campaign.statusDate) : new Date(campaign.updatedAt);
        }
        else {
          campaignDate = new Date(campaign.createdAt);
        }

        if(campaignDate > maxDateB) {
          maxDateB = campaignDate;
        }
      });

      return maxDateB - maxDateA;
    });

    return eloquaCampaignIds;
  } //end: sortEloquaCampaigns()

  //Open a campaign in Eloqua
  openEloquaCampaign(link) {
    window.open(link, 'eloqua');
  }

  //*********************************
  // Expand collapse handling
  //*********************************

  reopenExpandedCampaigns() {
    //Don't do when Impersonating.
    if(this.impersonation.isImpersonating()) return;

    var localCampaignsExpanded = JSON.parse(localStorage.getItem('campaignsExpanded'));
    if(localCampaignsExpanded) {
      this.campaignsExpanded = localCampaignsExpanded;
    }
  }

  expandCollapseCampaign(eloquaCampaignId) {
    this.campaignsExpanded[eloquaCampaignId] = !this.campaignsExpanded[eloquaCampaignId];
  }

  expandCampaign(eloquaCampaignId) {
    this.campaignsExpanded[eloquaCampaignId] = true;
  }

  expandCollapseAll() {
    this.allExpanded = !this.allExpanded;
    this.eloquaCampaigns.forEach(eloquaCampaign => {
      this.campaignsExpanded[eloquaCampaign.id] = this.allExpanded;
    });
  }
}
