import { Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { PersonaService } from '../../../../who/shared/persona.service';

import { cloneDeep } from 'lodash';

@Component({
  selector: 'persona-selector-modal',
  template: require('./persona-selector.html')
})
export class PersonaSelectorComponent {
  personas = [];
  campaignId = '';

  constructor(bsModalRef: BsModalRef, bsModalService: BsModalService, personaService: PersonaService) {
    this.bsModalRef = bsModalRef;
    this.bsModalService = bsModalService;
    this.personaService = personaService;
  }

  ngOnInit() {
    this.alerts = [];
    this.searchString = '';
    this.personasFiltered = cloneDeep(this.personas);

    this.numberSelected = 0;
    this.personas.forEach(persona => {
      if(persona.isSelected) this.numberSelected++;
    });
  }

  filterPersonas() {
    var searchString = this.searchString.toLowerCase();
    function searchFilter(persona) {
      return persona.name && persona.name.toLowerCase().indexOf(searchString) > -1;
    }
    this.personasFiltered = cloneDeep(this.personas.filter(searchFilter));
  }

  togglePersona(selectedPersona) {
    this.alerts = [];
    selectedPersona.isSelected = !selectedPersona.isSelected;
    // // Only allow 8 to be selected at a time
    if(this.numberSelected >= 8 && selectedPersona.isSelected) {
      selectedPersona.isSelected = false;
      this.alerts.push({ type: 'warning', msg: 'There are already 8 personas selected. Uncheck a different persona to select this one.' });
    }
    else {
      this.personas.forEach(persona => {
        if(persona.personaId == selectedPersona.personaId) {
          persona.isSelected = !persona.isSelected;
        }
      });

      if(selectedPersona.isSelected) {
        this.numberSelected++;
      }
      else {
        this.numberSelected--;
      }
    }
  }

  ok() {
    this.okClicked = true;

    var selectedIds = [];
    this.personas.forEach(persona => {
      if(persona.isSelected) {
        selectedIds.push(persona.personaId);
      }
    });
    this.personaService.saveSelectedPersonasForCampaign(selectedIds, this.campaignId).then(() => {
      console.log('Successfully saved selected personas');
      this.bsModalRef.hide();
    },
    err => {
      console.log('Error saving selected personas', err);
      this.bsModalRef.hide();
    });
  }

  cancel() {
    this.okClicked = false;
    this.bsModalRef.hide();
  }
}
