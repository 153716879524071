import { Component, ElementRef, HostListener, Input, SimpleChanges, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'viz-line-and-bar-chart',
  template: '<div></div>'
})
export class LineAndBarChartComponent implements OnInit {
  @Input() data;
  @Input() height;

  @HostListener('window:resize', ['$event'])
  onResize() {
    //If not vislble, don't resize.
    if(!(this.htmlElement.offsetParent !== null)) return;

    this.width = this.htmlElement.parentElement.clientWidth - 50;

    //Add margin - for width only.
    this.marginWidth = +this.width - this.margin.left - this.margin.right;

    this.svg.attr('width', this.width);
    this.x = d3
      .scaleBand()
      .range([this.xOffset, this.marginWidth])
      .padding(0.3);
    this.render(this.data);
  }

  htmlElement: HTMLElement;
  svg;

  static parameters = [ElementRef];
  constructor(element: ElementRef) {
    this.htmlElement = element.nativeElement;
    this.host = d3.select(element.nativeElement);
  }

  ngOnInit() {
    this.valueline;
    this.q = d3.quadtree();

    this.width = this.htmlElement.parentElement.clientWidth - 50;
    this.margin = { top: 10, left: 30, bottom: 40, right: 60 };
    this.xOffset = 35;

    //Create the svg using width 100% to fill the container.
    this.svg = this.host
      .append('svg')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
      .attr('height', this.height ? this.height : '170')
      .attr('width', this.width)
      .attr('id', 'frequency_lineAndBarChart');

    this.g = this.svg.append('g').attr('transform', `translate(${this.margin.left},${this.margin.top})`);

    // Tooltip div
    this.div = this.host
      .append('div')
      .attr('class', 'chart-tooltip')
      .style('opacity', 0);

    // Set up chart
    this.width = +this.svg.attr('width') - this.margin.left - this.margin.right;
    this.height = +this.svg.attr('height') - this.margin.top - this.margin.bottom;

    this.x = d3
      .scaleBand()
      .range([this.xOffset, this.width])
      .padding(0.3);
    this.y = d3.scaleLinear().range([this.height, 10]);
    this.y2 = d3.scaleLinear().range([this.height, 10]);

    this.render(this.data);
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'data' && this.data && this.g) {
        this.render(this.data);
      }
    }
  }

  render(data) {
    if(!data) return;
    this.g.selectAll('*').remove();
    this.svg.selectAll('.verticalLines').remove();
    this.svg.selectAll('.whiskers').remove();

    // Scale the range of the data in the domains
    this.x.domain(data.rateData.map(d => d.sendsPerContactPerWeek));

    var rateMax = d3.max(data.rateData, d => d[data.selectedRate].credibleIntervalMax);
    if(d3.max(data.rateData, d => d[data.selectedRate].rate) > rateMax) {
      rateMax = d3.max(data.rateData, d => d[data.selectedRate].rate);
    }

    //Round y domains to nearest 10 and nearest 1
    this.y.domain([0, Math.ceil(rateMax / 0.1) * 0.1]);
    var unsubscribeMax = d3.max(data.rateData, d => d.unsubscribe.credibleIntervalMax);
    this.y2.domain([0, Math.ceil(unsubscribeMax / 0.01) * 0.01]);

    // append the rectangles for the bar chart
    this.g
      .selectAll('.frequency-bar')
      .data(data.rateData)
      .enter()
      .append('rect')
      .attr('class', 'frequency-bar')
      .attr('fill', d => {
        var interval = d[data.selectedRate].credibleIntervalMax - d[data.selectedRate].credibleIntervalMin;
        if(interval < 0.01) return data.colors[0];
        else if(interval < 0.05) return data.colors[1];
        else if(interval < 0.1) return data.colors[2];
        else if(interval < 0.15) return data.colors[3];
        else return data.colors[4];
      })
      .attr('x', d => this.x(d.sendsPerContactPerWeek))
      .attr('width', this.x.bandwidth())
      .attr('y', d => this.y(d[data.selectedRate].rate))
      .attr('height', d => this.height - this.y(d[data.selectedRate].rate))
      .on('mouseover', d => {
        this.hideTooltip();
        this.showTooltip(d);
      })
      .on('mouseout', () => {
        this.hideTooltip();
        this.hideDot();
      });

    // Add unsubscribe line
    if(this.data.showUnsubscribes) {
      // Define the line
      var valueLine = d3
        .line()
        .x(d => this.x(d.sendsPerContactPerWeek))
        .y(d => this.y2(d.unsubscribe.rate));

      var area = d3
        .area()
        .x(d => this.x(d.sendsPerContactPerWeek))
        .y0(d => this.y2(d.unsubscribe.credibleIntervalMin >= 0 ? d.unsubscribe.credibleIntervalMin : 0))
        .y1(d => this.y2(d.unsubscribe.credibleIntervalMax));

      // Only show results with rate data in unsubscribe line
      var unsubscribeData = [];
      this.data.rateData.forEach(d => {
        if(d[this.data.selectedRate].rate !== null) {
          unsubscribeData.push(d);
        }
      });

      //draw uncertainty
      this.g
        .append('path')
        .attr('class', 'frequency-uncertainty')
        .attr('transform', `translate(${this.x.bandwidth() / 2},0)`)
        .datum(() => unsubscribeData)
        .attr('fill', '#EA5048')
        .attr('opacity', 0.35)
        .attr('d', area)
        .on('mouseover', () => {
          // get mouse position to show tooltip
          var mouseXy = d3.mouse(d3.event.target);
          var dot = this.q.find(mouseXy[0], mouseXy[1]);

          this.showTooltip(dot[2]);
          this.highlightDot(Math.floor(this.x(dot[2].sendsPerContactPerWeek)), Math.floor(this.y2(dot[2].unsubscribe.rate)));

          this.g.select('.frequency-uncertainty').attr('opacity', 0.5);
          this.g.selectAll('.frequency-bar').attr('opacity', 0.5);
        })
        .on('mouseout', () => {
          this.hideTooltip();
          this.hideDot();
          this.g.select('.frequency-uncertainty').attr('opacity', 0.35);
          this.g.selectAll('.frequency-bar').attr('opacity', 1);
        });

      //draw line
      this.g
        .append('path')
        .attr('transform', `translate(${this.x.bandwidth() / 2},0)`)
        .data([unsubscribeData])
        .attr('class', 'line')
        .attr('stroke', '#EA5048')
        .attr('d', valueLine)
        .attr('cursor', 'pointer')
        .on('mouseover', () => {
          // get mouse position to show tooltip
          var mouseXy = d3.mouse(d3.event.target);
          var dot = this.q.find(mouseXy[0], mouseXy[1]);

          this.showTooltip(dot[2]);
          this.highlightDot(Math.floor(this.x(dot[2].sendsPerContactPerWeek)), Math.floor(this.y2(dot[2].unsubscribe.rate)));

          this.g.select('.frequency-uncertainty').attr('opacity', 0.5);
          this.g.selectAll('.frequency-bar').attr('opacity', 0.5);
        })
        .on('mouseout', () => {
          this.hideTooltip();
          this.hideDot();
          this.g.select('.frequency-uncertainty').attr('opacity', 0.35);
          this.g.selectAll('.frequency-bar').attr('opacity', 1);
        });

      //draw dots
      this.g
        .selectAll('dot')
        .data(unsubscribeData)
        .enter()
        .append('circle')
        .attr('class', 'unsubscribe-line-dot')
        .attr('r', () => 1)
        .attr('cx', d => this.x(d.sendsPerContactPerWeek) + this.x.bandwidth() / 2)
        .attr('cy', d => this.y2(d.unsubscribe.rate))
        .attr('fill', '#EA5048')
        .attr('id', d => `unsubscribeDot_${Math.floor(this.x(d.sendsPerContactPerWeek))}_${Math.floor(this.y2(d.unsubscribe.rate))}`)
        // .attr('lineId', `dot_${id}`)
        .attr('opacity', () => 0.9)
        .attr('cursor', 'pointer')
        .on('mouseover', d => {
          this.highlightDot(Math.floor(this.x(d.sendsPerContactPerWeek)), Math.floor(this.y2(d.unsubscribe.rate)));
          this.showTooltip(d);
        });

      // go through unsubscribe series, get x,y and add to quadtree
      unsubscribeData.forEach(d => {
        var xVal = Math.floor(this.x(d.sendsPerContactPerWeek));
        var yVal = Math.floor(this.y2(d.unsubscribe.rate));

        // add this point to quadtree, so we can find it with UI
        this.q.add([xVal, yVal, d]);
      });
    } //end: addUnsubscribes -----------

    //Draw the whiskers
    this.svg
      .append('g')
      .selectAll('.verticalLines')
      .data(data.rateData)
      .enter()
      .append('line')
      .attr('class', 'verticalLines')
      .attr('x1', d => this.x(d.sendsPerContactPerWeek) + this.x.bandwidth() / 2 + this.margin.left)
      .attr('y1', d => this.y(d[data.selectedRate].credibleIntervalMin >= 0 ? d[data.selectedRate].credibleIntervalMin : 0) + this.margin.top)
      .attr('x2', d => this.x(d.sendsPerContactPerWeek) + this.x.bandwidth() / 2 + this.margin.left)
      .attr('y2', d => this.y(d[data.selectedRate].credibleIntervalMax) + this.margin.top)
      .attr('stroke', '#888')
      .attr('stroke-width', 1)
      .attr('fill', 'none');

    //Draw top line
    this.svg
      .append('g')
      .selectAll('.whiskers')
      .data(data.rateData)
      .enter()
      .append('line')
      .attr('class', 'whiskers')
      .attr('x1', d => this.x(d.sendsPerContactPerWeek) + this.x.bandwidth() * (2 / 5) + this.margin.left)
      .attr('y1', d => this.y(d[data.selectedRate].credibleIntervalMax) + this.margin.top)
      .attr('x2', d => this.x(d.sendsPerContactPerWeek) + this.x.bandwidth() * (3 / 5) + this.margin.left)
      .attr('y2', d => this.y(d[data.selectedRate].credibleIntervalMax) + this.margin.top)
      .attr('stroke', '#888')
      .attr('stroke-width', 1)
      .attr('fill', 'none');

    //Draw bottom line
    this.svg
      .append('g')
      .selectAll('.whiskers')
      .data(data.rateData)
      .enter()
      .append('line')
      .attr('class', 'whiskers')
      .attr('x1', d => this.x(d.sendsPerContactPerWeek) + this.x.bandwidth() * (2 / 5) + this.margin.left)
      .attr('y1', d => this.y(d[data.selectedRate].credibleIntervalMin >= 0 ? d[data.selectedRate].credibleIntervalMin : 0) + this.margin.top)
      .attr('x2', d => this.x(d.sendsPerContactPerWeek) + this.x.bandwidth() * (3 / 5) + this.margin.left)
      .attr('y2', d => this.y(d[data.selectedRate].credibleIntervalMin >= 0 ? d[data.selectedRate].credibleIntervalMin : 0) + this.margin.top)
      .attr('stroke', '#888')
      .attr('stroke-width', 1)
      .attr('fill', 'none');

    //If data removed, show a notation.
    var missingData = [];
    for(var i = 0; i < data.rateData.length; i++) {
      if(data.rateData[i][data.selectedRate].rate === null) {
        missingData.push(data.rateData[i]); //add null data to missingDAta
      }
    }

    if(missingData.length > 0) {
      // append the rectangles for missing data
      this.g
        .selectAll('.missing-data-bar')
        .data(missingData)
        .enter()
        .append('rect')
        .attr('class', 'missing-data-bar')
        .attr('x', d => this.x(d.sendsPerContactPerWeek))
        .attr('width', this.x.bandwidth())
        .attr('y', () => this.margin.top)
        .attr('height', () => this.height - this.margin.top)
        .style('z-index', 9999);

      // append label for the missing data
      this.g
        .selectAll('.missing-data-label')
        .data(missingData)
        .enter()
        .append('text')
        .attr('transform', d => `translate(${this.x(d.sendsPerContactPerWeek) + this.x.bandwidth() / 2},65)`)
        .attr('class', 'missing-data-label')
        .attr('text-anchor', 'middle')
        .selectAll('tspan')
        .data('No Results'.split(''))
        .enter()
        .append('tspan')
        .attr('x', 0)
        .attr('dy', '1em')
        .text(d => d);
    }

    // add the x Axis
    this.g
      .append('g')
      .attr('transform', `translate(0,${this.height})`)
      .attr('class', 'frequency-axis')
      .call(
        d3
          .axisBottom(this.x)
          .tickSize(0)
          .tickPadding(6)
      );

    // text label for the x axis
    this.g
      .append('text')
      .attr('transform', `translate(${this.width / 2 + 25} ,${this.height + this.margin.top + 25})`)
      .attr('class', 'frequency-axis-label')
      .style('text-anchor', 'middle')
      .text('Sends per contact per week');

    // add the y Axis
    this.g
      .append('g')
      .attr('transform', 'translate(40,0)')
      .attr('class', 'frequency-axis y-axis')
      .call(
        d3
          .axisLeft(this.y)
          .ticks(15)
          .tickFormat(d => {
            // If rate is low, show 1 decimal point
            if(this.rateMax < 0.05) return `${d3.format('.1f')(d * 100)}%`;
            return `${d3.format('.0f')(d * 100)}%`;
          })
      )
      .selectAll('.tick:nth-child(5n + 2)')
      .append('line')
      .attr('stroke', '#777')
      .attr('opacity', '0.2')
      .attr('x1', -6)
      .attr('x2', this.width - this.margin.left - 9);

    // Only show label for every 5th tick
    var yTicks = this.g.selectAll('.y-axis .tick text');
    yTicks.attr('class', function(d, idx) {
      if(idx % 5 != 0) {
        d3.select(this).remove();
      }
    });

    // text label for the y axis
    this.g
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0 - this.margin.left + 5)
      .attr('x', 0 - this.height / 2)
      .attr('dy', '1em')
      .attr('class', 'frequency-axis-label')
      .style('text-anchor', 'middle')
      .text(`Unique ${this.data.selectedRate} rate`);

    // add the unsubscribe y Axis
    this.g
      .append('g')
      .attr('transform', `translate(${this.width},0)`)
      .attr('class', 'frequency-axis y2-axis')
      .call(
        d3
          .axisRight(this.y2)
          .ticks(15)
          .tickFormat(d => {
            // If rate is low, show 1 decimal point
            if(unsubscribeMax < 0.05) return `${d3.format('.1f')(d * 100)}%`;
            return `${d3.format('.0f')(d * 100)}%`;
          })
      );

    // Only show label for every 5th tick
    var y2Ticks = this.g.selectAll('.y2-axis .tick text');

    y2Ticks.attr('class', function(d, idx) {
      if(idx % 5 != 0) {
        d3.select(this).remove();
      }
    });

    // text label for the unsubscribe y axis
    this.g
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', this.width + this.margin.right - 25)
      .attr('x', 0 - this.height / 2)
      .attr('dy', '1em')
      .attr('class', 'frequency-axis-label')
      .style('text-anchor', 'middle')
      .text('Unsubscribe rate');
  } // end: render

  showTooltip(d) {
    this.div
      .transition()
      .duration(600)
      .style('opacity', 1);

    this.div
      .html(
        `<p class="name"><span class="faded vLabel">Sends Per Week:</span> ${d.sendsPerContactPerWeek}</p><hr style="margin-top: 5px"/>`
          + `<p class="result"><span class="faded vLabel">Population:</span>${d.population.toLocaleString('en')}</p>`
          + `<p class="result"><span class="faded vLabel" style="text-transform: capitalize">${this.data.selectedRate} Rate:</span>${(d[this.data.selectedRate].rate * 100).toFixed(1)}%</p>`
          + `<p class="result"><span class="faded vLabel">Unsubscribe Rate:</span>${(d.unsubscribe.rate * 100).toFixed(1)}%</p>`
      )
      .style('left', `${this.x(d.sendsPerContactPerWeek)}px`)
      .style('top', '-60px')
      .style('min-width', '180px');
  }

  hideTooltip() {
    this.div
      .transition()
      .duration(200)
      .style('opacity', 0);
  }

  highlightDot(x, y) {
    d3.selectAll('.unsubscribe-line-dot')
      .attr('r', 1)
      .style('opacity', 0.1);

    d3.select(`[id='unsubscribeDot_${x}_${y}']`)
      .attr('r', 4)
      .style('opacity', 0.9);
  }

  hideDot() {
    d3.selectAll('.unsubscribe-line-dot')
      .attr('r', 1)
      .style('opacity', 0.1);
  }
}
