import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { DirectivesModule } from '../../components/directives.module';

import { AuthGuard } from '../../components/auth/auth-guard.service';
import { EmailDetailsComponent } from './email-details/email-details.component';
import { PerformanceComponent } from './performance.component';
import { PerformanceBenchmarkComponent } from './performance-benchmark/performance-benchmark.component';
import { PerformanceFiltersComponent } from './performance-filters/performance-filters.component';

const performanceRoutes: Routes = [
  {
    path: 'performance',
    component: PerformanceComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    RouterModule.forChild(performanceRoutes),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ButtonsModule.forRoot(),
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    NgxDatatableModule,
    DirectivesModule
  ],
  declarations: [EmailDetailsComponent, PerformanceComponent, PerformanceBenchmarkComponent, PerformanceFiltersComponent],
  entryComponents: [EmailDetailsComponent, PerformanceBenchmarkComponent, PerformanceFiltersComponent],
  exports: [PerformanceComponent]
})
export class PerformanceModule {}
